import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtroEspProfHorario'
})
export class FiltroEspProfHorarioPipe implements PipeTransform {

  transform(objectsArray, especialidade, profissional, horario) {
    // console.log(objectsArray, especialidade, profissional, horario)
    if (!objectsArray) {
      return objectsArray;
    }

    let a = objectsArray.filter(event => {

      let isStartValid = false;
      let isEspecialidadeValid = !especialidade || event.meta.especialidade == especialidade;
      let isProfissionalValid = !profissional || event.meta.profissional == profissional;

      if (!horario || horario == 'todos') {
        isStartValid = true;
      } else {
        const start = new Date(event.start);
        const hour = start.getHours();

        // console.log(start, hour)
        if (horario == "manha" && hour >= 1 && hour <= 11) {
          // console.log(start, hour)
          isStartValid = true;
        } else if (horario == "tarde" && hour >= 12 && hour <= 18) {
          isStartValid = true;
        } else if (horario == "noite" && hour >= 19 && hour <= 23) {
          isStartValid = true;
        }
      }



      return isEspecialidadeValid && isProfissionalValid && isStartValid;
    });
    // console.log(a)
    return a

  }








}



