import { AgendaSliderComponent } from './../components/agenda-slider/agenda-slider.component';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ModalController } from '@ionic/angular';
import { AgendaProfissionalPage } from '../modals/agenda-profissional/agenda-profissional.page';
import Swal from 'sweetalert2'

//import Strapi from 'strapi-sdk-javascript';


@Injectable({
  providedIn: 'root'
})
export class ProfissionaisService {
  strapiApiUrl = environment.strapiApiUrl
  refeshProfissionais$ = new BehaviorSubject<boolean>(true);


  constructor(private http: HttpClient,
    private modalCtrl: ModalController,
  ) { }

  createProfissional(ProfissionalData: any) {    

    return this.http.post<any>(this.strapiApiUrl + '/profissionals', ProfissionalData)
  }

  updateProfissional(ProfissionalData: any, profissionalId: string) {

    console.log('PROFISSIONAL DATA',ProfissionalData)

    for(let i=0; ProfissionalData?.data?.programacao?.janela_disponibilidade?.length > i;i++){
      ProfissionalData.data.programacao.janela_disponibilidade[i].valor =  + ((ProfissionalData?.data?.programacao?.janela_disponibilidade[i]?.valor).toString()).replace(',','.')
    }

    return this.http.put<any>(this.strapiApiUrl + '/profissionals/' + profissionalId, ProfissionalData)
  }

  getProfissionalByEmail(negocio_id:number, email:string){

    return this.http.get<any>(this.strapiApiUrl + '/profissionals?filters[$or][0][perfil_negocio][id]=' + negocio_id + '&filters[$or][1][perfis_negocios_assoc][id]=' + negocio_id + '&filters[$and][2][dados_pessoais][email]=' + email )

  }

  getProfissionais2(negocio_id: number, pagination: number = 0, pageSize: number = 25) {

    return this.http.get<any>(this.strapiApiUrl + '/profissionals?filters[perfil_negocio][id][$eq]=' + negocio_id + '&pagination[start]=' + pagination + '&pagination[limit]=' + pageSize + '&populate=*')
  }

  getProfissionais(negocio_id: number, pagination: number = 0, pageSize: number = 25) {

    return this.http.get<any>(this.strapiApiUrl + '/profissionals?filters[$or][0][perfil_negocio][id]=' + negocio_id + '&filters[$or][1][perfis_negocios_assoc][id]=' + negocio_id + '&pagination[start]=' + pagination + '&pagination[limit]=' + pageSize + '&populate=*')
  }

  getListProfissionais(negocio_id: number) {

    return this.http.get<any>(this.strapiApiUrl + '/profissionals?filters[$or][0][perfil_negocio][id]=' + negocio_id + '&filters[$or][1][perfis_negocios_assoc][id]=' + negocio_id +  '&[populate][0]=dados_pessoais&[populate][1]=avaliations')
  }
  getProfissionaisAgendamentos(negocio_id: number) {

    return this.http.get<any>(this.strapiApiUrl + '/profissionals?filters[$or][0][perfil_negocio][id]=' + negocio_id + '&filters[$or][1][perfis_negocios_assoc][id]=' + negocio_id + '&[populate][0]=agendamentos&[populate][1]=programacao.bloquear_agenda&[populate][2]=programacao.janela_disponibilidade')
  }
  getProfissionalIDAgendamentos(negocio_id: number, ProfissionalId: any) {

    console.log(negocio_id, ProfissionalId)

    return this.http.get<any>(this.strapiApiUrl + '/profissionals?filters[$and][0][perfil_negocio][id][$eq]=' + negocio_id + '&filters[$and][1][id][$eq]=' + ProfissionalId + '&[populate][0]=agendamentos&[populate][1]=programacao.bloquear_agenda&[populate][2]=programacao.janela_disponibilidade&[populate][3]=dados_pessoais&[populate][4]=especialidades_procedimentos')
  }

  getProfissionalID_JanelaDisponibilidade(negocio_id: number, ProfissionalId: any) {

    return this.http.get<any>(this.strapiApiUrl + '/profissionals?filters[$and][0][perfil_negocio][id][$eq]=' + negocio_id + '&filters[$and][1][id][$eq]=' + ProfissionalId + '&[populate][0]=programacao.janela_disponibilidade&[populate][3]=dados_pessoais')
  }

  getProfissionalDetails(ProfissionalId: number) {
    return this.http.get<any>(this.strapiApiUrl + '/profissionals?filters[id][$eq]=' + ProfissionalId + '&[populate][0]=dados_pessoais&[populate][1]=endereco&[populate][2]=especialidades_procedimentos&[populate][3]=avatar')
  }

  getProfissionalDeepDetails(ProfissionalId: number) {
    return this.http.get<any>(this.strapiApiUrl + '/profissionals?filters[id][$eq]=' + ProfissionalId + '&populate=deep')
  }

  getProfissionalEditDetails(ProfissionalId: number) {
    return this.http.get<any>(this.strapiApiUrl + '/profissionals?filters[id][$eq]=' + ProfissionalId + '&[populate][0]=dados_pessoais&[populate][1]=endereco&[populate][2]=especialidades_procedimentos&[populate][3]=programacao&[populate][4]=programacao.bloquear_agenda&[populate][5]=programacao.janela_disponibilidade&[populate][6]=avatar&[populate][7]=automated_tasks.actions_automations&populate[8]=perfil_negocio&populate[9]=perfis_negocios_assoc&[populate][10]=automated_tasks.perfil_negocios')
  }

  getProfissionaisWithEspecialidadeById(ProfissionalId: number) {
    return this.http.get<any>(this.strapiApiUrl + '/profissionals?filters[id][$eq]=' + ProfissionalId + ' &populate[0]=dados_pessoais&populate[1]=agendamentos&populate[2]=programacao.janela_disponibilidade&populate[3]=programacao.bloquear_agenda&populate[4]=programacao')

  }

  getProfissionalDetailsByEspecialidade(especialidade: string, negocio_id) {
    return this.http.get<any>(
      `${this.strapiApiUrl}/profissionals?filters[$or][0][perfil_negocio][id]=${negocio_id} &filters[$or][1][perfis_negocios_assoc][id]=${negocio_id}&filters[$and][1][especialidades_procedimentos][nome][$eq]=${especialidade}&populate[0]=dados_pessoais&populate[1]=agendamentos&populate[2]=programacao.janela_disponibilidade&populate[3]=programacao.bloquear_agenda&populate[4]=programacao&populate[5]=especialidades_procedimentos`
    );
  }

  getProfissionaisList(negocio_id:number){

    return this.http.get<any>(
      `${this.strapiApiUrl}/profissionals?filters[$or][0][perfil_negocio][id]=${negocio_id} &filters[$or][1][perfis_negocios_assoc][id]=${negocio_id}&populate[0]=dados_pessoais`
    ); 

  }

  insertProfissionalRelation(pId:number, relationId:number){

  return this.http.put<any>(`${this.strapiApiUrl}/profissionals/${pId}`, {data:{related_user:relationId}})

  }


  removeProfissionals(profissionalId: number) {
    return this.http.delete(this.strapiApiUrl + '/profissionals/' + profissionalId)
  }

  //Filter Services

  getActiveProfissionals(negocio_id: number) {
    return this.http.get<any>(this.strapiApiUrl + '/profissionals?filters[$and][0][perfil_negocio][id][$eq]=' + negocio_id + '&filters[$and][1][ativo][$eq]=true&populate=*')
  }

  getInativeProfissionalsByDate(negocio_id: number, data_inatividade: Date) {
    return this.http.get<any>(this.strapiApiUrl + '/profissionals?filters[$and][0][perfil_negocio][id][$eq]=' + negocio_id + `&filters[$and][1][inativo_desde][$gte]=` + data_inatividade + '&filters[$and][2][ativo][$eq]=false&populate=*')
  }

  filterProfissionals(negocio_id: number, filter_by: string, filter_key: string) {
    return this.http.get<any>(this.strapiApiUrl + '/profissionals?filters[$and][0][perfil_negocio][id][$eq]=' + negocio_id + `&filters[$and][1][dados_pessoais][${filter_by}][$containsi]=` + filter_key + '&populate=*')
  }



  // findProfissional(especialidade: string, dias: string){
  //   return this.http.get<any>(this.strapiApiUrl + '/profissionals.findProfissional',
  //    {
  //     params: {
  //       especialidade: especialidade ,
  //       dias: dias
  //     }
  //   })
  // }

  findProfissional(especialidade: string, dias: string[]): Observable<any> {
    return this.http.get<any>(`${this.strapiApiUrl}/findProfissional?especialidade=${especialidade}&dias=${dias.join(',')}`);
  }

  uploadImage(formData) {
    return this.http.post<any>(`${this.strapiApiUrl}/upload`,
      formData);
  }
  removeImage(id: number) {
    return this.http.delete(`${this.strapiApiUrl}/upload/files/${id}`);
  }

  async VerAgendaModal(id) {
    const modal = await this.modalCtrl.create({
      component: AgendaProfissionalPage,
      // cssClass: "modalCard-pet-agendamento",
      componentProps: {
        idProfissional: id,

      }
    })

    modal.onDidDismiss()

    await modal.present()
  }

  isProfissionalDataValid(profissionalData: any): { isValid: boolean; invalidField: string | null } {
    const nomeRegex = /^[a-zA-Z\s]*$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


    // const isValidNome = typeof profissionalData.data.dados_pessoais.nome === 'string' && nomeRegex.test(profissionalData.data.dados_pessoais.nome);
    // if (!isValidNome) {
    //   return { isValid: false, invalidField: 'Nome' };
    // }

    // const validSexo = ['feminino', 'masculino', 'naodeclarado'];
    // const isValidSexo = validSexo.includes(profissionalData.data.dados_pessoais.sexo);
    // if (!isValidSexo) {
    //   return { isValid: false, invalidField: 'Sexo' };
    // }

    const isValidCelular = profissionalData.data.dados_pessoais.celular.length === 15;
    if (!isValidCelular) {
      return { isValid: false, invalidField: 'Celular' };
    }

    // const isValidTelefone = profissionalData.data.dados_pessoais.telefone.length === 14;
    // if (!isValidTelefone) {
    //   return { isValid: false, invalidField: 'Telefone' };
    // }

    const isValidEmail = emailRegex.test(profissionalData.data.dados_pessoais.email);
    if (!isValidEmail) {
      return { isValid: false, invalidField: 'Email' };
    }

    // const isValidCPF = profissionalData.data.dados_pessoais.cpf.length === 14 || profissionalData.data.dados_pessoais.cpf.length === 11;
    // if (!isValidCPF) {
    //   return { isValid: false, invalidField: 'CPF' };
    // }

    const isValidCargo = profissionalData.data.dados_pessoais.cargo.length > 1;
    if (!isValidCargo) {
      return { isValid: false, invalidField: 'Cargo' };
    }

    const isValidCRMV = profissionalData.data.dados_pessoais.crmv.length > 1;
    if (!isValidCRMV) {
      return { isValid: false, invalidField: 'CRMV' };
    }

    return { isValid: true, invalidField: null };
  }

  validateJsonData(data: {}): { isValid: boolean; invalidFields: string[] } {
    let invalidFields: string[] = [];

    // Validação  janela_disponibilidade
    data?.['janela_disponibilidade']?.forEach((item, index) => {
      const keys = Object.keys(item);

      const hasNullValue = keys.some(key => {

        if (Array.isArray(item[key]) ? item[key]?.length === 0 : item[key] === null) {
          invalidFields.push(`${key}`);
        }
         console.log(key,item[key], Array.isArray(item[key]) ? item[key]?.length === 0 : item[key] === null)
        // return Array.isArray(item[key]) ? item[key]?.length === 0 : item[key] === null
      });


      if (hasNullValue) {
        keys.forEach(key => {
          if (item[key] === null) {
            invalidFields.push(`${key}`);
          }
        });
      }

      const horarioInicial = new Date(item.horario_inicial);
      const horarioFinal = new Date(item.horario_final);

      if (horarioFinal <= horarioInicial) {
        invalidFields?.push(`Janela N°${index + 1}: Hora Final:${item?.horario_final?.substring(11, 16)} < Hora Inicial: ${item?.horario_inicial?.substring(11, 16)}`);
      }
    });

    // Validação antecedencia_agendamento
    const validAntecedenciaAgendamentoValues = [
      'esta_semana_e_proxima',
      'de_3_semanas',
      'de_4_semanas',
      'de_5_semanas',
      'de_6_semanas',
      'de_7_semanas',
    ];
    if (
      !validAntecedenciaAgendamentoValues.includes(
        data?.['antecedencia_agendamento']
      )
    ) {
      invalidFields.push('Antecedencia Agendamento');
    }

    // Validation for tempo_minimo_aviso
    const validTempoMinimoAvisoValues = [
      'tempo_1h',
      'tempo_6h',
      'tempo_12h',
      'tempo_18h',
      'tempo_24h',
      'tempo_48h',
    ];
    if (!validTempoMinimoAvisoValues.includes(data?.['tempo_minimo_aviso'])) {
      invalidFields.push('Tempo Minimo Aviso');
    }

    // Validation for tipo_avatar
    const validTipoAvatarValues = ['profissional', 'empresa'];
    if (!validTipoAvatarValues.includes(data?.['tipo_avatar'])) {
      invalidFields.push('Avatar');
    }

    if (invalidFields.length > 0) {
      return { isValid: false, invalidFields };
    } else {
      return { isValid: true, invalidFields: [] };
    }
  }






  errorAlert(title: string) {

    Swal.fire({
      position: 'center',
      icon: 'error',
      title: title,
      showConfirmButton: false,
      timer: 3000,
      heightAuto: false
    })

  }

  errorAlert5000(title: string) {

    Swal.fire({
      position: 'center',
      icon: 'error',
      title: 'Erro de validação',
      text: title,
      showConfirmButton: false,
      timer: 5000,
      heightAuto: false
    })

  }


}
