import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removerDupilicadosArray'
})
export class RemoverDupilicadosArrayPipe implements PipeTransform {

  transform(value: any[]): any[] {
    return [...new Set(value)];
  }

}
