import { filter } from 'rxjs/operators';
import { AgendaService } from '../../services/agenda.service';
import { Component, ElementRef, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { Subject, Observable, switchMap, map, BehaviorSubject, takeUntil, takeLast } from 'rxjs';
import { UserdataService } from '../../services/userdata.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dash-board-infos',
  templateUrl: './dash-board-infos.component.html',
  styleUrls: ['./dash-board-infos.component.scss'],
})
export class DashBoardInfosComponent implements OnInit {

  @ViewChild('chart', { static: true }) chart: NgxChartsModule;
  @ViewChild('content', { static: true }) content: ElementRef;
  @ViewChild('ionImgElements', { read: ElementRef }) ionImgElements: ElementRef[];


  selectedButton;


  view = [600, 35];
  // view: any[] = [window.innerWidth, 35];
  //view deve ser um observable para que o grafico se ajuste ao tamanho da tela


  schemeType = 'ordinal';
  colorScheme = {
    domain: ['#FFE718', '#564193', '#FF6D6D', '#FBB824', '#30BEA4', '']
  };

  // grey
  // colorScheme = {
  //   domain: ['#FFE718', '#564193', '#FF6D6D', '#FBB824', '#30BEA4', '#5B61AC']





  PetPlace_PLACEID = "ChIJjWy5Wff3zpQR6zhF36hTCB8";

  visualPerfilPesquisaGoogle = 49920;
  visualPerfilGoogleMaps = 2722.95;
  visualPerfilFarmaciaPerto = 38121;

  acessosPaginaBuscaPaga = 192;
  acessosPaginaBuscaOrganica = 40;
  acessosPaginaTrafegoDireto = 17;
  acessosPaginaFarmaciaPerto = 200;

  contatoWhatsappThresholdConfig: {};

  satistacaoTutorThresholdConfig = {};
  //  = {
  //   '0': { color: '#30BEA4', bgOpacity: 1 },
  //   '75': { color: '#5B61AC', bgOpacity: 1 },
  // };

  chamadasTelefonicasThresholdConfig = {
    '0': { color: '#30BEA4', bgOpacity: 1 },
    '75': { color: '#5B61AC', bgOpacity: 1 },
  };

  comoChegarThresholdConfig =
    {
      '0': { color: '#30BEA4', bgOpacity: 1 },
      '75': { color: '#5B61AC', bgOpacity: 1 },
    };

  pesquisasMostraramPerfilList = [];

  pesquisasMostraramPerfilList_initial_index = 0;

  listaFarmacias = [];

  refreshReports$ = new BehaviorSubject(true);
  reports$ = new Observable<any>();

  Places_MainReport$ = new Observable<any>();
  refreshPlaces$ = new BehaviorSubject(true);
  places: any[];
  keywords: any;
  reportsByKeyword$ = new Observable<any>();
  refreshReportsByKeyword$ = new BehaviorSubject(true);
  keyword$ = new BehaviorSubject(true);
  key: boolean;
  last_Report: any;
  selectedBuzzIndex: string;
  businessId: any;
  refreshAgendamentos$ = new BehaviorSubject(true);
  countAgendamentos$ = new Observable<any>();
  totalAgendamentosPorCanal: {};
  data: any;
  atrpResume: { totalReports: any; totalReports_1a4: any; totalReports_4a10: any; totalReports_10a15: any; totalReports_15mais: any; totalReports_1a4_percent: number; totalReports_4a10_percent: number; totalReports_10a15_percent: number; totalReports_15mais_percent: number; };
  atrpMedio: number;
  lastReports_data_points_resume: any = {}
  place_id: any;
  backUps_length: any;
  reports_updated: any = [];
  backup_localFalcon: { place_id: any; perfil_negocio: any; reports: any; };
  backUp_id: any;
  complete1: boolean;
  complete2: boolean;

  dias = '30';
  NoReportsToshows: boolean;
  Percentual_PeriodoAnterior$ = new Observable<any>();
  Month_Variaton: number;
  backUp$ = new Observable<any>();
  rawImage: any;
  image: File;
  avaliation_data_to_chart: { name: string; value: any; }[];
  face_avaliation: any;
  aceitacaoTutor_percent: number;
  markers: Object;
  satisfactionIconText: string;


  refreshPerfilNegocio$ = new BehaviorSubject(true);
  perfil_negocio$ = new Observable<any>();
  aceitacaoTutor_percent$ = new Observable<any>();

  backupImage_localFalcon = new Subject<any>();
  backUp_image_URL: string;
  showImage: boolean;
  barHorizontalData: { name: string; series: any; }[];

  constructor(
    private dashboardService: DashboardService,
    private AgendaService: AgendaService,
    private userdataService: UserdataService,
    private renderer: Renderer2
  ) {
  }

  getPercentageColorClass(nomes: string): string {
    switch (nomes) {
      case 'E-mail':
        return 'porcentagem color-email';
      case 'Form Landing Page':
        return 'porcentagem color-landing-page';
      case 'Presencial':
        return 'porcentagem color-presencial';
      case 'Telefone':
        return 'porcentagem color-telefone';
      case 'WhatsApp':
        return 'porcentagem color-whatsapp';
      default:
        return '';
    }
  }


  ionViewWillEnter() {


  }


  getLocalFalcon_ImageBackup(name) {

    this.dashboardService.getLocalFalcon_ImagesBackup().subscribe(
      {
        next: (result) => {
          console.log("getLocalFalcon_ImagesBackup: ", result);
          result.filter((image) => {
            return image.name === name;
          }).map((image) => {
            this.dashboardService.getLocalFalcon_ImageBackup_id(image.id).subscribe(
              {
                next: (result) => {
                  console.log("getLocalFalcon_ImageBackup: ", result);
                  this.backUp_image_URL = environment.strapiBaseUrl + result?.url;
                  console.log('this.backUp_image_URL', this.backUp_image_URL)
                },
                error: (error) => {
                  console.log("getLocalFalcon_ImageBackup: ", error);
                  return null;
                }
              }
            )
          })

        }
      })
  }



  refreshEvents($event) {
    // console.log("refreshEvents: ", $event?.['detail'].value)
    this.dias = $event?.['detail'].value;
    this.refreshAgendamentos$.next(true)
  }


  getUserPersonalData() { // essa função é chamada no ionwillenter do dashboard.page.ts
    this.selectedBuzzIndex = localStorage.getItem('buzz_index') || '0';
    this.calculateViewSize();
    // console.log('selectedBuzzIndex', this.selectedBuzzIndex)

    this.userdataService.getUserPersonalDataPerfil().subscribe(result => {

      let userPersonalData = result;
      this.businessId = userPersonalData.perfil_negocios[this.selectedBuzzIndex]?.id;
      this.place_id = userPersonalData.perfil_negocios[this.selectedBuzzIndex]?.place_id; //local falcon

      console.log('place_id', this.place_id);

      this.dashboardService.getLocalFalcon_backup(this.place_id).subscribe(
        {
          next: (result) => {
            console.log("getLocalFalcon_backup: ", result);
            this.backUps_length = result?.data[0]?.attributes?.reports?.length || null;
            this.backUp_id = result?.data[0]?.id || null;
          },
          error: (error) => {
            // console.log("getLocalFalcon: ", error);
          }
        }
      )


      this.Percentual_PeriodoAnterior$ = this.refreshAgendamentos$.pipe(
        switchMap(() =>
          this.AgendaService.getAgendamentos_byultimosNdias(this.businessId, 2 * (+ this.dias))),
        map((data) => {
          // variação percentual do total dos ultimos 50% de agendamentos em relação ao total dos 50% anteriore
          let totalConsultas = data.data.filter((agendamento) => {
            return agendamento.attributes.tipo_agendamento === "Consulta" && agendamento.attributes.status !== "Cancelado"
          }
          )
          // console.log("totalConsultas: ", totalConsultas);

          let consultas_by_month = totalConsultas.reduce((acc, agendamento) => {
            let month = new Date(agendamento.attributes.data).getMonth();
            if (month in acc) {
              acc[month].total += 1;
            } else {
              acc[month] = {
                total: 1,
                percent: 0
              };
            }
            return acc;
          }, {});

          // console.log("consultas_by_month: ", consultas_by_month);

          if (this.dias === '30') {
            let consultas_ultimoMes = consultas_by_month[Object.keys(consultas_by_month)[1]]?.total;
            let consultas_mesAnterior = consultas_by_month[Object.keys(consultas_by_month)[0]]?.total;


            return this.Month_Variaton = this.calculatePercentageVariation(consultas_mesAnterior, consultas_ultimoMes);

          } else if (this.dias === '90') {

          }
        })

      );

      this.countAgendamentos$ = this.refreshAgendamentos$.pipe(
        switchMap(() =>
          this.AgendaService.getAgendamentos_byultimosNdias(this.businessId, + this.dias)),
        map((data) => {

          console.log("getAgendamentos: ", data);

          let avaliations = data?.data?.map((agendamento) => {

            return agendamento?.attributes?.avaliation?.data;
          }).filter((avaliation) => {
            return avaliation !== null;
          });

          let avaliation_geral = avaliations?.map((avaliation) => {
            const NOTAS = {
              'excelente': 4,
              'boa': 3,
              'regular': 2,
              'ruim': 0,
              'terrivel': 0
            };

            return NOTAS[avaliation?.attributes?.avaliacao_geral];
          })

          let avaliation_geral_media = avaliation_geral?.reduce((acc, nota) => {
            return acc + nota;
          }, 0) / avaliation_geral?.length;

          console.log("avaliation_geral_media: ", avaliation_geral_media);

          this.aceitacaoTutor_percent = Math.round(avaliation_geral_media * 100 / 4);

          if (this.aceitacaoTutor_percent >= 0 && this.aceitacaoTutor_percent <= 7) {
            this.satistacaoTutorThresholdConfig = {
              '0': { color: '#C72525', bgOpacity: 1 },
              [this.aceitacaoTutor_percent?.toString()]: { color: '#C1C1C1', bgOpacity: 1 },
            };
            this.markers = {
              [this.aceitacaoTutor_percent?.toString()]: { color: '#C72525', size: 8, type: 'triangle' },
            };
            this.face_avaliation = 'assets/icon/avaliacao/terrivel.svg';
            this.satisfactionIconText = 'Terrível'
          } else if (this.aceitacaoTutor_percent > 7 && this.aceitacaoTutor_percent <= 27) {
            this.satistacaoTutorThresholdConfig = {
              '0': { color: '#FF8812', bgOpacity: 1 },
              [this.aceitacaoTutor_percent?.toString()]: { color: '#C1C1C1', bgOpacity: 1 },
            };
            this.markers = {
              [this.aceitacaoTutor_percent?.toString()]: { color: '#FF8812', size: 8, type: 'triangle' },
            };
            this.face_avaliation = 'assets/icon/avaliacao/ruim.svg';
            this.satisfactionIconText = 'Ruim'
          } else if (this.aceitacaoTutor_percent > 27 && this.aceitacaoTutor_percent <= 50) {
            this.satistacaoTutorThresholdConfig = {
              '0': { color: '#FFE718', bgOpacity: 1 },
              [this.aceitacaoTutor_percent?.toString()]: { color: '#C1C1C1', bgOpacity: 1 },
            };
            this.markers = {
              [this.aceitacaoTutor_percent?.toString()]: { color: '#FFE718', size: 8, type: 'triangle' },
            };
            this.face_avaliation = 'assets/icon/avaliacao/regular.svg';
            this.satisfactionIconText = 'Regular'
          } else if (this.aceitacaoTutor_percent > 50 && this.aceitacaoTutor_percent <= 77) {
            this.satistacaoTutorThresholdConfig = {
              '0': { color: '#78D031', bgOpacity: 1 },
              [this.aceitacaoTutor_percent?.toString()]: { color: '#C1C1C1', bgOpacity: 1 },
            };
            this.markers = {
              [this.aceitacaoTutor_percent?.toString()]: { color: '#78D031', size: 8, type: 'triangle' },
            };
            this.face_avaliation = 'assets/icon/avaliacao/boa.svg';
            this.satisfactionIconText = 'Boa'
          } else if (this.aceitacaoTutor_percent > 77 && this.aceitacaoTutor_percent <= 100) {
            this.satistacaoTutorThresholdConfig = {
              '0': { color: '#30BEA4', bgOpacity: 1 },
              [this.aceitacaoTutor_percent?.toString()]: { color: '#C1C1C1', bgOpacity: 1 },
            };
            this.markers = {
              [this.aceitacaoTutor_percent?.toString()]: { color: '#30BEA4', size: 8, type: 'triangle' },
            };
            this.face_avaliation = 'assets/icon/avaliacao/excelente.svg';
            this.satisfactionIconText = 'Excelente'
          }

          console.log("aceitacaoTutor_percent: ", this.aceitacaoTutor_percent);






          let totalAgendamentos = data.data.filter((agendamento) => {
            return agendamento.attributes.status != "cancelado"
          }
          ).length;

          let totalConsultas = data.data.filter((agendamento) => {
            return agendamento.attributes.tipo_agendamento === "Consulta" && agendamento.attributes.status !== "cancelado"
          }
          ).length;

          let totalRetornos = data.data.filter((agendamento) => {
            return agendamento.attributes.tipo_agendamento === "Retorno" && agendamento.attributes.status !== "cancelado"
          }
          ).length;

          let totalCancelados = data.data.filter((agendamento) => {
            return agendamento.attributes.status === "cancelado"
          }
          ).length;


          this.totalAgendamentosPorCanal = data.data.reduce((acc, agendamento) => {
            if (agendamento.attributes.canal_agendamento in acc) {
              acc[agendamento.attributes.canal_agendamento].total += 1;
            } else {
              acc[agendamento.attributes.canal_agendamento] = {
                total: 1,
                percent: 0
              };
            }
            return acc;
          }, {});

          for (let canal in this.totalAgendamentosPorCanal) {
            this.totalAgendamentosPorCanal[canal].percent = Math.round((this.totalAgendamentosPorCanal[canal].total / totalAgendamentos) * 100);
          }

          // console.log("totalAgendamentosPorCanal: ", this.totalAgendamentosPorCanal);


          this.totalAgendamentosPorCanal['canais'] = Object.keys(this.totalAgendamentosPorCanal).sort((a, b) => {
            return a.localeCompare(b);
          });

          let dados = this.totalAgendamentosPorCanal?.['canais'].map(canal => ({
            name: canal,
            value: this.totalAgendamentosPorCanal[canal].total
          }));

          dados.sort((a, b) => {
            return a.name.localeCompare(b.name); // ordena os dados por nome
          });


          this.barHorizontalData = [
            {
              name: "",
              series: dados,
            },
          ]

          console.log("barHorizontalData: ", this.barHorizontalData);


          this.colorScheme = {
            domain: dados.map((nomes) => {
              switch (nomes.name) {
                case 'E-mail':
                  return '#FFE718';
                case 'Form Landing Page':
                  return '#564193';
                case 'Presencial':
                  return '#FF6D6D';
                case 'Telefone':
                  return '#FBB824';
                case 'WhatsApp':
                  return '#30BEA4';
                default:
                  return '';
              }
            })
          };




          const GRAFICO_AGENDAMENTOS = {
            totalAgendamentos: totalAgendamentos,
            totalConsultas: totalConsultas,
            totalRetornos: totalRetornos,
            totalCancelados: totalCancelados,
            RetornoPercent: (totalRetornos / totalAgendamentos) * 100,
            RetornoPercent_string: Math.round((totalRetornos / totalAgendamentos) * 100).toString(),
            ConsultaPercent: (totalConsultas / totalAgendamentos) * 100,
            ConsultaPercent_string: Math.round((totalConsultas / totalAgendamentos) * 100).toString(),
            CanceladosPercent: (totalCancelados / totalAgendamentos) * 100,
          }
          // console.log("totalAgendamentos: ", GRAFICO_AGENDAMENTOS);
          this.contatoWhatsappThresholdConfig = {
            '0': { color: '#30BEA4', bgOpacity: 1 }, //novos
            [GRAFICO_AGENDAMENTOS.ConsultaPercent_string]: { color: '#5B61AC', bgOpacity: 1 },
          };

          return GRAFICO_AGENDAMENTOS
        }),

      );

      this.aceitacaoTutor_percent$ = this.refreshAgendamentos$.pipe(
        switchMap(() =>
          this.AgendaService.getAgendamentos_to_avaliations(this.businessId)),
        map((data) => {

          console.log("getAgendamentos: ", data);

          let avaliations = data?.data?.map((agendamento) => {

            return agendamento?.attributes?.avaliation?.data;
          }).filter((avaliation) => {
            return avaliation !== null;
          });

          let avaliation_geral = avaliations?.map((avaliation) => {
            const NOTAS = {
              'excelente': 4,
              'boa': 3,
              'regular': 2,
              'ruim': 0,
              'terrivel': 0
            };

            return NOTAS[avaliation?.attributes?.avaliacao_geral];
          })

          let avaliation_geral_media = avaliation_geral?.reduce((acc, nota) => {
            return acc + nota;
          }, 0) / avaliation_geral?.length;

          console.log("avaliation_geral_media: ", avaliation_geral_media);

          this.aceitacaoTutor_percent = Math.round(avaliation_geral_media * 100 / 4);

          if (this.aceitacaoTutor_percent >= 0 && this.aceitacaoTutor_percent <= 7) {
            this.satistacaoTutorThresholdConfig = {
              '0': { color: '#C72525', bgOpacity: 1 },
              [this.aceitacaoTutor_percent?.toString()]: { color: '#C1C1C1', bgOpacity: 1 },
            };
            this.markers = {
              [this.aceitacaoTutor_percent?.toString()]: { color: '#C72525', size: 8, type: 'triangle' },
            };
            this.face_avaliation = 'assets/icon/avaliacao/terrivel.svg';
          } else if (this.aceitacaoTutor_percent > 7 && this.aceitacaoTutor_percent <= 27) {
            this.satistacaoTutorThresholdConfig = {
              '0': { color: '#FF8812', bgOpacity: 1 },
              [this.aceitacaoTutor_percent?.toString()]: { color: '#C1C1C1', bgOpacity: 1 },
            };
            this.markers = {
              [this.aceitacaoTutor_percent?.toString()]: { color: '#FF8812', size: 8, type: 'triangle' },
            };
            this.face_avaliation = 'assets/icon/avaliacao/ruim.svg';
          } else if (this.aceitacaoTutor_percent > 27 && this.aceitacaoTutor_percent <= 50) {
            this.satistacaoTutorThresholdConfig = {
              '0': { color: '#FFE718', bgOpacity: 1 },
              [this.aceitacaoTutor_percent?.toString()]: { color: '#C1C1C1', bgOpacity: 1 },
            };
            this.markers = {
              [this.aceitacaoTutor_percent?.toString()]: { color: '#FFE718', size: 8, type: 'triangle' },
            };
            this.face_avaliation = 'assets/icon/avaliacao/regular.svg';
          } else if (this.aceitacaoTutor_percent > 50 && this.aceitacaoTutor_percent <= 77) {
            this.satistacaoTutorThresholdConfig = {
              '0': { color: '#78D031', bgOpacity: 1 },
              [this.aceitacaoTutor_percent?.toString()]: { color: '#C1C1C1', bgOpacity: 1 },
            };
            this.markers = {
              [this.aceitacaoTutor_percent?.toString()]: { color: '#78D031', size: 8, type: 'triangle' },
            };
            this.face_avaliation = 'assets/icon/avaliacao/boa.svg';
          } else if (this.aceitacaoTutor_percent > 77 && this.aceitacaoTutor_percent <= 100) {
            this.satistacaoTutorThresholdConfig = {
              '0': { color: '#30BEA4', bgOpacity: 1 },
              [this.aceitacaoTutor_percent?.toString()]: { color: '#C1C1C1', bgOpacity: 1 },
            };
            this.markers = {
              [this.aceitacaoTutor_percent?.toString()]: { color: '#30BEA4', size: 8, type: 'triangle' },
            };
            this.face_avaliation = 'assets/icon/avaliacao/excelente.svg';
          }
          console.log("aceitacaoTutor_percent: ", this.aceitacaoTutor_percent);
          return this.aceitacaoTutor_percent;

        }),

      );

      this.reports$ = this.refreshReports$.pipe(
        switchMap(() =>
          this.dashboardService.getReports_place_id(this.place_id)),
        map(
          (result) => {
            console.log("getReports_place_id: ", result);

            // if (!result?.data?.reports?.length) {
            //   console.log('NoReportsToshows');
            //   this.NoReportsToshows = true;
            //   return;
            // }

            var RESULT = result?.data?.reports || result; // dados do local falcon || dados do local falcon_backup
            console.log('RESULT>>>>>>>>>>>>>>>>>>>', RESULT)
            let totalReports = RESULT?.length;


            if (this.backUps_length === 0 || this.backUps_length === null) { // se nao houver backup, criar um backup
              console.log('backUps_length>>>>>>>>>>', this.backUps_length)


              RESULT?.map( // para cada report, criamos um objeto com os dados do report
                (report, index) => {
                  this.reports_updated[index] = {
                    data: { ...report },
                    place_id: this.place_id,
                    report_key: report.report_key,
                    keyword: report.keyword,
                  }
                  this.dashboardService.getSpecificScanReport(this.place_id, report?.report_key).subscribe( // para cada report, pegamos os dados completos do report, como data_points e places e adicionamos ao objeto
                    {
                      next: (result) => {
                        this.reports_updated[index].data_points = result?.data?.data_points?.map( // reduzimos o tamanho do objeto data_points, pegando apenas rank e place_id
                          (data_point) => {
                            return {
                              rank: +data_point.rank === 0 ? 20 : +data_point?.rank,
                              lat: data_point?.lat,
                              lng: data_point?.lng,
                            }
                          }
                        );
                        this.reports_updated[index].places = { ...result?.places }; // adicionamos o objeto places ao objeto report
                        this.complete1 = true;
                        return result
                      },
                      error: (error) => {
                        // console.log("getSpecificScanReport: ", error);
                        return null;
                      }
                    }
                  );
                })

              this.backup_localFalcon = {
                place_id: this.place_id,
                perfil_negocio: this.businessId,
                reports: this.reports_updated
              }
              // console.log("backup_localFalcon: ", this.backup_localFalcon, this.complete1, this.complete2);
              setTimeout(() => { // esperamos 5 segundos para que o objeto reports_updated seja preenchido com os dados completos dos reports
                this.dashboardService.postLocalFalcon_backup(this.backup_localFalcon).subscribe(
                  {
                    next: (result) => {
                      console.log("postLocalFalcon: ", result);
                    },
                    error: (error) => {
                      console.log("postLocalFalcon: ", error);
                    }
                  }
                );
              }, 5000);

            }
            else if (totalReports > this.backUps_length) { // se o numero de reports for maior que o numero de reports no backup, atualize o backup
              var RESULT = result?.data?.reports || result;
              // console.log("RESULT: ", RESULT);
              RESULT?.map(
                (report, index) => {
                  this.reports_updated[index] = {
                    data: { ...report },
                    place_id: this.place_id,
                    report_key: report.report_key,
                    keyword: report.keyword,
                  }
                  var reports_keys = RESULT?.map((report) => {
                    return report.report_key;
                  });
                  this.dashboardService.getSpecificScanReport(this.place_id, report.report_key).subscribe(
                    {
                      next: (result) => {
                        // console.log("result>>>>>>>>>: ", result);
                        this.reports_updated[index].data_points = result?.data?.data_points?.[0]?.results?.map(
                          (data_point) => {
                            return {
                              rank: data_point?.rank,
                              place_id: data_point?.place_id,
                            }
                          }
                        );
                        this.reports_updated[index].places = result.places;
                        // console.log("this.reports_updated: ", this.reports_updated);
                        return result.data.data_points;
                      },
                      error: (error) => {
                        // console.log("getSpecificScanReport: ", error);
                        return null;
                      }
                    }
                  );
                  // this.reports_updated[index].places = Object.values(this.reports_updated[index]?.places)?.slice(0, 20);
                }
              )
              this.backup_localFalcon = {
                place_id: this.place_id,
                perfil_negocio: this.businessId,
                reports: this.reports_updated
              }
              this.dashboardService.putLocalFalcon_backup(this.backUp_id, this.backup_localFalcon).subscribe(
                {
                  next: (result) => {
                    console.log("postLocalFalcon: ", result);
                  },
                  error: (error) => {
                    console.log("postLocalFalcon: ", error);
                  }
                }
              );
            }

            this.keywords = RESULT?.map((report) => {
              return report?.keyword;
            }).filter((keyword, index, array) => {
              return array?.indexOf(keyword) === index;
            });
            this.keyword$?.next(this.keywords?.[0]);
            return RESULT;
          }
        ),
      )

      this.reportsByKeyword$ = this.keyword$.pipe(
        switchMap((keyword) => {
          console.log(keyword)
          this.key = keyword;
          return this.dashboardService.getScanReport_keyword(keyword, this.place_id)
        }),
        map((data) => {
          console.log("getScanReport_keyword>>>>>>>>>: ", data);
          return data?.data?.reports?.map(
            (report) => {
              if (report?.keyword === this.key) {
                return report
              }
            }).filter((report) => { return report !== undefined && report !== null; })

            ||

            data?.map(
              (report) => {
                if (report?.keyword === this.key) {
                  return report
                }
              }).filter((report) => { return report !== undefined && report !== null; })
        }),
        map((data1) => {
          console.log("getScanReport_keyword>>>>>>>>>data1: ", data1);

          this.last_Report = data1[0]?.data || data1[0];
          console.log("last_Report: ", this.last_Report);

          this.Places_MainReport$ = this.refreshPlaces$.pipe(
            switchMap(() =>
              this.dashboardService.getSpecificScanReport(this.place_id, this.last_Report?.report_key)),
            map((report) => {
              console.log("getSpecificScanReport-------report: ", report);

              if (report?.data) {
                 report.places = report?.data?.places;
                var report_sanitized =
                  [report?.data].map((report) => {
                    //console.log("getSpecificScanReport-------report$: ", Object?.keys(report)?.length);
                    console.log("getSpecificScanReport-------report$: ", report);
                    if (report !== undefined && report !== null) {
                      if (Object?.keys(report)?.length > 0) {
                        return report
                      }
                    } else {
                      return null
                    }
                  }).filter((report) => { return report !== undefined && report !== null })

              } else {
                report_sanitized =
                  report?.map((report) => {
                    //console.log("getSpecificScanReport-------report$: ", Object?.keys(report)?.length);
                    console.log("getSpecificScanReport-------report$: ", report);
                    if (report !== undefined && report !== null) {
                      if (Object?.keys(report)?.length > 0) {
                        return report
                      }
                    } else {
                      return null
                    }
                  }).filter((report) => { return report !== undefined && report !== null })
              }

              console.log("getSpecificScanReport-------report_sanitized Places_MainReport$: ", report_sanitized);

              if (report_sanitized[0]?.places) {
                this.places = Object.values(report_sanitized[0]?.places);
              } else if (report_sanitized && report_sanitized?.['places']) {
                this.places = Object.values(report_sanitized?.['places']);
              } else {
                this.places = [];
              }

              this.places?.sort((a, b) => {
                return +b.rating - (+a?.rating);
              });

              let DATA_POINTS = report_sanitized?.['data']?.data_points ?? report_sanitized?.[0]?.data_points;

              this.lastReports_data_points_resume = this.calculateDataPoints(DATA_POINTS);

              console.log("DATA_POINTS: ", DATA_POINTS, this.lastReports_data_points_resume);
              // remove places with empty name
              this.places = this.places?.filter((place) => {
                return place.name !== "";
              });
              console.log("this.places: ", this.places);
              this.places = this.places?.slice(0, 20);
              return this.places
            })
          )
          // console.log('data-------------------->>', data1)
          return data1;
        })
      )

    })


  }

  onImageLoad(evt) {
    console.log('onImageLoad: ', evt);
    if (evt.returnValue) {
      this.showImage = true;
    }
  }


  ngOnInit() {
    console.log('ngOnInit');
    // this.getUserPersonalData();
    // this.observeImages();

    // this.getLocalFalcon_ImageBackup('1_160_8116c58d9f15311.png');


  }

  observeImages() {
    const options = {
      root: this.content.nativeElement,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.loadImage(entry.target);
          observer.unobserve(entry.target);
        }
      });
    }, options);

    this.ionImgElements.forEach((imgElement) => {
      observer.observe(imgElement.nativeElement);
    });
  }

  loadImage(imgElement) {
    this.renderer.setAttribute(imgElement, 'src', imgElement.getAttribute('data-src'));
  }


  calculateViewSize() {

    this.view = [window?.innerWidth * 0.3, 35];
  }

  converterData(data: string): string {
    const partes = data.split('/');

    // Verificar se a data está no formato correto
    if (partes?.length !== 3) {
      throw new Error('Formato de data inválido');
    }

    const mes = Number(partes[0]);
    const dia = Number(partes[1]);
    const ano = Number(partes[2]);

    // Array com os nomes dos meses
    const nomesMeses = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];

    // Verificar se os valores são válidos
    if (mes < 1 || mes > 12 || dia < 1 || dia > 31 || isNaN(ano)) {
      throw new Error('Data inválida');
    }

    // Construir a string da data formatada
    const dataFormatada = `${dia} de ${nomesMeses[mes - 1]} de ${ano}`;

    return dataFormatada;
  }

  calculatePercentageVariation(totalConsultas_1: number, totalConsultas_2: number): number {
    const variation = totalConsultas_2 - totalConsultas_1;
    const percentageVariation = (variation / totalConsultas_1) * 100;
    // console.log("percentageVariation>>>>>>>>>>: ", percentageVariation);
    return percentageVariation;
  }

  uploadImage(imageUrl: string, filename: string, refId: string) {
    this.dashboardService.getData(imageUrl)
      .subscribe(
        imgData => {
          this.data = imgData



          this.rawImage = this.data;
          const imageBlob = this.dataURItoBlob(this.rawImage);
          this.image = new File([imageBlob], filename, { type: 'image/jpg' });
          ///start

          let ref = "api::local-falcon.local-falcon";


          if (this.image) {
            var formData = new FormData();
            formData.append('files', this.image);
            formData.append('ref', ref);
            formData.append('refId', refId);
            formData.append('field', 'imagem');
            console.log('formData', formData)

          }


          // if (formData) {

          //   console.log('formData', formData)
          //   // this.dashboardService.uploadImage(formData).subscribe(
          //   //   {
          //   //     next: (result) => {
          //   //       console.log("uploadImage: ", result);
          //   //     },
          //   //     error: (error) => {
          //   //       console.log("uploadImage: ", error);
          //   //     }
          //   //   }
          //   // );
          // }



          ///end
        },
        err => console.log(err)
      );
  }

  dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob(
      [new Uint8Array(array)],
      {
        type: 'image/jpg',
      },
    );
  }

  getGaugeSize() {
    let result = 210;
    let width = window.innerWidth;
    if (width <= 958) {
      result = 170;
    }
    return result;
  }

  getNgxchartsbarSize() {
    let result = 600;
    let width = window.innerWidth;
    if (width <= 958) {
      result = 400;
    }
    return result;
  }

  getScanReport(keyword) {
    this.keyword$.next(keyword);
    // this.refreshReportsByKeyword$.next(true);
  }

  calculateDataPoints(DATA_POINTS) {
    let dataPointCounters = {
      '1a3': 0,
      '3a10': 0,
      '10a15': 0,
      '15mais': 0
    };

    DATA_POINTS?.forEach(data_point => {
      let rank = data_point?.rank === '' ? 20 : +data_point?.rank;
      if (rank >= 1 && rank <= 3) dataPointCounters['1a3']++;
      if (rank > 3 && rank <= 10) dataPointCounters['3a10']++;
      if (rank > 10 && rank <= 15) dataPointCounters['10a15']++;
      if (rank > 15) dataPointCounters['15mais']++;
    });

    let totalDataPoints = DATA_POINTS?.length;
    let resume = { totalDataPoints };

    for (let range in dataPointCounters) {
      let count = dataPointCounters[range];
      let percent = Math.round((count / totalDataPoints) * 100);
      resume[`totalDataPoints_${range}`] = count;
      resume[`totalDataPoints_${range}_percent`] = percent;
    }
    return resume;
  }




}
