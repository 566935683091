import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { User } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class UserdataService {

  strapiApiUrl = environment.strapiApiUrl

  constructor(private http: HttpClient) { }

  getUserPersonalData() {

    return this.http.get<any>(this.strapiApiUrl + '/users/me?populate[0]=*&populate[1]=perfil_negocios.grupo_de_negocios&populate[2]=perfil_negocios.avatar&populate[3]=profissional&populate[4]=dados_pessoais&populate[5]=laboratorio')

  }

  getUsers(negocio_id:number){
    return this.http.get<any>(this.strapiApiUrl + '/users?filters[perfil_negocios][id][$eq]=' + negocio_id +'&pagination[limit]=-1&sort=dados_pessoais.nome:ASC&populate=*')
  }

  getUserPersonalDataPerfil() {

    return this.http.get<any>(this.strapiApiUrl + '/users/me?&populate[0]=perfil_negocios')

  }

  getUserPersonalDataDeep() {

    return this.http.get<any>(this.strapiApiUrl + '/users/me?&populate[0]=perfil_negocios&populate[1]=perfil_negocios.avatar&populate[2]=dados_pessoais&populate[3]=avatar&populate[4]=perfil_negocios.grupo_de_negocios')

  }

  getUserFunction(){
    return this.http.get<any>(this.strapiApiUrl + '/users/me?&populate[0]=function&populate[1]=dados_pessoais&populate[2]=perfil_negocios')
  }

  getUserData(id: string) {
    return this.http.get<any>(this.strapiApiUrl + '/users/' + id + '?populate=*')
  }

  removeUser(id: number){
    return this.http.delete(this.strapiApiUrl + '/users/' + id)
  }

  populateUserData() {
    return this.http.get<any>(this.strapiApiUrl + '/users?populate=*')
  }

  createBusinessProfile(dataObject: Object) {
    return this.http.post<any>(this.strapiApiUrl + '/perfil-negocios', dataObject)
  }

  updateBusinessProfile(dataObject: Object, buzId: number) {
    return this.http.put<any>(this.strapiApiUrl + '/perfil-negocios/' + buzId, dataObject)
  }
  updateUserProfile(dataObject: Object) {
    return this.http.put<any>(this.strapiApiUrl + '/user/me', dataObject)
  }

  updateUser(dataObject: Object, id:any) {
    return this.http.put<any>(this.strapiApiUrl + '/users/' + id, dataObject)
  }

  uploadImage(formData) {
    return this.http.post<any>(`${this.strapiApiUrl}/upload`,
      formData, { reportProgress: true, observe: 'events' });
  }
  removeImage(id: number) {
    return this.http.delete(`${this.strapiApiUrl}/upload/files/${id}`);
  }

  getPerfil_negocios(selectedBuzzIndex): Observable<any> {
    return this.getUserPersonalData()
      .pipe(map(result => {
        const userData = result;
        const perfilNegocio = userData?.perfil_negocios[selectedBuzzIndex]?.id;
        return { userData, perfilNegocio };
      }));
  }

}
