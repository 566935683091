import { IPet, ITutor } from './../../interfaces/interfaces';
import { Component, ElementRef, EventEmitter, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, PopoverController } from '@ionic/angular';
import { NgForm } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';
import { TutorService } from 'src/app/services/tutor.service';
import { TutoresService } from 'src/app/services/tutores.service';
import {UserdataService} from 'src/app/services/userdata.service'
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import {environment} from 'src/environments/environment';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { LoaderService } from 'src/app/services/loader.service';
import { LoadingController } from '@ionic/angular';
import {LogsService} from 'src/app/services/logs.service'

@Component({
  selector: 'app-novo-pet',
  templateUrl: './novo-pet.page.html',
  styleUrls: ['./novo-pet.page.scss'],
  providers: [DatePipe]
})
export class NovoPetPage implements OnInit {

  @Output() Novo_PetObject_Output = new EventEmitter<any>();

  @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef;
  files  = [];
  userdata: any = [];
  imageUrl:string;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imagename: File;
  image: File;
  imageselect : boolean;
  imagePath:any = null;
  isPopoverOpen:Boolean = false;
  createdPetID:any;
  

  strapiUrl = environment.strapiBaseUrl;


  form: FormGroup;
  selectedFile: File = null;
  mode: string;

  petID: string;
  tutorID:any;

  pets$: Observable<any[]>;
  pets: any;

  today = new Date;
  petDetails:any = [];

  especiesList:any = [];
  tutorId:any
  

  //Variables to bind in NGMODEL field values
  input_especies:string;
  input_name:string;
  input_race:string;
  input_birthdate:Date;
  input_genre:string;
  input_size:number;
  input_weight:number;
  input_sterilized:string;
  input_pedigree:string;
  input_pedigree_number:number;
  input_has_chip:string;
  input_observations:string;
  petAvatarId:number;
  userFunction:any;
  selectedBuzzIndex:any;
  perfilNegocio:any;
  removedPet:any = [];


  pet_Object = {}

  currentUser = JSON.parse(localStorage.getItem('currentUser'));


  constructor(
    private formBuilder: FormBuilder,
    public modalController: ModalController,
    private popoverController: PopoverController,
    private utilservice: UtilsService,
    private tutorService: TutorService,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private tutoresService: TutoresService,
    private loaderService: LoaderService,
    private loadingController: LoadingController,
    private _sanitizer: DomSanitizer,
    private userDataservice: UserdataService,
    private logsService: LogsService
  ) { }



  ngOnInit() {

    this.especiesList = this.utilservice.especies;
    this.getUserFunction();

    let selected_Tutor_Id_String =  this.tutorId;
    let has_chip;
    let sterilized;
    let pedigree;

    console.log('TUTOR ID',this.tutorId)

    if(this.input_sterilized === 'Sim'){
      sterilized = true;
    }else{
      sterilized = false;
    }

    if(this.input_has_chip === 'Sim'){
      has_chip = true;
    }else{
      has_chip = false;
    }

    if(this.input_pedigree === 'Sim'){
      pedigree = true;
    }else{
      pedigree = false;
    }



    this.pet_Object = {

      data:{

      nome: this.input_name,
      data_nascimento: this.input_birthdate,
      possuiPedigre: pedigree,
      pedigreeN: this.input_pedigree_number,
      peso: this.input_weight,
      tamanho: this.input_size,
      chip: has_chip,
      castrado: sterilized,
      raca: this.input_race,
      genero: this.input_genre,
      especie: this.input_especies,
      observacao: this.input_observations,
      tutor:+this.tutorId,
      user:this.currentUser?.user?.id

      }            
  
    }
   

    if (this.mode == 'edit') {


        let petDetails$ = this.tutoresService.getPetDetails(+this.petID).subscribe({
          next:(result)=>{

          this.petDetails = result.data[0];

          let has_chip;
          let sterilized;
          let pedigree;

          this.imageUrl = this.strapiUrl + this.petDetails?.attributes?.avatar?.data?.attributes?.url;
        

          if(this.petDetails?.attributes?.castrado === true){
            sterilized = 'Sim';
          }else{
            sterilized = 'Não';
          }

          if(this.petDetails?.attributes?.chip === true){
            has_chip = 'Sim';
          }else{
            has_chip = 'Não';
          }

          if(this.petDetails?.attributes?.pedigree === true){
            pedigree = 'Sim';
          }else{
            pedigree = 'Não';
          }

          this.input_especies = this.petDetails?.attributes?.especie;
          this.input_name = this.petDetails?.attributes?.nome;
          this.input_race = this.petDetails?.attributes?.raca;
          this.input_birthdate = this.petDetails?.attributes?.data_nascimento;
          this.input_genre = this.petDetails?.attributes?.genero;
          this.input_size = this.petDetails?.attributes?.tamanho;
          this.input_weight = this.petDetails?.attributes?.peso;
          this.input_sterilized = sterilized;
          this.input_pedigree = pedigree;
          this.input_pedigree_number = this.petDetails?.attributes?.pedigreeN;
          this.input_has_chip = has_chip;
          this.input_observations = this.petDetails?.attributes?.observacao;
          this.petAvatarId = this.petDetails?.attributes?.avatar?.data?.id
        },
        error:(e)=>{},
        complete:()=>{
          petDetails$.unsubscribe();
        }
        }     
        )

    }
  }


  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
  }

  getUserFunction(){

    this.selectedBuzzIndex = localStorage.getItem('buzz_index') || 0;

    this.userDataservice.getUserFunction().subscribe(result=>{

      this.userFunction = result;

      this.perfilNegocio = this.userFunction?.perfil_negocios[this.selectedBuzzIndex]?.id;


    })
  }


/*
  onNovaDataAgendaChange($event: Event) {
    const date = new Date($event['detail'].value);;
    ;
    const formattedDate = date.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    this.pet_Object.data_nascimento = date
    console.log(formattedDate);
    console.log(this.pet_Object.data_nascimento);
  }
*/


  onSalvarClick(form: NgForm) {

    if (form.valid) {
      console.log(this.pet_Object);
      this.Novo_PetObject_Output.emit(this.pet_Object); // emite evento com o ojeto NOvo agendamento
      this.modalController.dismiss({
        'dismissed': true,
        'data': this
      });
    } else {
      console.log('FORMULARIO Invalido')
    }

  }

  removeConfirmation(){

    Swal.fire({
      title: 'Você deseja remover este Pet?',
      text: "Esta ação não poderá ser revertida",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'var(--ion-color-success)',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, remover.',
      cancelButtonText:'Cancelar',
      heightAuto:false
    }).then((result) => {
      if (result.isConfirmed) {
        this.removePet();
      }
    })

  }

  removePet(){

    this.tutoresService.removePet(+this.petID).subscribe(result=>{


      this.removedPet = result

      this.successAlert('O pet foi removido com sucesso');

      this.modalController.dismiss({
        'dismissed': true,
        'data': this
      });

      let logData = {
        what_changed:'pet',
        action:'deleted',
        details:{
          description:'Remoção de Pet via painel de gerenciamento PETinhodemim',
          return:this.removedPet          
        },
        element_id:this.removedPet?.id,
        performed_by:{
          nome:this.userFunction?.dados_pessoais?.nome + ' ' + this.userFunction?.dados_pessoais?.sobrenome,
          usuario: this.userFunction?.username,
          email: this.userFunction?.email
        },
        perfil_negocio:this.perfilNegocio

      }

      this.createLog(logData,null)

    })

  }

  createPet(){

    let selected_Tutor_Id_String =  this.tutorId;
    let has_chip;
    let sterilized;
    let pedigree;

    if(this.input_sterilized === 'Sim'){
      sterilized = true;
    }else{
      sterilized = false;
    }

    if(this.input_has_chip === 'Sim'){
      has_chip = true;
    }else{
      has_chip = false;
    }

    if(this.input_pedigree === 'Sim'){
      pedigree = true;
    }else{
      pedigree = false;
    }



    this.pet_Object = {

      data:{

      nome: this.input_name,
      data_nascimento: this.input_birthdate,
      possuiPedigre: pedigree,
      pedigreeN: this.input_pedigree_number,
      peso: this.input_weight,
      tamanho: this.input_size,
      chip: has_chip,
      castrado: sterilized,
      raca: this.input_race,
      genero: this.input_genre,
      especie: this.input_especies,
      observacao: this.input_observations,
      tutor:+selected_Tutor_Id_String,
      users:this.currentUser?.user?.id

      }            
  
    }

    this.tutoresService.getTutorDetails(+selected_Tutor_Id_String).subscribe(result=>{

      let tutorDetails = result.data[0]?.attributes;

      this.tutoresService.createPet(this.pet_Object).subscribe(result=>{

        let createdPet = result.data;
        let petsIds = []
        petsIds.push(createdPet?.id)

        //create a Log

      let logData = {
        what_changed:'pet',
        action:'created',
        details:{
          description:'Criação de Pet via painel de gerenciamento PETinhodemim'          
        },
        element_id:createdPet?.id,
        performed_by:{
          nome:this.userFunction?.dados_pessoais?.nome + ' ' + this.userFunction?.dados_pessoais?.sobrenome,
          usuario: this.userFunction?.username,
          email: this.userFunction?.email
        },
        perfil_negocio:this.perfilNegocio

      }

      this.createLog(logData,null)

        this.createdPetID = createdPet?.id
        
        for(let i=0; tutorDetails?.pets?.data?.length > i;i++){

          petsIds.push(tutorDetails?.pets?.data[i]?.id)

        }

        if(this.image){

          this.send()

        }
  
        let petsData = {data:{pets:petsIds}}
  
        this.tutoresService.updateTutor(petsData, selected_Tutor_Id_String).subscribe(result=>{})
  
        this.successAlert('Pet criado com sucesso');
  
        this.modalController.dismiss({
          'dismissed': true,
          'data': this
        });
  
      })
    }) 

  }

  getPetDetails(){

    let petDetails$ = this.tutoresService.getPetDetails(+this.petID).subscribe({
      next:(result)=>{

      this.petDetails = result.data[0];

      let has_chip;
      let sterilized;
      let pedigree;

      this.imageUrl = this.strapiUrl + this.petDetails?.attributes?.avatar?.data?.attributes?.url;
    

      if(this.petDetails?.attributes?.castrado === true){
        sterilized = 'Sim';
      }else{
        sterilized = 'Não';
      }

      if(this.petDetails?.attributes?.chip === true){
        has_chip = 'Sim';
      }else{
        has_chip = 'Não';
      }

      if(this.petDetails?.attributes?.pedigree === true){
        pedigree = 'Sim';
      }else{
        pedigree = 'Não';
      }

      this.input_especies = this.petDetails?.attributes?.especie;
      this.input_name = this.petDetails?.attributes?.nome;
      this.input_race = this.petDetails?.attributes?.raca;
      this.input_birthdate = this.petDetails?.attributes?.data_nascimento;
      this.input_genre = this.petDetails?.attributes?.genero;
      this.input_size = this.petDetails?.attributes?.tamanho;
      this.input_weight = this.petDetails?.attributes?.peso;
      this.input_sterilized = sterilized;
      this.input_pedigree = pedigree;
      this.input_pedigree_number = this.petDetails?.attributes?.pedigreeN;
      this.input_has_chip = has_chip;
      this.input_observations = this.petDetails?.attributes?.observacao;
      this.petAvatarId = this.petDetails?.attributes?.avatar?.data?.id
    },
    error:(e)=>{},
    complete:()=>{
      petDetails$.unsubscribe();
    }
    }     
    )

  }

  removePetAvatar(petId:string){

     let updatePet$ =  this.tutoresService.updatePet({data:{avatar:null}}, petId).subscribe({
        next:(result)=>{},
        error:(e)=>{},
        complete:()=>{
          this.tutoresService.getPetDetails(+petId).subscribe({
            next:(result)=>{
              this.petDetails = result.data[0];
            },
            error:(e)=>{},
            complete:()=>{
              updatePet$.unsubscribe();
              this.getPetDetails();              
            }
          })
        }
      })

  }


  updatePet(){

    
    let has_chip;
    let sterilized;
    let pedigree;

    if(this.input_sterilized === 'Sim'){
      sterilized = true;
    }else{
      sterilized = false;
    }

    if(this.input_has_chip === 'Sim'){
      has_chip = true;
    }else{
      has_chip = false;
    }

    if(this.input_pedigree === 'Sim'){
      pedigree = true;
    }else{
      pedigree = false;
    }



    this.pet_Object = {

      data:{

      nome: this.input_name,
      data_nascimento: this.input_birthdate,
      possuiPedigre: pedigree,
      pedigreeN: this.input_pedigree_number,
      peso: this.input_weight,
      tamanho: this.input_size,
      chip: has_chip,
      castrado: sterilized,
      raca: this.input_race,
      genero: this.input_genre,
      especie: this.input_especies,
      observacao: this.input_observations,
      tutor:+this.tutorID,
      users:this.currentUser?.user?.id

      }            
  
    }
    

    this.tutoresService.updatePet(this.pet_Object,this.petID).subscribe(result=>{

      

      if(this.image){

        this.tutoresService.removeImage(this.petAvatarId).subscribe(result=>{
          

        },
        ()=>{this.send()}
        )       

      }

      //create a Log

      let logData = {
        what_changed:'pet',
        action:'updated',
        details:{
          description:'Atualização de Pet via painel de gerenciamento PETinhodemim',
          sent_data:this.pet_Object    
        },
        element_id:result?.data?.id,
        performed_by:{
          nome:this.userFunction?.dados_pessoais?.nome + ' ' + this.userFunction?.dados_pessoais?.sobrenome,
          usuario: this.userFunction?.username,
          email: this.userFunction?.email
        },
        perfil_negocio:this.perfilNegocio

      }

      this.createLog(logData,null)

      this.successAlert('Pet atualizado com sucesso');

      this.modalController.dismiss({
        'dismissed': true,
        'data': this
      });

    })

  }

  cancelEdition(){

    this.modalController.dismiss({
      'dismissed': true,
      'data': this
    });

  }

  successAlert(title:string){

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: title,
      showConfirmButton: false,
      timer: 2000,
      heightAuto:false
    })

  }

fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.imagename = event.target.files[0];
    this.imageselect = false;
}
imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const imageBlob = this.dataURItoBlob(this.croppedImage);
    this.image = new File([imageBlob], this.imagename.name, { type: 'image/jpeg' });
    //console.log(this.croppedImage)
    this.imageselect = true;
    this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl(`${this.croppedImage}`)
    console.log(this.imagePath)
}
imageLoaded() {
    // show cropper
}
cropperReady() {
    // cropper ready
}
loadImageFailed() {
    // show message
}

dataURItoBlob(dataURI) {
const binary = atob(dataURI.split(',')[1]);
const array = [];
for (let i = 0; i < binary.length; i++) {
  array.push(binary.charCodeAt(i));
}
return new Blob(
  [new Uint8Array(array)],
  {
    type: 'image/png',
  },
);
}

async sendFile(file) {

  let ref = "api::pet.pet";  
  let refId:string = this.createdPetID || this.petID;

  const formData = new FormData();
  formData.append('files', this.image);  
  formData.append('ref', ref);  
  formData.append('refId', refId);  
  formData.append('field', 'avatar');
  file.inProgress = true;
  await this.tutoresService.uploadImage(formData).subscribe((event: any) => {
      if (typeof (event) === 'object') {
        console.log(event.body);

    if(event.body){
    
    } 

      }
    });
    

}

private sendFiles() {
  this.loaderService.presentLoading().then(()=>{
  this.fileUpload.nativeElement.value = '';
  this.files.forEach(file => {
    this.sendFile(file);      
  });
  this.loadingController.dismiss();    
  })    
}

onClick() {
  const fileUpload = this.fileUpload.nativeElement;
  fileUpload.onchange = () => {
  for (let index = 0; index < fileUpload.files.length; index++)
  {
   const file = fileUpload.files[index];
   this.files.push({ data: file, inProgress: false, progress: 0});
   this.isPopoverOpen = true;
  }      
  };
  fileUpload.click();

}

send(){

  this.sendFiles();

}

closePopover(){

  this.isPopoverOpen = false

}

revertImage(){

  this.imagePath = null;

}

createLog(elementData:any, id:any){


  this.logsService.createLogs(elementData).subscribe(result=>{

    console.log('Log criado')

  })
}


}
