import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-agenda-profissional',
  templateUrl: './agenda-profissional.page.html',
  styleUrls: ['./agenda-profissional.page.scss'],
})
export class AgendaProfissionalPage implements OnInit {
  ProfissionalIdModal: any;

  constructor(
    private navParams: NavParams
  ) { }

  ngOnInit() {

    this.ProfissionalIdModal = this.navParams?.get('idProfissional')
  }

}
