import { Component } from '@angular/core';
import { Subscription } from 'rxjs';

/**
 * "Decorator para desinscrever automaticamente de observables quando o componente é destruído."
 */
export function AutoUnsubscribe(constructor: any) {
  const original = constructor.prototype.ngOnDestroy;

  constructor.prototype.ngOnDestroy = function () {
    for (const prop in this) {
      const property = this[prop];
      if (property instanceof Subscription) {
        console.log(`AutoUnsubscribe: Unsubscribing from ${prop} in ${constructor.name}`);
        property.unsubscribe();
      }
    }
    original?.apply(this, arguments);
  };
}
