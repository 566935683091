import { UtilsService } from 'src/app/services/utils.service';
import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { UserdataService } from 'src/app/services/userdata.service';
import { BehaviorSubject, Observable, timeInterval } from 'rxjs';
import { ModalController, ViewDidEnter, ViewWillEnter, ViewWillLeave } from '@ionic/angular';
import { environment } from 'src/environments/environment'
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';
import { AuthenticationService } from 'src/app/services/authentication.service';
import Swal from 'sweetalert2';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';


// @AutoUnsubscribe
@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})

export class TitleComponent implements OnInit {

  userData$: any;
  userPersonalData: any = [];
  strapiBaseUrl = environment.strapiBaseUrl;
  popoverVariations = 1;
  isOpen = false;
  @ViewChild('popover') popover;
  selectedBuzzIndex = '0';

  @Input() title = 'Título';
  constructor(
    public userService: UserdataService,
    private auth: AuthenticationService,
    private router: Router,
    private utilsService: UtilsService
  ) { }

  ngOnInit() {

    //this.generatePopoverVariations();
    this.userPersonalData.perfil_negocios = [];
    setInterval(run => { this.selectedBuzzIndex = localStorage.getItem('buzz_index') || '0'; }, 500)
    this.getUserPersonalData();

  }

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  dismissPopover() {

    this.isOpen = false;

  }

  logoutUser() {

    this.auth.logout();

  }

  generatePopoverVariations() {

    setInterval(result => {

      this.popoverVariations = this.popoverVariations++;

    }, 1000)

  }

  openPopover() {

    this.isOpen = true;

  }

  // getUserPersonalData() {

  //   this.userData$ = this.userService.getUserPersonalDataDeep()
  //     .subscribe(result => {

  //       this.userPersonalData = result;

  //       sessionStorage.setItem('unity_id', this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.id)

  //       console.log(this.userPersonalData)

  //     },      
  //       err=>{
  //         if(err === 'Unauthorized'){

  //           localStorage.removeItem('currentUser');
  //           window.location.href = './'

  //         }
  //       }
  //     );

  // }

  getUserPersonalData(){
    this.userData$ = this.userService.getUserPersonalDataDeep().subscribe({
      next:(result)=>{
        this.userPersonalData = result;

        sessionStorage.setItem('unity_id', this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.id)

        console.log(this.userPersonalData)
      },
      error:(err)=>{
        if(err === 'Unauthorized'){

          localStorage.removeItem('currentUser');
          window.location.href = './'

        }

        let interval = setInterval(run=>{
          this.getUserPersonalDataFallBack()
        },2000);

        setTimeout(run=>{
          clearInterval(interval);
          this.connectionErrorAlert()
        }, 4500)

      },
      complete:()=>{
        this.userData$.unsubscribe();
      }
    })
  }

  getUserPersonalDataFallBack(){
    this.userData$ = this.userService.getUserPersonalDataDeep().subscribe({
      next:(result)=>{
        this.userPersonalData = result;

        sessionStorage.setItem('unity_id', this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.id)

        console.log(this.userPersonalData)
      },
      error:(err)=>{
        if(err === 'Unauthorized'){

          localStorage.removeItem('currentUser');
          window.location.href = './'

        }       

      },
      complete:()=>{
        this.userData$.unsubscribe();
      }
    })
  }

  selectUnity(index) {

    localStorage.setItem('buzz_index', index);
    let currentUrl = '-' + this.router.url;
    let navigateTo = '/loader/' + currentUrl
    this.router.navigate(['/tabs/loader', currentUrl]);

    this.selectedBuzzIndex = index;

    this.utilsService.selectedBuzzIndex$.next(index);


  }


  ionViewDidLeave() {
    this.userData$.unsubscribe();
  }

  exitAlert() {

    Swal.fire({
      title: 'Você deseja sair?',
      showCancelButton: true,
      confirmButtonText: 'Sair',
      customClass: 'removeProfAlertClass',
      heightAuto: false,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: 'var(--ion-color-danger)'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.logoutUser();
        window.location.href = './'
      }
    })

  }

  connectionErrorAlert(){

    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Houve um problema em conectar com o servidor, verifique sua conexão com a internet e recarregue a página da aplicação!",
      confirmButtonText:'Recarregar aplicação',
      confirmButtonColor:'#51438e',
      heightAuto:false
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        window.location.reload();
      }
    });

  }




}
