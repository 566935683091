import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';
import { BehaviorSubject, Observable, map, switchMap } from 'rxjs';
import { AddTemplateModalComponent } from 'src/app/modals/add-template-modal/add-template-modal.component';
import { AutomationService } from 'src/app/services/automation.service';
import { LogsService } from 'src/app/services/logs.service';
import { ProfissionaisService } from 'src/app/services/profissionais.service';
import { UserdataService } from 'src/app/services/userdata.service';
import { UtilsService } from 'src/app/services/utils.service';
import { SubSink } from 'subsink';
import swal from 'sweetalert2';


@Component({
  selector: 'app-add-action-modal',
  templateUrl: './add-action-modal.component.html',
  styleUrls: ['./add-action-modal.component.scss'],
})
export class AddActionModalComponent implements OnInit {
  addActionForm: FormGroup;

  refreshMailsIntegrated$ = new BehaviorSubject(true);
  integratedMails$: Observable<any>;

  refreshTemplateMails$ = new BehaviorSubject(true);
  templateMails$: Observable<any>;

  id: number;
  mode: string;

  subs: SubSink;

  perfilNegocio: any;
  actions_id: any;
  loading = true;
  texto_Delay = 'Quanto tempo após o agendamento realizado?'
  array_perfilNegocio: any;
  showMail = false;
  templates_ID_Array: any;
  integratedMails_array_id: any;
  userData: any = [];
  matchingPerfis_clonado_em$: Observable<any>;
  selectedBuzzIndex: string | number;



  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService,
    private navParams: NavParams,
    private profissionalsService: ProfissionaisService,
    private userDataService: UserdataService,
    private automationService: AutomationService,
    private formBuilder: FormBuilder,
    private logsService: LogsService

  ) { }

  ngOnInit() {


    this.mode = this.navParams.get('mode');
    this.id = this.navParams.get('id');

    this.refreshTemplateMails$ = this.automationService.refreshTemplateMails_Subject$
    this.userDataService.getUserPersonalData()
      .subscribe(result => {
        this.selectedBuzzIndex = localStorage.getItem('buzz_index') || 0;

        this.userData = result;

        this.perfilNegocio = result?.perfil_negocios[this.selectedBuzzIndex]?.id;
        console.log('perfilNegocio', this.perfilNegocio)

        this.array_perfilNegocio = [...result?.perfil_negocios].map((perfil) => {
          console.log('perfil', perfil)

          if (perfil?.id != this.perfilNegocio) {
            return perfil
          }

        }).filter((perfil) => { return perfil != undefined })


        console.log('array_perfilNegocio', this.array_perfilNegocio)


        this.integratedMails$ = this.refreshMailsIntegrated$.pipe(
          switchMap(() => this.automationService.getIntegratedMails_shortInfos(this.perfilNegocio)),
          map((result: any) => {
            console.log('integratedMails', result.data)
            return result.data;
          }
          ));

        this.templateMails$ = this.refreshTemplateMails$.pipe(
          switchMap(() => this.automationService.getMailTemplates(this.perfilNegocio)),
          map((result: any) => {
            console.log('templateMails', result.data)
            this.templates_ID_Array = result.data.map((template => template.id))
            console.log('templates_ID_Array', this.templates_ID_Array)
            return result.data;
          }
          ));
      })

    this.addActionForm = this.formBuilder.group({
      action: '',
      integration_mails: '',
      mail_template: '',
      automated_tasks: [],

    });




  }



  onDeleteActionClick(action_id) {
    console.log('onDeleteActionClick', action_id)

    swal
      .fire({
        title: 'Você deseja remover esta Ação?, <br> Esse comando não poderá ser desfeito.',
        showCancelButton: true,
        confirmButtonText: 'Remover',
        customClass: 'removeProfAlertClass',
        heightAuto: false,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: 'var(--ion-color-danger)',
      })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {

          this.automationService.deleteAction_Automation(action_id).subscribe({
            next: result => {
              console.log(result);

              let logData = {
                what_changed: 'automacao_action',
                action: 'deleted',
                details: {
                  description: 'Remoção de ação de integração via painel de gerenciamento PETinhodemim'
                },
                element_id: result?.data?.id,
                performed_by: {
                  nome: this.userData?.dados_pessoais?.nome + ' ' + this.userData?.dados_pessoais?.sobrenome,
                  usuario: this.userData?.username,
                  email: this.userData?.email
                },
                perfil_negocio: this.perfilNegocio

              }

              this.createLog(logData, null)

              // this.automationService.getAutomatedTask_ID(this.id).subscribe({
              //   next: result => {
              //     console.log(result);
              //     this.automationForm.patchValue(
              //       {
              //         acao_dados: result.data.attributes.actions_automations.data,
              //         acao: result.data.attributes.actions_automations.data.map((action => action.id))
              //       });
              //   },
              //   complete: () => {
              //     console.log('complete getAutomatedTask_ID');
              //   },
              //   error: error => console.log(error)
              // });
            },
          });
        }
      });


  }


  async openAddTemplateModal() {
    await this.modalController.create({
      component: AddTemplateModalComponent,
      cssClass: 'modal-fullscreen',
    }).then(modalEl => {
      modalEl.present();
    }),
      this.modalController.dismiss().then(() => {
        this.refreshTemplateMails$.next(true);
      });

  }

  async openEditTemplateModal() {
    await this.modalController.create({
      component: AddTemplateModalComponent,
      componentProps: {
        id: this.addActionForm.value.mail_template,
        mode: 'insert'
      },
      cssClass: 'modal-fullscreen',
    }).then(modalEl => {
      modalEl.present();
    })


  }

  change_Action($event) {
    if (
      $event.detail.value.substring(0, 8) !== 'sendMail'
    ) {
      this.showMail = false
    } else if ($event.detail.value.substring(0, 8) === 'sendMail'
    ) {
      this.showMail = true
    }

  }

  DismisModal() {
    let modo = this.mode == 'insert' ? 'esse cadastro' : 'essa edição';
    this.utilsService.showAlert(
      'Tem certeza que deseja <span>Cancelar</span> <br>' + modo + '? <br>Todos os dados não salvos serão perdidos.</br>',
      () => {
        this.modalController.dismiss();
      },
      function () {
        // Ação a ser executada quando o usuário cancelar o alerta, se necessário.
      }.bind(this)
    );

  }


  createLog(elementData: any, id: any) {


    this.logsService.createLogs(elementData).subscribe(result => {

      console.log('Log criado')

    })
  }

}
