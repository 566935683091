import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectPropertyFilter'
})
export class ObjectPropertyFilterPipe implements PipeTransform {

  transform(objectsArray, searchTerm, property, componentName) {
    if (!objectsArray || !searchTerm) {
      return objectsArray;
    }

    searchTerm = searchTerm.toLowerCase();

    switch (componentName) {
        case 'tutorLista':
        case 'profissionalLista':
            switch (property) {
                case 'cpf_cnpj':
                    return objectsArray.filter(object => object?.['attributes']?.['dados_pessoais']?.['cpf']?.includes(searchTerm));
                case 'telefone':
                    return objectsArray.filter(object => object?.['attributes']?.['dados_pessoais']?.['celular']?.includes(searchTerm));
                default:
                    return objectsArray.filter(object => object?.['attributes']?.['dados_pessoais']?.[property]?.toLowerCase().includes(searchTerm));
            }
        case 'agendaSlider':
            return objectsArray.filter(object => object?.meta?.[property]?.toLowerCase().includes(searchTerm));
        default:
            return objectsArray;
    }
  }








}



