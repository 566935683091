import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';

@Pipe({
  name: 'filterAgendamentos'
})
export class FilterAgendamentosPipe implements PipeTransform {

  transform(events: CalendarEvent[], filter: string): CalendarEvent[] {
    if (!filter) {
      return events;
    }
    return events.filter(event => event.title.includes(filter));
  }
}
