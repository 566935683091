import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TutoresService {

  private tutorDetailsSubject = new BehaviorSubject<any>(null);
  tutorData$ = this.tutorDetailsSubject.asObservable();


  strapiApiUrl = environment.strapiApiUrl

  constructor(private http: HttpClient) { }

  createTutor(tutorData: Object) {

    return this.http.post<any>(this.strapiApiUrl + '/tutors', tutorData)

  }

  createPet(petData: Object) {

    return this.http.post<any>(this.strapiApiUrl + '/pets', petData)

  }

  updateTutor(tutorData: Object, tutorId: string) {

    return this.http.put<any>(this.strapiApiUrl + '/tutors/' + tutorId, tutorData)

  }

  updatePet(petData: Object, petId: string) {

    return this.http.put<any>(this.strapiApiUrl + '/pets/' + petId, petData)

  }

  getTutors2(negocio_id: number, pagination: number = 0, pageSize: number = 25) {
    return this.http.get<any>(this.strapiApiUrl + '/tutors?filters[perfil_negocio][id][$eq]=' + negocio_id + '&pagination[start]=' + pagination + '&pagination[limit]=' + pageSize + '&populate=*')
  }

  getTutors(negocio_id: number, pagination: number = 0, pageSize: number = 25) {
    return this.http.get<any>(this.strapiApiUrl + '/tutors?filters[$or][0][perfil_negocio][id]=' + negocio_id + '&filters[$or][1][perfis_negocios_assoc][id]=' + negocio_id + '&pagination[start]=' + pagination + '&pagination[limit]=' + pageSize + '&populate=*')
  }

  getActiveTutors(negocio_id: number, pagination: number = 0, pageSize: number = 25) {
    return this.http.get<any>(this.strapiApiUrl + '/tutors?filters[$and][0][perfil_negocio][id][$eq]=' + negocio_id + '&filters[$and][1][ativo][$eq]=true' + '&pagination[start]=' + pagination + '&pagination[limit]=' + pageSize + '&populate=*')
  }

  getInativeTutorsByDate(negocio_id: number, data_inatividade: Date) {
    return this.http.get<any>(this.strapiApiUrl + '/tutors?filters[$and][0][perfil_negocio][id][$eq]=' + negocio_id + `&filters[$and][1][inativo_desde][$gte]=` + data_inatividade + '&filters[$and][2][ativo][$eq]=false&populate=*')
  }

  filterTutors(negocio_id: number, filter_by: string, filter_key: string) {
    return this.http.get<any>(this.strapiApiUrl + '/tutors?filters[$and][0][perfil_negocio][id][$eq]=' + negocio_id + `&filters[$and][1][dados_pessoais][${filter_by}][$containsi]=` + filter_key + '&populate=*')
  }

  filterTutorsList(negocio_id: number, filter_by: string, filter_key: string) {
    return this.http.get<any>(this.strapiApiUrl + '/tutors?filters[$or][0][perfil_negocio][id]=' + negocio_id + '&filters[$or][1][perfis_negocios_assoc][id]=' + negocio_id + `&filters[$and][1][dados_pessoais][${filter_by}][$containsi]=` + filter_key + '&populate[0]=dados_pessoais&populate[1]=pets&populate[2]=perfil_negocio')
  }
  filterTutorsListAvatar(negocio_id: number, filter_by: string, filter_key: string) {
    return this.http.get<any>(this.strapiApiUrl + '/tutors?filters[$or][0][perfil_negocio][id]=' + negocio_id + '&filters[$or][1][perfis_negocios_assoc][id]=' + negocio_id + `&filters[$and][1][dados_pessoais][${filter_by}][$containsi]=` + filter_key + '&populate[0]=dados_pessoais&populate[1]=pets&populate[2]=perfil_negocio&populate[3]=avatar&populate[4]=endereco&populate[5]=pets')
  }


  getTutorDetails(tutorId: number) {
    return this.http.get<any>(this.strapiApiUrl + '/tutors?filters[id][$eq]=' + tutorId + '&populate=*')
  }

  getPetDetails(petId: number) {
    return this.http.get<any>(this.strapiApiUrl + '/pets?filters[id][$eq]=' + petId + '&populate=*')
  }

  getPets(tutorId: number) {
    return this.http.get<any>(this.strapiApiUrl + '/pets?filters[tutors][id][$eq]=' + tutorId + '&populate=*')
  }

  getPetsAgendamentos(PetId: number) {
    return this.http.get<any>(this.strapiApiUrl + '/agendamentos?filters[pet][id][$eq]=' + PetId)
  }

  removeTutor(tutorId: number) {
    return this.http.delete(this.strapiApiUrl + '/tutors/' + tutorId)
  }
  removePet(petId: number) {
    return this.http.delete(this.strapiApiUrl + '/pets/' + petId)
  }
  uploadImage(formData) {
    return this.http.post<any>(`${this.strapiApiUrl}/upload`,
      formData, { reportProgress: true, observe: 'events' });
  }
  removeImage(id: number) {
    return this.http.delete(`${this.strapiApiUrl}/upload/files/${id}`);
  }
}
