import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-phone-integration',
  templateUrl: './add-phone-integration.component.html',
  styleUrls: ['./add-phone-integration.component.scss'],
})
export class AddPhoneIntegrationComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
