import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalController, NavParams } from '@ionic/angular';
import { TutoresService } from 'src/app/services/tutores.service';
import { switchMap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { UtilsService } from 'src/app/services/utils.service';
import { UserdataService } from 'src/app/services/userdata.service';
import { SubSink } from 'subsink';
@Component({
  selector: 'app-incluir-tutor-modal',
  templateUrl: './incluir-tutor-modal.page.html',
  styleUrls: ['./incluir-tutor-modal.page.scss'],
})
export class IncluirTutorModalPage implements OnInit {

  @Output() celularNewTutor = new EventEmitter<string>();
  @Output() idNewTutor = new EventEmitter<string>();
  @Output() nomeTutor = new EventEmitter<string>();






  nome: string;
  celular: string;
  cpf: string;
  email: string;
  nomePet: string;
  especiePet: string;
  ShowPetCAdastro = false;
  petsId: any = [];
  tutorID: string;
  saveUpadates = false;
  petsIdArray: any = [];
  especiesList: any = [];
  userData: any;
  perfilNegocio: any;
  selectedBuzzIndex: any;
  subs = new SubSink();

  constructor(
    private http: HttpClient,
    private modalCtrl: ModalController,
    private tutoresService: TutoresService,
    public modalController: ModalController,
    public utilsService: UtilsService,
    private navParams: NavParams,
    private userDataService: UserdataService,



  ) {
    this.especiesList = this.utilsService.especies;

  }

  ngOnInit() {
    this.selectedBuzzIndex = localStorage.getItem('buzz_index');

    this.celular = this.navParams.get('tutorcelular');
    this.subs.add(this.userDataService.getPerfil_negocios(this.selectedBuzzIndex).subscribe(({ userData, perfilNegocio }) => {
      this.userData = userData;
      this.perfilNegocio = perfilNegocio;
      console.log(this.perfilNegocio)
    }))

  }

  createTutor() {
    let dataTutor = {
      data: {
        dados_pessoais: {
          nome: this.nome,
          celular: this.celular.replace(/\D/g, ''),
          cpf: this.cpf,
          email: this.email
        },
        perfil_negocio: this.perfilNegocio,
      }
    }

    this.tutoresService.createTutor(dataTutor)
      .subscribe(
        tutor => {
          this.tutorID = tutor?.data?.id;
          this.ShowPetCAdastro = true;
          console.log(tutor)

        },
        error => {
          console.error('Erro ao criar o tutor: ', error);
        }
      );
  }

  createPet() {

    let dataPEt = {
      data: {
        tutor: this.tutorID,
        nome: this.nomePet,
        especie: this.especiePet
      }
    };

    this.tutoresService.createPet(dataPEt)
      .subscribe(
        pet => {
          console.log(pet)
          this.petsIdArray = [...this.petsIdArray, pet?.data?.id];
          console.log(this.petsIdArray);

          this.nomePet = '',
            this.especiePet = '',
            console.log('Pet criado com sucesso!');
          this.saveUpadates = true;

          this.successAlert("Inclua outros Pet's ou clique Concluir para finalizar", 3001);

        },
        error => {
          console.error('Erro ao criar o pet: ', error);
        }
      );
  }

  updateTutorWithPets() {
    console.log(this.petsIdArray);
    let petsData = { data: { pets: this.petsIdArray } }
    console.log(petsData);
    console.log(this.tutorID);
    this.tutoresService.updateTutor(petsData, this.tutorID).subscribe(
      data => {
        console.log(data)
        this.celularNewTutor.emit(this.celular);
        this.idNewTutor.emit(data.id);
        this.nomeTutor.emit(this.nome);
        this.successAlert('Cadastro realizado com sucesso', 4000);
        this.dismissModal()


      },
      error => {
        console.error('Erro ao realizar o cadastro: ', error);
      }
    );



  }

  successAlert(title: string, time: number) {

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: title,
      showConfirmButton: false,
      timer: time,
      heightAuto: false
    })

  }

  dismissModal() {
    this.modalController.dismiss({
      'dismissed': true,
      'data': this
    });
  }

  // createTutor() {
  //   let dataTutor = {
  //     data: {
  //       dados_pessoais: {
  //         nome: this.nome,
  //         celular: this.celular,
  //         cpf: this.cpf
  //       }
  //     }
  //   }

  //   this.tutoresService.createTutor(dataTutor)
  //     .pipe(
  //       switchMap(tutor => {
  //         console.log(tutor);
  //         let tutorID = tutor?.data?.id;
  //         console.log(tutorID);
  //         let dataPEt = {
  //           data: {
  //             tutor: tutorID,
  //             nome: this.nomePet,
  //             especie: this.especiePet
  //           }
  //         };

  //         return this.tutoresService.createPet(dataPEt).subscribe
  //       })
  //     )
  //     .subscribe(
  //       pet => {
  //         console.log('Pet criado com sucesso!');
  //       },
  //       error => {
  //         console.error('Erro ao criar o pet: ', error);
  //       }
  //     );
  // }



}
