import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import localeBrl from '@angular/common/locales/pt-PT';
import localept from '@angular/common/locales/pt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';




import { CommonModule } from '@angular/common';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { ProdutosPageModule } from './produtos/produtos.module';
import { Chart, registerables } from 'chart.js';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

//Fonts Awasome Imports
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { SwiperModule } from 'swiper/angular';


//form
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { IConfig } from 'ngx-mask'
import { FilterAgendamentosPipe } from './pipes/filter-agendamentos.pipe';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { IonicInputMaskModule } from "@thiagoprz/ionic-input-mask";

//Login System e JWT Helpers
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ConnectionServiceModule } from 'ng-connection-service';
// import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';


registerLocaleData(localept, 'pt');

//registerLocaleData(localeBrl);
export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;


Chart.register(...registerables);

@NgModule({
  declarations: [AppComponent, FilterAgendamentosPipe],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    ComponentsModule,
    HttpClientModule,
    ProdutosPageModule,
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    BrowserAnimationsModule,
    //IonicInputMaskModule,
    SwiperModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    ConnectionServiceModule

  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }    
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, fab, far);
  }

}

