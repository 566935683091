import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  strapiApiUrl = environment.strapiApiUrl

  constructor(private http: HttpClient) { }

  getLogsByBusiness(negocio_id:any, page:number, ){
    return this.http.get<any>(this.strapiApiUrl + '/logs?filters[perfil_negocio][id][$eq]=' + negocio_id + '&pagination[page]='+ page +'&pagination[pageSize]=50&sort=createdAt:desc&populate=*')
  }

  getLogsByBusinessAndDate(negocio_id:any, page:number, date:any){

    let initDate = date + 'T00:00:00.000Z'
    let endDate = date + 'T23:59:59.000Z'

    return this.http.get<any>(this.strapiApiUrl + '/logs?filters[$and][0][perfil_negocio][id][$eq]=' + negocio_id + '&filters[$and][1][createdAt][$gt]='+ initDate +'&filters[$and][2][createdAt][$lte]='+endDate+'&pagination[page]='+ page +'&pagination[pageSize]=100&sort=createdAt:desc&populate=*')
  }

  getlementDetails(id:any, element:string){

    if(element === 'agendamento'){
      return this.http.get<any>(this.strapiApiUrl + '/' + element + '/' + id + '?populate[0]=pet&populate[1]=profissional&populate[2]=profissional.dados_pessoais&populate[3]=tutors&populate[4]=tutors.dados_pessoais')
    }else{
      return this.http.get<any>(this.strapiApiUrl + '/' + element + '/' + id + '?populate=*')
    }   

  }

  createLogs(logData){

    let Data = {data:logData}

    return this.http.post<any>(this.strapiApiUrl + '/logs',Data)
  }

}
