import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Platform, AlertController } from '@ionic/angular';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';
import { User } from '../_models/user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    private currentUserFunctionSubject: BehaviorSubject<any>;
    public currentUser: Observable<User>;
    
    isLoggedin: boolean = false;

    constructor(private http: HttpClient, private alertController: AlertController, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUserFunctionSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser'))?.user?.function);
        this.currentUser = this.currentUserSubject.asObservable();        

    }

    public get currentUserValue(): User {
        return this.currentUserSubject?.value;
    }

    public get userFunctionValue(): String{
      return this.currentUserFunctionSubject?.value
    }

    login(identifier: string, password: string) {
        return this.http.post<any>(`${environment.strapiApiUrl}/auth/local`, { identifier, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                console.log('User',user.user.function)
                this.currentUserSubject.next(user);
                this.currentUserFunctionSubject.next(user.user.function)              
                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');       
        this.router.navigateByUrl('/login');
    }

   forgetPass(email){
    return this.http.post<any>(`${environment.strapiApiUrl}/auth/forgot-password`, email).pipe(map(response => {
      },
      error=>{
          return this.showErrorAlert("O e-mail não está cadastrado em nosso sistema");
      }

      ))

   }

   resetPass(password:String, passwordConfirmation:String, code:String){
    return this.http.post<any>(`${environment.strapiApiUrl}/auth/reset-password`, {code:code, password:password, passwordConfirmation:passwordConfirmation}).pipe(map(response => {
          
      }))

   }

  register(uName: String, fName: String, lName: String, email: String, password: String, celular:string,cargo:string, funcao:string, perfil_negocios:Array<number>) {  

    let userData = {
      username: uName,       
      email: email, 
      password: password,
      function:funcao,
      ftoken:'-',
      perfil_negocios:perfil_negocios,      
      dados_pessoais:{
        email:email,
        celular:celular,
        nome:fName,
        sobrenome:lName,
        cargo:cargo,
      }

    }

    return this.http.post(`${environment.strapiApiUrl}/auth/local/register`,
      userData
    )
  }


  createNew(uName: String, fName: String, lName: String, email: String, password: String, celular:string,cargo:string, funcao:string, perfil_negocios:Array<number>) {  

    let userData = {
      username: uName,       
      email: email, 
      password: password,
      function:funcao,
      ftoken:'-',
      perfil_negocios:perfil_negocios,      
      dados_pessoais:{
        email:email,
        celular:celular,
        nome:fName,
        sobrenome:lName,
        cargo:cargo,
      }

    }

    return this.http.post(`${environment.strapiApiUrl}/user/create-new`,
      userData
    )
  }
  
isLoggedIn() {

    if (JSON.parse(localStorage.getItem('currentUser')) == null) {
      this.isLoggedin = false;
      return this.isLoggedin;
    }
    else {
      return true;
    }
  }

  providerLogin(accessToken:String, provider:String){

    return this.http.get<any>(`${environment.strapiApiUrl}/auth/${provider}/callback?access_token=`+ accessToken);
  }
 
  showErrorAlert(msg) {
    let alert = this.alertController.create({
      message: msg,
      header: 'Erro :(',
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }

  showAlert(msg) {
    let alert = this.alertController.create({
      message: msg,
      header: 'Redefinição de senha',
      buttons: ['OK']
    });
    alert.then(alert => alert.present());
  }

  


}