import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';

@Pipe({
  name: 'todayEvents'
})
export class TodayEventsPipe implements PipeTransform {

  transform(events: CalendarEvent[], searchTerm, filtroEspecialidade, filtroProfissional, filtroHorario, filtroStatus): CalendarEvent[] {
    let hoje = new Date()?.toLocaleDateString('pt-BR')?.substring(0, 10)?.replace(/\//g, '-');
    const parts = hoje?.split('-');
    const dataHoje = parts[2] + '-' + parts[1] + '-' + parts[0];

    if (!searchTerm && !filtroEspecialidade && !filtroProfissional && !filtroHorario && !filtroStatus) {
      return events?.filter(event => (event?.meta?.data === dataHoje));
    }
    return events || [];
  }
}
