import { UtilsService } from './../../services/utils.service';
import { AutomationService } from 'src/app/services/automation.service';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IntegracaoService } from '../../services/integracao.service';
import { UserdataService } from 'src/app/services/userdata.service';
import { ModalController, NavParams } from '@ionic/angular';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import {LogsService} from 'src/app/services/logs.service';

// Interface para a configuração do servidor de e-mail
interface EmailServerConfig {
  imapServer: string; //endereço do servidor SMTP a ser utilizado
  imapPort: number; //porta do servidor SMTP a ser utilizado
  imapAuthType: string; //tipo de autenticação a ser utilizada
  smtpServer: string; //endereço do servidor SMTP a ser utilizado
  smtpPort: number; //porta do servidor SMTP a ser utilizado
  smtpSecurityMode: string; //modo de segurança a ser utilizado
  smtpAuthType: string; //tipo de autenticação a ser utilizada
}

@Component({
  selector: 'app-add-mail-integation',
  templateUrl: './add-mail-integation.component.html',
  styleUrls: ['./add-mail-integation.component.scss'],
})
export class AddMailIntegationComponent implements OnInit {
  mailForm: FormGroup<any>;
  selectedBuzzIndex: any;
  perfil_negocio: any;

  email: any;
  username: any;
  password: any;
  imapServer: any;
  imapPort: any;
  imapAuthType: any;
  smtpServer: any;
  smtpPort: any;
  smtpSecurityMode: any;
  smtpAuthType: any;
  user_id: any;
  mode: any;
  mailId: any;
  userData:any = []

  chaveSecreta = environment.CHAVE_SECRETA;




  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private integracaoService: IntegracaoService,
    private userdataService: UserdataService,
    private navParams: NavParams,
    private AutomationService: AutomationService,
    private modalController: ModalController,
    private UtilsService: UtilsService,
    private logsService: LogsService

  ) { }

  ngOnInit() {
    console.log('init')
    console.log(this.chaveSecreta)

    //get this.mode from the modal
    this.mode = this.navParams.get('mode');
    this.mailId = this.navParams.get('mailId');

    this.mailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      username: [''],
      password: ['', Validators.required],
      imapServer: [''],
      imapPort: [''],
      imapAuthType: ['LOGIN'],
      smtpServer: ['', Validators.required],
      smtpPort: ['', Validators.required],
      smtpSecurityMode: ['SSL/TLS'],
      smtpAuthType: ['LOGIN'],
    });

    if (this.mode === 'edit') {
      this.AutomationService.getIntegratedMail(this.mailId).subscribe({
        next: data => {
          console.log(data);
          this.mailForm.patchValue({
            email: data?.data?.attributes?.email,
            username: data?.data?.attributes?.username,
            // password: data?.data?.attributes?.password,
            imapServer: data?.data?.attributes?.imapServer,
            imapPort: data?.data?.attributes?.imapPort,
            imapAuthType: data?.data?.attributes?.imapAuthType,
            smtpServer: data?.data?.attributes?.smtpServer,
            smtpPort: data?.data?.attributes?.smtpPort,
            smtpSecurityMode: data?.data?.attributes?.smtpSecurityMode,
            smtpAuthType: data?.data?.attributes?.smtpAuthType,
          });
        },
        error: error => {
          console.error('There was an error!', error);
        }

      });
    }

    this.selectedBuzzIndex = localStorage.getItem('buzz_index') || '0';

    this.userdataService.getUserPersonalData().
      subscribe(
        {
          next: (data) => {
            this.perfil_negocio = data.perfil_negocios[this.selectedBuzzIndex]?.id;
            this.user_id = data.id;
            this.userData = data
            console.log(this.perfil_negocio, this.user_id)
          }
        }
      );

  }

  dismissModal() {
    this.mailForm.reset();
    //dismsiss the modal
    this.modalController.dismiss({
      'dismissed': true,
      'data': this
    });

  }

  onSubmit() {
    if (this.mailForm.get('email').value && this.mailForm.get('password').value && this.mailForm.get('smtpServer').value && this.mailForm.get('smtpPort').value) {
      var email = this.mailForm.get('email').value;
      var username = this.mailForm.get('username').value || null;
      var password = this.mailForm.get('password').value;
      var imapPort = this.mailForm.get('imapPort').value || null;
      var imapServer = this.mailForm.get('imapServer').value || null;
      var imapAuthType = this.mailForm.get('imapAuthType').value || null;
      var smtpServer = this.mailForm.get('smtpServer').value;
      var smtpPort = this.mailForm.get('smtpPort').value;
      var smtpSecurityMode = this.mailForm.get('smtpSecurityMode').value || null;
      var smtpAuthType = this.mailForm.get('smtpAuthType').value || null;




      let integrationMailData = {

        email: email,
        username: username,
        password: CryptoJS.AES.encrypt(password, this.chaveSecreta).toString(),
        imapPort: imapPort,
        imapServer: imapServer,
        imapAuthType: imapAuthType,
        smtpServer: smtpServer,
        smtpPort: smtpPort,
        smtpSecurityMode: smtpSecurityMode,
        smtpAuthType: smtpAuthType,
        perfil_negocios: [this.perfil_negocio],
        users_permissions_user: this.user_id


      };
      console.log(integrationMailData)
      //enviar dados para o servidor
      if (this.mode === 'edit') {
        this.AutomationService.updateEmailIntegration(integrationMailData, this.mailId).subscribe({
          next: data => {
            console.log(data);

            let logData = {
              what_changed:'integracao',
              action:'updated',
              details:{
                description:'Atualização de integração de email via painel de gerenciamento PETinhodemim'          
              },
              element_id:data?.data?.id,
              performed_by:{
                nome:this.userData?.dados_pessoais?.nome + ' ' + this.userData?.dados_pessoais?.sobrenome,
                usuario: this.userData?.username,
                email: this.userData?.email
              },
              perfil_negocio:this.perfil_negocio
      
            }
      
            this.createLog(logData,null)

            //success alert
            this.UtilsService.successAlert('Dados atualizados com sucesso!');
            this.dismissModal();
          },
          error: error => {
            this.UtilsService.errorAlert('Erro ao atualizar dados!');
            this.dismissModal();
            console.error('There was an error!', error);
          }
        });
      } else {
        this.integracaoService.addEmailIntegration(integrationMailData).subscribe({
          next: data => {
            console.log(data);
            this.UtilsService.successAlert('Dados cadastrados com sucesso! Verifique sua caixa de entrada para confirmar a integração.');

            //Create Logs

            let logData = {
              what_changed:'integracao',
              action:'created',
              details:{
                description:'Criação de integração de email via painel de gerenciamento PETinhodemim'          
              },
              element_id:data?.data?.id,
              performed_by:{
                nome:this.userData?.dados_pessoais?.nome + ' ' + this.userData?.dados_pessoais?.sobrenome,
                usuario: this.userData?.username,
                email: this.userData?.email
              },
              perfil_negocio:this.perfil_negocio
      
            }
      
            this.createLog(logData,null)
            
            this.dismissModal();
          },
          error: error => {
            console.error('There was an error!', error);
            this.dismissModal();
          }

        });
      }

    }


  }

  createLog(elementData:any, id:any){


    this.logsService.createLogs(elementData).subscribe(result=>{
  
      console.log('Log criado')
  
    })
  }


}
