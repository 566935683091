import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, IonPopover, ModalController, NavParams, PickerController, PopoverController, ViewDidLeave, ViewWillEnter } from '@ionic/angular';
import { UtilsService } from '../../services/utils.service';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { parse } from 'date-fns';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { TutoresService } from 'src/app/services/tutores.service';
import { UserdataService } from 'src/app/services/userdata.service';
import { ProfissionaisService } from 'src/app/services/profissionais.service';
import { AgendaService } from 'src/app/services/agenda.service';
import { IncluirTutorModalPage } from '../incluir-tutor-modal/incluir-tutor-modal.page';
import { debounceTime, filter } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';
import { FilterProfissionais } from 'src/app/decorators/filter-profissionais.decorator';
import {LogsService} from 'src/app/services/logs.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';



@AutoUnsubscribe
@Component({
  selector: 'app-incluir-agendamento',
  templateUrl: './incluir-agendamento.page.html',
  styleUrls: ['./incluir-agendamento.page.scss'],
  providers: [DatePipe]
})
export class IncluirAgendamentoPage implements OnInit, ViewWillEnter, ViewDidLeave {
  


  ArrayAtendimentoTimes$ = this.agendaService.ArrayAtendimentoTimes_Subject$.asObservable()


  inputSubject = new Subject<string>();
  inputValue: string;
  
  selected_Tutor$: Observable<any>;

  showProfissional2 = false;
  showProfissional1 = false;
  selected_Tutor_Id_Number: number;
  showSlectedTutorInfo = false;
  TutorPets:any = [];
  profissionaisWithEspecialidades: any[];
  DisableData = true;
  filterdTutors:any = [];
  especiesList = this.utilsService.especies;

  filterKey: string;

  petsIdArray:any = [];

  phoneNumber: any;
  openAddPetForm:Boolean = false;
  selectedProfessionalId:number = null;

  public horaInicial_draged: Date;
  public horaFinal_draged: Date;
  public dataInicial_draged: string;
  public horario_inicial_MIlisegungos: any
  public acrescimoHoraFinal: any;
  public mode: string; // insert/edit/insert-draged
  public idAgendamento: any = 159;




  novo_agendamento_form = { // ------------------------------------apenas para preencher os dados do ion-select----EDITAR AQUI
    "celularTutor": '',
    "nomeTutor": '',
    "paciente": '',
    "tipoAtendimento": '',
    "especialidade": '',
    "data": '',
    "horaio": [],
    "duracao": [],
    "canalAgendamento": ''
  };

  especialidades_cadastradas: string[];



  horario_Final_MIlisegungos: any;
  hora_inicial_String: any;
  especialidade_selecionada: any;
  public daysSelecionados = []
  intervaloHoras = [];
  intervaloHorasFake = [];
  //Atual---------------------------------------------------------------
  listaTutores$: Observable<any>;
  userData: any = [];
  apiUrl:string = environment.strapiBaseUrl


  // formulario novo agendamento
  input_profissional_ID: number;
  input_pet_ID: number;
  input_tipo_atendimento: string
  id_usuario_agendamento: number;
  statusAgendamento: string;
  input_especialidades_procedimentos: any;
  input_canal_agendamento: string;
  input_especialidade: any;

  input_data = '';
  input_horario_inicial: any
  input_horario_final: string;
  Tutor_nome: any;
  Pet_nome: any;
  nomePet:string = '';
  especiePet:string = '';

  profissionaisWithEspecialidades$: Observable<any>;
  hora_inicial_HoraFinal_PorDia: {};
  input_Tutor_ID: any;
  tutors: any = [];
  tutorsTeste: any = [];
  Tutor_celular: any;
  maskConfig: string;
  ShowTutorList = true;
  minDate: string;
  // duracao_TempoAtendimento: any;
  NewTutorcelular: any;
  NewTutorId: any;
  NewTutorNome: any;
  minutesStep: any = [];
  janelaHoraFinal: any;
  perfilNegocio: any;
  horariosAgendados: {};
  AgendamentosProfissionalByData: any;
  toBlockHours: any;
  toBlockHoursMinutes: any;




  especialidadeSelecionada: number;
  especialidadesDiasDisponiveis: any[] = [];

  diasQueAtendeArray: any[] = [];
  horarioInicialQueAtendeArray: number[] = [];
  horarioFinalQueAtendeArray: number[] = [];
  duracao_TempoAtendimento: any[] = [];
  //minutesStep: number[] = [];
  horariosBloqueios: {};
  intervalHorasTimeString: string[];
  ArrayAtendimentoTimes: string[] = [];
  BloqueioProfissional: any;
  horariosLivre: string[];
  BloqueiosProfissionalByData = {};
  horasAgendadasToblock: string[];
  horasAgendadasToblock_Booleans: boolean[];
  horasBloqueioAgendaToblock: string[];
  antecedencia_agendamento_selectedProfissional: any;
  metaDados: any;
  especialidade_edit: any;
  selectedProfissional_Id: string;
  especiliadedesByProfissional: string[];
  profissional_nome: any;
  profissionalSelecionado: any;
  tutorCadastroCelular: string;
  subs = new SubSink();
  tempo_minimo_aviso: any;
  diaSemanaSelecionado: string;
  ShowDuracao_TempoAtendimento: boolean;
  testeHoraInical: any;
  profissionaisProcessados2: {};
  profissionaisProcessados3: {};
  profissionalProcessado4: any;
  lock = true;
  hora_Inical_toShow: any;
  hora_Final_toShow: any;
  keyTime: any;
  janelaHoraInicial: any;
  especiePetSelecionada: any;
  minAgendamentoInterval:number = null;

  disabledAntecedencia:Boolean = false;




  @ViewChild('dateTimePopover', { static: true }) dateTimePopover: IonPopover;
  @ViewChild(IonPopover) popover: IonPopover;
  @ViewChild('searchPopover') searchPopover;
  carenciaRetorno: any;
  showOptions = false;
  dataMProxima = false;
  dataMProxima1: boolean;
  carenciaDesconto: any;
  valorDesconto: any;
  selectedBuzzIndex: any;
  valorAgendamento: any;
  isOpen = false;


  constructor(public router: Router,
    public route: ActivatedRoute,
    public modalController: ModalController,
    public utilsService: UtilsService,
    private userDataService: UserdataService,
    private tutoresService: TutoresService,
    public profissionaisService: ProfissionaisService,
    public agendaService: AgendaService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private popoverCtrl: PopoverController,
    private navParams: NavParams,
    private pickerController: PickerController,
    private logsService: LogsService
  ) {

    let hoje = new Date().toLocaleDateString('pt-BR').substring(0, 10).replace(/\//g, '-');
    const parts = hoje.split('-');
    this.minDate = parts[2] + '-' + parts[1] + '-' + parts[0];



  }


  ngAfterViewInit() {
    // console.log(this.dateTimePopover);
  }

  searchTerm$ = new Subject<string>();
  data$ = this.searchTerm$.pipe(
    debounceTime(500),
    switchMap((term: string) => {
      if (!term?.length) {
        //console.log(term)
        this.input_pet_ID = null;
        this.Pet_nome = null
        this.especiePetSelecionada = 'especie'
        return of([]);
      }


      if (term?.length < 15) {
        this.zerarInputs()
        this.DisableData = true;
      }
      //console.log(term, term?.replace(/\D/g, ''))

      if (term?.length > 5) {

        return this.tutoresService.filterTutorsList(this.perfilNegocio, 'celular', term?.replace(/\D/g, ''))
          .pipe(
            map(
              result => {
                this.tutorsTeste = result?.data || [];

                if (!result?.data?.length && term?.length > 14) {
                  this.tutorCadastroCelular = term;
                  this.showNoResultsAlert();
                  this.searchTerm$?.next(null)
                  return of([])
                } return result;
              }
            )
          )
      }
      return of([]);
    })
  );

  zerarInputs() {

    if (this.selectedProfissional_Id) {
      this.input_pet_ID = null;
      this.Pet_nome = null
      this.especiePetSelecionada = 'especie'
      this.input_data = null
      this.input_horario_inicial = null
      this.input_horario_final = null
      this.input_especialidade = null
      this.input_especialidades_procedimentos = null
      this.Tutor_nome = null
      this.diasQueAtendeArray = []
      this.hora_inicial_HoraFinal_PorDia = {}
      this.ArrayAtendimentoTimes = []
      this.duracao_TempoAtendimento = []
      this.intervaloHoras = []
    } else {
      this.input_pet_ID = null;
      this.Pet_nome = null
      this.especiePetSelecionada = 'especie'
      this.input_data = null
      this.input_horario_inicial = null
      this.input_horario_final = null
      this.input_especialidade = null
      this.input_especialidades_procedimentos = null
      this.input_profissional_ID = null
      this.Tutor_nome = null
      this.diasQueAtendeArray = []
      this.hora_inicial_HoraFinal_PorDia = {}
      this.ArrayAtendimentoTimes = []
      this.duracao_TempoAtendimento = []
      this.intervaloHoras = []
    }

  }

  zerarInputsPetSlect() {
    if (this.selectedProfissional_Id) {
      this.input_pet_ID = null;
      this.Pet_nome = null
      this.especiePetSelecionada = 'especie'
      this.input_data = null
      this.input_horario_inicial = null
      this.input_horario_final = null
      this.input_especialidade = null
      this.input_especialidades_procedimentos = null
      this.diasQueAtendeArray = []
      this.hora_inicial_HoraFinal_PorDia = {}
      this.ArrayAtendimentoTimes = []
      this.duracao_TempoAtendimento = []
      this.intervaloHoras = []
    } else {
      this.input_pet_ID = null;
      this.Pet_nome = null
      this.especiePetSelecionada = 'especie'
      this.input_data = null
      this.input_horario_inicial = null
      this.input_horario_final = null
      this.input_especialidade = null
      this.input_especialidades_procedimentos = null
      this.input_profissional_ID = null

      this.diasQueAtendeArray = []
      this.hora_inicial_HoraFinal_PorDia = {}
      this.ArrayAtendimentoTimes = []
      this.duracao_TempoAtendimento = []
      this.intervaloHoras = []
    }


  }

  formatarCelular(numero) {
    // Remove todos os caracteres não numéricos
    numero = numero.replace(/\D/g, '');

    // Verifica se o número tem 11 dígitos
    if (numero.length >= 11) {
        // Formata o número
        return `(${numero.slice(0, 2)}) ${numero.slice(2, 7)}-${numero.slice(7)}`;
    }
  }


  ionViewWillEnter(): void {

    this.selectedProfissional_Id = this.navParams.get('idProfissional'); // selectedProfissional_Idindica que esse component está sendo usado pelo componente profissional agenda
    console.log('>>>>>>>>>>>selectedProfissional_Id', this.selectedProfissional_Id)
    this.getUserPersonalData()
    console.log(this.mode)
    switch (this.mode) {
      case 'insert':
        break;
      case 'edit':
        this.idAgendamento = this.navParams.get('id');
        this.Tutor_nome = this.navParams.get('tutorNome');
        this.mode = this.navParams.get('mode');
        this.Tutor_celular = this.utilsService.transformPhoneNumber(this.navParams.get('tutorCelular'));
        this.metaDados = this.navParams.get('metaDados');
        this.hora_Inical_toShow = this.navParams.get('horaInicial');
        this.hora_Final_toShow = this.navParams.get('horaFinal');
        this.selectedProfissional_Id = this.metaDados?.profissionalID;
        this.input_Tutor_ID = this.metaDados?.tutorObject.id;
        this.input_pet_ID = this.metaDados?.petId;
        this.input_canal_agendamento = this.metaDados?.canalAgendamento
        this.input_tipo_atendimento = this.metaDados?.tipo_atendimento
        this.Pet_nome = this.metaDados?.pet
        this.especiePetSelecionada = this.metaDados?.especie
        this.especialidade_edit = this.metaDados?.especialidade
        this.especialidade_selecionada = this.metaDados?.especialidade
        this.input_especialidades_procedimentos = this.metaDados?.especialidade
        this.input_especialidade = this.metaDados?.especialidade
        this.input_data = this.metaDados?.data,
          // this.mask_input_data = this.utilsService.getFormattedDate(this.input_data)
          console.log(this.input_data, this.metaDados, this.especialidade_selecionada)
        this.subs.add(this.userDataService.getUserPersonalData()
          .subscribe(result => {
            this.userData = result
            this.selectedBuzzIndex = localStorage.getItem('buzz_index') || 0;
            this.perfilNegocio = this.userData?.perfil_negocios[this.selectedBuzzIndex]?.id;
            // //console.log(this.perfilNegocio)
            this.profissionaisWithEspecialidades$ = this.profissionaisService.getProfissionaisWithEspecialidadeById(this.metaDados?.profissionalID)
              .pipe(
                map(profissional => {
                  this.profissionaisWithEspecialidades = profissional?.data
                  console.log(this.profissionaisWithEspecialidades)
                  this.selectedProfissional(null, this.metaDados?.profissionalID)                  
                })
              )
          }))
        break;
      case 'retorno':

        this.idAgendamento = this.navParams.get('id');
        this.Tutor_nome = this.navParams.get('tutorNome');
        this.mode = this.navParams.get('mode');
        this.Tutor_celular = this.utilsService.transformPhoneNumber(this.navParams.get('tutorCelular'));
        this.metaDados = this.navParams.get('metaDados');
        this.Pet_nome = this.metaDados?.pet;
        this.especiePetSelecionada = this.metaDados?.especie
        this.input_pet_ID = this.metaDados?.petId;
        this.input_canal_agendamento = this.metaDados?.canalAgendamento
        this.input_tipo_atendimento = this.metaDados?.tipo_atendimento
        this.selectedProfissional_Id = this.metaDados?.profissionalID;
        this.especialidade_edit = this.metaDados?.especialidade
        this.especialidade_selecionada = this.metaDados?.especialidade
        this.input_especialidades_procedimentos = this.metaDados?.especialidade
        this.input_especialidade = this.metaDados?.especialidade
        this.input_Tutor_ID = this.metaDados?.tutorObject.id;
        this.input_data = this.metaDados?.data
        console.log(this.metaDados, this.especialidade_selecionada)
        this.subs.add(this.userDataService.getUserPersonalData()
          .subscribe(result => {
            this.userData = result
            this.selectedBuzzIndex = localStorage.getItem('buzz_index') || 0;
            this.perfilNegocio = this.userData?.perfil_negocios[this.selectedBuzzIndex]?.id;
            // //console.log(this.perfilNegocio)
            this.profissionaisWithEspecialidades$ = this.profissionaisService.getProfissionaisWithEspecialidadeById(this.metaDados.profissionalID)
              .pipe(
                map(profissional => {
                  this.profissionaisWithEspecialidades = profissional.data
                  console.log(this.profissionaisWithEspecialidades)
                  this.selectedProfissional(null, this.metaDados.profissionalID)
                })
              )
          }))
        break;
      default:
        break;
    }

  }

  ionViewDidLeave(): void {
    this.subs.unsubscribe()
    //console.log('ionViewDidLeave')
  }

  ngOnInit() {
    this.intervaloHoras = [];
  }

  /**
  * Obtém os dados pessoais do usuário, incluindo as informações de agendamento e bloqueios de horários.
  * Se o componente for usado pelo componente de agendamento do profissional, as informações do profissional selecionado serão exibidas.
  */
  getUserPersonalData() {
    this.selectedBuzzIndex = localStorage.getItem('buzz_index');
    this.subs.add(this.userDataService.getUserPersonalData()
      .subscribe(result => {
        this.userData = result
        this.perfilNegocio = this.userData?.perfil_negocios?.[this.selectedBuzzIndex]?.id;
        // //console.log(this.perfilNegocio),
        if (!this.selectedProfissional_Id) {

          this.subs.add(this.profissionaisService?.getProfissionaisAgendamentos(this.perfilNegocio)?.subscribe(
            profissional => {
              console.log(profissional.data)
              this.horariosAgendados = this.agendaService.transformAgendamentos(profissional?.data);
              this.horariosBloqueios = this.agendaService.transformBloqueios(profissional?.data);
              console.group('Agenda Profissionais')
              console.log('profissionalSelecionado horariosBloqueios', this.horariosBloqueios)
              console.log('profissionalSelecionado horariosAgendados', this.horariosAgendados)
              console.groupEnd()
              let especialidadesArray = [];
              profissional.data.forEach(element => {
                //console.log(element?.attributes?.programacao?.janela_disponibilidade)
                let a = this.agendaService.getEspecialidadesArrayFromProfissional(element?.attributes?.programacao?.janela_disponibilidade, this.perfilNegocio)
                a.forEach(specialidade => {
                  especialidadesArray.push(specialidade);
                });
                console.log('especialidadesArray', a);
              });
              this.especialidades_cadastradas = especialidadesArray;
              
            }))
        } else if (this.selectedProfissional_Id) { // selectedProfissional_Idindica que esse component está sendo usado pelo componente profissional agenda

          this.profissionaisService.getProfissionalEditDetails(+this.selectedProfissional_Id).subscribe(
            {
              next: profissional => {
                this.profissional_nome = profissional.data[0]?.attributes?.dados_pessoais?.nome;
                this.profissionalSelecionado = profissional.data[0];
                this.tempo_minimo_aviso = profissional.data[0]?.attributes?.programacao?.tempo_minimo_aviso;
                this.especiliadedesByProfissional = this.agendaService.getEspecialidadesArrayFromProfissional(profissional?.data?.[0]?.attributes?.programacao?.janela_disponibilidade, this.perfilNegocio)
                console.log('Especialidades Array', this.especiliadedesByProfissional)
                console.log('profissionalSelecionado', this.profissionalSelecionado)
                this.profissionaisWithEspecialidades = [this.profissionalSelecionado];
                this.calcMinInterval(this.profissionalSelecionado?.id);
              }
            }
          )

          console.log('selectedProfissional_Id', this.selectedProfissional_Id, this.perfilNegocio)
          this.input_profissional_ID = +this.selectedProfissional_Id;
          // Subscreve-se ao serviço profissionaisService para buscar os agendamentos, bloqueios e programação do profissional selecionado.
          this.subs.add(this.profissionaisService?.getProfissionalIDAgendamentos(this.perfilNegocio, this.selectedProfissional_Id)?.subscribe(profissional => {
            console.log(profissional)
            this.horariosAgendados = this.agendaService.removerAgendamento(this.agendaService.transformAgendamentos(profissional?.data), this.idAgendamento);
            this.horariosBloqueios = this.agendaService.transformBloqueios(profissional?.data);


            console.group('Agenda Profissionais')
            console.log('profissionalSelecionado horariosBloqueios', this.horariosBloqueios)
            console.log('profissionalSelecionado horariosAgendados', this.horariosAgendados)
            console.groupEnd()
            this.selectedProfissional(null, this.selectedProfissional_Id)
          }))

        }

      }))
  }





  onClick() {
    this.dataMaisProxima([...this.profissionaisWithEspecialidades], { ...this.horariosAgendados }, { ...this.horariosBloqueios }, this.perfilNegocio)
  }

  /**
  * Encontra a data mais próxima disponível para agendamento, com base na programação de disponibilidade, agendamentos e bloqueios de um conjunto de profissionais.
  * @param {Array} profissionaisWithEspecialidades - Array de objetos que contêm informações de profissionais, incluindo suas especialidades e programação de disponibilidade.
  * @param {Object} horariosAgendados - Objeto que contém informações de agendamentos já realizados, organizados por profissional.
  * @param {Object} horariosBloqueios - Objeto que contém informações de bloqueios de horário, organizados por profissional e data.
  * @returns {Object} Objeto que contém informações sobre a data mais próxima disponível para agendamento de cada profissional, incluindo nome, horário de disponibilidade, agendamentos e bloqueios para essa data.
  */
  dataMaisProxima(profissionaisWithEspecialidades, horariosAgendados, horariosBloqueios, perfilNegocio) {


    // this.input_data = null
    console.group('Detalhes dos profissionais e horários');
    console.log('Profissionais com especialidade selecionada:', profissionaisWithEspecialidades);
    console.log('Horários agendados:', horariosAgendados);
    console.log('Horários de bloqueio:', horariosBloqueios);
    console.log('Perfil de negócio:', perfilNegocio);
    console.groupEnd();
    const result = {};


    profissionaisWithEspecialidades?.forEach(profissionaL => {
      let profissionalID = profissionaL?.id;
      let especialidades_dias_disponiveis = profissionaL?.attributes?.programacao?.janela_disponibilidade?.reduce((acumulador, janela) => {



        switch (this.mode) {
          case 'insert':
            if (janela?.visivel && !(janela?.tipo_atendimento && janela.tipo_atendimento.length === 1 && janela?.tipo_atendimento[0] == "Retorno") && +janela?.perfil_negocio === perfilNegocio) {
              if (!acumulador[janela?.especialidades]) {
                acumulador[janela?.especialidades] = [];
              }
              acumulador[janela?.especialidades]?.push({
                dias: janela?.dias_disponiveis,
                horario_inicial: janela?.horario_inicial,
                horario_final: janela?.horario_final,
                duracao_TempoAtendimento: janela?.duracao_TempoAtendimento,
                visivel: janela?.visivel,
                valor: janela?.valor,
              });
            }
            break;

          case 'edit':
            if (janela?.visivel && !(janela?.tipo_atendimento && janela.tipo_atendimento.length === 1 && janela?.tipo_atendimento[0] == "Retorno") && +janela?.perfil_negocio === perfilNegocio) {
              if (!acumulador[janela?.especialidades]) {
                acumulador[janela?.especialidades] = [];
              }
              acumulador[janela?.especialidades]?.push({
                dias: janela?.dias_disponiveis,
                horario_inicial: janela?.horario_inicial,
                horario_final: janela?.horario_final,
                duracao_TempoAtendimento: janela?.duracao_TempoAtendimento,
                visivel: janela?.visivel,
                valor: janela?.valor,
              });
            }

            break;
          case 'retorno':
            if (janela?.visivel && janela?.tipo_atendimento.includes("Retorno") && +janela?.perfil_negocio === perfilNegocio) {
              if (!acumulador[janela?.especialidades]) {
                acumulador[janela?.especialidades] = [];
              }
              acumulador[janela?.especialidades]?.push({
                dias: janela?.dias_disponiveis,
                horario_inicial: janela?.horario_inicial,
                horario_final: janela?.horario_final,
                duracao_TempoAtendimento: janela?.duracao_TempoAtendimento,
                visivel: janela?.visivel,
                valor: janela?.valor,
              });
            }

            break;
          default:
            break;
        }


        return this.agendaService.replicarEspecialidades(acumulador);
      }, {});

      let hora_inicial_HoraFinal_PorDia;
      if (especialidades_dias_disponiveis) {
        hora_inicial_HoraFinal_PorDia = this.agendaService.Hora_inicial_HoraFinal_PorDia(especialidades_dias_disponiveis[this.especialidade_selecionada]);
      }

      let AgendamentosProfissionalByData = horariosAgendados?.[profissionalID] || [];
      let BloqueiosProfissionalByData = horariosBloqueios?.[profissionalID] || [];

      result[profissionalID] = {
        nome: profissionaL?.attributes?.dados_pessoais?.nome,
        antecedencia_agendamento: profissionaL?.attributes?.programacao?.antecedencia_agendamento,
        tempo_minimo_aviso: profissionaL?.attributes?.programacao?.tempo_minimo_aviso,
        hora_inicial_HoraFinal_PorDia,
        AgendamentosProfissionalByData,
        BloqueiosProfissionalByData,
      };

    });



    console.log('Tratamento de dados 1:', result);
    let resultado = { ...result }

    this.profissionaisProcessados2 = {
      ...this.agendaService.extrairDadosProfissionais({ ...resultado })
    }
    let profissinaisObject = {
      ...this.profissionaisProcessados2
    }
    this.profissionaisProcessados3 = {
      ...this.agendaService.gerarDatasAtendimento({ ...profissinaisObject })
    }
    let profissionais = { ...this.profissionaisProcessados3 }

    if (profissionais) {
      let profissinaisFinal = {
        ...this.agendaService.excluirAgendamentosBloqueios({ ...profissionais }, { ...profissinaisObject })
      }
      const profissionalProcessado4_sanitazed = {
        ...this.agendaService.removeEmptyObjects({ ...profissinaisFinal })
      }
      console.group('Tratamento de dados');
      console.log('Tratam this.daento de dados 1:', result);
      console.log('Tratamento de dados 2 - data/dia e hora que atenda:', this.profissionaisProcessados2);
      console.log('Tratamento de dados 3 - array step 15min entre hora inicial e final:', profissionais);
      console.log('Tratamento de dados 4:', profissionalProcessado4_sanitazed);
      console.groupEnd();   
      
      console.log('PROFISSIONAIS FINAIS', profissinaisFinal )

      if (Object.keys(profissionalProcessado4_sanitazed)?.length === 0) {

        switch (this.mode) {
          case 'insert':

            break;

          case 'edit':


            break;
          case 'retorno':
            this.utilsService.errorAlert('Não há horarios livres para retorno')
            break;
          default:
            break;
        }

      } else {
        
        this.selectedProfessionalId = this.profissionaisWithEspecialidades[0]?.id
        console.log('DURAÇÃO ATENDIMENTO LINHA 673', this.selectedProfessionalId);
        let tempMinOBJ = this.encontrarMenorDuracaoAtendimento(this.profissionaisWithEspecialidades);
        let minTimeString = tempMinOBJ[0]?.menor_duracao_atendimento;
        this.minAgendamentoInterval = tempMinOBJ[0]?.menor_duracao_atendimento;

        console.log('TempMinOBJ', tempMinOBJ)
          

        // const teste = this.agendaService.obterProximoHorario(profissionalProcessado4_sanitazed)
        const teste1 = this.agendaService.findAvailableSlot(profissionalProcessado4_sanitazed)   


        // for(let tempoMin of tempMinOBJ){

        //   console.log(this.selectedProfessionalId)

        //   if(tempoMin.id == this.selectedProfessionalId){
        //     this.duracao_TempoAtendimento.push(tempoMin.menor_duracao_atendimento)
        //   }

        // }

        console.log(minTimeString);        

        this.minutesStep = this.agendaService.minutesStep(this.duracao_TempoAtendimento);
        this.hora_inicial_HoraFinal_PorDia = result[teste1['id']]?.hora_inicial_HoraFinal_PorDia

        this.input_profissional_ID = teste1['id'];

        this.selectedProfissionalDataMaisProxima(teste1['id'])
        this.input_horario_inicial = teste1['hora'];
        this.input_data = teste1['data'];
        this.agendaService.ArrayAtendimentoTimes_Subject$.next(teste1['HorarioAtendimento'])
        console.log(teste1)

        const dateString = teste1['data'];
        const date = new Date(`${dateString}T00:00:00-03:00`);


        let diaSemanaSelecionado = this.agendaService.extrairDiaSemanaString(date.getDay());
        this.diaSemanaSelecionado = diaSemanaSelecionado;
        console.log(this.diaSemanaSelecionado, this.input_data)

        return this.onNovaDataAgendaChange(null, date, 'datamaisProxima')

      }
    }




  }

  calcMinInterval(id:number){
    this.selectedProfessionalId = id
    console.log('PROFISSIONAIS POR ESPECIALIDADE', this.profissionaisWithEspecialidades);
    let profissional = this.profissionaisWithEspecialidades.filter(profissional=>profissional.id == id)
    console.log('PROFISSIONAL', profissional)
    let tempMinOBJ = this.encontrarMenorDuracaoAtendimento(profissional);    
    this.minAgendamentoInterval = tempMinOBJ[0]?.menor_duracao_atendimento;

    console.log('TempMinOBJ', tempMinOBJ)

  }

converterParaMinutos(duracao) { 
    let duracaoTime = duracao.replace('h', ' h');

    console.log('duração', duracaoTime);

    const [valor, unidade] = duracaoTime.split(' ');
    if (unidade === 'min') {
        return parseInt(valor);
    } else if (unidade === 'h') {
        return parseInt(valor) * 60;
    } else if (unidade === 'h' && valor.includes('h')) {
        const [horas, minutos] = valor.split('h');
        return parseInt(horas) * 60 + parseInt(minutos);
    }
    return 0;
}

encontrarMenorDuracaoAtendimento(profissionais) {
    return profissionais.map(profissional => {
        const duracoes = profissional.attributes.programacao.janela_disponibilidade.flatMap(janela => 
            janela.duracao_TempoAtendimento.map(this.converterParaMinutos)
        );
        const menorDuracao = Math.min(...duracoes);
        console.log('MENOR DURAÇÃO',menorDuracao);
        return {
            id: profissional.id,
            menor_duracao_atendimento: menorDuracao
        };
    });
}

  // encontrarMenorDuracaoAtendimento(profissionais) {
  //   return profissionais.map(profissional => {
  //     const menorDuracaoAtendimento = Math.min(
  //       ...profissional.attributes.programacao.janela_disponibilidade.map(janela => {
  //         switch (janela.duracao_atendimento) {
  //           case '15 min':
  //             return 15;
  //           case '30 min':
  //             return 30;
  //           case '45 min':
  //             return 45;
  //           case '1 h':
  //             return 60;
  //           case '1h 15min':
  //             return 75;
  //           case '1h 30min':
  //             return 90;
  //           case '1h 45min':
  //             return 105;
  //           case '2h':
  //             return 120;
  //           default:
  //             return 0;
  //         }
  //       })
  //     );
  
  //     return {
  //       id: profissional.id,
  //       menor_duracao_atendimento: menorDuracaoAtendimento
  //     };
  //   });
  // }
  

  // console.log(profissionaL?.attributes?.dados_pessoais.nome, 'hora_inicial_HoraFinal_PorDia', this.hora_inicial_HoraFinal_PorDia)
  // console.log(profissionaL?.attributes?.dados_pessoais.nome, "Agendamentos do profissional: ", AgendamentosProfissionalByData);
  // console.log(profissionaL?.attributes?.dados_pessoais.nome, "Bloqueios do profissional: ", BloqueiosProfissionalByData);



  habilitarBusca() {
    this.ShowTutorList = true;
  }



  /**
   * Este é um método chamado "selectTutor" que aceita três parâmetros de entrada: "id", "nome" e "celular" (opcional). O objetivo deste método é selecionar um tutor e exibir suas informações.

  O método define o valor de "Tutor_nome" como "nome" e usa o serviço "utilsService" para transformar o número de telefone "celular" em um formato legível para a pesquisa, definindo o valor de "filterKey" e usando "searchTerm$.next" para atualizar o valor de "searchTerm$".

  Em seguida, o método define "ShowTutorList" como false para ocultar a lista de tutores, define "selected_Tutor_Id_Number" como "id" e define "showSlectedTutorInfo" como true para exibir as informações do tutor selecionado. Ele também define "input_Tutor_ID" como o mesmo valor de "selected_Tutor_Id_Number".

  Em seguida, o método chama o método "getTutorDetails" de "tutoresService" para obter os detalhes do tutor selecionado. Este método retorna um observable, que a função atribui à variável "selected_Tutor $".

  O observable retornado por "getTutorDetails" é então encadeado por meio de um operador "map". O operador "map" recebe uma função de retorno de chamada que recebe os detalhes do tutor selecionado e define o valor de "TutorPets" como os dados dos pets do tutor selecionado, obtidos a partir dos detalhes do tutor.
  O método também contém uma instrução de console.log para registrar os detalhes do tutor selecionado.
   * @param id - id do tutor
   * @param nome  - nome do tutor
   * @param celular  - celular do tutor
   */

  selectTutor(id, nome, celular?) {

    this.input_pet_ID = null;
    this.Pet_nome = 'Selecione um Pet ';
    this.especiePetSelecionada = 'aaa'




    this.Tutor_nome = nome;
    this.filterKey = this.utilsService.transformPhoneNumber(celular)
    this.searchTerm$.next(this.utilsService.transformPhoneNumber(celular))
    this.ShowTutorList = false;
    this.selected_Tutor_Id_Number = id;
    this.showSlectedTutorInfo = true;
    this.input_Tutor_ID = this.selected_Tutor_Id_Number;

    this.selected_Tutor$ = this.tutoresService.getTutorDetails(this.selected_Tutor_Id_Number)
      .pipe(
        map(
          (selectedTutor) => {
            console.log(selectedTutor);
            // this.Tutor_nome = selectedTutor?.data[0]?.attributes?.dados_pessoais?.nome
            this.TutorPets = selectedTutor?.data[0]?.attributes?.pets?.data
          }
        )
      )
  }
  selectPet($event) {
    console.log($event?.['detail']['value'])
    let IdPetSelected = $event?.['detail']['value'];


    this.input_pet_ID = IdPetSelected;

    this.TutorPets?.map(
      (pet: any) => {
        if (pet?.id == IdPetSelected) {
          this.Pet_nome = pet?.attributes?.nome
          this.especiePetSelecionada = pet?.attributes?.especie
          console.log(this.Pet_nome, this.especiePetSelecionada)
          return pet
        }
        return pet
      })

  }


  showSelectPet() {

    this.showOptions = true

    if (this.input_especialidade) {
      this.zerarInputsPetSlect()
    }


  }

  selectPet1(id, nome, especie) {
    let IdPetSelected = id;
    this.input_pet_ID = IdPetSelected;
    this.Pet_nome = nome
    this.especiePetSelecionada = especie

    this.showOptions = false


  }


  /**
  -Objetivo: Mudar a especialidade selecionada e atualizar a lista de profissionais disponíveis de acordo com a nova especialidade.

  -Se "selectedProfissional_Id" for verdadeiro, chama o método "transformToGroupedAvailability" para atualizar a disponibilidade agrupada e define "input_especialidades_procedimentos" como "especialidade_selecionada".

  -Se "selectedProfissional_Id" não for verdadeiro:

    - Define "input_especialidades_procedimentos" como "especialidade_selecionada".

    - Define "input_profissional_ID" como nulo.

    - Redefine "janelaHoraFinal" como nulo.

    - Chama o método "getProfissionalDetailsByEspecialidade" de "profissionaisService" para obter os detalhes dos profissionais disponíveis para a nova especialidade.

    - Atribui o observable retornado por "getProfissionalDetailsByEspecialidade" à variável "profissionaisWithEspecialidades$".


  -O operador "map" recebe uma função de retorno de chamada que:

    - Recebe os detalhes dos profissionais disponíveis.

    - Define o valor de "profissionaisWithEspecialidades" como uma lista de profissionais filtrada que possuem a nova especialidade selecionada e têm janelas de disponibilidade visíveis.

    - Registra a lista atualizada de profissionais disponíveis com uma instrução de console.log.

    - Define "showProfissional2" como verdadeiro para exibir a lista atualizada de profissionais disponíveis.
   * @param $event
   */
  changeEspecialidade($event) {
    console.log($event?.detail?.value)
    if ($event?.detail?.value === null) {
      return
    }

    this.input_especialidade = $event?.detail?.value


    if ($event?.detail?.value != this.input_especialidades_procedimentos) {
      this.input_data = null
      this.diasQueAtendeArray = []
      this.hora_inicial_HoraFinal_PorDia = {}
      this.ArrayAtendimentoTimes = []
      this.duracao_TempoAtendimento = []
      this.intervaloHoras = []
      this.input_horario_final = null
      this.input_horario_inicial = null
    }


    this.especialidade_selecionada = $event?.detail?.value


    if (this.selectedProfissional_Id) { // selectedProfissional_Idindica que esse component está sendo usado pelo componente profissional agenda
      this.transformToGroupedAvailability(this.profissionalSelecionado, this.especialidade_selecionada)
      this.input_especialidades_procedimentos = this.especialidade_selecionada;
      this.profissionaisWithEspecialidades = [this.profissionalSelecionado]

      let HasCarencia = this.profissionalSelecionado?.attributes?.especialidades_procedimentos?.filter(
        object => {
          return object['nome'] === this.especialidade_selecionada
        }
      )?.[0]?.carencia



      if (HasCarencia) {
        console.log('HasCarencia', HasCarencia)

        this.carenciaRetorno = this.profissionalSelecionado?.attributes?.especialidades_procedimentos?.filter(
          object => {
            return object['nome'] === this.especialidade_selecionada
          }
        )?.[0]?.prazo



        this.carenciaDesconto = 0;
        this.valorDesconto = 0;

        let testando = this.carenciaRetorno

        console.log(testando)
      } else {
        console.log('HasCarencia', HasCarencia)
        this.carenciaRetorno = 0

        this.valorDesconto = this.profissionalSelecionado?.attributes?.especialidades_procedimentos?.filter(
          object => {
            return object['nome'] === this.especialidade_selecionada
          }
        )?.[0]?.desconto

        console.log(this.valorDesconto)

        this.carenciaDesconto = this.profissionalSelecionado?.attributes?.especialidades_procedimentos?.filter(
          object => {
            return object['nome'] === this.especialidade_selecionada
          }
        )?.[0]?.carenciaDesconto
      }

    } else {
      this.input_especialidades_procedimentos = this.especialidade_selecionada;
      console.log(this.input_especialidades_procedimentos)
      this.input_profissional_ID = null; // ajuste visualização html
      this.janelaHoraFinal = null; // ajuste visualização html
      this.janelaHoraInicial = null; // ajuste visualização html


      this.profissionaisWithEspecialidades$ = this.profissionaisService
        .getProfissionalDetailsByEspecialidade(this.especialidade_selecionada, this.perfilNegocio)
        .pipe(
          map(profissional => {
            this.profissionaisWithEspecialidades = profissional?.data?.filter((profissional) => {
              profissional.attributes.programacao.janela_disponibilidade = profissional?.attributes?.programacao?.
                janela_disponibilidade?.filter(
                  (janela) =>
                    janela?.visivel !== false && (janela?.tipo_atendimento?.includes('Presencial') || janela?.tipo_atendimento?.includes('Online'))
                );
              return profissional?.attributes?.programacao?.janela_disponibilidade?.
                some(
                  (janela) => janela?.especialidades?.includes(
                    this.especialidade_selecionada
                  ) && profissional?.attributes?.especialidades_procedimentos?.some((item: any) => {
                    return (
                      item.nome === this.especialidade_selecionada &&
                      item.especies_atendidas?.includes(this.especiePetSelecionada) // Filktra os profissionais por especie atendida
                    );
                  })
                );
            });
            console.log('profissionaisWithEspecialidades', this.profissionaisWithEspecialidades)
            // if (this.profissionaisWithEspecialidades?.length < 1 && this.Tutor_nome) {
            //   this.utilsService.errorAlert(`Nenhum profissional encontrado para a especialidade ${this.especialidade_selecionada}, que atende à espécie ${this.especiePetSelecionada}`)
            // }
            return this.profissionaisWithEspecialidades;
          })
        );



      this.showProfissional2 = true;
    }





  }

  /**
  * Objetivo: Selecionar um profissional e atualizar as informações relacionadas.
  - Define "janelaHoraFinal" como nulo.
  - Define "profissionalID" como "$event?.['detail']?.['value']" ou "id".
  - Define "input_profissional_ID" como "profissionalID".
  - Verifica se "selectedProfissional_Id" é verdadeiro.
  - Se "selectedProfissional_Id" for verdadeiro:
  - Define "input_profissional_ID" como "profissionalID".
  - Define "selectedProfissional" como "this.profissionalSelecionado".
  - Se "selectedProfissional_Id" não for verdadeiro e "profissionalID" existir:
  - Define "selectedProfissional" como o primeiro profissional encontrado em "profissionaisWithEspecialidades" cujo ID corresponde a "profissionalID".
  - Se "selectedProfissional_Id" não for verdadeiro e "profissionalID" não existir, retorna.
  - Define "tempo_minimo_aviso" como o valor de "selectedProfissional?.attributes?.programacao?.tempo_minimo_aviso".
  - Define "antecedencia_agendamento_selectedProfissional" como o valor de "selectedProfissional?.attributes?.programacao?.antecedencia_agendamento".
  - Reduz as janelas de disponibilidade para uma única lista agrupada por especialidade em "especialidades_dias_disponiveis".
  - Define "diasQueAtendeArray" como a lista de dias que o profissional atende para a especialidade selecionada.
  - Define "minutesStep" como o número de minutos em que o tempo de atendimento é dividido.
  - Define "daysSelecionados" como a lista de dias convertida para números.
  - Define "hora_inicial_HoraFinal_PorDia" como a lista de horários de início e fim para cada dia da semana.
  - Se "profissionalID" existir:
  - Define "AgendamentosProfissionalByData" como a lista de horários agendados para o profissional e a data selecionada.
  - Define "BloqueiosProfissionalByData" como a lista de bloqueios para o profissional e a data selecionada.
  - Registra as informações atualizadas do profissional selecionado com uma instrução de console.log.

  /**
  * Seleciona um profissional e atualiza as informações relacionadas.
  *
  * @param {Event} $event - O objeto de evento que acionou a seleção do profissional.
  * @param {string} id - O ID do profissional (opcional).
  *
  * @returns {void}
  */
  selectedProfissional($event: Event, id?) {
    this.janelaHoraFinal = null;
    this.janelaHoraInicial = null;
    this.DisableData = false;
    let profissionalID = $event?.['detail']?.['value'] || id
    this.selectedProfessionalId = profissionalID;

    if (this.input_profissional_ID != profissionalID && this.dataMProxima === false) {
      this.input_data = null
      this.diasQueAtendeArray = []
      this.hora_inicial_HoraFinal_PorDia = {}
      this.ArrayAtendimentoTimes = []
      this.duracao_TempoAtendimento = []
      this.intervaloHoras = []
      this.input_horario_final = null
      this.input_horario_inicial = null
    }




    // console.log($event?.['detail']?.['value'])


    // console.log(profissionalID)
    this.input_profissional_ID = profissionalID


    if (this.selectedProfissional_Id) {// selectedProfissional_Idindica que esse component está sendo usado pelo componente profissional agenda
      this.input_profissional_ID = profissionalID
      var selectedProfissional = { ...this.profissionalSelecionado };
      console.log('selectedProfissional', selectedProfissional)
      this.tempo_minimo_aviso = selectedProfissional?.attributes?.programacao?.tempo_minimo_aviso;


    } else if (profissionalID) {
      var selectedProfissional = { ...this.profissionaisWithEspecialidades?.find(profissional => profissional['id'] == profissionalID) };
      console.log('selectedProfissional', selectedProfissional)
      this.tempo_minimo_aviso = selectedProfissional?.attributes?.programacao?.tempo_minimo_aviso;     

    } else {
      this.tempo_minimo_aviso = selectedProfissional?.attributes?.programacao?.tempo_minimo_aviso;

      return
    }


    if (this.mode === 'retorno') {
      selectedProfissional = this.agendaService.removeJanelasSemRetorno(selectedProfissional)
      console.log('selectedProfissional', selectedProfissional)
      this.tempo_minimo_aviso = selectedProfissional?.attributes?.programacao?.tempo_minimo_aviso;

    }


    // let Janela_atualizada_Tempo_Minimo_aviso = this.agendaService.atualizarJanelaDisponibilidade(tempo_minimo_aviso,selectedProfissional )

    this.antecedencia_agendamento_selectedProfissional = selectedProfissional?.attributes?.programacao?.antecedencia_agendamento;

    let HasCarencia = selectedProfissional?.attributes?.especialidades_procedimentos?.filter(
      object => {
        return object['nome'] === this.especialidade_selecionada
      }
    )?.[0]?.carencia

    console.log(HasCarencia)

    if (HasCarencia) {

      this.carenciaRetorno = selectedProfissional?.attributes?.especialidades_procedimentos?.filter(
        object => {
          return object['nome'] === this.especialidade_selecionada
        }
      )?.[0]?.prazo



      this.carenciaDesconto = 0;
      this.valorDesconto = 0;

      let testando = this.carenciaRetorno

      console.log(testando)
    } else {
      this.carenciaRetorno = 0

      this.valorDesconto = selectedProfissional?.attributes?.especialidades_procedimentos?.filter(
        object => {
          return object['nome'] === this.especialidade_selecionada
        }
      )?.[0]?.desconto

      this.carenciaDesconto = selectedProfissional?.attributes?.especialidades_procedimentos?.filter(
        object => {
          return object['nome'] === this.especialidade_selecionada
        }
      )?.[0]?.carenciaDesconto
    }
    // console.log(this.antecedencia_agendamento_selectedProfissional, this.tempo_minimo_aviso)

    //reduz as janelas de disponibilidade para uma única lista agrupada por especialidade
    let especialidades_dias_disponiveis = selectedProfissional?.attributes?.programacao?.janela_disponibilidade?.reduce((acumulador, janela) => {
      switch (this.mode) {
        case 'insert':
          if (janela?.visivel && !(janela?.tipo_atendimento && janela.tipo_atendimento.length === 1 && janela?.tipo_atendimento[0] == "Retorno") && +janela?.perfil_negocio === this.perfilNegocio) {
            if (!acumulador[janela?.especialidades]) {
              acumulador[janela?.especialidades] = [];
            }
            acumulador[janela?.especialidades].push({
              dias: janela?.dias_disponiveis,
              horario_inicial: janela?.horario_inicial,
              horario_final: janela?.horario_final,
              duracao_TempoAtendimento: janela?.duracao_TempoAtendimento,
              visivel: janela?.visivel,
              valor: janela?.valor,
            });
          }
          break;

        case 'edit':
          if (janela?.visivel && !(janela?.tipo_atendimento && janela.tipo_atendimento.length === 1 && janela?.tipo_atendimento[0] == "Retorno") && +janela?.perfil_negocio === this.perfilNegocio) {
            if (!acumulador[janela?.especialidades]) {
              acumulador[janela?.especialidades] = [];
            }
            acumulador[janela?.especialidades].push({
              dias: janela?.dias_disponiveis,
              horario_inicial: janela?.horario_inicial,
              horario_final: janela?.horario_final,
              duracao_TempoAtendimento: janela?.duracao_TempoAtendimento,
              visivel: janela?.visivel,
              valor: janela?.valor,
            });
          }

          break;
        case 'retorno':
          if (janela?.visivel && +janela?.perfil_negocio === this.perfilNegocio) {
            if (!acumulador[janela?.especialidades]) {
              acumulador[janela?.especialidades] = [];
            }
            acumulador[janela?.especialidades].push({
              dias: janela?.dias_disponiveis,
              horario_inicial: janela?.horario_inicial,
              horario_final: janela?.horario_final,
              duracao_TempoAtendimento: janela?.duracao_TempoAtendimento,
              visivel: janela?.visivel,
              valor: janela?.valor,
            });
          }

          break;
        default:
          break;
      }
      return this.agendaService.replicarEspecialidades(acumulador);
    }, {});



    //console.log('especialidades_dias_disponiveis', especialidades_dias_disponiveis);
    // console.log(especialidades_dias_disponiveis[this.especialidade_selecionada]);



    if (especialidades_dias_disponiveis && this.especialidade_selecionada) {

      // console.log(especialidades_dias_disponiveis[this.especialidade_selecionada])

      this.diasQueAtendeArray = this.agendaService.diasQueAtendeArray(this.especialidade_selecionada, especialidades_dias_disponiveis); /*Array dos dias */
      this.minutesStep = this.agendaService.minutesStep(this.duracao_TempoAtendimento);
      this.daysSelecionados = this.convertWeekDaysToNumbers(this.diasQueAtendeArray)
      this.hora_inicial_HoraFinal_PorDia = this.agendaService.Hora_inicial_HoraFinal_PorDia(especialidades_dias_disponiveis[this.especialidade_selecionada])

      // console.log(this.especialidade_selecionada)
      // console.log(especialidades_dias_disponiveis)
      // console.log(this.daysSelecionados)
      // console.log(this.diasQueAtendeArray)
      // console.log(this.horarioInicialQueAtendeArray)
      // console.log(this.horarioFinalQueAtendeArray)
      // console.log(this.duracao_TempoAtendimento)
      // console.log(this.minutesStep)
      // console.log(this.daysSelecionados)


    }


    if (profissionalID) {
      this.AgendamentosProfissionalByData = this.horariosAgendados?.[profissionalID] || [];
      this.BloqueiosProfissionalByData = this.horariosBloqueios?.[profissionalID] || [];
      console.group('Agendamentos e bloqueios por data');
      console.log('Agendamentos do profissional por data:', this.AgendamentosProfissionalByData);
      console.log('Bloqueios do profissional por data:', this.BloqueiosProfissionalByData);
      // console.log('this.horariosBloqueios?.[profissionalID]?.horarios:', this.horariosBloqueios);
      console.groupEnd();

    }

  }


  selectedProfissionalDataMaisProxima(id) {

    var selectedProfissional = this.profissionaisWithEspecialidades?.find(profissional => profissional['id'] == id);
    
    if (this.mode === 'retorno') {
      selectedProfissional = this.agendaService.removeJanelasSemRetorno(selectedProfissional)
      console.log('selectedProfissional', selectedProfissional)
      this.tempo_minimo_aviso = selectedProfissional?.attributes?.programacao?.tempo_minimo_aviso;
    }
    this.tempo_minimo_aviso = selectedProfissional?.attributes?.programacao?.tempo_minimo_aviso;


    // let Janela_atualizada_Tempo_Minimo_aviso = this.agendaService.atualizarJanelaDisponibilidade(tempo_minimo_aviso,selectedProfissional )

    this.antecedencia_agendamento_selectedProfissional = selectedProfissional?.attributes?.programacao?.antecedencia_agendamento;
    let HasCarencia = selectedProfissional?.attributes?.especialidades_procedimentos?.filter(
      object => {
        return object['nome'] === this.especialidade_selecionada
      }
    )?.[0]?.carencia

    console.log(HasCarencia)

    if (HasCarencia) {

      this.carenciaRetorno = selectedProfissional?.attributes?.especialidades_procedimentos?.filter(
        object => {
          return object['nome'] === this.especialidade_selecionada
        }
      )?.[0]?.prazo

      let testando = this.carenciaRetorno

      console.log(testando)
    } else {
      this.carenciaRetorno = 0
      this.carenciaDesconto = selectedProfissional?.attributes?.especialidades_procedimentos?.filter(
        object => {
          return object['nome'] === this.especialidade_selecionada
        }
      )?.[0]?.carenciaDesconto
    }
    // console.log(this.antecedencia_agendamento_selectedProfissional, this.tempo_minimo_aviso)

    //reduz as janelas de disponibilidade para uma única lista agrupada por especialidade
    let especialidades_dias_disponiveis = selectedProfissional?.attributes?.programacao?.janela_disponibilidade?.reduce((acumulador, janela) => {
      switch (this.mode) {
        case 'insert':
          if (janela?.visivel && !(janela?.tipo_atendimento && janela.tipo_atendimento.length === 1 && janela?.tipo_atendimento[0] == "Retorno") && +janela?.perfil_negocio === this.perfilNegocio) {
            if (!acumulador[janela?.especialidades]) {
              acumulador[janela?.especialidades] = [];
            }
            acumulador[janela?.especialidades].push({
              dias: janela?.dias_disponiveis,
              horario_inicial: janela?.horario_inicial,
              horario_final: janela?.horario_final,
              duracao_TempoAtendimento: janela?.duracao_TempoAtendimento,
              visivel: janela?.visivel,
              valor: janela?.valor,
            });
          }
          break;

        case 'edit':
          if (janela?.visivel && !(janela?.tipo_atendimento && janela.tipo_atendimento.length === 1 && janela?.tipo_atendimento[0] == "Retorno") && +janela?.perfil_negocio === this.perfilNegocio) {
            if (!acumulador[janela?.especialidades]) {
              acumulador[janela?.especialidades] = [];
            }
            acumulador[janela?.especialidades].push({
              dias: janela?.dias_disponiveis,
              horario_inicial: janela?.horario_inicial,
              horario_final: janela?.horario_final,
              duracao_TempoAtendimento: janela?.duracao_TempoAtendimento,
              visivel: janela?.visivel,
              valor: janela?.valor,
            });
          }

          break;
        case 'retorno':
          if (janela?.visivel && +janela?.perfil_negocio === this.perfilNegocio) {
            if (!acumulador[janela?.especialidades]) {
              acumulador[janela?.especialidades] = [];
            }
            acumulador[janela?.especialidades].push({
              dias: janela?.dias_disponiveis,
              horario_inicial: janela?.horario_inicial,
              horario_final: janela?.horario_final,
              duracao_TempoAtendimento: janela?.duracao_TempoAtendimento,
              visivel: janela?.visivel,
              valor: janela?.valor,
            });
          }

          break;
        default:
          break;
      }
      return this.agendaService.replicarEspecialidades(acumulador);
    }, {});



    // console.log('especialidades_dias_disponiveis', especialidades_dias_disponiveis);
    // console.log(especialidades_dias_disponiveis[this.especialidade_selecionada]);



    if (especialidades_dias_disponiveis && this.especialidade_selecionada) {

      // console.log(especialidades_dias_disponiveis[this.especialidade_selecionada])

      this.diasQueAtendeArray = this.agendaService.diasQueAtendeArray(this.especialidade_selecionada, especialidades_dias_disponiveis); /*Array dos dias */
      this.minutesStep = this.agendaService.minutesStep(this.duracao_TempoAtendimento);
      this.daysSelecionados = this.convertWeekDaysToNumbers(this.diasQueAtendeArray)
      this.hora_inicial_HoraFinal_PorDia = this.agendaService.Hora_inicial_HoraFinal_PorDia(especialidades_dias_disponiveis[this.especialidade_selecionada])

      // console.log(this.especialidade_selecionada)
      // console.log(especialidades_dias_disponiveis)
      // console.log(this.daysSelecionados)
      // console.log(this.diasQueAtendeArray)
      // console.log(this.horarioInicialQueAtendeArray)
      // console.log(this.horarioFinalQueAtendeArray)
      // console.log(this.duracao_TempoAtendimento)
      // console.log(this.minutesStep)
      // console.log(this.daysSelecionados)


    }


    this.AgendamentosProfissionalByData = this.horariosAgendados?.[id] || [];
    this.BloqueiosProfissionalByData = this.horariosBloqueios?.[id] || [];

    // this.BloqueiosProfissionalByData = this.horariosBloqueios?.[profissionalID]?.horarios?.[0]?.bloqueiosByData || [];


    // this.horariosBloqueios?.[profissionalID]?.horarios?.forEach((data, index) => {
    //   const date = Object.keys(data.bloqueiosByData)[0];

    //   // Verifica se a propriedade 'profissionalID' existe no acumulador e, caso contrário, cria-a como um objeto vazio
    //   if (!this.BloqueiosProfissionalByData[profissionalID]) {
    //     this.BloqueiosProfissionalByData[profissionalID] = {};
    //   }

    //   this.BloqueiosProfissionalByData[profissionalID][date] = data.bloqueiosByData[date];
    // });

    // this.BloqueioProfissional = this.horariosBloqueios?.[profissionalID]?.['horarios'] || [];
    console.group('Agendamentos e bloqueios por data');
    console.log('Agendamentos do profissional por data:', this.AgendamentosProfissionalByData);
    console.log('Bloqueios do profissional por data:', this.BloqueiosProfissionalByData);
    // console.log('this.horariosBloqueios?.[profissionalID]?.horarios:', this.horariosBloqueios);

    console.groupEnd();


  }


  disableRestricoesAntecedencia(){

    if(this.disabledAntecedencia){

      this.disabledAntecedencia = false;

    }else{

      this.disabledAntecedencia = true;

    }

    console.log('ANTECEDENCIA DESABILITADA',this.disabledAntecedencia)

  }





  onNovaDataAgendaChange($event: any, data?, fromDatamaisProxima?: any) {
    const dataSelecionada1 = $event === null ? data : ($event.detail.value ? $event.detail.value : data);

    console.log($event, data, fromDatamaisProxima)
    // console.log(dataSelecionada1)

    if (!data) {
      this.input_data = $event?.detail?.value;
      this.input_horario_inicial = null
    }

    if (!fromDatamaisProxima) {
      this.dataMProxima = true
    }

    if ($event === null) {
      var dataSelecionada = new Date(dataSelecionada1)?.toISOString();
      var diaDaSemanaSelecionado = new Date(dataSelecionada)?.getUTCDay();

      console.log(dataSelecionada1, dataSelecionada);
    } else {
      dataSelecionada = new Date(`${dataSelecionada1?.substring(0, 10)}T00:00:00-03:00`)?.toISOString();
      diaDaSemanaSelecionado = new Date(dataSelecionada)?.getUTCDay();

      console.log(dataSelecionada1, dataSelecionada);
    }



    if (isNaN(new Date(dataSelecionada)?.getTime())) {
      console.error("Data inválida: " + dataSelecionada);
      return;
    }

    const diasDaSemana = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
    const diaDaSemana = diasDaSemana[diaDaSemanaSelecionado];

    const horasDeTrabalho = this.hora_inicial_HoraFinal_PorDia?.[diaDaSemana];

    this.valorAgendamento = this.hora_inicial_HoraFinal_PorDia?.[diaDaSemana].valor;
    console.log('valorAgendamento>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.valorAgendamento)
    if (!horasDeTrabalho) {
      console.error("Não foi possível encontrar as horas de trabalho para o dia selecionado.");
      return;
    }

    const horarioInicial = horasDeTrabalho?.horario_inicial;
    const horarioFinal = horasDeTrabalho?.horario_final;
    const intervaloDeHoras = 1;
    const tamanhoDoArray = Math.floor((((horarioFinal) - horarioInicial) / intervaloDeHoras)) + 1;
    const horariosDisponiveis = [...Array(tamanhoDoArray).keys()].map(x => (x * intervaloDeHoras) + horarioInicial);

    this.intervaloHoras = horariosDisponiveis;
    this.janelaHoraFinal = horarioFinal;
    this.janelaHoraInicial = horarioInicial;
    this.diaSemanaSelecionado = diaDaSemana;

    const dataSelecionadaObj = new Date(dataSelecionada);
    const hoje = new Date();
    const eHoje = dataSelecionadaObj?.getDate() === hoje?.getDate() && dataSelecionadaObj?.getMonth() === hoje?.getMonth() && dataSelecionadaObj?.getFullYear() === hoje?.getFullYear();
    this.minutesStep.push(0);
    this.minutesStep.push(this.minAgendamentoInterval);

    console.log('MIN AGENDAMENTO INTERVAL',this.minAgendamentoInterval)


    //console.log(this.minutesStep, dataSelecionadaObj, dataSelecionadaObj?.getDate(), hoje?.getDate())
    if (this.minutesStep?.length > 0) {
      let horariosDisponiveisSemMinutos = this.agendaService.getAvailableTimes(this.agendaService.convertNumberToTimeString(horariosDisponiveis), this.minAgendamentoInterval);

      let updatedProcessarTempoMinimoAviso: any = {};

      console.log(dataSelecionada)
      if (eHoje) {
        horariosDisponiveisSemMinutos = this.agendaService
          .atualizarHorariosTempoAviso(horariosDisponiveisSemMinutos, this.tempo_minimo_aviso);
      }

      const currentDate = new Date();
      const minimumNoticeTime = this.tempo_minimo_aviso;
      // Inicializa o horário atual para o profissional com base no tempo mínimo de aviso
      let currentTime = new Date(currentDate.getTime());
      // Define o horário atual do profissional com base no tempo mínimo de aviso

      switch (minimumNoticeTime) {
        case "tempo_15min":
          currentTime.setTime(currentTime.getTime() + 15 * 60 * 1000);
          break;
        case "tempo_30min":
          currentTime.setTime(currentTime.getTime() + 30 * 60 * 1000);
          break;
        case "tempo_45min":
          currentTime.setTime(currentTime.getTime() + 45 * 60 * 1000);
          break;
        case "tempo_1h":
          currentTime.setTime(currentTime.getTime() + 60 * 60 * 1000);
          break;
        case "tempo_1h15min":
          currentTime.setTime(currentTime.getTime() + 75 * 60 * 1000);
          break;
        case "tempo_1h30min":
          currentTime.setTime(currentTime.getTime() + 90 * 60 * 1000);
          break;
        case "tempo_1h45min":
          currentTime.setTime(currentTime.getTime() + 105 * 60 * 1000);
          break;
        case "tempo_2h":
          currentTime.setTime(currentTime.getTime() + 120 * 60 * 1000);
          break;
        case "tempo_6h":
          currentTime.setTime(currentTime.getTime() + (6 * 60) * 60 * 1000);
          break;
        case "tempo_12h":
          currentTime.setTime(currentTime.getTime() + (12 * 60) * 60 * 1000);
          break;
        case "tempo_18h":
          currentTime.setTime(currentTime.getTime() + (18 * 60) * 60 * 1000);
          break;
        case "tempo_24h":
          currentTime.setTime(currentTime.getTime() + (24 * 60) * 60 * 1000);
          break;
        case "tempo_48h":
          currentTime.setTime(currentTime.getTime() + (48 * 60) * 60 * 1000);
          break;
        default:
          break;
      }

      if(this.disabledAntecedencia){
        currentTime.setTime(currentTime.getTime() + 15 * 60 * 1000);
      }

      console.log('CURRENTDATE',String(new Date(currentDate.getTime())).valueOf());

      updatedProcessarTempoMinimoAviso = this.agendaService.processarTempoMinimoAviso({
        data_hora_inicial: String(new Date(currentDate.getTime())).valueOf(),
        data_hora_final: String(new Date(currentTime.getTime())).valueOf()
      },this.disabledAntecedencia)

      // }

      console.log(eHoje, 'horariosDisponiveisSemMinutos', horariosDisponiveisSemMinutos, updatedProcessarTempoMinimoAviso)
      console.log('input_profissional_ID', this.input_profissional_ID)

      const agendamentos = this.AgendamentosProfissionalByData?.[dataSelecionada?.slice(0, 10)]?.['horarios'] || [];
      const horariosBloqueados = this.BloqueiosProfissionalByData?.[dataSelecionada?.slice(0, 10)]?.['horarios'] || [];

      const horariosAgendadosParaBloquear = this.agendaService?.gerarHorarios(agendamentos);
      const horariosBloqueadosParaBloquear = this.agendaService?.gerarHorarios(horariosBloqueados);
      console.log(horariosBloqueadosParaBloquear , horariosAgendadosParaBloquear)

      this.horasAgendadasToblock = horariosAgendadosParaBloquear
      this.horasBloqueioAgendaToblock = horariosBloqueadosParaBloquear
      this.horasAgendadasToblock.pop();
      // this.horasBloqueioAgendaToblock;
      if (horariosBloqueadosParaBloquear || horariosAgendadosParaBloquear || updatedProcessarTempoMinimoAviso) {
        this.horasAgendadasToblock_Booleans = horariosDisponiveisSemMinutos?.map(horario => horariosAgendadosParaBloquear?.includes(horario) || horariosBloqueadosParaBloquear?.includes(horario) || updatedProcessarTempoMinimoAviso?.[dataSelecionada?.slice(0, 10)]?.intervaloTotal.includes(horario));
      } else {
        this.horasAgendadasToblock_Booleans = horariosDisponiveisSemMinutos?.map(horario => false);
      }

      console.log('this.horasAgendadasToblock_Booleans',this.horasAgendadasToblock_Booleans);

      this.ArrayAtendimentoTimes = horariosDisponiveisSemMinutos;
      for(let i = 0; this.horasAgendadasToblock_Booleans?.length > i;i++){
        if(this.horasAgendadasToblock_Booleans[i] != true){
          this.input_horario_inicial = this.ArrayAtendimentoTimes[i];
          break;
        }
      }      

      if (this.ArrayAtendimentoTimes?.length && this.dataMProxima === false && !data) {
        this.input_horario_inicial = null
      }

      this.ArrayAtendimentoTimes.pop();
    }
  }


  isDateEnabled(date: any): boolean {
    // Se o parâmetro 'date' não existir, retorna 'true' para indicar que a data está habilitada
    if (!date) return true;

    // Cria um objeto Date com a data atual
    const today = new Date();

    // Cria um objeto Date com a data do parâmetro
    const dateObject = new Date(date);
    //  console.log(today, dateObject)


    // Obtém o dia da semana para a data fornecida como parâmetro (0 = Domingo, 1 = Segunda, ..., 6 = Sábado)
    const day = dateObject.getUTCDay();

    // Calcula a diferença em dias entre a data fornecida como parâmetro e a data atual
    const dayDifference = Math.floor((dateObject.getTime() - today.getTime()) / (1000 * 3600 * 24));

    // console.log(today, dateObject, dayDifference)

    // console.group()
    // console.log(this.daysSelecionados?.includes(day) )
    // console.log(this.daysSelecionados)
    // console.log(day)
    // console.log(today)
    // console.log(dateObject)
    // console.log(dayDifference)
    // console.groupEnd()



    // Define um valor máximo de diferença em dias baseado no valor de uma variável chamada 'antecedencia_agendamento_selectedProfissional'
    let maxDayDifference = 21;
    if (this.antecedencia_agendamento_selectedProfissional) {
      switch (this.antecedencia_agendamento_selectedProfissional) {
        case "esta_semana_e_proxima":
          maxDayDifference = 14;
          break;
        case "de_3_semanas":
          maxDayDifference = 21;
          break;
        case "de_4_semanas":
          maxDayDifference = 28;
          break;
        case "de_5_semanas":
          maxDayDifference = 35;
          break;
        case "de_6_semanas":
          maxDayDifference = 42;
          break;
        case "de_7_semanas":
          maxDayDifference = 49;
          break;
        default:
          maxDayDifference = 14;
      }
    }

    // Verifica se o array 'daysSelecionados' (não definido neste trecho de código) contém o dia da semana para a data fornecida como parâmetro
    // e se a diferença em dias está dentro do limite máximo definido anteriormente
    // console.group('If statment')
    // console.log(this.daysSelecionados?.includes(day) )
    // console.log(dayDifference >= -1)
    // console.log(dayDifference <= maxDayDifference)
    // console.groupEnd()
    if (this.daysSelecionados?.includes(day) && dayDifference >= -1 && dayDifference <= maxDayDifference) {
      // Se a data estiver habilitada, retorna 'true'

      return true;
    } else {
      // Caso contrário, retorna 'false'
      return false;
    }
  }

  onHoraInicalChange($event: Event) {


    this.dataMProxima = false



    console.log('onHoraInicalChange', $event?.['detail'].value)

    let timeString = $event?.['detail']?.value;
    let hours = Number(timeString?.slice(0, 2));
    let minutes = Number(timeString?.slice(3, 5));
    let seconds = Number(timeString?.slice(6, 8));
    let date = new Date();

    date?.setHours(hours, minutes, seconds);

    this.horario_inicial_MIlisegungos = date?.getTime();
    //console.log(this.horario_inicial_MIlisegungos);
    this.hora_inicial_String = new Date($event?.['detail'].value);

    if (this.diaSemanaSelecionado) {
      this.duracao_TempoAtendimento = this.agendaService.duracao_TempoAtendimentoByWeekDay(this.hora_inicial_HoraFinal_PorDia[this.diaSemanaSelecionado]?.duracao_TempoAtendimento);
      console.log('DURACAO TEMPO ATENDIMENTO',this.duracao_TempoAtendimento, this.diaSemanaSelecionado)
    }


    this.AcrescimoHoraFinal(null)

  }

  onDragedHoraInicalChange($event: Event) {
    let hora = new Date($event?.['detail'].value).getHours();
    let minutos = new Date($event?.['detail'].value).getMinutes();
  }

  AcrescimoHoraFinal($event) {

    if ($event === null) {
      this.keyTime

    } else {
      this.keyTime = $event?.['detail']?.value;
    }
    //console.log(typeof (keyTime), keyTime)

    const AcrescimoHora = { // objeto contendo função para calcular a hora final do atendimento, os Keyvalues tem o mesmo nome do value do ion-select-option
      Add15m(horaInicial) { return horaInicial + 900000; },
      Add30m(horaInicial) { return horaInicial + 1.8e+6; },
      Add45m(horaInicial) { return horaInicial + 2.7e+6; },
      Add1h(horaInicial) { return horaInicial + 3.6e+6; },
      Add1h15m(horaInicial) { return horaInicial + 4.5e+6; },
      Add1h30m(horaInicial) { return horaInicial + 5.4e+6; },
      Add1h45m(horaInicial) { return horaInicial + 6.3e+6; },
      Add2h(horaInicial) { return horaInicial + 7.2e+6; },

    }

    this.horario_Final_MIlisegungos = AcrescimoHora?.[this.keyTime]?.(this.horario_inicial_MIlisegungos)
    this.horario_Final_MIlisegungos = new Date(this.horario_Final_MIlisegungos)
    let horarioFinal = this.utilsService.milisecondsToDate(this.horario_Final_MIlisegungos)

    const horarioAtendimentoProfissional = this.janelaHoraFinal;

    const horarioAtendimentoProfissionalDate = new Date(`1970-01-01T${horarioAtendimentoProfissional}:00:00`);
    const horarioFinalDate = new Date(`1970-01-01T${this.utilsService.milisecondsToDate(this.horario_Final_MIlisegungos)}`);

    if (horarioFinalDate > horarioAtendimentoProfissionalDate) {
      this.utilsService.errorAlert('Atenção! O horário final do agendamento está ultrapassando o horário de atendimento do profissional.');
      this.input_horario_final = null
      return;
    }

    let RejectvalidateNewAgendamento = this.agendaService.RejectvalidateNewAgendamento(this.input_horario_inicial, horarioFinal, this.horasBloqueioAgendaToblock, this.horasAgendadasToblock)
    //console.log(RejectvalidateNewAgendamento)
    if (RejectvalidateNewAgendamento == true) {
      this.utilsService.errorAlert('Horário já agendado. Por favor, escolha outro horário.')
      this.input_horario_final = null
      this.input_horario_inicial = null
      RejectvalidateNewAgendamento = null
      return
    } else if (RejectvalidateNewAgendamento == false) {
      this.input_horario_final = this.utilsService.milisecondsToDate(this.horario_Final_MIlisegungos) || null
      //console.log(this.input_horario_final)
      RejectvalidateNewAgendamento = null
      return
    }
  }
  isValidHour(hours: string): boolean {
    const date = new Date(`1970-01-01T${hours}`);
    return !isNaN(date.getTime());
  }

  changeTipoAtendimento($event: Event) {
    this.showProfissional1 = true;
    console.log('input_data', this.input_data, 'hora final', this.input_horario_final, 'canal', this.input_canal_agendamento, 'id Prof', this.input_profissional_ID, 'especialidade', this.input_especialidades_procedimentos, 'id tutor', this.input_Tutor_ID)
    //console.log("changeTipoAtendimento ", $event)
    console.log(this.dateTimePopover);
  }

  onCancelarClick() {
    let modo = this.mode == 'insert' ? 'esse cadastro' : 'essa edição';
    this.utilsService.showAlert('Tem certeza que deseja <span>Cancelar</span> <br>' + modo + '? <br>Todos os dados não salvos serão perdidos.</br>', function () {
      this.modalController.dismiss({
        'dismissed': false,
        'data': this
      });
    }.bind(this), function () {
      //
    }.bind(this));
  }

  onSalvarClick(form: NgForm) {

    let current_User = localStorage.getItem('currentUser');
    let id_current_User = JSON.parse(current_User)['user']['id']
    let agendadoPor = JSON.parse(current_User)['user']['username']

    if (this.mode === 'insert' || this.mode === 'edit') {
      var tipo_agendamento = 'Consulta'

      if (this.carenciaRetorno) { var dataMaximaRetorno = new Date(new Date(this.input_data).getTime() + (this.carenciaRetorno * 24) * 60 * 60 * 1000) } else {
        dataMaximaRetorno = null
      }


      if (this.carenciaDesconto) { var dataMaximaDesconto = new Date(new Date(this.input_data).getTime() + (this.carenciaDesconto * 24) * 60 * 60 * 1000) } else {
        dataMaximaDesconto = null
      }
      // console.log(dataMaximaRetorno)

    } else if (this.mode === 'retorno') {
      var tipo_agendamento = 'Retorno'
      this.valorAgendamento = this.valorAgendamento - (this.valorAgendamento * (this.valorDesconto / 100))
    }


    //console.log(id_current_User)

    if (form.valid) {

      //console.log(this.input_data)
      //console.log(parse(this.input_data, 'yyyy-MM-dd', new Date),)

      // this.profissionaisService.getProfissionalID_JanelaDisponibilidade(this.userData?.perfil_negocios[this.selectedBuzzIndex]?.id, this.input_profissional_ID).subscribe(
      //   {
      //     next: (response) => {
      //       const JANELA_DISPONIBILIDADE = response?.data?.attributes?.programacao?.janela_disponibilidade;
      //       //JANELA É UM ARRAY DE OBJETOS COM AS JANELAS DE DISPONIBILIDADE DO PROFISSIONAL,
      //       //
      //     },
      //   }
      // )


      let NovoAgendamentoInput = {
        data: {
          canal_agendamento: this.input_canal_agendamento,
          data: this.input_data.substring(0, 10),
          especialidade: this.input_especialidade,
          // especialidades_procedimentos: this.input_especialidades_procedimentos,
          faltas: 0,
          tipo_atendimento: this.input_tipo_atendimento,
          tipo_agendamento: tipo_agendamento,
          horario_final: this.input_horario_final,
          horario_inicial: this.input_horario_inicial,
          id_ultimo_atendimento: 0,
          id_usuario_agendamento: +id_current_User,
          agendadoPor: agendadoPor,
          pet: + this.input_pet_ID,
          profissional: +this.input_profissional_ID,
          dataMaximaRetorno: dataMaximaRetorno,
          dataMaximaDesconto: dataMaximaDesconto,
          valorDesconto: this.valorDesconto,
          valorAtendimento: this.valorAgendamento,
          perfil_negocio: this.userData?.perfil_negocios[this.selectedBuzzIndex]?.id,
          status: 'agendado',
          tutors: [+this.input_Tutor_ID],
        }



      }

      console.log(NovoAgendamentoInput)

      switch (this.mode) {
        case "insert":
          this.agendaService.createAgendamento(NovoAgendamentoInput)
            .subscribe(
              {
                next: (result) => {
                  //console.log('TESTEEEE', response);
                  this.Tutor_nome = null;
                  this.input_profissional_ID = null;
                  this.input_pet_ID = null;
                  this.id_usuario_agendamento = null;
                  this.input_Tutor_ID = null;
                  this.input_tipo_atendimento = null;
                  this.statusAgendamento = null;
                  this.input_especialidades_procedimentos = null;
                  this.input_canal_agendamento = null;
                  this.input_especialidade = null;
                  this.input_data = null;
                  this.input_horario_inicial = null;
                  this.input_horario_final = null;
                  this.utilsService.successAlert('Evento cadastrado com sucesso');
                  this.modalController.dismiss({
                    'dismissed': true,
                    'data': this
                  });
                  
                  let logData = {
                    what_changed:'agendamento',
                    action:'created',
                    details:{
                      description:'Criação de agendamento via painel de gerenciamento PETinhodemim',
                      sent_data:NovoAgendamentoInput
                      
                    },
                    element_id:result?.data?.id,
                    performed_by:{
                      nome:this.userData?.dados_pessoais?.nome + ' ' + this.userData?.dados_pessoais?.sobrenome,
                      usuario: this.userData?.username,
                      email: this.userData?.email
                    },
                    perfil_negocio:this.perfilNegocio
            
                  }
            
                  this.createLog(logData, this.userData?.id)

                },
                error: (error) => {
                  console.error(error);
                  switch (error.status) {
                    case 400:
                      this.utilsService.errorAlert('Houve um problema com os dados de entrada. Por favor, verifique os dados e tente novamente.');
                      this.modalController.dismiss({
                        'dismissed': true,
                        'data': this
                      });
                      break;
                    case 403:
                      this.utilsService.errorAlert('Você não está autorizado a criar agendamentos. Por favor, entre em contato com o administrador para obter mais informações.');
                      this.modalController.dismiss({
                        'dismissed': true,
                        'data': this
                      });
                      break;
                    case 500:
                      this.utilsService.errorAlert('Houve um erro ao criar o agendamento. Por favor, tente novamente mais tarde.');
                      this.modalController.dismiss({
                        'dismissed': true,
                        'data': this
                      });
                      break;
                    default:
                      this.utilsService.errorAlert('Houve um erro ao criar o agendamento. Por favor, tente novamente mais tarde.');
                      this.modalController.dismiss({
                        'dismissed': true,
                        'data': this
                      });
                  }
                },
                complete:()=>{
                  
                }
              },
            )
          break;
        case "edit":
          this.agendaService.updateAgendamento(NovoAgendamentoInput, this.idAgendamento)
            .subscribe(
              response => {

                let logData = {
                  what_changed:'agendamento',
                  action:'updated',
                  details:{
                    description:'Atualização de agendamento via painel de gerenciamento PETinhodemim',
                    sent_data: NovoAgendamentoInput
                    
                  },
                  element_id:response?.data?.id,
                  performed_by:{
                    nome:this.userData?.dados_pessoais?.nome + ' ' + this.userData?.dados_pessoais?.sobrenome,
                    usuario: this.userData?.username,
                    email: this.userData?.email
                  },
                  perfil_negocio:this.perfilNegocio
          
                }
          
                this.createLog(logData, this.userData?.id)

                this.utilsService.successAlert('Evento atualizado com sucesso');
                this.modalController.dismiss({
                  'dismissed': true,
                  'data': this
                });
              },
              error => {
                console.error(error);
                switch (error.status) {
                  case 400:
                    this.utilsService.errorAlert('Houve um problema com os dados de entrada. Por favor, verifique os dados e tente novamente.');
                    this.modalController.dismiss({
                      'dismissed': true,
                      'data': this
                    });
                    break;
                  case 403:
                    this.utilsService.errorAlert('Você não está autorizado a atualizar agendamentos. Por favor, entre em contato com o administrador para obter mais informações.');
                    this.modalController.dismiss({
                      'dismissed': true,
                      'data': this
                    });
                    break;
                  case 500:
                    this.utilsService.successAlert('Evento cadastrado com sucesso');
                    this.modalController.dismiss({
                      'dismissed': true,
                      'data': this
                    });
                    break;
                  default:
                    this.utilsService.successAlert('Evento cadastrado com sucesso');
                    this.modalController.dismiss({
                      'dismissed': true,
                      'data': this
                    });
                }
              }
            )
          break;
        case "retorno":
          this.agendaService.createAgendamento(NovoAgendamentoInput)
            .subscribe(
              response => {
                //console.log('TESTEEEE', response);
                this.Tutor_nome = null;
                this.input_profissional_ID = null;
                this.input_pet_ID = null;
                this.id_usuario_agendamento = null;
                this.input_Tutor_ID = null;
                this.input_tipo_atendimento = null;
                this.statusAgendamento = null;
                this.input_especialidades_procedimentos = null;
                this.input_canal_agendamento = null;
                this.input_especialidade = null;
                this.input_data = null;
                this.input_horario_inicial = null;
                this.input_horario_final = null;

                let logData = {
                  what_changed:'agendamento',
                  action:'created',
                  details:{
                    description:'Criação de agendamento de retorno via painel de gerenciamento PETinhodemim',
                    sent_data:NovoAgendamentoInput
                    
                  },
                  element_id:response?.data?.id,
                  performed_by:{
                    nome:this.userData?.dados_pessoais?.nome + ' ' + this.userData?.dados_pessoais?.sobrenome,
                    usuario: this.userData?.username,
                    email: this.userData?.email
                  },
                  perfil_negocio:this.perfilNegocio
          
                }
          
                this.createLog(logData, this.userData?.id)

                this.utilsService.successAlert('Retorno cadastrado com sucesso');
                this.modalController.dismiss({
                  'dismissed': true,
                  'data': this
                });
              },
              error => {
                console.error(error);
                switch (error.status) {
                  case 400:
                    this.utilsService.errorAlert('Houve um problema com os dados de entrada. Por favor, verifique os dados e tente novamente.');
                    this.modalController.dismiss({
                      'dismissed': true,
                      'data': this
                    });
                    break;
                  case 403:
                    this.utilsService.errorAlert('Você não está autorizado a criar Retornos. Por favor, entre em contato com o administrador para obter mais informações.');
                    this.modalController.dismiss({
                      'dismissed': true,
                      'data': this
                    });
                    break;
                  case 500:
                    console.log('Houve um erro interno no servidor. Por favor, tente novamente mais tarde.');

                    // this.utilsService.errorAlert('Houve um erro interno no servidor. Por favor, tente novamente mais tarde.');
                    this.modalController.dismiss({
                      'dismissed': true,
                      'data': this
                    });
                    break;
                  default:
                    this.utilsService.errorAlert('Houve um erro ao criar o Retorno. Por favor, tente novamente mais tarde.');
                    this.modalController.dismiss({
                      'dismissed': true,
                      'data': this
                    });
                }
              }
            )
          break;

      }

      // this.Novo_AgendamentosObject_Output.emit(this.Novo_AgendamentosObject); // emite evento com o ojeto NOvo agendamento


    } else {
      console.log('FORMULARIO Invalido')
    }
  }







  // Funções de regras

  transformToGroupedAvailability(selectedProfissional: any, especialidade_selecionada: any) {
    let especialidades_dias_disponiveis = selectedProfissional?.attributes?.programacao?.janela_disponibilidade?.reduce((acumulador, janela) => {

      if (janela?.visivel && !(janela?.tipo_atendimento && janela.tipo_atendimento.length === 1 && janela?.tipo_atendimento[0] == "Retorno") && +janela?.perfil_negocio === this.perfilNegocio) {
        if (!acumulador[janela?.especialidades]) {
          acumulador[janela?.especialidades] = [];
        }

        acumulador[janela?.especialidades].push({
          dias: janela?.dias_disponiveis,
          horario_inicial: janela?.horario_inicial,
          horario_final: janela?.horario_final,
          duracao_TempoAtendimento: janela?.duracao_TempoAtendimento,
          visivel: janela?.visivel,
          valor: janela?.valor,
        });
      }
      return this.agendaService.replicarEspecialidades(acumulador);
    }, {});


    console.log('especialidades_dias_disponiveis', especialidades_dias_disponiveis);

    if (especialidades_dias_disponiveis && especialidade_selecionada) {
      console.log('especialidades_dias_disponiveis', especialidades_dias_disponiveis);
      // //console.log(especialidade_selecionada)

      // //console.log(especialidades_dias_disponiveis)

      //console.log(especialidades_dias_disponiveis[especialidade_selecionada])
      this.diasQueAtendeArray = this.agendaService.diasQueAtendeArray(especialidade_selecionada, especialidades_dias_disponiveis);
      // this.horarioInicialQueAtendeArray = this.agendaService.horarioInicialQueAtendeArray(especialidade_selecionada, especialidades_dias_disponiveis);
      // this.horarioFinalQueAtendeArray = this.agendaService.horarioFinalQueAtendeArray(especialidade_selecionada, especialidades_dias_disponiveis);
      this.duracao_TempoAtendimento = this.agendaService.duracao_TempoAtendimento(especialidade_selecionada, especialidades_dias_disponiveis);
      this.minutesStep = this.agendaService.minutesStep(this.duracao_TempoAtendimento);
      this.daysSelecionados = this.convertWeekDaysToNumbers(this.diasQueAtendeArray)
      this.hora_inicial_HoraFinal_PorDia = this.agendaService.Hora_inicial_HoraFinal_PorDia(especialidades_dias_disponiveis[especialidade_selecionada])
      // //console.log(this.daysSelecionados)
      // //console.log(this.diasQueAtendeArray)
      // //console.log(this.horarioInicialQueAtendeArray)
      // //console.log(this.horarioFinalQueAtendeArray)
      console.log('DURAÇÃO ATENDIMENTO',this.duracao_TempoAtendimento)
      // //console.log(this.minutesStep)
      // //console.log(this.daysSelecionados)
    }
  }

  convertWeekDaysToNumbers(days) {
    const daysOfWeek = {
      "Todos os dias": [0, 1, 2, 3, 4, 5, 6],
      "Seg - Sex": [1, 2, 3, 4, 5],
      "Sáb - Dom": [0, 6],
      "Seg": [1],
      "Ter": [2],
      "Qua": [3],
      "Qui": [4],
      "Sex": [5],
      "Sáb": [6],
      "Dom": [0]
    };


    return days = days?.map(day => {

      // //console.log(day[0]);
      return daysOfWeek[day[0]] || [Number.parseInt(day[0])]


    })?.flat(Infinity);
  }


  async showNoResultsAlert() {
    let alert = await this.utilsService.showAlert('Tutor não encontrado - Deseja cadastrar Tutor?',
      function () {
        this.showTutorModal();

      }.bind(this),

      function () {
        this.modalController.dismiss({
          'dismissed': false,
          'data': this
        });
      }.bind(this)

    );

  }

  async showTutorModal() {
    const modal = await this.modalCtrl.create({
      component: IncluirTutorModalPage,
      cssClass: "modal-Cadastro-Tutor-agendamento",
      componentProps: {
        tutorcelular: this.tutorCadastroCelular

      }
    });
    modal.onDidDismiss().then((data) => {
      if (data?.data) {
        //console.log(data?.data)
        this.NewTutorcelular = data?.data?.data?.celular;
        this.NewTutorId = data?.data?.data?.tutorID;
        this.NewTutorNome = data?.data?.data?.nome;
        //console.log(this.NewTutorcelular, 'id: ' + this.NewTutorId, this.NewTutorNome)

        let celular = this.NewTutorcelular?.replace(/\D/g, '');
        let id = this.NewTutorId;
        this.filterKey = celular;

        this.selectTutor(this.NewTutorId, this.NewTutorNome, celular)

      }
    });
    return await modal.present();
  }

  // openDateTimePicker() {
  //   console.log('openDateTimePicker')

  // }

  highlightedDates = (isoString) => {
    const date = new Date(isoString);
    const utcDay = date.getUTCDate();

    if (utcDay % 5 === 0) {
      return {
        textColor: '#800080',
        backgroundColor: '#ffc0cb',
      };
    }

    if (utcDay % 3 === 0) {
      return {
        textColor: 'var(--ion-color-secondary-contrast)',
        backgroundColor: 'var(--ion-color-secondary)',
      };
    }

    return undefined;
  };

  createLog(elementData:any, id:any){


    this.logsService.createLogs(elementData).subscribe(result=>{

      console.log('Log criado')

    })
  }


  filterTutorByName(name:string){    

    let tutorsList$ = this.tutoresService.filterTutorsListAvatar(this.perfilNegocio, 'nome',  name).subscribe({
      next:(result)=>{
        this.filterdTutors = result?.data;

        if(name?.length < 3){
          this.filterdTutors = [];
        }

      },
      error:(e)=>{},
      complete:()=>{
        tutorsList$.unsubscribe()
      }
    })

  }

  createPet() {

    let createdPet:any = [];

    let dataPEt = {
      data: {
        tutor: this.input_Tutor_ID,
        nome: this.nomePet,
        especie: this.especiePet
      }
    };

    this.tutoresService.createPet(dataPEt)
      .subscribe(
        {
          next:(pet)=>{

            createdPet = pet.data

            for(let petId of this.TutorPets){
              this.petsIdArray.push(petId.id)
            }

            console.log(pet)
            this.petsIdArray = [...this.petsIdArray, pet?.data?.id];
            this.nomePet = '',
            this.especiePet = '',
            this.successAlert("Pet criado com sucesso!", 3001);
          },
          error:(e)=>{console.error('Erro ao criar o pet: ', e);},
          complete:()=>{
            this.TutorPets = [...this.TutorPets, createdPet]
            this.updateTutor({data:{pets:this.petsIdArray}}, this.input_Tutor_ID.toString())
          }
        }       
      );
  }

  updateTutor(data:any, tId:string){
    this.tutoresService.updateTutor(data, tId).subscribe({
      next:(result)=>{

      }
    })
  }

  successAlert(title: string, time: number) {

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: title,
      showConfirmButton: false,
      timer: time,
      heightAuto: false
    })

  }


  presentSearchPopover(e: Event) {
    this.searchPopover.event = e;
    this.isOpen = true;
  }

  openAddPetModal(openBool:Boolean){
    if(openBool == true){
      this.openAddPetForm = false
    }
    else{
      this.openAddPetForm = true
    }
  }


}





