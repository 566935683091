import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { CalendarEvent } from 'angular-calendar';

@Pipe({
  name: 'statusEvents'
})
export class StatusEventsPipe implements PipeTransform {

  transform(events: CalendarEvent[], status): CalendarEvent[] {

    if (!events || !status) {
      return events;
    }


    return events.filter(event => {
      // console.log(event)
      return event?.meta?.statusAgendamento == status
    });

  }
}
