import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.page.html',
  styleUrls: ['./produtos.page.scss'],
})
export class ProdutosPage implements OnInit {

  produtos = [];
  order = "nome";
  orderTitle = 'nome';
  situacao = "ativos";
  situacaoTitle = "ativos";
  tagsProdutos = 0;
  dataProdutosAtualizados = "2022-01-01";  

  constructor(public router: Router, 
    public route: ActivatedRoute, 
  ) {     
  }

  ngOnInit() {
    this.produtos = [
      {id: 1, produto: 'Remédio', tipo: 'Medicamentos', preco: 'R$ 24,99', estoque: '250 unidades', sku: '2161651561265', fabricante: 'Bayer', custo: 'R$ 18,99'},
      {id: 2, produto: 'Remédio', tipo: 'Medicamentos', preco: 'R$ 24,99', estoque: '250 unidades', sku: '2161651561265', fabricante: 'Bayer', custo: 'R$ 18,99'},
      {id: 3, produto: 'Remédio', tipo: 'Medicamentos', preco: 'R$ 24,99', estoque: '250 unidades', sku: '2161651561265', fabricante: 'Bayer', custo: 'R$ 18,99'},
      {id: 4, produto: 'Remédio', tipo: 'Medicamentos', preco: 'R$ 24,99', estoque: '250 unidades', sku: '2161651561265', fabricante: 'Bayer', custo: 'R$ 18,99'},
      {id: 5, produto: 'Remédio', tipo: 'Medicamentos', preco: 'R$ 24,99', estoque: '250 unidades', sku: '2161651561265', fabricante: 'Bayer', custo: 'R$ 18,99'},
      {id: 6, produto: 'Remédio', tipo: 'Medicamentos', preco: 'R$ 24,99', estoque: '250 unidades', sku: '2161651561265', fabricante: 'Bayer', custo: 'R$ 18,99'},
      {id: 7, produto: 'Remédio', tipo: 'Medicamentos', preco: 'R$ 24,99', estoque: '250 unidades', sku: '2161651561265', fabricante: 'Bayer', custo: 'R$ 18,99'},
      {id: 8, produto: 'Remédio', tipo: 'Medicamentos', preco: 'R$ 24,99', estoque: '250 unidades', sku: '2161651561265', fabricante: 'Bayer', custo: 'R$ 18,99'},
      {id: 9, produto: 'Remédio', tipo: 'Medicamentos', preco: 'R$ 24,99', estoque: '250 unidades', sku: '2161651561265', fabricante: 'Bayer', custo: 'R$ 18,99'},
      {id: 10, produto: 'Remédio', tipo: 'Medicamentos', preco: 'R$ 24,99', estoque: '250 unidades', sku: '2161651561265', fabricante: 'Bayer', custo: 'R$ 18,99'},
      {id: 11, produto: 'Remédio', tipo: 'Medicamentos', preco: 'R$ 24,99', estoque: '250 unidades', sku: '2161651561265', fabricante: 'Bayer', custo: 'R$ 18,99'},
      {id: 12, produto: 'Remédio', tipo: 'Medicamentos', preco: 'R$ 24,99', estoque: '250 unidades', sku: '2161651561265', fabricante: 'Bayer', custo: 'R$ 18,99'},
      {id: 13, produto: 'Remédio', tipo: 'Medicamentos', preco: 'R$ 24,99', estoque: '250 unidades', sku: '2161651561265', fabricante: 'Bayer', custo: 'R$ 18,99'},
      {id: 14, produto: 'Remédio', tipo: 'Medicamentos', preco: 'R$ 24,99', estoque: '250 unidades', sku: '2161651561265', fabricante: 'Bayer', custo: 'R$ 18,99'},
    ];
  }

  loadData(event){
    console.log("scroll")
    // Esse timeout é apenas pra simular a espera do carregamento 
    setTimeout(() => { 
      event.target.complete();

      this.produtos = this.produtos.concat(this.produtos);

      // quando chegar ao final, desabilita o infiniteScroll
      if (this.produtos.length === 20) {
        event.target.disabled = true;
      } 
    }, 500);    
  }  

  onIncluirProduto(){
    const navigationExtras: NavigationExtras = {
      state: {
        mode: "insert"
      }
    }
    this.router.navigateByUrl('tabs/produtos-cadastro', navigationExtras);
  }

  onAlterarProduto(){
    const navigationExtras: NavigationExtras = {
      state: {
        mode: "edit"
      }
    }
    this.router.navigateByUrl('tabs/produtos-cadastro', navigationExtras);
  }

  onOrderClick(order, orderTitle){
    this.order = order;    
    this.orderTitle = orderTitle;
  }

  onSituacaoClick(situacao, situacaoTitle){
    this.situacao = situacao;    
    this.situacaoTitle = situacaoTitle;
  }
}
