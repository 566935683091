import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ITutor } from '../interfaces/interfaces';
import { addHours, addMinutes, startOfDay } from 'date-fns';
import { EventColor } from 'calendar-utils';
import { CalendarEvent, CalendarEventAction } from 'angular-calendar';
import { Observable, throwError, of, BehaviorSubject } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';



const colors: Record<string, EventColor> = {
  red: {
    primary: '#fff',
    secondary: '#fff',
  },
  blue: {
    primary: '#fff',
    secondary: '#fff',
  },
  yellow: {
    primary: '#fff',
    secondary: '#fff',
  },
};

type ObjectWithUniqueKey = {
  [key: string]: any; // adiciona uma propriedade com nome dinâmico
}




@Injectable({
  providedIn: 'root'
})



export class UtilsService {



  locateApiUrl = environment.locateApi;

  selectedConfigView$ = new BehaviorSubject<any>('Usuario');
  selectedBuzzIndex$ = new BehaviorSubject<string>(localStorage.getItem('buzz_index'));

  constructor(
    public alertController: AlertController,
    private httpClient: HttpClient,
    public router: Router
  ) { }


  getLocateByUF(uf: string) {

    return this.httpClient.get<any>(this.locateApiUrl + `${uf}/municipios`);

  }

  formatDate(date: Date): string {
    // console.log(date)
    var ano = date.getFullYear();
    var mes = (date.getMonth() + 1).toString().padStart(2, "0");
    var dia = date.getDate().toString().padStart(2, "0");
    var hora = date.getHours().toString().padStart(2, "0");
    var minuto = date.getMinutes().toString().padStart(2, "0");
    var segundo = date.getSeconds().toString().padStart(2, "0");
    // console.log(hora)
    // console.log(`${ano}-${mes}-${dia}T${hora}:${minuto}:${segundo}.000-03:00`)

    return `${ano}-${mes}-${dia}T${hora}:${minuto}:${segundo}.000-03:00`;

  }



  estados = {
    "ListagemEstados": [
      {
        "id": 1,
        "nome": "AC - Acre",
        "ibge": 12
      },
      {
        "id": 2,
        "nome": "AL - Alagoas",
        "ibge": 27
      },
      {
        "id": 3,
        "nome": "AP - Amapá",
        "ibge": 16
      },
      {
        "id": 4,
        "nome": "AM - Amazonas",
        "ibge": 13
      },
      {
        "id": 5,
        "nome": "BA - Bahia",
        "ibge": 29
      },
      {
        "id": 6,
        "nome": "CE -Ceará",
        "ibge": 23
      },
      {
        "id": 7,
        "nome": "DF - Distrito Federal",
        "ibge": 53
      },
      {
        "id": 8,
        "nome": "ES - Espírito Santo",
        "ibge": 32
      },
      {
        "id": 9,
        "nome": "GO - Goiás",
        "ibge": 52
      },
      {
        "id": 10,
        "nome": "MA - Maranhão",
        "ibge": 21
      },
      {
        "id": 11,
        "nome": "MT - Mato Grosso",
        "ibge": 51
      },
      {
        "id": 12,
        "nome": "MS - Mato Grosso do Sul",
        "ibge": 50
      },
      {
        "id": 13,
        "nome": "MG - Minas Gerais",
        "ibge": 31
      },
      {
        "id": 14,
        "nome": "PA - Pará",
        "ibge": 15
      },
      {
        "id": 15,
        "nome": "PB - Paraíba",
        "ibge": 25
      },
      {
        "id": 16,
        "nome": "PR - Paraná",
        "ibge": 41
      },
      {
        "id": 17,
        "nome": "PE - Pernambuco",
        "ibge": 26
      },
      {
        "id": 18,
        "nome": "PI - Piauí",
        "ibge": 22
      },
      {
        "id": 19,
        "nome": "RJ - Rio de Janeiro",
        "ibge": 33
      },
      {
        "id": 20,
        "nome": "RN - Rio Grande do Norte",
        "ibge": 24
      },
      {
        "id": 21,
        "nome": "RS - Rio Grande do Sul",
        "ibge": 43
      },
      {
        "id": 22,
        "nome": "RO - Rondônia",
        "ibge": 11
      },
      {
        "id": 23,
        "nome": "RR - Roraima",
        "ibge": 14
      },
      {
        "id": 24,
        "nome": "SC - Santa Catarina",
        "ibge": 42
      },
      {
        "id": 25,
        "nome": "SP - São Paulo",
        "ibge": 35
      },
      {
        "id": 26,
        "nome": "SE - Sergipe",
        "ibge": 28
      },
      {
        "id": 27,
        "nome": "TO - Tocantins",
        "ibge": 17
      }
    ]
  }


  especilidades_Array = [
    {
      nome: 'Clinico Geral',
      especies_atendidas: ['Canina', 'Felina'],
      retorno: 'Não',
      carencia: 'Não',
      desconto: 10.00,
      prazo: 30,
      crmv: '125698745',
    },
    {
      nome: 'Vacinação',
      especies_atendidas: ['Canina'],
      retorno: 'Sim',
      carencia: 'Não',
      desconto: 20.00,
      prazo: 10,
      crmv: '78523',
    },
    {
      nome: 'Banho',
      especies_atendidas: ['Felina'],
      retorno: 'Não',
      carencia: 'Sim',
      desconto: 0,
      prazo: 15,
      crmv: '56987',
    },
    {
      nome: "Acupuntura",
      especies_atendidas: ['Felina', 'Canina'],
      retorno: 'Não',
      carencia: 'Sim',
      desconto: 0,
      prazo: 10,
      crmv: '582369',
    },
    {
      nome: 'Anestesiologia',
      especies_atendidas: ['Felina', 'Canina'],
      retorno: 'Não',
      carencia: 'Sim',
      desconto: 0,
      prazo: 5,
      crmv: '582369',
    },
    {
      nome: 'Cardiologia',
      especies_atendidas: ['Felina', 'Canina'],
      retorno: 'Não',
      carencia: 'Sim',
      desconto: 0,
      prazo: 42,
      crmv: '789654',
    },



  ];

  dados_form = {
    "duracao_atendimento": [
      { "tempo": "15 min", "valor": "tempo_15min" },
      { "tempo": "30 min", "valor": "tempo_30min" },
      { "tempo": "45 min", "valor": "tempo_45min" },
      { "tempo": "1h", "valor": "tempo_1h" },
      { "tempo": "1h 15 min", "valor": "tempo_1h15min" },
      { "tempo": "1h 30 min", "valor": "tempo_1h30min" },
      { "tempo": "1h 45 min", "valor": "tempo_1h45min" },
      { "tempo": "2h", "valor": "tempo_2h" },
    ],

    "disponibilidade_dias": [

      { "dia": "Todos os dias" },
      { "dia": "Seg - Sex" },
      { "dia": "Sáb - Dom" },
      { "dia": "Seg" },
      { "dia": "Ter" },
      { "dia": "Qua" },
      { "dia": "Qui" },
      { "dia": "Sex" },
      { "dia": "Sáb" },
      { "dia": "Dom" }
    ],
    "disponibilidade_hora_inical": [
      { "hours": "06:00", "valor": "06:00:00" },
      { "hours": "07:00", "valor": "07:00:00" },
      { "hours": "08:00", "valor": "08:00:00" },
      { "hours": "09:00", "valor": "09:00:00" },
      { "hours": "10:00", "valor": "10:00:00" },
      { "hours": "11:00", "valor": "11:00:00" },
      { "hours": "12:00", "valor": "12:00:00" },
      { "hours": "13:00", "valor": "13:00:00" },
      { "hours": "14:00", "valor": "14:00:00" },
      { "hours": "15:00", "valor": "15:00:00" },
      { "hours": "16:00", "valor": "16:00:00" },
      { "hours": "17:00", "valor": "17:00:00" },
      { "hours": "18:00", "valor": "18:00:00" },
      { "hours": "19:00", "valor": "19:00:00" },
      { "hours": "20:00", "valor": "20:00:00" },
      { "hours": "21:00", "valor": "21:00:00" },
      { "hours": "22:00", "valor": "22:00:00" },
      { "hours": "23:00", "valor": "23:00:00" }
    ],

    "disponibilidade_hora_final": [
      { "hours": "06:00", "valor": "06:00:00" },
      { "hours": "07:00", "valor": "07:00:00" },
      { "hours": "08:00", "valor": "08:00:00" },
      { "hours": "09:00", "valor": "09:00:00" },
      { "hours": "10:00", "valor": "10:00:00" },
      { "hours": "11:00", "valor": "11:00:00" },
      { "hours": "12:00", "valor": "12:00:00" },
      { "hours": "13:00", "valor": "13:00:00" },
      { "hours": "14:00", "valor": "14:00:00" },
      { "hours": "15:00", "valor": "15:00:00" },
      { "hours": "16:00", "valor": "16:00:00" },
      { "hours": "17:00", "valor": "17:00:00" },
      { "hours": "18:00", "valor": "18:00:00" },
      { "hours": "19:00", "valor": "19:00:00" },
      { "hours": "20:00", "valor": "20:00:00" },
      { "hours": "21:00", "valor": "21:00:00" },
      { "hours": "22:00", "valor": "22:00:00" },
      { "hours": "23:00", "valor": "23:00:00" }
    ],
    "tempo_aviso": [
      // { "tempo": "15 min", "valor": "tempo_15min" },
      // { "tempo": "30 min", "valor": "tempo_30min" },
      // { "tempo": "45 min", "valor": "tempo_45min" },
      { "tempo": "1h", "valor": "tempo_1h" },
      // { "tempo": "1h 15 min", "valor": "tempo_1h15min" },
      // { "tempo": "1h 30 min", "valor": "tempo_1h30min" },
      // { "tempo": "1h 45 min", "valor": "tempo_1h45min" },
      // { "tempo": "2h", "valor": "tempo_2h" },
      { "tempo": "6h", "valor": "tempo_6h" },
      { "tempo": "12h", "valor": "tempo_12h" },
      { "tempo": "18h", "valor": "tempo_18h" },
      { "tempo": "24h", "valor": "tempo_24h" },
      { "tempo": "48h", "valor": "tempo_48h" },
    ],



  }

  listaEspecialidades = [

    'Acupuntura',
    'Anestesiologia',
    'Banho',
    'Banho e Tosa',
    'Cardiologia',
    'Clinica Geral',
    'Eletrocardiograma',
    'Ecocardiograma',
    'Cirurgia geral e especializada',
    'Dermatologia',
    'Diagnósticos por imagem',
    'Radiologia',
    'Ultrassonografia',
    'Ressonância Magnética',
    'Emergência',
    'Endocrinologia',
    'Especialista em gatos',
    'Gastroenterelogista',
    'Geriatria',
    'Gastroenterologia',
    'Homeopatia',
    'Nefrologia e Urologia',
    'Neurologia',
    'Nutrição',
    'Odontologia',
    'Oftalmologia',
    'Oncologia',
    'Ortopedia',
    'Terapia intensiva (UTI)',
    'Tosa',
    'Vacinação'
  ]

  especies = [
    "Canina",
    "Felina",
    "Aves",
    "Réptil",
    "Aracnídea",
    "Bovina",
    "Caprina",
    "Equina",
    "Asinina",
    "Muares",
    "Roedores",
    "Peixes"
  ]

  Object_refinarBusca =
    [
      {
        key: 'nome',
        value: 'Nome',
      },

      {
        key: 'email',
        value: 'E-mail',
      },

      {
        key: 'cpf',
        value: 'CPF',
      },

      {
        key: 'celular',
        value: 'Celular',
      },

      // {
      //   key: 'Nrefinar',
      //   value: 'Não refinar'
      // }
    ]



  tutors: ITutor[] = [
    {
      id: "tn24mQJxSohM",
      dados_pessoais:
      {
        nome: 'Victor Lemos Cintra',
        sexo: 'masculino',
        data_nascimento: new Date('01/01/1970'),
        documento_identidade: '123456',
        cpf: '12345678901',
        celular: '31997207812',
        telefone: '1234567890',
        email: 'victor@gmail.com',
      },
      endereco: {
        cep: '12345678',
        logradouro: 'Main Street',
        numero: 123,
        complemento: '',
        bairro: 'Downtown',
        cidade: 'New York',
        uf: 'NY'
      },
      agendamentos: [],
      pets: [
        {
          id: "a0jFBmV7PSM2e",
          nome: 'Lice',
          data_nascimento: new Date('01/14/2010'),
          possuiPedigre: 'Sim',
          pedigreeN: 123456,
          peso: 10,
          tamanho: '50',
          chip: 'Sim',
          castrado: 'Sim',
          raca: 'SRD',
          genero: 'feminino',
          especie: 'gato',
          observacao: 'Ad perferendis magni sit rerum eligendi ut voluptatem praesentium et modi provident ad temporibus aperiam. Non nihil unde ut corrupti molestias eos laborum odit. Ut nihil perferendis hic sunt quae ea aliquid facere et magni odit et animi dolores ut voluptas fugit aut dolores sint!',
          agendamentos: []
        },
        {
          id: "rsJh0dNYXBWm6",
          nome: 'Buddy',
          data_nascimento: new Date('01/01/2011'),
          possuiPedigre: 'Sim',
          pedigreeN: 123456,
          peso: 15,
          tamanho: '42',
          chip: 'Sim',
          castrado: 'Não',
          raca: 'Pastor',
          genero: 'masculino',
          especie: 'cachorro',
          observacao: 'Ad perferendis magni sit rerum eligendi ut voluptatem praesentium et modi provident ad temporibus aperiam. Non nihil unde ut corrupti molestias eos laborum odit. Ut nihil perferendis hic sunt quae ea aliquid facere et magni odit et animi dolores ut voluptas fugit aut dolores sint!',
          agendamentos: []
        }
      ],
      ultimo_contato: new Date('12/15/2022'),

    },

    {
      id: "IGIGev3S4yXE",
      dados_pessoais: {
        nome: 'Jane Doe',
        sexo: 'feminino',
        data_nascimento: new Date('01/01/1980'),
        documento_identidade: '123456',
        cpf: '12345678901',
        celular: '31998756645',
        telefone: '1234567890',
        email: 'jane@example.com'
      },
      endereco: {
        cep: '12345678',
        logradouro: 'Main Street',
        numero: 123,
        complemento: '',
        bairro: 'Downtown',
        cidade: 'New York',
        uf: 'NY'
      },
      agendamentos: [],
      pets: [
        {
          id: "sWF4lOp9aycZ5",
          nome: 'Bóris',
          data_nascimento: new Date('01/20/2011'),
          possuiPedigre: 'Sim',
          pedigreeN: 123456,
          peso: 5,
          tamanho: '38',
          chip: true,
          castrado: false,
          raca: 'Mixed',
          genero: 'masculino',
          especie: 'gato',
          observacao: 'Ad perferendis magni sit rerum eligendi ut voluptatem praesentium et modi provident ad temporibus aperiam. Non nihil unde ut corrupti molestias eos laborum odit. Ut nihil perferendis hic sunt quae ea aliquid facere et magni odit et animi dolores ut voluptas fugit aut dolores sint! ',
          agendamentos: []
        }
      ],
      ultimo_contato: new Date('12/25/2022'),
    },

    {
      id: 'uC43pAOtEGTw',
      dados_pessoais: {
        nome: 'Rafael França',
        sexo: 'masculino',
        data_nascimento: new Date('01/01/1980'),
        documento_identidade: '123456',
        cpf: '12345678901',
        celular: '31998756645',
        telefone: '1234567890',
        email: 'rafael@example.com'
      },
      endereco: {
        cep: '12345678',
        logradouro: 'Main Street',
        numero: 123,
        complemento: '',
        bairro: 'Downtown',
        cidade: 'New York',
        uf: 'NY'
      },
      agendamentos: [],
      pets: [
        {
          id: "tDhHJor1H2ZTO",
          nome: 'lele',
          data_nascimento: new Date('01/01/2011'),
          possuiPedigre: 'Sim',
          pedigreeN: 123456,
          peso: 5,
          tamanho: '38',
          chip: 'Não',
          castrado: 'Não',
          raca: 'Mixed',
          genero: 'masculino',
          especie: 'gato',
          observacao: 'Ad perferendis magni sit rerum eligendi ut voluptatem praesentium et modi provident ad temporibus aperiam. Non nihil unde ut corrupti molestias eos laborum odit. Ut nihil perferendis hic sunt quae ea aliquid facere et magni odit et animi dolores ut voluptas fugit aut dolores sint!',
          agendamentos: []
        }
      ],
      ultimo_contato: new Date('12/25/2022'),
    },
    // Add additional objects here
  ];

  horaio = [
    {
      "hours": "06:00"
    },
    {
      "hours": "07:00"
    },
    {
      "hours": "08:00"
    },
    {
      "hours": "09:00"
    },
    {
      "hours": "10:00"
    },
    {
      "hours": "11:00"
    },
    {
      "hours": "12:00"
    },
    {
      "hours": "13:00"
    },
    {
      "hours": "14:00"
    },
    {
      "hours": "15:00"
    },
    {
      "hours": "16:00"
    },
    {
      "hours": "17:00"
    },
    {
      "hours": "18:00"
    },
    {
      "hours": "19:00"
    },
    {
      "hours": "20:00"
    }
  ];

  duracao = [
    {
      "tempo": "15 min",
      "valor": "Add15m"
    },
    {
      "tempo": "30 min",
      "valor": "Add30m"
    },
    {
      "tempo": "45 min",
      "valor": "Add45m"
    },
    {
      "tempo": "1h",
      "valor": "Add1h"
    },
    {
      "tempo": "1h 15 min",
      "valor": "Add1h15"
    },
    {
      "tempo": "1h 30 min",
      "valor": "Add1h30"
    },
    {
      "tempo": "1h 45 min",
      "valor": "Add1h45"
    },
    {
      "tempo": "2h",
      "valor": "Add2h"
    }

  ]

  especialidades_cadastradas = [
    'Clinico Geral', 'Banho', 'Banho e Tosa', 'Cirurgia', 'Dermatologista', 'Tosa'
  ];

  especies_que_atende = [
    'Canina', 'Felina', 'Aves', 'Répteis', 'Grande Porte', 'Peixes'
  ];

  profissionais = [
    {
      id: "uC43pAOtEGTw",
      dados_pessoais:
      {
        nome: 'Alex Pereira',
        sexo: 'masculino',
        data_nascimento: new Date('01/01/1970'),
        documento_identidade: '123456',
        cpf: '12345678901',
        celular: '31997206612',
        telefone: '1234567890',
        email: 'alex@example.com'
      },
      endereco: {
        cep: '12345678',
        logradouro: 'Main Street',
        numero: 123,
        complemento: '',
        bairro: 'Downtown',
        cidade: 'New York',
        uf: 'NY'
      },
      programacao: {
        janela_disponibilidade: [
          {
            id: 1,
            dias: 'Ter',
            horario_inicial: { hours: 9, minutes: 0 },
            horario_final: { hours: 16, minutes: 0 },
            valor: 36,
            tipo_atendimento: 'online',
            especialidades: ["Clinico Geral"],
            visivel: 'Não',
            duracao_atendimento: '1h',
            tipo_avatar: 'empresa',
            antecedencia_agendamento: '1 semana',
            tempo_minimo_aviso: '15min',
          },

          {
            id: 2,
            dias: 'Seg - Sex',
            horario_inicial: { hours: 8, minutes: 30 },
            horario_final: { hours: 10, minutes: 0 },
            valor: 78,
            tipo_atendimento: 'online',
            especialidades: ["Vacinação"],
            visivel: 'Não',
            duracao_atendimento: '1',
            tipo_avatar: 'empresa',
            antecedencia_agendamento: '1 semana',
            tempo_minimo_aviso: '15',
          }
        ],
        tipo_avatar: '',
        antecedencia_agendamento: '',
        tempo_minimo_aviso: 0,
        bloquear_agenda: [
          {
            data: new Date('05/012023'),

            horario_inicial: '10h',

            horario_final: '18h',
          },

          {
            data: new Date('06/012023'),

            horario_inicial: '10h',

            horario_final: '18h',
          },

        ]



      },
      especialidade_procedimentos: [this.especilidades_Array[0], this.especilidades_Array[1]],
      especializacao: 'Clínico Geral',
      cargo: 'Analista Comercial',
      horario_trabalho: '09h as 17h',
    },

    {
      id: "VmhHfCY0QJal",
      dados_pessoais: {
        nome: 'Amanda Santos',
        sexo: 'feminino',
        data_nascimento: new Date('06/01/1970'),
        documento_identidade: '345678',
        cpf: '34567890123',
        celular: '31997206614',
        telefone: '3456789012',
        email: 'amanda@example.com'
      },
      endereco: {
        cep: '34567890',
        logradouro: 'Tertiary Street',
        numero: 789,
        complemento: '',
        bairro: 'Midtown',
        cidade: 'New York',
        uf: 'NY'
      },
      programacao: {
        janela_disponibilidade: [
          {
            id: 1,
            dias: 'Seg',
            horario_inicial: { hours: 12, minutes: 0 },
            horario_final: { hours: 19, minutes: 0 },
            valor: 20,
            tipo_atendimento: 'online',
            especialidades: ['Acupuntura'],
            visivel: 'Não',
            duracao_atendimento: '1h',
            tipo_avatar: 'empresa',
            antecedencia_agendamento: '1 semana',
            tempo_minimo_aviso: '15min',
          },

          {
            id: 2,
            dias: 'Seg',
            horario_inicial: { hours: 10, minutes: 0 },
            horario_final: { hours: 15, minutes: 0 },
            valor: 510,
            tipo_atendimento: 'online',
            especialidades: ["Banho"],
            visivel: 'Não',
            duracao_atendimento: '1h',
            tipo_avatar: 'empresa',
            antecedencia_agendamento: '1 semana',
            tempo_minimo_aviso: '15min',
          }
        ],
        tipo_avatar: '',
        antecedencia_agendamento: '',
        tempo_minimo_aviso: 0,
        bloquear_agenda: [
          {
            data: new Date('05/012023'),

            horario_inicial: '10h',

            horario_final: '18h',
          },

          {
            data: new Date('06/012023'),

            horario_inicial: '10h',

            horario_final: '18h',
          },

        ]



      },
      especialidade_procedimentos: [this.especilidades_Array[2], this.especilidades_Array[3]],
      especializacao: 'Clínico Geral',
      cargo: 'Médico Veterinário',
      horario_trabalho: '11h as 20h',
    },

    {
      id: "kX38imskliZ6",
      dados_pessoais: {
        nome: 'Barbara Almeida',
        sexo: 'feminino',
        data_nascimento: new Date('02/01/1970'),
        documento_identidade: '234567',
        cpf: '23456789012',
        celular: '31997206613',
        telefone: '2345678901',
        email: 'olivia@example.com'
      },
      endereco: {
        cep: '23456789',
        logradouro: 'Secondary Street',
        numero: 456,
        complemento: '',
        bairro: 'Uptown',
        cidade: 'New York',
        uf: 'NY'
      },
      programacao: {
        janela_disponibilidade: [
          {
            id: 1,
            dias: 'Qui',
            horario_inicial: { hours: 10, minutes: 0 },
            horario_final: { hours: 16, minutes: 0 },
            valor: 58,
            tipo_atendimento: 'online',
            especialidades: ["Cardiologia"],
            visivel: 'Não',
            duracao_atendimento: '1h',
            tipo_avatar: 'empresa',
            antecedencia_agendamento: '1 semana',
            tempo_minimo_aviso: '15min',
          },

          {
            id: 2,
            dias: 'Seg - Sex',
            horario_inicial: { hours: 9, minutes: 0 },
            horario_final: { hours: 18, minutes: 0 },
            valor: 50,
            tipo_atendimento: 'online',
            especialidades: ["Anestesiologia"],
            visivel: 'Não',
            duracao_atendimento: '1h',
            tipo_avatar: 'empresa',
            antecedencia_agendamento: '1 semana',
            tempo_minimo_aviso: '15min',
          }
        ],
        tipo_avatar: '',
        antecedencia_agendamento: '',
        tempo_minimo_aviso: 0,
        bloquear_agenda: [
          {
            data: new Date('05/012023'),

            horario_inicial: '10h',

            horario_final: '18h',
          },

          {
            data: new Date('06/012023'),

            horario_inicial: '10h',

            horario_final: '18h',
          },

        ]



      },
      especialidade_procedimentos: [this.especilidades_Array[4], this.especilidades_Array[5]],
      especializacao: 'Clínico Geral',
      cargo: 'Tosador',
      horario_trabalho: '10h as 19h',
    }
  ]



  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        console.log('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        console.log('Deleted', event);
      },
    },
  ];




  events: CalendarEvent[] = [

    {
      id: 1,
      start: addMinutes(addHours(startOfDay(new Date()), 9), 0),
      end: addMinutes(addHours(startOfDay(new Date()), 10), 0), // an end date is always required for resizable events to work
      title: 'test1',
      color: { ...colors.yellow },
      actions: this.actions,
      cssClass: 'atendimento-completo',
      resizable: {
        beforeStart: true,  // this allows you to configure the sides the event is resizable from
        afterEnd: true,
      },
      draggable: true,
      meta: {
        tutorObject: this.tutors[0],
        tutor: 'Victor Lemos Cintra',
        profissional: 'Alex Pereira',


        email: 'victor@gmail.com',
        celular: '31997207812',
        canalAgendamento: 'Telefone',
        agendadoPor: 'Olivia Freitas',
        statusAgendamento: 'Confirmado',

        pet: "Lice",
        petId: "a0jFBmV7PSM2e",
        faltas: 2,
        especie: 'gato',
        especialidade: 'Cilinico Geral',
        idade: 1,
        peso: 10,
        ultima_consulta: '05/12/2022',
        data: new Date(),
        dataString: new Date().toLocaleDateString(),
      }
    },

    {
      id: 2,
      start: addMinutes(addHours(startOfDay(new Date()), 10), 10),
      end: addMinutes(addHours(startOfDay(new Date()), 10), 40),
      title: 'test2',
      color: { ...colors.yellow },
      actions: this.actions,
      cssClass: 'agendamento-confirmado',
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: true,
      meta: {
        tutorInfo: this.tutorToMetaEvents('uC43pAOtEGTw', this.tutors)[0],
        tutorID: "IGIGev3S4yXE",
        profissionalID: 'VmhHfCY0QJal',
        tutorObject: this.tutors[1],
        tutor: 'Jane Doe',

        profissional: 'Amanda Santos',
        email: 'jane@gmail.com',
        celular: '31998756645',

        canalAgendamento: 'Telefone',
        agendadoPor: 'Olivia Freitas',
        statusAgendamento: 'Confirmado',
        petId: "sWF4lOp9aycZ5",

        pet: "Bóris",
        faltas: 0,
        especie: 'gato',
        especialidade: 'Cilinico Geral',
        idade: 1,
        peso: 5,
        ultima_consulta: '05/12/2022',
        data: new Date(),
        dataString: new Date().toLocaleDateString(),
      }
    },

    {
      id: 3,
      start: addMinutes(addHours(startOfDay(new Date()), 11), 0),
      end: addMinutes(addHours(startOfDay(new Date()), 11), 20),
      title: 'test3',
      color: { ...colors.yellow },
      actions: this.actions,
      cssClass: 'agendamento-cancelado',
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: true,
      meta: {
        tutorID: "tn24mQJxSohM",
        profissionalID: 'kX38imskliZ6',
        tutorInfo: this.tutorToMetaEvents('uC43pAOtEGTw', this.tutors)[0],
        tutor: 'Victor Lemos Cintra',

        profissional: 'Barbara Almeida',
        email: 'victor@gmail.com',
        celular: '31997207812',

        canalAgendamento: 'Telefone',
        agendadoPor: 'Olivia Freitas',
        statusAgendamento: 'Confirmado',

        pet: "Buddy",
        petId: "rsJh0dNYXBWm6",
        especie: 'cachorro',
        faltas: 1,
        especialidade: 'Cilinico Geral',
        idade: 4,
        peso: 15,
        ultima_consulta: '05/12/2022',
        data: new Date(),
        dataString: new Date().toLocaleDateString(),
      }
    },

    {
      id: 4,
      start: addMinutes(addHours(startOfDay(new Date()), 12), 0),
      end: addMinutes(addHours(startOfDay(new Date()), 13), 0),
      title: 'test4',
      color: { ...colors.yellow },
      actions: this.actions,
      cssClass: 'confirmar-agendamento',
      resizable: {
        beforeStart: true,
        afterEnd: true,
      },
      draggable: true,
      meta: {
        tutorID: "uC43pAOtEGTw",
        profissionalID: "uC43pAOtEGTw",
        tutorInfo: this.tutorToMetaEvents('uC43pAOtEGTw', this.tutors)[0],
        tutorObject: this.tutors[2],
        profissionalObject: this.profissionais[2],

        tutor: 'Rafael França',
        profissional: 'Alex Pereira',
        email: 'rafael@gmail.com',
        celular: '31985579862',

        canalAgendamento: 'Telefone',
        agendadoPor: 'Olivia Freitas',
        statusAgendamento: 'Confirmado',

        petID: "tDhHJor1H2ZTO",
        pet: "lele",
        faltas: 0,
        especie: 'gato',
        especialidade: 'Vacinação',
        idade: 3,
        peso: 7,
        ultima_consulta: '22/12/2022',
        data: new Date(),
        dataString: new Date().toLocaleDateString(),
      }
    },
  ]


  tutorToMetaEvents(id: string, tutors: ITutor[]) {
    return tutors.filter(tutor => tutor.id === id).map(tutor => {

      let tutorInfo: {} = {
        tutor: tutor.dados_pessoais?.nome,
        email: tutor.dados_pessoais?.email,
        celular: tutor.dados_pessoais?.celular,

      }

      return tutorInfo
    })
  }


  getEvents(): Observable<CalendarEvent[]> {
    return of(this.events)
  }

  addEvent(event: CalendarEvent) {

    this.events = [...this.events, event]
    console.log(this.events);
  }




  showAlert(message, success, failure, header?) {
    this.alertController.create({
      header: 'x',
      message: message,
      buttons: [
        {
          cssClass: "custom-alert-button-confirm",
          text: 'Sim',
          handler: () => {
            if (success) {
              success();
            }
          }
        },
        {
          cssClass: "custom-alert-button-cancel",
          text: 'Não',
          handler: () => {
            if (failure) {
              failure();
            }
          }
        }
      ],
      cssClass: "custom-alert",
    }).then((alert) => {
      alert.present().then(() => {
        let el = document.querySelector(".alert-title");
        if (el) {
          el.addEventListener("click", function () {
            setTimeout(() => {
              alert.dismiss();
              if (failure) {
                failure();
              }
            }, 100);
          });
        }
      });
    });
  }


  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }



  extractHoursElements_Array(horaInicial: string): [number, number] {
    let firstTwoElements = horaInicial.substring(0, 2);
    let hour: number;
    if (firstTwoElements[0] === "0") {
      hour = parseInt(firstTwoElements[1]);
    } else {
      hour = parseInt(firstTwoElements);
    }

    let minutes = parseInt(horaInicial.substring(3, 5));

    return [hour, minutes];
  }

  extractHoursElements(horaInicial: string): number {
    let firstTwoElements = horaInicial.substring(0, 2);
    if (firstTwoElements[0] === "0") {
      return parseInt(firstTwoElements[1]);
    } else {
      return parseInt(firstTwoElements);
    }
  }

  extractMinutesElements(horaInicial: string): number {
    let Elements = horaInicial.substring(3, 5);
    if (Elements[0] === "0") {
      return parseInt(Elements[1]);
    } else {
      return parseInt(Elements);
    }
  }

  successAlert(title: string) {

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: title,
      showConfirmButton: false,
      timer: 2000,
      heightAuto: false
    })

  }

  errorAlert(title: string) {

    Swal.fire({
      position: 'center',
      icon: 'error',
      title: title,
      showConfirmButton: false,
      timer: 3000,
      heightAuto: false
    })

  }



  removeDuplicateObjects(objects: ObjectWithUniqueKey[]): ObjectWithUniqueKey[] {
    const uniqueObjects = []; // um array vazio para armazenar objetos distintos

    for (const obj of objects) {
      const uniqueKey = JSON.stringify(obj); // gera uma string JSON única para cada objeto
      if (!uniqueObjects.some((o) => JSON.stringify(o) === uniqueKey)) {
        uniqueObjects.push(obj); // adiciona o objeto ao array único se sua chave única não foi encontrada
      }
    }

    return uniqueObjects;
  }

  transformPhoneNumber(phoneNumber: string | undefined): string {
    if (phoneNumber === undefined) {
      return "";
    }

    const areaCode = phoneNumber?.slice(0, 2);
    const firstFive = phoneNumber?.slice(2, 7);
    const lastFour = phoneNumber?.slice(7, 11);

    return `(${areaCode}) ${firstFive}-${lastFour}`;
  }

  milisecondsToDate(millisecond) {
    let milliseconds = millisecond;
    let date = new Date(milliseconds);
    let timezoneOffset = -date?.getTimezoneOffset() / 60;
    let offset = `${timezoneOffset >= 0 ? '+' : '-'}${('0' + Math.abs(timezoneOffset))?.slice(-2)}:00`;
    let isoString = date?.toLocaleTimeString("pt-BR")?.replace('Z', offset);
    console.log(isoString); // "2023-02-02T13:09:00-03:00"
    return isoString
  }

  verificaHorario = (hourInitial: string, hourFinal: string): boolean => {
    const initialTime = new Date(`1970-01-01T${hourInitial}Z`);
    const finalTime = new Date(`1970-01-01T${hourFinal}Z`);
    return finalTime > initialTime;
  };
  getFormattedDate(dateString: string): string {
    // console.log(dateString)
    if (!dateString) {
      return "-";
    }
    const date = new Date(`${dateString}T00:00:00-03:00`);
    const options = { day: '2-digit', month: 'long' } as Intl.DateTimeFormatOptions;
    return date.toLocaleDateString('pt-BR', options);
  }

  getFormattedDate3(dateString: string): string {
    // console.log(dateString)
    if (!dateString) {
      return "-";
    }
    const inputString = dateString;
    const date = new Date(inputString);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so we add 1
    const year = date.getFullYear().toString().substr(2, 2); // Get the last two digits of the year
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    const formattedDate = `${day}/${month}/${year} às ${hours}:${minutes}`;
    // console.log(formattedDate); // Output: "20/03/23 às 17:00"
    return formattedDate
  }

  getFormattedDate1(dateString: string): string {
    if (!dateString) {
      return "";
    }
    const date = new Date(`${dateString}T00:00:00-03:00`);
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    } as Intl.DateTimeFormatOptions;
    return date.toLocaleDateString('pt-BR', options).replace(/\//g, '-');
  }

  onVisualizarTutor(id: number) {
    console.log(id)

    this.router.navigateByUrl('tabs/tutores-info-card/' + id);

  }

  onVisualizarProfissional(id: number) {
    console.log(id)

    this.router.navigateByUrl('tabs/profissionais-card-agenda/' + id);

  }

  getIcon(canalAgendamento: string) {
    switch (canalAgendamento) {
      case 'Telefone':
        return 'call';
      case 'E-mail':
        return 'mail';
      case 'WhatsApp':
        return 'logo-whatsapp';
      case 'PETinho de Mim':
        return 'home-outline';
      case 'Presencial':
        return 'home-outline';
      case 'Form Landing Page':
        return 'earth-outline';
      default:
        return '';
    }
  }




}



