import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import {
  ModalController,
  ViewDidLeave,
  ViewWillEnter,
  ViewWillLeave,
} from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { IPet, ITutor } from 'src/app/interfaces/interfaces';
import { NovoPetPage } from 'src/app/modals/novo-pet/novo-pet.page';
import { TutorService } from 'src/app/services/tutor.service';
import { TutoresService } from 'src/app/services/tutores.service';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';
import { EventColor } from 'calendar-utils';
import { UtilsService } from 'src/app/services/utils.service';
import { CalendarEvent, CalendarEventAction } from 'angular-calendar';
import { AgendaService } from 'src/app/services/agenda.service';
import { addMinutes, addHours, startOfDay, parse } from 'date-fns';
import { CardAgendamentoDiaPage } from 'src/app/modals/card-agendamento-dia/card-agendamento-dia.page';

const colors: Record<string, EventColor> = {
  red: {
    primary: '#fff',
    secondary: '#fff',
  },
  blue: {
    primary: '#fff',
    secondary: '#fff',
  },
  yellow: {
    primary: '#fff',
    secondary: '#fff',
  },
};

@AutoUnsubscribe
@Component({
  selector: 'app-pet-card-cadastro',
  //changeDetection: ChangeDetectionStrategy.Default,
  templateUrl: './pet-card-cadastro.component.html',
  styleUrls: ['./pet-card-cadastro.component.scss'],
})
export class PetCardCadastroComponent
  implements OnInit, ViewWillEnter, ViewWillLeave, ViewDidLeave, OnDestroy {
  // isLoading = true;

  // --> Esse component possui 2 inputs ; selected_Tutor é válido quando Parent_Component = 'TutorCardWithPet'
  // isso significa que esse component esta sendo chamado pelo no detalhes do tutor já cadastrado,
  // dessa forma o component usa o objeto selected_Tutor  para exibir as informações.
  // --> Quando  Parent_Component = 'NovoPet' significa que o component esta sendo chamado por Tutores cadastrado
  // dessa forma deve iniciar com um formulario novo.
  //--> NovoPet_Object_fromModal_ToEvents deve ser um output...

  //@Input()  CustomWidth: number;

  @Input() selected_TutorWithPets: ITutor[];

  @Input() Parent_Component: string;

  @Input() wasSaved = false;

  @Output() Novo_PetObject_Output = new EventEmitter<any>(); // ao cadastrar um pet um evento é emitido para atulizar

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        console.log('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        console.log('Deleted', event);
      },
    },
  ];

  pets: IPet[] = [];

  NovoPet_Object_fromModal: any;

  NovoPet_Object_fromModal_ToEvents: any;

  displayModeObservations: any = [];
  displayModeSchedules: any = [];
  selected_Tutor: any;
  show = false;
  subs: Subscription;
  petsList: any = [];

  tutores_Array: ITutor[];
  selected_Tutor_index_String: string;
  selected_Tutor_index_Number: number;

  selected_Tutor$: Observable<ITutor[]>;
  pets$: Observable<any[]>;
  selected_Tutor_Id_String: string;

  tutorId: string;
  strapiBaseUrl = environment.strapiBaseUrl;

  petsAgendamentos: any;
  statusSelector: string = '';
  public statusColor: string = '';
  arrayEvent: any;
  event: any;

  constructor(
    private modalCtrl: ModalController,
    private tutorService: TutorService,
    private tutoresService: TutoresService,
    private router: ActivatedRoute,
    public utilsService: UtilsService,
    private agendaService: AgendaService
  ) {
    this.tutorId = this.router.snapshot.paramMap.get('id');

    console.log('TUTOR ID', this.tutorId);
  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter');
  }

  ionViewWillLeave() {
    console.log('ionViewWillleave');
  }

  ionViewDidLeave() {
    console.log('ioViewDidLeave');
  }

  ngOnInit() {
    this.getPetList();

    if (
      this.Parent_Component == 'TutorCardWithPet' ||
      this.Parent_Component == 'editPet'
    ) {
      this.selected_Tutor_index_String = localStorage.getItem(
        'selected_Tutor_index_String'
      );
      this.selected_Tutor_index_Number = new Number(
        this.selected_Tutor_index_String
      ).valueOf();

      let selected_Tutor_Id_String = localStorage.getItem(
        'selected_Tutor_Id_String'
      );
      this.selected_Tutor_Id_String = selected_Tutor_Id_String;
    } else if (this.Parent_Component == 'NovoPet') {
      const objectString = localStorage.getItem('myObject'); ///  Para o card não sumir ao mudar de guia no cadastro de tutores e pets

      if (objectString == null) {
        this.pets = [];
        // this.isLoading = false;
      } else {
        this.pets = JSON.parse(objectString);
        // this.isLoading = false;
      }
      console.log(this.pets);

      return this.pets;
    }
  }

  ngOnDestroy(): void {
    console.log('destroyed');

    if (this.Parent_Component == 'NovoPet') {
      if (this.wasSaved) localStorage.removeItem('myObject');
    }
  }

  getPetList() {
    let tutorId = this.router.snapshot.paramMap.get('id');

    this.tutoresService.getPets(+tutorId).subscribe((result) => {
      let color_class;

      this.petsList = result?.data;
      console.log(this.petsList);
    });
  }

  getSelectedPet() {
    this.subs = this.tutorService
      .getSelectedTutor()
      .pipe(first())
      .subscribe((data) => {
        this.selected_Tutor = data;
        // this.isLoading = false;
        if (this.Parent_Component == 'TutorCardWithPet') {
          this.pets = this.selected_Tutor.pets;
          console.log(this.Parent_Component);
        } else if (this.Parent_Component == 'NovoPet') {
          const objectString = localStorage.getItem('myObject'); ///  Para o card não sumir ao mudar de guia no cadastro de tutores e pets

          if (objectString == null) {
            this.pets = [];
          } else {
            this.pets = JSON.parse(objectString);
          }
          console.log(this.pets);

          return this.pets;
        }
      });
  }

  addPet(petObjetc: IPet) {
    //this.selected_Tutor.pets.push(petObjetc);
    this.pets.push(petObjetc); // adciona o objeto pet no array de objetos
    this.Novo_PetObject_Output.emit(this.pets); // emite um evento para atualizar o JSON_Tutor no componente tutores-cadastro

    localStorage.setItem('myObject', JSON.stringify(this.pets));
    console.log(this.pets);
  }

  //*Diogo
  //Esta função troca o modo de exibição dentro do Card de informações do Pet, exibindo informações ou observações

  displayObservations(modeIndex: string) {
    this.displayModeObservations.push(modeIndex);
    this.removeAgendamentos(modeIndex);
  }

  displayInformations(modeIndex: string) {
    let indexToRemove = this.displayModeObservations.findIndex(
      (index) => index === modeIndex
    );
    this.displayModeObservations.splice(indexToRemove);
    this.removeAgendamentos(modeIndex);
  }

  //*Diogo
  //Esta função habilita o modo de exibição dos agendamentos no Card dos Pets

  displayAgendamentos(modeIndex: string, petid) {
    this.displayModeSchedules.push(modeIndex);
  }

  getFormattedDate(dateString: string): string {
    console.log(dateString);
    const date = new Date(`${dateString}T00:00:00-03:00`);
    const options = {
      day: '2-digit',
      month: 'short',
    } as Intl.DateTimeFormatOptions;
    return date.toLocaleDateString('pt-BR', options);
  }

  selectStatusColor(status: string): string {
    switch (status) {
      case 'confirmado':
        return 'success';
      case 'agendado':
        return 'warning';
      case 'aguardandoAtendimento':
        return 'medium';
      case 'Ncompareceu':
        return 'dark';
      case 'pacienteAtendido':
        return 'light';
      case 'pacientAtrasado':
        return 'primary';
      case 'cancelado':
        return 'danger';
      default:
        return 'warning';
    }
  }

  removeAgendamentos(modeIndex: string) {
    let indexToRemove = this.displayModeSchedules.findIndex(
      (index) => index === modeIndex
    );
    this.displayModeSchedules.splice(indexToRemove);
  }

  async onEditarPet(petID: string) {
    console.log(petID);

    const modal = await this.modalCtrl.create({
      component: NovoPetPage,
      cssClass: 'modal-incluir-agendamento',
      componentProps: {
        mode: 'edit',
        petID: petID,
        tutorID: this.tutorId,
      },
    });

    modal.onDidDismiss().then((NovoPet_Object) => {
      //console.log(NovoPet_Object)

      this.getPetList();

      if (NovoPet_Object.data === undefined) {
        console.log(NovoPet_Object, 'CRIAR REGRA PARA OBJETO UNDEFINED');
      } else {
        this.NovoPet_Object_fromModal = NovoPet_Object.data;

        this.NovoPet_Object_fromModal_ToEvents =
          this.NovoPet_Object_fromModal.data.pet_Object;
        //console.log(`Novo_AgendamentosObject mudou de  ${change.previousValue} para ${change}`);
        if (this.NovoPet_Object_fromModal_ToEvents != undefined) {
          console.log(
            'NovoPet_Object_fromModal_ToEvents',
            this.NovoPet_Object_fromModal_ToEvents
          );
          this.tutorService.editPet(
            this.NovoPet_Object_fromModal_ToEvents,
            this.selected_Tutor_Id_String,
            this.NovoPet_Object_fromModal_ToEvents.id
          );
          this.tutorService.refeshTutors$.next(true);
        }
      }
    });
    await modal.present();
  }

  async onIncluirPet() {
    const modal = await this.modalCtrl.create({
      component: NovoPetPage,
      cssClass: 'modal-incluir-agendamento',
      componentProps: {
        mode: 'insert',
        tutorId: this.tutorId,
      },
    });

    modal.onDidDismiss().then((NovoPet_Object) => {
      //console.log(NovoPet_Object)

      this.getPetList();

      if (NovoPet_Object.data === undefined) {
        console.log(NovoPet_Object, 'CRIAR REGRA PARA OBJETO UNDEFINED');
      } else {
        this.NovoPet_Object_fromModal = NovoPet_Object.data;

        this.NovoPet_Object_fromModal_ToEvents =
          this.NovoPet_Object_fromModal.data.pet_Object;
        //console.log(`Novo_AgendamentosObject mudou de  ${change.previousValue} para ${change}`);
        if (this.NovoPet_Object_fromModal_ToEvents != undefined) {
          console.log(
            'NovoPet_Object_fromModal_ToEvents',
            this.NovoPet_Object_fromModal_ToEvents
          );
          this.addPet(this.NovoPet_Object_fromModal_ToEvents);
        }
      }
    });
    await modal.present();
  }

  openCardAgendamentoDia(idAgendamento: any) {
    return this.getEvent(idAgendamento);
  }

  getEvent(idAgendamento) {
    console.log(idAgendamento);
    let color_class;
    return this.agendaService
      .getAgendamentoByID(idAgendamento)
      .subscribe((result) => {
        console.log(result);
        this.arrayEvent = result?.data.map((dados) => {
          const {
            data,
            horario_inicial,
            horario_final,
            pet,
            profissional,
            tutors,
          } = dados?.attributes;
          // console.log(parse(data, 'yyyy-MM-dd', new Date()), data)
          // console.table(dados?.attributes);
          const [horaInicial, minutesInicial] =
            this.utilsService.extractHoursElements_Array(horario_inicial);
          const [horaFinal, minutesFinal] =
            this.utilsService.extractHoursElements_Array(horario_final);
          const petObject = pet?.data;
          console.log(petObject, profissional);
          const petDateOfBirth = petObject?.attributes?.data_nascimento;
          const petAge = petDateOfBirth
            ? Math.floor(
              Math.floor(
                (new Date().getTime() - new Date(petDateOfBirth).getTime()) /
                1000 /
                60 /
                60 /
                24 /
                365
              )
            )
            : '';
          //Rotina para selecionar a categoria de cor, conforme status

          let status = dados?.attributes?.status;

          switch (status) {
            case 'confirmado':
              color_class = 'confirmado';
              break;
            case 'agendado':
              color_class = 'agendado';
              break;
            case 'aguardandoAtendimento':
              color_class = 'completo';
              break;
            case 'Ncompareceu':
              color_class = 'noshow';
              break;
            case 'pacienteAtendido':
              color_class = 'atendido';
              break;
            case 'cancelado':
              color_class = 'cancelado';
              break;
          }

          return {
            color: { ...colors.yellow },
            actions: this.actions,
            cssClass1: `${color_class}`,
            draggable: false,
            start: addMinutes(
              addHours(
                startOfDay(parse(data, 'yyyy-MM-dd', new Date())),
                horaInicial
              ),
              minutesInicial
            ),
            end: addMinutes(
              addHours(
                startOfDay(parse(data, 'yyyy-MM-dd', new Date())),
                horaFinal
              ),
              minutesFinal
            ),
            title: 'agendamento',
            resizable: { beforeStart: false, afterEnd: false },
            meta: {
              idAgendamento: dados?.id,
              tutorObject: tutors?.data[0],
              tutor: tutors?.data[0]?.attributes?.dados_pessoais?.nome,
              profissional:
                profissional?.data?.attributes?.dados_pessoais?.nome,
              profissionalID: profissional?.data?.id,
              email: tutors?.data[0]?.attributes?.dados_pessoais?.email,
              celular: tutors?.data[0]?.attributes?.dados_pessoais?.celular,

              especialidade_procedimentoSelected:
                profissional?.data?.attributes?.especialidades_procedimentos?.filter(
                  (result) => result?.nome === dados?.attributes?.especialidade
                ),
              canalAgendamento: dados?.attributes?.canal_agendamento,
              id_usuario_agendamento: dados?.attributes?.id_usuario_agendamento,
              tipo_atendimento: dados?.attributes?.tipo_atendimento,
              tipo_agendamento: dados?.attributes?.tipo_agendamento,
              agendadoPor: dados?.attributes?.agendadoPor,
              especialidade: dados?.attributes?.especialidade,
              faltas: dados?.attributes?.faltas,
              statusAgendamento: dados?.attributes?.status,
              dataMaximaRetorno: dados?.attributes?.dataMaximaRetorno,
              createdAt: new Date(dados?.attributes?.publishedAt),
              updatedAt: new Date(dados?.attributes?.updatedAt),

              pet: petObject?.attributes?.nome,
              petId: petObject?.id,
              petAvatar: petObject?.attributes?.avatar?.data?.attributes?.url,
              especie: petObject?.attributes?.especie,
              idade: petAge,
              peso: petObject?.attributes?.peso,
              ultima_consulta: '05/12/2022',
              data: data,
              dataString: data,
            },
          };
        });
        this.event = this.arrayEvent;
        console.log(this.event);

        this.VerDetalhesModalCard(
          this.event[0].start,
          this.event[0].end,
          this.event[0].meta
        );
        return this.event;
      });
  }

  async VerDetalhesModalCard(start, end, meta) {
    const modal = await this.modalCtrl.create({
      component: CardAgendamentoDiaPage,
      cssClass: 'modalCard-pet-agendamento',
      componentProps: {
        horaInicial: start,
        horaFinal: end,
        metaDados: meta,
      },
    });

    modal.onDidDismiss().then(() => {
      this.getPetList();
    });
    await modal.present();
    await modal.present();
  }
}
