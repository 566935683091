import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        const currentFunction = this.authenticationService.userFunctionValue;

        console.log('AUTHGUARD', currentFunction)

        if (currentUser && currentFunction != 'laboratorio') {
            // logged in so return true
            return true;
        }
        else if(currentUser && currentFunction === 'laboratorio'){
            this.router.navigate(['/tabs/exames-laboratorios']);
            return true;
        }        

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}