import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordemALgabeticaArray'
})
export class OrdemALgabeticaArrayPipe implements PipeTransform {

  transform(value: string[]): string[] {
    if (!value) return []; // retorna um array vazio se o valor de entrada for falso ou indefinido
    return value.sort((a, b) => a.localeCompare(b)); // usa o método de comparação "localeCompare" para ordenar as strings em ordem alfabética
  }

}
