import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExamesService {
  
  strapiApiUrl = environment.strapiApiUrl

  constructor(private http: HttpClient) { }

  getExamesByBuzz(perfil_negocio:number, pagination:number, pageSize: number = 50){

    return this.http.get<any>(this.strapiApiUrl + '/pedido-exames?filters[perfil_negocio][id][$eq]=' + perfil_negocio + '&pagination[page]=' + pagination + '&pagination[pageSize]=' + pageSize + '&populate[pet][populate][0]=*&populate[tutor][populate][1]=dados_pessoais&populate[laboratorio][populate][0]=*&populate[remessas][populate][0]=*&populate[resultado][populate][0]=*&populate[profissional][populate][0]=dados_pessoais&sort=createdAt:desc')

  }

  getExamesByLab(lab_id:number, pagination:number, pageSize: number = 50){

    return this.http.get<any>(this.strapiApiUrl + '/pedido-exames?filters[laboratorio][id][$eq]=' + lab_id + '&pagination[page]=' + pagination + '&pagination[pageSize]=' + pageSize + '&populate[perfil_negocio][populate][0]=*&populate[pet][populate][0]=*&populate[tutor][populate][1]=dados_pessoais&populate[laboratorio][populate][0]=*&populate[remessas][populate][0]=*&populate[resultado][populate][0]=*&populate[profissional][populate][0]=dados_pessoais&sort=createdAt:desc')

  }

  getExamesWithResults(perfil_negocio:number, pagination:number, pageSize: number = 50){

    return this.http.get<any>(this.strapiApiUrl + '/pedido-exames?filters[$or][0][perfil_negocio][id][$eq]=' + perfil_negocio + '&filters[$and][1][resultado_disponivel][$eq]=true&pagination[page]=' + pagination + '&pagination[pageSize]=' + pageSize + '&populate[pet][populate][0]=*&populate[tutor][populate][1]=dados_pessoais&populate[laboratorio][populate][0]=*&populate[remessas][populate][0]=*&sort=createdAt:desc')

  }

  getExamesWithResultsByLab(laboratorio_id:number, pagination:number, pageSize: number = 50){

    return this.http.get<any>(this.strapiApiUrl + '/pedido-exames?filters[$or][0][laboratorio][id][$eq]=' + laboratorio_id + '&filters[$and][1][resultado_disponivel][$eq]=true&pagination[page]=' + pagination + '&pagination[pageSize]=' + pageSize + '&populate[pet][populate][0]=*&populate[tutor][populate][1]=dados_pessoais&populate[perfil_negocio][populate][0]=*&populate[laboratorio][populate][0]=*&populate[remessas][populate][0]=*&sort=createdAt:desc')

  }

  getExamesCanceled(perfil_negocio:number, pagination:number, pageSize: number = 50){

    return this.http.get<any>(this.strapiApiUrl + '/pedido-exames?filters[$or][0][perfil_negocio][id][$eq]=' + perfil_negocio + '&filters[$and][1][status][$eq]=Cancelado&pagination[page]=' + pagination + '&pagination[pageSize]=' + pageSize + '&populate[pet][populate][0]=*&populate[tutor][populate][1]=dados_pessoais&populate[laboratorio][populate][0]=*&populate[remessas][populate][0]=*&sort=createdAt:desc')

  }

  getExamesCanceledByLab(laboratorio_id:number, pagination:number, pageSize: number = 50){

    return this.http.get<any>(this.strapiApiUrl + '/pedido-exames?filters[$or][0][laboratorio][id][$eq]=' + laboratorio_id + '&filters[$and][1][status][$eq]=Cancelado&pagination[page]=' + pagination + '&pagination[pageSize]=' + pageSize + '&populate[pet][populate][0]=*&populate[perfil_negocio][populate][0]=*&populate[tutor][populate][1]=dados_pessoais&populate[laboratorio][populate][0]=*&populate[remessas][populate][0]=*&sort=createdAt:desc')

  }

  getExamesByFilters(perfil_negocio:number, pagination:number, pageSize: number = 50, filters:string){

    return this.http.get<any>(this.strapiApiUrl + '/pedido-exames?filters[$or][0][perfil_negocio][id][$eq]=' + perfil_negocio  + '&' + filters + '&pagination[start]=' + pagination + '&pagination[limit]=' + pageSize + '&populate=*&sort=createdAt:asc')

  }

  getExamesByFiltersLab(negocio_id:number, pagination:number, pageSize: number = 50, filters:string){

    return this.http.get<any>(this.strapiApiUrl + '/pedido-exames?filters[$or][0][laboratorio][id][$eq]=' + negocio_id  + '&' + filters + '&pagination[start]=' + pagination + '&pagination[limit]=' + pageSize + '&populate=*&sort=createdAt:asc')

  }

  getExameDetails(exId:number){
    return this.http.get<any>(this.strapiApiUrl + '/pedido-exames/' + exId +'?populate=*')
  }

  createExame(exameData:any){
    return this.http.post<any>(this.strapiApiUrl + '/pedido-exames', {data:exameData})
  } 

  getTiposExames(pagination=1, pageSize=50, perfil_negocio: number){
    return this.http.get<any>(this.strapiApiUrl + '/exames?filters[grupo_de_negocio][perfis_negocios][id][$eq]=' + perfil_negocio +'&pagination[page]=' + pagination + '&pagination[pageSize]=' + pageSize + '&populate=*&sort=createdAt:asc')
  }
  
  createTiposExames(exameData){

    return this.http.post<any>(this.strapiApiUrl + '/exames',{data: exameData})

  }

  updateTiposExames(exameData, exameId){

    return this.http.put<any>(this.strapiApiUrl + '/exames/' + exameId,{data: exameData})

  }

  deleteTiposExames(eId:number){
    return this.http.delete<any>(this.strapiApiUrl + '/exames/' + eId)
  }

  getTiposExamesByTitle(perfil_negocio:number, title:string){
    return this.http.get<any>(this.strapiApiUrl + '/exames?filters[$and][0][grupo_de_negocio][perfis_negocios][id][$eq]=' + perfil_negocio + '&filters[$or][1][search_field][$containsi]=' + title +'&filters[$or][2][titulo][$containsi]=' + title +'&populate=*')
  }
  getLaboratorios(pagination=1, pageSize=50, perfil_negocio: number){
    return this.http.get<any>(this.strapiApiUrl + '/laboratorios?filters[grupo_de_negocio][perfis_negocios][id][$eq]=' + perfil_negocio +'&pagination[page]=' + pagination + '&pagination[pageSize]=' + pageSize + '&populate=*&sort=createdAt:asc')
  }
  

  getLaboratoriosByName(perfil_negocio:number, title:string){
    return this.http.get<any>(this.strapiApiUrl + '/laboratorios?filters[$and][0][grupo_de_negocio][perfis_negocios][id][$eq]=' + perfil_negocio + '&filters[$or][1][nome][$containsi]=' + title + '&filters[$or][2][search_field][$containsi]=' + title + '&populate=*')
  }

  createLaboratorio(laboratorioData){

    return this.http.post<any>(this.strapiApiUrl + '/laboratorios',{data: laboratorioData})

  }

  updateLaboratorio(laboratorioData, labId){

    return this.http.put<any>(this.strapiApiUrl + '/laboratorios/' + labId,{data: laboratorioData})

  }

  deleteLaboratorio(labId:number){
    return this.http.delete<any>(this.strapiApiUrl + '/laboratorios/' + labId)
  }

  getTutorByNameOrPhone(input:string, negocio_id:number){
    return this.http.get<any>(this.strapiApiUrl + '/tutors?filters[$and][0][$or][0][dados_pessoais][nome][$containsi]='+input+'&filters[$and][0][$or][1][dados_pessoais][celular][$containsi]='+ input + '&filters[$and][1][$or][0][perfil_negocio][id][$eq]='+ negocio_id + '&filters[$and][1][$or][1][perfis_negocios_assoc][id][$eq]=' + negocio_id + '&populate[0]=pets&populate[1]=dados_pessoais&populate[2]=endereco')
  }

  searchPedidosExames(input:string, negocio_id:number){
    return this.http.get<any>(this.strapiApiUrl + '/pedido-exames?filters[$and][0][$or][0][tutor][dados_pessoais][nome][$containsi]='+input+'&filters[$and][0][$or][1][laboratorio][nome][$containsi]='+ input + '&filters[$and][0][$or][2][codigo][$containsi]='+ input + '&filters[$and][1][$or][0][perfil_negocio][id][$eq]='+ negocio_id  + '&populate[pet][populate][0]=*&populate[tutor][populate][1]=dados_pessoais&populate[laboratorio][populate][0]=*&populate[remessas][populate][2]=*&sort=createdAt:desc')
  }

  searchPedidosExamesByLab(input:string, laboratorio_id:number){
    return this.http.get<any>(this.strapiApiUrl + '/pedido-exames?filters[$and][0][$or][0][tutor][dados_pessoais][nome][$containsi]='+input+'&filters[$and][0][$or][1][perfil_negocio][nome_unidade][$containsi]='+ input + '&filters[$and][0][$or][2][perfil_negocio][nome_fantasia][$containsi]='+ input + '&filters[$and][0][$or][2][codigo][$containsi]='+ input + '&filters[$and][1][$or][0][laboratorio][id][$eq]='+ laboratorio_id  + '&populate[pet][populate][0]=*&populate[tutor][populate][1]=dados_pessoais&populate[perfil_negocio][populate][0]=*&populate[laboratorio][populate][0]=*&populate[remessas][populate][2]=*&sort=createdAt:desc')
  }

  filterPedidodExamesByDate(negocio_id:number, date:string){
    return this.http.get<any>(this.strapiApiUrl + '/pedido-exames?filters[$and][0][perfil_negocio][id][$eq]='+ negocio_id + '&filters[$and][1][data_criacao][$eq]=' + date + '&populate[pet][populate][0]=*&populate[tutor][populate][1]=dados_pessoais&populate[laboratorio][populate][0]=*&populate[remessas][populate][0]=*&sort=createdAt:desc')
  }

  filterPedidodExamesByDateLab(laboratorio_id:number, date:string){
    return this.http.get<any>(this.strapiApiUrl + '/pedido-exames?filters[$and][0][laboratorio][id][$eq]='+ laboratorio_id + '&filters[$and][1][data_criacao][$eq]=' + date + '&populate[pet][populate][0]=*&populate[perfil_negocio][populate][0]=*&populate[tutor][populate][1]=dados_pessoais&populate[laboratorio][populate][0]=*&populate[remessas][populate][0]=*&sort=createdAt:desc')
  }

  updatePedidosExamesStatus(pId:number, status:string){
    return this.http.put<any>(this.strapiApiUrl + '/pedido-exames/' + pId, {data:{status:status}})
  }

  updatePedidosExamesResult(pId:number, result:Boolean){
    return this.http.put<any>(this.strapiApiUrl + '/pedido-exames/' + pId, {data:{resultado_disponivel:result}})
  }

  updatePedidosExamesObservation(pId:number, observation:string){
    return this.http.put<any>(this.strapiApiUrl + '/pedido-exames/' + pId, {data:{observacoes_resultado:observation}})
  }

  getProfissionaisByName(negocio_id:number, input:string){
    return this.http.get<any>(this.strapiApiUrl + '/profissionals?filters[$and][0][$or][0][perfil_negocio][id][$eq]='+ negocio_id + '&filters[$and][0][$or][1][perfis_negocios_assoc][id][$eq]=' + negocio_id + '&filters[$and][1][dados_pessoais][nome][$containsi]=' + input + "&populate=*")
  }

  getRemessasByBuzz(perfil_negocio:number, pagination:number, pageSize: number = 50){

    return this.http.get<any>(this.strapiApiUrl + '/remessas?filters[perfil_negocio][id][$eq]=' + perfil_negocio + '&pagination[page]=' + pagination + '&pagination[pageSize]=' + pageSize + '&populate[pedido_exames][populate][0]=*&populate[perfil_negocio][populate][1]=*&populate[laboratorio][populate][0]=*&populate[pedido_exames][populate][2]=pet&sort=createdAt:desc')

  }

  getRemessaDetails(id:number){
    return this.http.get<any>(this.strapiApiUrl + '/remessas/' + id + '?&populate[pedido_exames][populate][0]=*&populate[pedido_exames][populate][3]=tutor.dados_pessoais&populate[perfil_negocio][populate][1]=*&populate[laboratorio][populate][3]=*&populate[pedido_exames][populate][4]=laboratorio&populate[pedido_exames][populate][5]=pet')
  }

  createRemessa(remessaData:any){
    return this.http.post<any>(this.strapiApiUrl + '/remessas', {data:remessaData})
  }

  updateRemessa(rId:number,remessaData:any){
    return this.http.put<any>(this.strapiApiUrl + '/remessas/' + rId, {data:remessaData})
  }

  deleteRemessa(rId:number){
    return this.http.delete<any>(this.strapiApiUrl + '/remessas/' + rId)
  }

  searchRemessas(input:string, negocio_id:number){
    return this.http.get<any>(this.strapiApiUrl + '/remessas?filters[$and][0][$or][0][codigo][$containsi]='+input+'&filters[$and][0][$or][1][laboratorio][nome][$containsi]='+ input + '&filters[$and][0][$or][2][data_criacao][$containsi]='+ this.convertDate(input) + '&filters[$and][1][$or][1][perfil_negocio][id][$eq]=' + negocio_id + '&populate[0]=*&populate[laboratorio][populate][1]=*')
  }
  
  convertDate(input){

    if(input.includes('/')){

      let separated = input.split('/');
      if(!separated[1]){
        separated[1]=new Date().getMonth()
      }
      if(!separated[2]){
        separated[2]=new Date().getFullYear()
      }
      let joined = separated[2 ] +'-'+ separated[1] + '-' + separated[0]

      console.log('joined',joined)

    return joined

    }else{
      return input
    }    
  }

  uploadFile(formData:FormData){

    return this.http.post<any>(this.strapiApiUrl + '/upload',formData)

  }

  deleteFile(fId:number){
    return this.http.delete(this.strapiApiUrl + '/upload/files/' + fId)
  }

}
