import { IonModal, ModalController } from '@ionic/angular';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AddTemplateModalComponent } from 'src/app/modals/add-template-modal/add-template-modal.component';
import { UtilsService } from 'src/app/services/utils.service';
import { ConfigPage } from 'src/app/config/config.page';
import { BehaviorSubject, Observable, firstValueFrom, map, of, switchMap } from 'rxjs';
import { AutomationService } from 'src/app/services/automation.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProfissionaisService } from 'src/app/services/profissionais.service';
import { UserdataService } from 'src/app/services/userdata.service';
import { SubSink } from 'subsink';
import swal from 'sweetalert2';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LogsService } from 'src/app/services/logs.service'
import { AddActionModalComponent } from '../add-action-modal/add-action-modal.component';


@Component({
  selector: 'app-automacao-cadastro',
  templateUrl: './automacao-cadastro.component.html',
  styleUrls: ['./automacao-cadastro.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      state('*', style({
        opacity: 1
      })),
      transition('void <=> *', animate('1000ms ease-in-out')),
      transition('* <=> void', animate('1000ms ease-in-out'))

    ])
  ]

})
export class AutomacaoCadastroComponent implements OnInit, OnDestroy {


  @ViewChild('AddAction') modal: IonModal;

  automationForm: FormGroup;
  addActionForm: FormGroup;


  automationTasks$: Observable<any>;
  refreshAutomationTasks$ = new BehaviorSubject(true);

  profissionais_By_PerfilNegocio$: Observable<any>;
  refreshProfissionais$ = new BehaviorSubject(true);

  refreshMailsIntegrated$ = new BehaviorSubject(true);
  integratedMails$: Observable<any>;

  refreshTemplateMails$ = new BehaviorSubject(true);
  templateMails$: Observable<any>;

  @Input() id: number;
  @Input() mode: string;
  subs: SubSink;
  selectedBuzzIndex: string | number;
  perfilNegocio: any;
  actions_id: any;
  loading = true;
  texto_Delay = 'Quanto tempo após o agendamento realizado?'
  array_perfilNegocio: any;
  showMail = false;
  templates_ID_Array: any;
  integratedMails_array_id: any;
  userData: any = [];
  matchingPerfis_clonado_em$: any;





  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService,
    private profissionalsService: ProfissionaisService,
    private userDataService: UserdataService,
    private configPage: ConfigPage,
    private automationService: AutomationService,
    private formBuilder: FormBuilder,
    private logsService: LogsService
  ) { }

  ngOnInit() {
    this.refreshTemplateMails$ = this.automationService.refreshTemplateMails_Subject$
    this.userDataService.getUserPersonalData()
      .subscribe(result => {
        this.selectedBuzzIndex = localStorage.getItem('buzz_index') || 0;

        this.userData = result;

        this.perfilNegocio = result?.perfil_negocios[this.selectedBuzzIndex]?.id;
        console.log('perfilNegocio', this.perfilNegocio)

        this.array_perfilNegocio = [...result?.perfil_negocios].map((perfil) => {
          console.log('perfil', perfil)

          if (perfil?.id != this.perfilNegocio) {
            return perfil
          }

        }).filter((perfil) => { return perfil != undefined })


        console.log('array_perfilNegocio', this.array_perfilNegocio)

        this.profissionais_By_PerfilNegocio$ = this.refreshProfissionais$.pipe(
          switchMap(() => this.profissionalsService.getProfissionais(this.perfilNegocio)),
          map((result: any) => {

            this.loading = false;


            return result.data;
          }
          ));

        this.integratedMails$ = this.refreshMailsIntegrated$.pipe(
          switchMap(() => this.automationService.getIntegratedMails_shortInfos(this.perfilNegocio)),
          map((result: any) => {
            console.log('integratedMails', result.data)
            return result.data;
          }
          ));

        this.templateMails$ = this.refreshTemplateMails$.pipe(
          switchMap(() => this.automationService.getMailTemplates(this.perfilNegocio)),
          map((result: any) => {
            console.log('templateMails', result.data)
            this.templates_ID_Array = result.data.map((template => template.id))
            console.log('templates_ID_Array', this.templates_ID_Array)
            return result.data;
          }
          ));
      })

    this.automationForm = this.formBuilder.group({
      nome: '',
      quandoAcontece: '',
      delay_Automacao: '',
      delay_Unity: '',
      acao_dados: [],
      acao: [],
      profissionals: [],
      perfil_negocios: [],
      clonado_em: [],

    });


    this.addActionForm = this.formBuilder.group({
      action: '',
      integration_mails: '',
      mail_template: '',
      automated_tasks: [],
      perfil_negocio: '',

    });


    console.log('id: ', this.id);
    console.log('mode: ', this.mode);

    if (this.mode === 'edit_new') {
      console.log('edit_new')
      this.automationForm.patchValue(
        {
          nome: '',
          quandoAcontece: '',
          delay_Automacao: '',
          delay_Unity: '',
          acao: [],
          acao_dados: [],
          profissionals: [],
          clonado_em: [],
        });

      this.automationTasks$ = this.refreshAutomationTasks$.pipe(

        switchMap(() => this.automationService.getAutomatedTask_ID(this.id)),
        map((result: any) => {
          console.log('result', result.data)
          this.automationForm.patchValue(
            {
              acao_dados: result.data.attributes.actions_automations.data,
              acao: result.data.attributes.actions_automations.data.map((action => action.id))
            });
          this.actions_id = result.data.attributes.actions_automations.data.map((action => action.id))

          console.log('this.actions_id', this.actions_id)
          console.log('result', this.automationForm?.value?.acao)

          return result.data;
        })
      );




    } else if (this.mode === 'edit') {
      this.automationTasks$ = this.refreshAutomationTasks$.pipe(

        switchMap(() => this.automationService.getAutomatedTask_ID(this.id)),
        map((result: any) => {
          console.log('result', result.data)
          this.automationForm.patchValue(
            {
              nome: result.data.attributes.nome,
              quandoAcontece: result.data.attributes.quandoAcontece,
              delay_Automacao: result.data.attributes.delay_Automacao,
              delay_Unity: result.data.attributes.delay_Unity,
              acao_dados: result.data.attributes.actions_automations.data,
              acao: result.data.attributes.actions_automations.data.map((action => action.id)),
              profissionals: result.data.attributes.profissionals.data.map((profissional => profissional.id)),
              clonado_em: result.data.attributes.clonado_em,
              // perfil_negocios: result.data.attributes.perfil_negocios.data.map((perfil_negocio => perfil_negocio.id))

            });
          this.actions_id = result.data.attributes?.actions_automations?.data?.map((action => action.id))

          const uniqueArray = Array.from(new Set(this.automationForm.value.clonado_em));

          this.matchingPerfis_clonado_em$ = uniqueArray.map((perfil_id) => {
            return this.array_perfilNegocio.find((item) => item.id === perfil_id);
          }).filter(Boolean).map((perfil) => `${perfil?.nome_fantasia} - ${perfil?.nome_unidade}`).join(', ');

          //remove identicl




          console.log('matchingPerfis', this.matchingPerfis_clonado_em$);




          this.change_texto_Delay();

          console.log('result', this.automationForm?.value?.acao_dados)

          return result.data;
        })
      );
    }

  }

  ngOnDestroy(): void {

    this.id = null;
    this.configPage.showAutomations = false;
    console.log('ngOnDestroy')
  }

  onSubmit() {
    console.log('teste')
    console.log(this.automationForm.value);

    if (!this.automationForm.value?.acao.length || !this.automationForm.get('quandoAcontece').value || !this.automationForm.get('delay_Unity').value || !this.automationForm.get('nome').value || this.automationForm.get('delay_Automacao').value < 0) {
      this.utilsService.errorAlert('Dados inválidos, por favor verifique os campos e tente novamente.');
      return;
    }



    if (this.mode === 'edit') {

      if (this.automationForm?.value?.perfil_negocios) {
        var ARRAY_PERFIS = [...this.automationForm?.value?.perfil_negocios, this.perfilNegocio];
        this.automationForm.patchValue({ clonado_em: [...ARRAY_PERFIS, ...this.automationForm.value.clonado_em] });
      } else {
        ARRAY_PERFIS = [this.perfilNegocio]
      }
      console.log(ARRAY_PERFIS)
      const FORM_ACTUAL_AUTOMATION = { ...this.automationForm.value }
      FORM_ACTUAL_AUTOMATION.perfil_negocios = this.perfilNegocio
      this.automationService.updateAutomatedTask(FORM_ACTUAL_AUTOMATION, this.id).subscribe(
        {
          next: result => {
            console.log(result);
            this.configPage.showAutomations = false;
            this.id = null;
            this.configPage.showAutomations = false;
            this.id = null;
          },
          complete: () => {
            console.log('complete updateMailTemplate');
          },
          error: error => {
            console.log(error);
          }
        }
      );


      if (this.automationForm?.value?.perfil_negocios?.length > 0) {
        console.log('this.automationForm?.value?.perfil_negocios', this.automationForm?.value?.perfil_negocios)

        let PERFIS_PARA_REPLICAR = [...this.automationForm?.value?.perfil_negocios]?.map((perfil) => {
          if (perfil != this.perfilNegocio) {
            return perfil
          }
        })?.filter((perfil) => { return perfil != undefined })

        console.log('PERFIS_PARA_REPLICAR', PERFIS_PARA_REPLICAR)

        const AUTOMATION_FORM_VALUE = { ...this.automationForm.value };


        // console.log('>>>>>>ACTION_FORM', ACTION_FORM)

        PERFIS_PARA_REPLICAR.map(perfil => {
          const ACTION_FORM = {
            action: AUTOMATION_FORM_VALUE?.acao_dados[0]?.attributes?.action,
            mail_template: AUTOMATION_FORM_VALUE?.acao_dados[0]?.attributes.mail_template?.data?.id,
            integration_mails: null,
            perfil_negocio: [perfil]
          };

          console.log('>>>>>>ACTION_FORM', ACTION_FORM)
          this.automationService.addAction_Automation(ACTION_FORM).subscribe(
            {
              next: result => {
                AUTOMATION_FORM_VALUE.perfil_negocios = [perfil];
                AUTOMATION_FORM_VALUE.actions_automations = [result.data.id];
                this.automationService.createAutomatedTask(AUTOMATION_FORM_VALUE).subscribe(
                  {
                    next: result => {
                      console.log(result);
                    },
                    error: error => {
                      console.log('error', error)
                    }
                  }
                )
              },
              error: error => {
                console.log('error', error)
              }
            }
          )
        })
      }
      else {
        this.automationForm.patchValue(
          {
            perfil_negocios: [this.perfilNegocio]
          });
      }

    }

    else if (this.mode === 'edit_new') {
      if (this.automationForm?.value?.perfil_negocios) {
        var ARRAY_PERFIS = [...this.automationForm?.value?.perfil_negocios, this.perfilNegocio];
        this.automationForm.patchValue({ clonado_em: [...ARRAY_PERFIS] });
      } else {
        ARRAY_PERFIS = [this.perfilNegocio]
      }
      console.log(ARRAY_PERFIS)

      const FORM_ACTUAL_AUTOMATION = { ...this.automationForm.value }
      FORM_ACTUAL_AUTOMATION.perfil_negocios = [this.perfilNegocio]
      console.log(FORM_ACTUAL_AUTOMATION)

      this.automationService.updateAutomatedTask(FORM_ACTUAL_AUTOMATION, this.id).subscribe(
        {
          next: result => {
            console.log(result);
            this.configPage.showAutomations = false;
            this.id = null;
          },
          complete: () => {
            console.log('complete updateMailTemplate');
          },
          error: error => {
            console.log(error);
          }
        }
      );

      if (this.automationForm?.value?.perfil_negocios?.length > 0) {
        console.log('')
        let PERFIS_PARA_REPLICAR = [...this.automationForm?.value?.perfil_negocios]?.map((perfil) => {
          console.log('perfil', perfil)
          if (perfil != this.perfilNegocio) {
            return perfil
          }
        })?.filter((perfil) => { return perfil != undefined })
        console.log('PERFIS_PARA_REPLICAR', PERFIS_PARA_REPLICAR)
        if (PERFIS_PARA_REPLICAR?.length > 0) {
          const AUTOMATION_FORM_VALUE = { ...this.automationForm.value };
          // const ACTION_FORM = {
          //   action: AUTOMATION_FORM_VALUE.acao_dados[0].attributes.action,
          //   mail_template: AUTOMATION_FORM_VALUE.acao_dados[0].attributes.mail_template.data.id,
          //   integration_mails: null,
          // };
          // console.log('>>>>>>ACTION_FORM', ACTION_FORM)
          PERFIS_PARA_REPLICAR.forEach(perfil => {

            const ACTION_FORM = {
              action: AUTOMATION_FORM_VALUE?.acao_dados[0]?.attributes?.action,
              mail_template: AUTOMATION_FORM_VALUE?.acao_dados[0]?.attributes.mail_template?.data?.id,
              integration_mails: null,
              perfil_negocio: [perfil]
            };

            console.log('>>>>>>ACTION_FORM', ACTION_FORM)
            this.automationService.addAction_Automation(ACTION_FORM).subscribe(
              {
                next: result => {
                  AUTOMATION_FORM_VALUE.perfil_negocios = [perfil];
                  AUTOMATION_FORM_VALUE.actions_automations = [result.data.id];
                  this.automationService.createAutomatedTask(AUTOMATION_FORM_VALUE).subscribe(
                    {
                      next: result => {
                        console.log(result);
                      },
                      error: error => {
                        console.log('error', error)
                      }
                    }
                  )
                },
                error: error => {
                  console.log('error', error)
                }
              }
            )
          })
        }
      }
    }
  }

  change_Action($event) {
    if (
      $event.detail.value.substring(0, 8) !== 'sendMail'
    ) {
      this.showMail = false
    } else if ($event.detail.value.substring(0, 8) === 'sendMail'
    ) {
      this.showMail = true
    }

  }

  onDeleteActionClick(action_id) {
    console.log('onDeleteActionClick', action_id)

    swal
      .fire({
        title: 'Você deseja remover esta Ação?, <br> Esse comando não poderá ser desfeito.',
        showCancelButton: true,
        confirmButtonText: 'Remover',
        customClass: 'removeProfAlertClass',
        heightAuto: false,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: 'var(--ion-color-danger)',
      })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {

          this.automationService.deleteAction_Automation(action_id).subscribe({
            next: result => {
              console.log(result);

              let logData = {
                what_changed: 'automacao_action',
                action: 'deleted',
                details: {
                  description: 'Remoção de ação de integração via painel de gerenciamento PETinhodemim'
                },
                element_id: result?.data?.id,
                performed_by: {
                  nome: this.userData?.dados_pessoais?.nome + ' ' + this.userData?.dados_pessoais?.sobrenome,
                  usuario: this.userData?.username,
                  email: this.userData?.email
                },
                perfil_negocio: this.perfilNegocio

              }

              this.createLog(logData, null)

              this.automationService.getAutomatedTask_ID(this.id).subscribe({
                next: result => {
                  console.log(result);
                  this.automationForm.patchValue(
                    {
                      acao_dados: result.data.attributes.actions_automations.data,
                      acao: result.data.attributes.actions_automations.data.map((action => action.id))
                    });
                },
                complete: () => {
                  console.log('complete getAutomatedTask_ID');
                },
                error: error => console.log(error)
              });
            },
          });
        }
      });


  }

  onEditActionClick(action_id) {
    console.log('onEditActionClick', action_id),
      this.modal.present().then(
        () => {
          this.automationService.getAction_Automation(action_id).subscribe({
            next: result => {
              console.log(result);

              this.integratedMails_array_id = result?.data?.attributes?.integration_mails?.data?.map((item) => {
                return item?.id
              });

              console.log('this.integratedMails_array_id', this.integratedMails_array_id)

              this.addActionForm.patchValue(
                {
                  action: result.data.attributes.action,
                  integration_mails: result?.data?.attributes?.integration_mails?.data.filter(
                    (integration_mail) => {
                      return integration_mail?.attributes?.perfil_negocios?.data?.some((item) => {
                        return item?.id === this.perfilNegocio
                      });
                    })[0]?.id || null,
                  mail_template: result?.data?.attributes?.mail_template?.data?.id,
                });
            },
            complete: () => {
              console.log('complete getAction_Automation_ID', this.addActionForm.value);
            },
            error: error => console.log(error)
          });
        }

      )

  }

  async openEditTemplateModal() {
    await this.modalController.create({
      component: AddTemplateModalComponent,
      componentProps: {
        id: this.addActionForm.value.mail_template,
        mode: 'edit'
      },
      cssClass: 'modal-fullscreen',
    }).then(modalEl => {
      modalEl.present();
    })


  }

  getIconName(action: string): string {
    const iconNameMap = {
      'sendMail': 'mail',
      'sendSMS': 'chatbubbles',
      'sendZap': 'logo-whatsapp',
    };

    const actionKey = action?.split('_')[0];
    return iconNameMap[actionKey] || '';
  }

  actionText(action) {

    const actions = {
      'sendMail_tutor': 'Envia e-mail automátco para o Tutor',
      'sendMail_profissional': 'Envia e-mail automátco para o Profissional',
      'sendSMS_tutor': 'Envia SMS automátco para o Tutor',
      'sendSMS_profissional': 'Envia SMS automátco para o Profissional',
      'sendZap_profissional': 'Envia mensagem automátca para o Profissional',
      'sendZap_tutor': 'Envia mensagem automátca para o Tutor',
      '': ''
    };

    return actions[action] || '';
  }

  onClick() {
    console.log('click')
    this.modal.present();

  }

  // onSubmitActions_Automation() {
  //   console.log('teste')

  //   console.log(this.addActionForm.value);

  //   if (!this.showMail) {
  //     this.addActionForm.patchValue(
  //       {
  //         integration_mails: null,
  //       });
  //   }
  //   //create a new action for this automation
  //   this.automationService.addAction_Automation(this.addActionForm.value).subscribe({
  //     next: result => {
  //       console.log(result);
  //       this.automationService.updateAutomatedTask({ actions_automations: result.data.id }, this.id).subscribe({
  //         next: result => {
  //           console.log(result);
  //           this.refreshAutomationTasks$.next(true);
  //         },
  //         complete: () => {
  //           console.log('complete updateAutomatedTask');
  //         },
  //         error: error => console.log(error)
  //       });

  //     },
  //     complete: () => {
  //       console.log('complete addAction_Automation');
  //       this.modalController.dismiss().then(() => {

  //       });

  //     },
  //     error: error => {
  //       console.log(error);
  //     }
  //   });

  // }

  onSubmitActions_Automation() {

    if (!this.addActionForm.valid || (this.addActionForm.get('action').value.substring(0, 8) === 'sendMail' && !this.addActionForm.get('integration_mails').value) || !this.addActionForm.get('mail_template').value) {
      this.utilsService.errorAlert('Dados inválidos, por favor verifique os campos e tente novamente.');


      return;
    }

    if (!this.showMail) {
      this.addActionForm.patchValue(
        {
          integration_mails: null,
        });
    }
    //create a new action for this automation
    this.addActionForm.patchValue(
      {
        automated_tasks: this.id,
        perfil_negocio: this.perfilNegocio
      });
    this.automationService.addAction_Automation(this.addActionForm.value).subscribe({
      next: result => {
        console.log(result);

        //Create Logs

        let logData = {
          what_changed: 'automacao_action',
          action: 'created',
          details: {
            description: 'Criação de ação de integração via painel de gerenciamento PETinhodemim'
          },
          element_id: result?.data?.id,
          performed_by: {
            nome: this.userData?.dados_pessoais?.nome + ' ' + this.userData?.dados_pessoais?.sobrenome,
            usuario: this.userData?.username,
            email: this.userData?.email
          },
          perfil_negocio: this.perfilNegocio

        }

        this.createLog(logData, null)

        this.automationService.updateAutomatedTask({ actions_automations: result.data.id }, this.id).subscribe({
          next: result => {
            console.log(result);
            this.refreshAutomationTasks$.next(true);
          },
          complete: () => {
            console.log('complete updateAutomatedTask');
          },
          error: error => console.log(error)
        });

      },
      complete: () => {
        console.log('complete addAction_Automation');
        this.modalController.dismiss().then(() => {

        });

      },
      error: error => {
        console.log(error);
      }
    });
  }

  async openAddTemplateModal() {
    await this.modalController.create({
      component: AddTemplateModalComponent,
      cssClass: 'modal-fullscreen',

    }).then(modalEl => {
      modalEl.present();
    }),
      this.modalController.dismiss().then(() => {
        this.refreshTemplateMails$.next(true);
      });

  }


  async openAddActionModal() {
    await this.modalController.create({
      component: AddActionModalComponent,
      cssClass: 'modal-fullscreen',
      componentProps: {
        id: this.id,
        mode: 'insert'
      }
    }).then(modalEl => {
      modalEl.present();
    }),
      this.modalController.dismiss().then(() => {
        this.refreshTemplateMails$.next(true);
      });

  }

  onCancelarClick() {
    let modo = this.mode == 'insert' ? 'esse cadastro' : 'essa edição';
    this.utilsService.showAlert(
      'Tem certeza que deseja <span>Cancelar</span> <br>' + modo + '? <br>Todos os dados não salvos serão perdidos.</br>',
      () => {
        //se os campos
        this.configPage.showAutomations = false;
        this.id = null;
      },
      function () {
        // Ação a ser executada quando o usuário cancelar o alerta, se necessário.
      }.bind(this)
    );
  }


  deleteTask(id) {

    swal.fire({
      title: 'Você deseja remover esta Automação?, <br> Esse comando não poderá ser desfeito.',
      showCancelButton: true,
      confirmButtonText: 'Remover',
      customClass: 'removeProfAlertClass',
      heightAuto: false,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: 'var(--ion-color-danger)',
    }).then((result) => {

      if (result.isConfirmed) {
        //deleteaction first

        this.automationService.deleteAction_Automation(this.actions_id[0]).subscribe(
          {
            next: result => {
              console.log(result);
              this.automationService.deleteAutomatedTask(id).subscribe({
                next: result => {
                  console.log(result);

                  let logData = {
                    what_changed:'automacao',
                    action:'deleted',
                    details:{
                      description:'Remoção de integração via painel de gerenciamento PETinhodemim',
                      content:result?.data          
                    },
                    element_id:result?.data?.id,
                    performed_by:{
                      nome:this.userData?.dados_pessoais?.nome + ' ' + this.userData?.dados_pessoais?.sobrenome,
                      usuario: this.userData?.username,
                      email: this.userData?.email
                    },
                    perfil_negocio: this.perfilNegocio

                  }

                  this.createLog(logData, null)

                  this.configPage.showAutomations = false;
                  this.id = null;
                },
                complete: () => {
                  console.log('complete deleteAutomatedTask');
                },
                error: error => console.log(error)
              });
            },
            complete: () => {
              console.log('complete deleteAction_Automation');
            },
            error: error => console.log(error)
          }


        )



      }
    }
    )



  }

  DismisModal() {
    let modo = this.mode == 'insert' ? 'esse cadastro' : 'essa edição';
    this.utilsService.showAlert(
      'Tem certeza que deseja <span>Cancelar</span> <br>' + modo + '? <br>Todos os dados não salvos serão perdidos.</br>',
      () => {
        this.modalController.dismiss();
      },
      function () {
        // Ação a ser executada quando o usuário cancelar o alerta, se necessário.
      }.bind(this)
    );

  }
  Dismis_deleteNew() {



    this.automationService.deleteAutomatedTask(this.id).subscribe({
      next: result => {
        console.log(result);
        return this.configPage.showAutomations = false;

      },
      complete: () => {
        console.log('complete deleteAction_Automation');
        this.configPage.showAutomations = false;
        this.id = null;
      },
      error: error => {
        console.log(error);
      }
    });

  }

  change_texto_Delay() {
    let texto = this.automationForm.value.quandoAcontece;
    if (texto === 'novo_Agendamento') {
      return this.texto_Delay = 'Quanto tempo após o agendamento realizado ?';

    } else if (texto === 'novo_Retorno') {
      return this.texto_Delay = 'Quanto tempo após o retorno realizado ?';
    }
    else if (texto === 'antesIniciar_Agendamento') {
      return this.texto_Delay = 'Quanto tempo antes do atendimento iniciar ?';
    }
    else if (texto === 'antesIniciar_Retorno') {
      return this.texto_Delay = 'Quanto tempo antes do retorno iniciar ?';
    }
    else if (texto === 'iniciado_Agendamento') {
      return this.texto_Delay = 'Quanto tempo após o atendimento iniciar ?';
    } else if (texto === 'iniciado_Retorno') {
      return this.texto_Delay = 'Quanto tempo após o retorno iniciar ?';
    } else if (texto === 'finalizado_Agendamento') {
      return this.texto_Delay = 'Quanto tempo após o atendimento finalizar ?';
    } else if (texto === 'finalizado_Retorno') {
      return this.texto_Delay = 'Quanto tempo após o retorno finalizar ?';
    } else if (texto === 'cancelado_Agendamento') {
      return this.texto_Delay = 'Quanto tempo após o atendimento ser cancelado ?';
    } else if (texto === 'cancelado_Retorno') {
      return this.texto_Delay = 'quanto tempo após o retorno ser cancelado ?';
    }
  }

  createLog(elementData: any, id: any) {


    this.logsService.createLogs(elementData).subscribe(result => {

      console.log('Log criado')

    })
  }
}
