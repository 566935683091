import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class IntegracaoService {

  strapiApiUrl = environment.strapiApiUrl



  constructor(
    private http: HttpClient,
  ) { }

  // Método para adicionar uma nova integração de e-mail
  addEmailIntegration(integrationMailData): Observable<any> {
    let data = {
      data: integrationMailData
    }

    return this.http.post(this.strapiApiUrl + '/integration-mails',
      data
    );
  }

  getEmailIntegration(userId): Observable<any> {
    return this.http.get(this.strapiApiUrl + '/integration-mails?user_id=' + userId);
  }

}
