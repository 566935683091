import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ExamesService } from 'src/app/services/exames.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-exame-result-upload',
  templateUrl: './exame-result-upload.component.html',
  styleUrls: ['./exame-result-upload.component.scss'],
})
export class ExameResultUploadComponent implements OnInit {

  @Input() requiredFileType:string;

    fileName = '';
    uploadProgress:number;
    uploadSub: Subscription;
    file:File;
    exameDetails:any = [];
    

    pedidoId:number;
    observation:string;

  constructor(private examesService:ExamesService) { }

  ngOnInit() {
    this.getExameDetails();
  }


    getExameDetails(){
      let exameDetail$ = this.examesService.getExameDetails(this.pedidoId).subscribe({
        next:(result)=>{
          this.exameDetails = result.data;
          this.observation = this.exameDetails?.attributes?.observacoes_resultado;          
        },
        error:(e)=>{},
        complete:()=>{
          exameDetail$.unsubscribe();
        }
      })
    }
    

    onFileSelected(event) {
        this.file = event.target.files[0];
        this.fileName = this.file.name;        
        console.log(this.file)
    }

  uploadFile(){

    if (this.file) {
      
      const formData = new FormData();
      formData.append('files', this.file);
      formData.append('ref','api::pedido-exame.pedido-exame');
      formData.append('refId',this.pedidoId.toString());
      formData.append('field','resultado');      

      this.examesService.uploadFile(formData).subscribe({
        next:(result)=>{
          this.openLoader();
          if(this.observation){
            this.updateObservation(this.observation);
          }
        },
        error:(e)=>{
          this.errorAlert();
        },
        complete:()=>{          
          Swal.close();
          this.updateResultadoStatus(true);
          this.resetFile();
          this.successAlert();
          this.getExameDetails();
        }
      })
           
  }

  }

  updateObservation(observation:string){
    this.examesService.updatePedidosExamesObservation(this.pedidoId, observation).subscribe({
      next:(result)=>{},
      error:(e)=>{},
      complete:()=>{
        this.getExameDetails();
      }
    })
  }

  updateObservation2(observation:string){
    this.examesService.updatePedidosExamesObservation(this.pedidoId, observation).subscribe({
      next:(result)=>{},
      error:(e)=>{},
      complete:()=>{
        this.successObservationAlert()
        this.getExameDetails();
      }
    })
  }

  resetFile() {
    this.file = null;
    this.fileName = null;
  }

  openLoader(){
    
    Swal.fire({
      title: 'Carregando...',     
      heightAuto:false,
      backdrop:false,
      customClass:{
        loader: 'page-loader',       
      },
      didOpen: () => {
               
      },
      willClose: () => {
        
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      
    })

  }

  deleteFile(pId:number){
    this.examesService.deleteFile(pId).subscribe({
      next:(result)=>{},
      error:(e)=>{},
      complete:()=>{
        this.updateResultadoStatus(false);
        this.resetFile();
        this.getExameDetails();
      }
    })
  }

  errorAlert(){
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "O arquivo não pode ser enviando",
      footer: 'Verifique se o arquivo esta no formato PDF e tente novamente.',
      confirmButtonColor:"#51438e",
      heightAuto:false
    });
  }

  successAlert(){
    Swal.fire({
      icon: "success",
      title: "Sucesso!",
      text: "O resultado do exame foi anexado com êxito",      
      showConfirmButton:false,
      timer:2000,
      heightAuto:false
    });
  }
  successObservationAlert(){
    Swal.fire({
      icon: "success",
      title: "Sucesso!",
      text: "A observação foi atualizado com sucesso",      
      showConfirmButton:false,
      timer:2000,
      heightAuto:false
    });
  }

  deleteAlert(pId:number){
    Swal.fire({      
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `
        },
        title: "Você deseja remover o resultado?",
        text: "Esta ação não pode ser revertida",
        icon: "warning",
        showCancelButton: true,        
        confirmButtonText: "Sim, remover!",
        heightAuto:false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteFile(pId)
        }
    });
  }

  updateResultadoStatus(result:Boolean){
    this.examesService.updatePedidosExamesResult(this.pedidoId,result).subscribe({
      next:(result)=>{}
    })
  }
}
