import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, forkJoin, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  localFalconURL = 'https://api.localfalcon.com/v1';
  strapiApiUrl = environment.strapiApiUrl;
  StrapiBaseURL = environment.strapiBaseUrl;
  localFalconURL_backup = this.strapiApiUrl + '/local-falcons/';
  api_key = 'a4f2710f81b9e3db838522dbc863bf9b'
  Image_name_Id_array: any;

  constructor(
    private http: HttpClient,

  ) { }

  //LocalFalcon List All Connected Locations
  getLocations() {
    return this.http.get<any>(this.localFalconURL + '/locations/' + '?api_key=' + this.api_key)
  }



  getAutoScans() {
    return this.http.get<any>(this.localFalconURL + '/autoscans/' + '?api_key=' + this.api_key)
  }

  //list all reports

  getReports() {
    return this.http.get<any>(this.localFalconURL + '/reports/' + '?api_key=' + this.api_key)
  }

  getKeywordReports() {
    return this.http.get<any>(this.localFalconURL + '/keyword-reports/' + '?api_key=' + this.api_key)
  }


  getLocationReports() {
    return this.http.get<any>(this.localFalconURL + '/location-reports/' + '?api_key=' + this.api_key)
  }


  getAutoScans_place_id(place_id: any) {
    return this.http.get<any>(this.localFalconURL + '/autoscans/?api_key=' + this.api_key + '&place_id=' + place_id)
  }




  getReports_place_id(place_id: any) {
    return this.http.get<any>(this.localFalconURL + '/reports/?api_key=' + this.api_key + '&place_id=' + place_id)
      .pipe(
        catchError(
          (error) => {
            console.error('Error fetching localFalconURL:', error);
            return this.http.get<any>(this.localFalconURL_backup + '?filters[$and][0][place_id][$eq]=' + place_id + '&populate[reports]=true&populate[perfil_negocio]=true')
              .pipe(
                catchError(
                  (error) => {
                    console.error('Error fetching localFalconURL_backup:', error);
                    return of([]);
                  }
                )
              )
              .pipe(
                map(
                  (data) => {
                    console.log('getReports_place_id>>>>>>>>', data);

                    data?.data?.[0]?.attributes?.reports?.map((report: any) => {

                      let reportKey = report?.['data']?.['report_key'];
                      const perfil_negocio = data?.data?.[0]?.attributes?.perfil_negocio.data.id;
                      const localFalcon_backupId = data?.data?.[0]?.id;
                      const imageBackupName = `${perfil_negocio}_${localFalcon_backupId}_${reportKey}.png`;

                      this.getLocalFalcon_ImageBackup(imageBackupName).subscribe((result) => {
                        // console.log('result', result);
                        report.data.image = result;
                      })
                    });

                    return data?.data?.[0]?.attributes?.reports;
                  }
                )
              );
          })

      );
  }


  getLocalFalcon_ImageBackup(name) {
    return this.getLocalFalcon_ImagesBackup().pipe(
      switchMap(result => {
        const image = result.find(image => image.name === name);
        if (image) {
          return this.getLocalFalcon_ImageBackup_id(image.id);
        } else {
          return null;
        }
      }),
      switchMap(result => {
        if (result) {
          const backUp_image_URL = environment.strapiBaseUrl + result?.url;
          // console.log('this.backUp_image_URL', backUp_image_URL);
          return backUp_image_URL;
        } else {
          return null;
        }
      })
    );
  }












  //Get Specific Scan Report

  getSpecificScanReport(place_id: any, report_key: any) {
    return this.http.get<any>(this.localFalconURL + '/reports/' + report_key + '?api_key=' + this.api_key).pipe(
      catchError(
        (error) => {
          console.error('Error fetching localFalconURL:', error);
          return this.http.get<any>(this.localFalconURL_backup + '?place_id=' + place_id + '&populate[reports]=true&populate[perfil_negocio]=true')
            .pipe(
              catchError(
                (error) => {
                  console.error('Error fetching localFalconURL_backup:', error);
                  return of([]);
                }
              )
            )
            .pipe(
              map(
                (data) => {

                  const finalData = data.data[0].attributes.reports.map((report: any) => {
                    if (report?.['data']?.['report_key'] === report_key) {
                      return report;
                    } else {
                      return null;
                    }

                  });


                  // finalData.map((report: any) => {
                  //   report.image = report?.['data']?.['image'] || null;
                  //   report.date = report?.['data']?.['date'] || null;
                  // });

                  finalData.from = 'backup';
                  return finalData
                })
            );

        }
      )
    );
  }


  getScanReport_keyword(keyword: any, place_id: any): Observable<any> {
    console.log('getScanReport_keyword', keyword, place_id);
    const url = `${this.localFalconURL}/reports/?api_key=${this.api_key}+&keyword=${keyword}&place_id=${place_id}`;

    return this.http.get<any>(url).pipe(
      catchError((error) => this.handleReportsError(error, keyword, place_id))
    );
  }

  private handleReportsError(error: any, keyword: any, place_id: any): Observable<any> {
    console.error('Error fetching reports:', error);
    const backupUrl = `${this.localFalconURL_backup}?place_id=${place_id}&keyword=${keyword}&populate[reports]=true&populate[perfil_negocio]=true`;

    return this.http.get<any>(backupUrl).pipe(
      catchError((error) => {
        console.error('Error fetching reports:', error);
        return of([]);
      }),
      mergeMap((data) => {
        const filterData = this.extractFilteredReports(data, keyword);
        const imageBackupNameArray = this.extractImageBackupNames(data, filterData);

        return this.getLocalFalcon_ImagesBackup().pipe(
          mergeMap((result) => {
            const imageObservables = imageBackupNameArray.map((imageBackupName: string) => {
              const image = result.find((image) => image.name === imageBackupName);
              if (image) {
                return this.getLocalFalcon_ImageBackup_id(image.id).pipe(
                  map((imageResult) => {
                    filterData.forEach((report: any) => {
                      report.data.image = this.StrapiBaseURL + imageResult.url;
                    });
                  })
                );
              } else {
                return of(null);
              }
            });

            return forkJoin(imageObservables).pipe(
              map(() => {
                const finalData = this.prepareFinalData(filterData);
                return finalData;
              })
            );
          })
        );
      })
    );
  }


  private extractFilteredReports(data: any, keyword: any): any[] {
    return data?.data?.[0]?.attributes?.reports?.filter((report: any) => {
      return report?.data?.keyword === keyword;
    }) || [];
  }

  private extractImageBackupNames(data: any, filterData: any[]): string[] {
    return filterData.map((report: any) => {
      const reportKey = report?.data?.report_key;
      const perfilNegocio = data?.data?.[0]?.attributes?.perfil_negocio.data.id;
      const localFalconBackupId = data?.data?.[0]?.id;
      return `${perfilNegocio}_${localFalconBackupId}_${reportKey}.png`;
    });
  }

  private prepareFinalData(filterData: any[]): any[] {
    return filterData.map((report: any) => {
      report.image = report?.data?.image;
      report.date = report?.data?.date;
      return report;
    });
  }




  //get data from localFalcon_backup, with input place_id

  getLocalFalcon_backup(place_id: any) {
    console.log('getLocalFalcon_backup', place_id);
    return this.http.get<any>(this.localFalconURL_backup + '?filters[$and][0][place_id][$eq]=' + place_id + '&populate[reports]=true&populate[perfil_negocio]=true')
  }



  postLocalFalcon_backup(data: any) {
    const data2 = {
      data: data
    }
    return this.http.post<any>(this.localFalconURL_backup, data2)
  }

  putLocalFalcon_backup(id: any, data: any) {
    const data2 = {
      data: data
    }
    return this.http.put<any>(this.localFalconURL_backup + id, data2)
  }


  getData(url: string): Observable<string> {
    return this.http.get(url, { responseType: 'blob' })
      .pipe(
        switchMap(response => this.readFile(response))
      );
  }

  private readFile(blob: Blob): Observable<string> {
    return new Observable<string>(observer => {
      const reader = new FileReader();

      reader.onerror = err => observer.error(err);
      reader.onload = () => {
        observer?.next(reader.result as string);
        observer?.complete();
      };

      reader.readAsDataURL(blob);

      return () => {
        reader.abort();
      };
    });
  }


  uploadImage(formData) {

    return this.http.post<any>(`${this.strapiApiUrl}/upload`,
      formData, { reportProgress: true, observe: 'events' });
  }


  getLocalFalcon_ImagesBackup() {
    return this.http.get<any>(`${this.strapiApiUrl}/upload/files`)
  }

  getLocalFalcon_ImageBackup_id(id: any) {
    return this.http.get<any>(`${this.strapiApiUrl}/upload/files/${id}`)
  }





}
