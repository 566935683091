import { Component, OnInit, AfterContentInit, Input, AfterViewInit } from '@angular/core';
import { ViewDidEnter, ViewWillEnter } from '@ionic/angular';

import { NavigationExtras, Router } from '@angular/router';
import { ITutor, IDados_pessoais, IEndereco, IPet } from '../../interfaces/interfaces';


@Component({
  selector: 'app-tutor-card-with-pet-card',
  templateUrl: './tutor-card-with-pet-card.component.html',
  styleUrls: ['./tutor-card-with-pet-card.component.scss'],
})
export class TutorCardWithPetCardComponent implements OnInit {

  @Input()  selected_Tutor : ITutor;

  tutors: ITutor[] = [
    {
      id: 1,
      dados_pessoais: {
        nome: 'John Smith',
        sexo: 'male',
        data_nascimento: new Date('01/01/1970'),
        documento_identidade: '123456',
        cpf: '12345678901',
        celular: '1234567890',
        telefone: '1234567890',
        email: 'john@example.com'
      },
      endereco: {
        cep: '12345678',
        logradouro: 'Main Street',
        numero: 123,
        complemento: '',
        bairro: 'Downtown',
        cidade: 'New York',
        uf: 'NY'
      },
      agendamentos: [],
      pets: [
        {
          nome: 'Fluffy',
          data_nascimento: new Date('01/01/2010'),
          pedigreeN: 123456,
          peso: 10,
          tamanho: 'small',
          chip: true,
          castrado: false,
          raca: 'Mixed',
          genero: 'female',
          especie: 'gato',
          observacao: '',
          agendamentos: []
        },
        {
          nome: 'Buddy',
          data_nascimento: new Date('01/01/2011'),
          pedigreeN: 123456,
          peso: 15,
          tamanho: 'medium',
          chip: true,
          castrado: false,
          raca: 'Mixed',
          genero: 'male',
          especie: 'cachorro',
          observacao: '',
          agendamentos: []
        }
      ],
      ultimo_contato: new Date('15/12/2022')
    },
    {
      id: 2,
      dados_pessoais: {
        nome: 'Jane Doe',
        sexo: 'female',
        data_nascimento: new Date('01/01/1980'),
        documento_identidade: '123456',
        cpf: '12345678901',
        celular: '1234567890',
        telefone: '1234567890',
        email: 'jane@example.com'
      },
      endereco: {
        cep: '12345678',
        logradouro: 'Main Street',
        numero: 123,
        complemento: '',
        bairro: 'Downtown',
        cidade: 'New York',
        uf: 'NY'
      },
      agendamentos: [],
      pets: [
        {
          nome: 'Buddy',
          data_nascimento: new Date('01/01/2011'),
          pedigreeN: 123456,
          peso: 15,
          tamanho: 'medium',
          chip: true,
          castrado: false,
          raca: 'Mixed',
          genero: 'male',
          especie: 'cachorro',
          observacao: '',
          agendamentos: []
        }
      ],
      ultimo_contato: new Date('25/12/2022')
    },
    // Add additional objects here
  ];
  Parent_Component: string;

  constructor(
    public router: Router,
  ) { }

  ngOnInit() {

    this.Parent_Component = 'TutorCardWithPet'

    this.selected_Tutor;
    console.log(this.selected_Tutor);
  }

  onEditarTutor() {

    let mode = 'edit';
    const navigationExtras: NavigationExtras = {
      state: {
        mode: "edit"
      }
    }
    this.router.navigateByUrl('tabs/tutores-cadastro', navigationExtras);
    localStorage.setItem('mode_cadastroTutor',mode)

  }



}
