import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AutomationService {

  strapiApiUrl = environment.strapiApiUrl

  constructor(
    private http: HttpClient
  ) { }


  refreshTemplateMails_Subject$ = new BehaviorSubject(true);

  getAutomatedTasks(IdPerfilNegocio: any) {
    return this.http.get<any>(this.strapiApiUrl + `/automated-tasks?filters[perfil_negocios][id][$eq]=${IdPerfilNegocio}&populate[actions_automations][populate][0]=profissionals`)
  }

  getAutomatedTask_ID(IdAutomatedTask: any) {
    return this.http.get<any>(this.strapiApiUrl + '/automated-tasks/' + IdAutomatedTask + '?populate=deep,3')
  }

  createAutomatedTask(dataObject: Object) {
    let dataStrapi = {
      "data": dataObject
    }
    return this.http.post<any>(this.strapiApiUrl + '/automated-tasks', dataStrapi)
  }

  updateAutomatedTask(dataObject: Object, IdAutomatedTask: number) {
    let dataStrapi = {
      "data": dataObject
    }
    return this.http.put<any>(this.strapiApiUrl + '/automated-tasks/' + IdAutomatedTask, dataStrapi)
  }

  deleteAutomatedTask(IdAutomatedTask: number) {
    return this.http.delete<any>(this.strapiApiUrl + '/automated-tasks/' + IdAutomatedTask)
  }

  getIntegratedMails(IdPerfilNegocio: any) {
    return this.http.get<any>(this.strapiApiUrl + `/integration-mails?filters[perfil_negocio][id][$eq]=${IdPerfilNegocio}&populate=deep,4`)
  }

  getIntegratedMail(IdIntegratedMail: any) {
    return this.http.get<any>(this.strapiApiUrl + '/integration-mails/' + IdIntegratedMail + '?populate=*')
  }

  updateEmailIntegration(dataObject: Object, IdIntegratedMail: number) {
    let dataStrapi = {
      "data": dataObject
    }
    return this.http.put<any>(this.strapiApiUrl + '/integration-mails/' + IdIntegratedMail, dataStrapi)
  }

  deleteIntegratedMail(IdIntegratedMail: number) {
    return this.http.delete<any>(this.strapiApiUrl + '/integration-mails/' + IdIntegratedMail)
  }

  getIntegratedMails_shortInfos(IdPerfilNegocio: any) {
    console.log(IdPerfilNegocio)
    return this.http.get<any>(this.strapiApiUrl + `/integration-mails?filters[perfil_negocios][id][$eq]=${IdPerfilNegocio}&populate=*`)
  }

  getAllIntegratedMails_shortInfos() {
    return this.http.get<any>(this.strapiApiUrl + `/integration-mails&populate=*`)
  }

  addAction_Automation(dataObject: Object) {
    let dataStrapi = {
      "data": dataObject
    }
    return this.http.post<any>(this.strapiApiUrl + '/actions-automations', dataStrapi)
  }

  updateAction_Automation(dataObject: Object, IdAction_Automation: number) {
    let dataStrapi = {
      "data": dataObject
    }
    return this.http.put<any>(this.strapiApiUrl + '/actions-automations/' + IdAction_Automation, dataStrapi)
  }

  deleteAction_Automation(IdAction_Automation: number) {
    return this.http.delete<any>(this.strapiApiUrl + '/actions-automations/' + IdAction_Automation)
  }

  getAction_Automation(IdAction_Automation: number) {
    return this.http.get<any>(`${this.strapiApiUrl}/actions-automations/${IdAction_Automation}?populate=integration_mails,integration_mails.perfil_negocios,mail_template`);
  }




  createMailTemplate(dataObject: Object) {
    let dataStrapi = {
      "data": dataObject
    }
    return this.http.post<any>(this.strapiApiUrl + '/templates-emails', dataStrapi)
  }

  updateMailTemplate(dataObject: Object, IdMailTemplate: any) {
    let dataStrapi = {
      "data": dataObject
    }

    // console.log('dataStrapi', dataStrapi)
    return this.http.put<any>(this.strapiApiUrl + '/templates-emails/' + IdMailTemplate, dataStrapi)
  }

  getMailTemplates(IdPerfilNegocio: any) {
    return this.http.get<any>(this.strapiApiUrl + `/templates-emails?filters[perfil_negocios][id][$eq]=${IdPerfilNegocio}&populate=*`)
  }

  getMailTemplateId(IdTemplateEmail: any) {
    return this.http.get<any>(this.strapiApiUrl + `/templates-emails/${IdTemplateEmail}?populate=perfil_negocios`)
  }
}
