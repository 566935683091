import { SchedulerComponent } from './scheduler/scheduler.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TitleComponent } from './title/title.component';
import { AgendaSliderComponent } from './agenda-slider/agenda-slider.component';
import { NgCalendarModule } from 'ionic2-calendar';
import { CalendarCommonModule, CalendarMonthModule, CalendarWeekModule, DateAdapter, CalendarDayModule } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { EventCardComponent } from './event-card/event-card.component';
import { HourMinutePipe } from '../pipes/hour-minute.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ObjectPropertyFilterPipe } from '../pipes/object-property-filter.pipe';
import { PetCardCadastroComponent } from './pet-card-cadastro/pet-card-cadastro.component';
import { TutorCardWithPetCardComponent } from './tutor-card-with-pet-card/tutor-card-with-pet-card.component';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { IonicInputMaskModule } from "@thiagoprz/ionic-input-mask";
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxViacepModule } from "@brunoc/ngx-viacep";
import { RouterModule } from '@angular/router';
import { RemoverDupilicadosArrayPipe } from '../pipes/remover-dupilicados-array.pipe';
import { OrdemALgabeticaArrayPipe } from '../pipes/ordem-algabetica-array.pipe';
import { FiltroEspProfHorarioPipe } from '../pipes/FiltroEspProfHorario.pipe';
import { TodayEventsPipe } from '../pipes/today-events.pipe';
import { StatusEventsPipe } from '../pipes/StatusEvent.pipe';
import { DateFormatPipe } from '../pipes/dateFotmat.pipe';
import { AutomacaoCadastroComponent } from './automacao-cadastro/automacao-cadastro.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AddTemplateModalComponent } from '../modals/add-template-modal/add-template-modal.component';
import { AddMailIntegationComponent } from '../modals/add-mail-integation/add-mail-integation.component';
import { AddPhoneIntegrationComponent } from '../modals/add-phone-integration/add-phone-integration.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { EdituserComponent } from 'src/app/components/edituser/edituser.component'
import { AddActionModalComponent } from './add-action-modal/add-action-modal.component';
import { DashBoardInfosComponent } from './dash-board-infos/dash-board-infos.component';
import { NgxGaugeModule } from 'ngx-gauge';
import { QRCodeModule } from 'angularx-qrcode';
import { ExameResultUploadComponent } from './exame-result-upload/exame-result-upload.component';


// import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';




@NgModule({
  declarations: [
    TitleComponent,
    AgendaSliderComponent,
    EventCardComponent,
    PetCardCadastroComponent,
    TutorCardWithPetCardComponent,
    AddTemplateModalComponent,
    HourMinutePipe,
    ObjectPropertyFilterPipe,
    FiltroEspProfHorarioPipe,
    DateFormatPipe,
    TodayEventsPipe,
    StatusEventsPipe,
    RemoverDupilicadosArrayPipe,
    OrdemALgabeticaArrayPipe,
    AutomacaoCadastroComponent,
    AddMailIntegationComponent,
    AddPhoneIntegrationComponent,
    EdituserComponent,
    AddActionModalComponent,
    DashBoardInfosComponent,
    ExameResultUploadComponent
  ],
  imports: [
    IonicModule.forRoot(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    NgCalendarModule,
    CalendarCommonModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    CalendarMonthModule,
    CalendarWeekModule,
    CalendarDayModule,
    NgxMaskModule,
    IonicInputMaskModule,
    ImageCropperModule,
    NgxViacepModule,
    CKEditorModule,
    NgxChartsModule,
    NgxGaugeModule,
    QRCodeModule,

    // RichTextEditorModule,

    RouterModule
  ],
  exports: [
    TitleComponent,
    AgendaSliderComponent,
    PetCardCadastroComponent,
    TutorCardWithPetCardComponent,
    EventCardComponent,
    CalendarMonthModule,
    CalendarCommonModule,
    FontAwesomeModule,
    AutomacaoCadastroComponent,
    AddTemplateModalComponent,
    AddActionModalComponent,
    AddMailIntegationComponent,
    AddPhoneIntegrationComponent,
    NgxMaskModule,
    ObjectPropertyFilterPipe,
    FiltroEspProfHorarioPipe,
    DateFormatPipe,
    TodayEventsPipe,
    StatusEventsPipe,
    RemoverDupilicadosArrayPipe,
    OrdemALgabeticaArrayPipe,
    IonicInputMaskModule,
    ImageCropperModule,
    NgxViacepModule,
    CKEditorModule,
    NgxChartsModule,
    NgxGaugeModule,
    EdituserComponent,
    DashBoardInfosComponent,
    RouterModule,
    QRCodeModule,



  ],
  bootstrap: [],
})
export class ComponentsModule { }
