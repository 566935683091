import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import { UserdataService } from 'src/app/services/userdata.service';
import Swal from 'sweetalert2';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { LoaderService } from 'src/app/services/loader.service';
import { LoadingController, ModalController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment'
import { AutoUnsubscribe } from '../decorators/auto-unsubscribe.decorator';
import { AgendaService } from '../services/agenda.service';
import { AddMailIntegationComponent } from '../modals/add-mail-integation/add-mail-integation.component';
import { BehaviorSubject, Observable, map, switchMap, catchError } from 'rxjs';
import { AutomationService } from '../services/automation.service';
import { AddPhoneIntegrationComponent } from '../modals/add-phone-integration/add-phone-integration.component';
import { IonModal } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core/components';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EdituserComponent } from 'src/app/components/edituser/edituser.component';
import { LogsService } from 'src/app/services/logs.service';
import { DatePipe } from '@angular/common';
import { ProfissionaisService } from '../services/profissionais.service';
import { ExamesService } from '../services/exames.service';






@AutoUnsubscribe
@Component({
  selector: 'app-config',
  templateUrl: './config.page.html',
  styleUrls: ['./config.page.scss'],
})
export class ConfigPage implements OnInit {

  public content: string = '';
  @ViewChild('popover') popover;
  @ViewChild(IonModal) modal: IonModal;


  diasSemInteracao = 30;
  selectedView: string = 'Usuario';
  childView: string = 'informacoes';
  especies: any = [];
  estados: any = [];
  locations: any = [];
  userPersonalData: any = [];
  currentUser: any = [];
  businessId: any;
  userId: any;
  isPopoverOpen: Boolean = false;
  selectedSpecie = [];
  species_list: any = [];
  isOpen = false;

  message = 'This modal example uses triggers to automatically open a modal when the button is clicked.';
  name: string;

  //Insert and Edit User form model inputs

  insert_input_name: string;
  insert_input_lastname: string;
  insert_input_email: string;
  insert_input_password: string;
  insert_input_passconfirm: string;
  insert_input_celular: string
  insert_input_cargo: string;
  insert_input_username: string;
  insert_input_unities: any = [];
  insert_input_funcao: string;
  insert_input_profissional: number;
  insert_input_laboratorio: number;

  edit_input_name: string;
  edit_input_lastname: string;
  edit_input_email: string;
  edit_input_password: string;
  edit_input_passconfirm: string;
  edit_input_celular: string
  edit_input_cargo: string;
  edit_input_username: string;
  edit_input_unities: any = [];
  edit_input_funcao: string;
  edit_input_profisional: string;
  edit_input_laboratorio: string;

  profissionaisList:any = [];
  laboratoriosList:any = [];

  strapiUrl = environment.strapiBaseUrl;

  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;

  files = [];
  userdata: any = [];
  imageUrl: string;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imagename: File;
  image: File;
  imageselect: boolean;

  //Variables binding to config personal data form

  user_firstname: string;
  user_lastname: string;
  user_email: string;
  user_whatsapp: string;
  user_function: string;
  user_new_pass: string;
  user_confirm_pass: string;
  user_username: string;

  //Variables binding to config business/informations data form

  buz_cnpj: string;
  buz_razao_social: string;
  buz_cep: string;
  buz_logradouro: string;
  buz_address_number: number;
  buz_bairro: string;
  buz_state: number;
  buz_city: string;
  buz_whatsapp: string;
  buz_telefone:string;
  buz_site: string;
  buz_instagram: string;
  buz_linkedin: string;
  buz_facebook: string;
  buz_tiktok: string;
  buz_especies: any = [];

  place_id: string;

  userAvatarId: any;
  buzAvatarUrl: any;
  filters: any;
  usersList: any = [];


  //Variables binding to config business/services data form

  buz_faz_entrega: string;
  buz_taxa_entrega: string;
  buz_tempo_entrega: string;
  buz_pedido_minimo: string;
  buz_outras_especies: any = [];
  buz_modalidades_atendimento: any = [];
  editUserDetails: any = [];

  imagePath: any;
  logoPath: any;
  automation_id: number;
  mode: string;
  showAutomations: boolean;
  emails: any;
  phones: any;
  refreshMailsIntegrated$ = new BehaviorSubject(true);
  refreshPhonesIntegrated$ = new BehaviorSubject(true);
  selectedBuzzIndex: string;
  automationTasks$: Observable<any>;
  refreshAutomationTasks$ = new BehaviorSubject(true);
  integratedMails$: Observable<any>;
  forca: any;
  stregthBarColor: string;
  registerFormValid: Boolean = false;
  editFormValid: Boolean = false;
  emailIsValid: Boolean = false;
  selectedUserId: any;
  buzProfiles: any;
  createdUser: any = [];
  removedUser: any = [];
  logs: any = [];
  selectedDate: any;
  elementDetails: any = [];
  selectedPage: any = 1;
  resultMeta: any;
  elementError: any;

  constructor(public router: Router,
    public route: ActivatedRoute,
    public utils: UtilsService,
    private userdataService: UserdataService,
    private loaderService: LoaderService,
    private loadingController: LoadingController,
    private _sanitizer: DomSanitizer,
    private agendaService: AgendaService,
    private modalCtrl: ModalController,
    private automationService: AutomationService,
    private utilsService: UtilsService,
    private authservice: AuthenticationService,
    private logsService: LogsService,
    private profissionaisService:ProfissionaisService,
    private exameService:ExamesService

  ) {


  }


  ionViewWillEnter() {

    this.selectedView = this.utils.selectedConfigView$.value;
    this.getUserPersonalData();    

  }







  ngOnInit() {
    this.especies = this.utils?.especies;
    this.estados = this.utils?.estados?.ListagemEstados;
    this.getLocationsByUF('12');
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))

  }




  actionText(action) {
    const actions = {
      'sendMail_tutor': 'Envia e-mail automátco para o Tutor',
      'sendMail_profissional': 'Envia e-mail automátco para o Profissional',
      'sendSMS_tutor': 'Envia SMS automátco para o Tutor',
      'sendSMS_profissional': 'Envia SMS automátco para o Profissional',
      'sendZap_profissional': 'Envia mensagem automátco para o Profissional',
      'sendZap_tutor': 'Envia mensagem automátco para o Tutor',
      '': ''
    };

    return actions[action] || '';
  }

  getIconName(action: string): string {
    const iconNameMap = {
      'sendMail': 'mail',
      'sendSMS': 'chatbubbles',
      'sendZap': 'logo-whatsapp',
    };

    const actionKey = action?.split('_')[0];
    return iconNameMap[actionKey] || '';
  }


  setSelectedUserId(id: any) {

    this.selectedUserId = id;

  }



  onAutomationClick(id: number) {
    this.automation_id = id;
    this.mode = 'edit';
    this.showAutomations = true
  }

  onAutomationClick_addNew(id: number) {
    this.automation_id = id;
    this.mode = 'edit_new';
    this.showAutomations = true
  }

  onIncluirTask() {
    // this.showAutomations = true;
    // this.mode = 'insert';

    // criar uma task em branco depois chama onAutomationClick com o id da task criada
    this.automationService.createAutomatedTask({ perfil_negocios: [this.businessId] }).subscribe({
      next: (response) => {
        this.onAutomationClick_addNew(response.data.id);
      },
      complete: () => {
        console.log('createAutomatedTask request finished');
      },
      error: (error) => {
        console.error('An error occurred while processing the createAutomatedTask request:', error);
      }

    });



    // this.agendaService
    //   .automation({ agendamentoId: 397, status: 'confirmado', professionalId: 10 })
    //   .subscribe({
    //     next: (response) => {
    //       console.log('Automation request was successful:', response);
    //     },
    //     error: (error) => {
    //       console.error('An error occurred while processing the automation request:', error);
    //     },
    //   });

  }



  onEditarMarcadoresClick() {
    const navigationExtras: NavigationExtras = {
      state: {
      }
    }
    this.router.navigateByUrl('tabs/config-marcadores', navigationExtras);
  }

  onEditarEstagiosFunilClick() {
    const navigationExtras: NavigationExtras = {
      state: {
      }
    }
    this.router.navigateByUrl('tabs/config-estagios-funil', navigationExtras);
  }

  getLocationsByUF(uf: string) {

    this.utils.getLocateByUF(uf).subscribe(result => {

      this.locations = result;

    })

  }

  getUserPersonalData() {
    this.selectedBuzzIndex = localStorage.getItem('buzz_index') || '0';
    console.log('selectedBuzzIndex', this.selectedBuzzIndex)


    this.userdataService.getUserPersonalDataDeep().subscribe(result => {

      this.userPersonalData = result;

      this.user_username = this.userPersonalData?.username;

      this.businessId = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.id;
      console.log('businessId', this.businessId)

      this.buzProfiles = this.userPersonalData?.perfil_negocios;



      //get automation_tasks from businessId
      this.automationTasks$ = this.refreshAutomationTasks$.pipe(
        switchMap(() => this.automationService.getAutomatedTasks(this.businessId)),
        map(
          (result: any) => {
            console.log('result', result.data)
            //verificar o array de objetos result.data e deletar os objetos vazio
            result.data.forEach((element) => {
              if (!element.attributes.nome || !element.attributes.quandoAcontece) {

                this.automationService.deleteAutomatedTask(element.id).subscribe({
                  next: (response) => {
                    console.log('deleteAutomatedTask request was successful');
                  }
                });
              }

            });
            return result.data;
          }
        ));

      this.integratedMails$ = this.refreshMailsIntegrated$.pipe(
        switchMap(() => this.automationService.getIntegratedMails_shortInfos(this.businessId)),
        map((result: any) => {
          console.log('result', result)
          return result.data;
        }
        ));




      // this.automationService.getAutomatedTasks(this.businessId).pipe(
      //   map((result: any) => {
      //     return result;
      //   }
      //   ));
      this.userId = this.userPersonalData?.id

      this.user_firstname = this.userPersonalData?.dados_pessoais?.nome
      this.user_lastname = this.userPersonalData?.dados_pessoais?.sobrenome
      this.user_email = this.userPersonalData?.email
      this.user_whatsapp = this.userPersonalData?.dados_pessoais?.celular?.replace(/\D/g, '').replace(/(\d{2})(\d)/, "($1) $2").replace(/(\d)(\d{4})$/, "$1-$2")
      this.user_function = this.userPersonalData?.dados_pessoais?.cargo

      //Avatar ID, if isn't empty
      this.userAvatarId = this.userPersonalData?.avatar?.id


      //Business Informations

      this.buz_cnpj = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.cnpj
      this.buz_razao_social = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.razao_social
      this.buz_cep = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.cep
      this.buz_logradouro = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.logradouro
      this.buz_address_number = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.numero
      this.buz_bairro = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.bairro
      this.buz_state = +this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.estado
      this.buz_city = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.cidade
      this.buz_whatsapp = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.whatsapp_negocio?.replace(/\D/g, '').replace(/(\d{2})(\d)/, "($1) $2").replace(/(\d)(\d{4})$/, "$1-$2")
      this.buz_telefone = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.telefone_negocio?.replace(/\D/g, '').replace(/(\d{2})(\d)/, "($1) $2").replace(/(\d)(\d{4})$/, "$1-$2")
      this.buz_site = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.site
      this.buz_instagram = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.instagram
      this.buz_linkedin = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.linkedin
      this.buz_facebook = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.facebook
      this.buz_tiktok = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.tiktok
      this.buz_modalidades_atendimento = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.modalidades_atendimento
      this.place_id = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.place_id

      //Business  Services

      this.buz_faz_entrega = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.faz_entrega
      this.buz_taxa_entrega = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.taxa_entrega
      this.buz_tempo_entrega = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.tempo_entrega
      this.buz_pedido_minimo = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.pedido_minimo
      this.buz_especies = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.especies_atendidas || []
      this.buz_outras_especies = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.outras_especies

      this.selectedSpecie = this.buz_especies;



      //User Avatar
      
      if(this.userPersonalData?.avatar?.url){
        this.imagePath = this.strapiUrl + this.userPersonalData?.avatar?.url
      }else{
        this.imagePath = '';
      }      

      //Business Avatart

      if(this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.avatar?.url){
        this.buzAvatarUrl = this.strapiUrl + this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.avatar?.url
      }else{
        this.buzAvatarUrl = '';
      }     

      //console.log('IMAGEM DO USUÁRIO',this.imagePath)

      this.checkIncludedSpecies();

    },
      err => { },
      () => { this.getUsers() }
    )

  }

  getProfissionaisList(){
    
    let profissionais$ = this.profissionaisService.getProfissionaisList(this.businessId).subscribe({
      next:(result)=>{
        this.profissionaisList = result.data;
      },
      error:(e)=>{console.log('Erro Lista de Profissionais - Cadastrar Usuário')},
      complete:()=>{
        profissionais$.unsubscribe();
      }
    })

  }

  getLaboratoriosList(){

    let laboratorios$ = this.exameService.getLaboratorios(1,50,this.businessId).subscribe({
      next:(result)=>{
        this.laboratoriosList = result.data;
      },
      error:(e)=>{},
      complete:()=>{
        laboratorios$.unsubscribe();
      }
    })

  }

  getAutomationTasks(businessId: string) {

    return this.automationService.getAutomatedTasks(businessId).pipe(
      map((result: any) => {
        return result;
      }
      ));


  }



  getUserData(id: string) {

    this.userdataService.getUserData(id).subscribe(result => {

      this.userPersonalData = result;

    })

  }

  checkIncludedSpecies() {

    this.species_list = [];

    for (let i = 0; this.especies.length > i; i++) {
      if (this.buz_especies?.includes(this.especies[i])) {
        this.species_list.push({ especie: this.especies[i], checked: true })
      } else {
        this.species_list.push({ especie: this.especies[i], checked: false })
      }
    }

    console.log(this.species_list)

  }


  includesEspecies(especie: string) {

    this.selectedSpecie = this.buz_especies;

    //let checkArray = (element) => element === especie;
    let index = this.selectedSpecie?.indexOf(especie);

    if (index == -1) {
      this.selectedSpecie.push(especie)
    } else {
      this.selectedSpecie.splice(index, 1)
    }

    console.log(this.selectedSpecie);

  }

  checkArray(especie) {
    return (element) => element === especie;
  }

  updateUserData() {


    let userData = {

      username: this.user_username,
      email: this.user_email,
      confirmPassword: this.user_confirm_pass,
      dados_pessoais: {
        nome: this.user_firstname,
        sobrenome: this.user_lastname,
        email: this.user_email,
        celular: this.user_whatsapp?.replace(/[^0-9.]/g, ''),
        cargo: this.user_function,
      }

    }

    this.userdataService.updateUserProfile(userData).subscribe(result => {

      this.successAlert('Informações atualizadas com sucesso');

      if (this.image) {

        this.userdataService.removeImage(this.userAvatarId).subscribe(result => {

          this.send()

        },
          (error) => { this.send() }
        )

      }

    },
      (error) => { this.errorAlert('Houve um erro ao atualizar as informações, favor tentar novamente') }
    )

  }

  updateBusinessProfile() {

    let businessData = {
      data: {
        cnpj: this.buz_cnpj,
        razao_social: this.buz_razao_social,
        cep: this.buz_cep,
        logradouro: this.buz_logradouro,
        numero: this.buz_address_number,
        bairro: this.buz_bairro,
        estado: this.buz_state.toString(),
        cidade: this.buz_city,
        whatsapp_negocio: this.buz_whatsapp.replace(/[^0-9.]/g, ''),
        telefone_negocio:this.buz_telefone.replace(/[^0-9.]/g, ''),
        site: this.buz_site,
        instagram: this.buz_instagram,
        linkedin: this.buz_linkedin,
        facebook: this.buz_facebook,
        tiktok: this.buz_tiktok,
        users_permissions_users: [this.currentUser?.user?.id],
        especies_atendidas: this.selectedSpecie,
        outras_especies: JSON.parse(this.buz_outras_especies),
        faz_entrega: this.buz_faz_entrega,
        taxa_entrega: this.buz_taxa_entrega,
        tempo_entrega: this.buz_tempo_entrega,
        pedido_minimo: this.buz_pedido_minimo,
        modalidades_atendimento: this.buz_modalidades_atendimento,
        place_id: this.place_id
      }

    }

    this.userdataService.updateBusinessProfile(businessData, this.businessId).subscribe(result => {

      this.species_list = [];

      this.getUserPersonalData()

      if (this.image) {

        this.sendLogo()

      }

      this.successAlert('Informações atualizadas com sucesso');

    },
      (error) => { this.errorAlert('Houve um erro ao atualizar as informações, favor tentar novamente'); this.sendLogo() })
  }

  createBusinessProfile() {

    let businessData = {
      data: {
        cnpj: this.buz_cnpj,
        razao_social: this.buz_razao_social,
        cep: this.buz_cep,
        logradouro: this.buz_logradouro,
        numero: this.buz_address_number,
        bairro: this.buz_bairro,
        estado: this.buz_state.toString(),
        cidade: this.buz_city,
        whatsapp_negocio: this.buz_whatsapp.replace(/[^0-9.]/g, ''),
        site: this.buz_site,
        instagram: this.buz_instagram,
        linkedin: this.buz_linkedin,
        facebook: this.buz_facebook,
        tiktok: this.buz_tiktok,
        users_permissions_users: [this.currentUser?.user?.id],
        outras_especies: JSON.parse(this.buz_outras_especies),
        faz_entrega: this.buz_faz_entrega,
        taxa_entrega: this.buz_taxa_entrega,
        tempo_entrega: this.buz_tempo_entrega,
        pedido_minimo: this.buz_pedido_minimo,
        modalidades_atendimento: this.buz_modalidades_atendimento,
        place_id: this.place_id
      }

    }

    this.userdataService.createBusinessProfile(businessData).subscribe(result => {

      this.getUserPersonalData()

      this.successAlert('Informações atualizadas com sucesso');

    },
      (error) => { this.errorAlert('Houve um erro ao atualizar as informações, favor tentar novamente') })

  }

  successAlert(title: string) {

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: title,
      showConfirmButton: false,
      timer: 2000,
      heightAuto: false
    })

  }

  errorAlert(title: string) {

    Swal.fire({
      position: 'center',
      icon: 'error',
      title: title,
      showConfirmButton: false,
      timer: 2000,
      heightAuto: false
    })

  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.imagename = event.target.files[0];
    this.imageselect = false;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const imageBlob = this.dataURItoBlob(this.croppedImage);
    this.image = new File([imageBlob], this.imagename.name, { type: 'image/jpeg' });
    //console.log(this.croppedImage)
    this.imageselect = true;
    this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl(`${this.croppedImage}`)
    console.log(this.imagePath)
  }

  logotypeCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    const imageBlob = this.dataURItoBlob(this.croppedImage);
    this.image = new File([imageBlob], this.imagename.name, { type: 'image/jpeg' });
    //console.log(this.croppedImage)
    this.imageselect = true;
    this.buzAvatarUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`${this.croppedImage}`)

  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob(
      [new Uint8Array(array)],
      {
        type: 'image/png',
      },
    );
  }

  async sendFile(file) {

    let ref = "plugin::users-permissions.user";
    let refId: string = this.currentUser?.user?.id;

    const formData = new FormData();
    formData.append('files', this.image);
    formData.append('ref', ref);
    formData.append('source', 'users-permissions');
    formData.append('refId', refId);
    formData.append('field', 'avatar');
    file.inProgress = true;
    await this.userdataService.uploadImage(formData).subscribe((event: any) => {
      if (typeof (event) === 'object') {
        console.log(event.body);

        if (event.body) {

        }

      }
    });


  }

  async sendLogoFile(file) {

    let ref = "api::perfil-negocio";
    let refId: string = this.businessId;

    const formData = new FormData();
    formData.append('files', this.image);
    formData.append('ref', ref);
    formData.append('refId', refId);
    formData.append('field', 'avatar');
    file.inProgress = true;
    await this.userdataService.uploadImage(formData).subscribe((event: any) => {
      if (typeof (event) === 'object') {
        console.log(event.body);

        if (event.body) {

        }

      }
    });


  }

  private sendFiles() {
    this.loaderService.presentLoading().then(() => {
      this.fileUpload.nativeElement.value = '';
      this.files.forEach(file => {
        this.sendFile(file);
      });
      this.loadingController.dismiss();
    })
  }

  private sendLogoFiles() {
    this.loaderService.presentLoading().then(() => {
      this.fileUpload.nativeElement.value = '';
      this.files.forEach(file => {
        this.sendFile(file);
      });
      this.loadingController.dismiss();
    })
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0 });
        this.isPopoverOpen = true;
      }
    };
    fileUpload.click();

  }

  send() {

    this.sendFiles();

  }

  sendLogo() {

    this.sendLogoFiles();

  }

  closePopover() {

    this.isPopoverOpen = false

  }

  async onAddEmailClick() {
    //Open a modal for the user to fill out an email integration form via SMTP
    const modal = await this.modalCtrl.create({
      component: AddMailIntegationComponent,
      cssClass: 'modal-incluir-agendamento',
    })
    // .then(modal => {
    //   modal.present();
    // }
    //);

    modal.onDidDismiss().then((_) => {
      this.refreshMailsIntegrated$.next(true);

    });
    await modal.present();

  }


  async onAddPhoneClick() {
    const modal = await this.modalCtrl.create({
      component: AddPhoneIntegrationComponent,
      cssClass: 'modal-incluir-agendamento',
    })

    modal.onDidDismiss().then((_) => {
      this.refreshPhonesIntegrated$.next(true);

    });

    await modal.present();

  }

  async onEditMailClick(mailId: number) {
    const modal = await this.modalCtrl.create({
      component: AddMailIntegationComponent,
      cssClass: 'modal-incluir-agendamento',
      componentProps: {
        mode: 'edit',
        mailId: mailId
      }
    })

    modal.onDidDismiss().then((_) => {
      this.refreshMailsIntegrated$.next(true);

    });

    await modal.present();

  }

  onDeleteEmailClick(mailId: number) {

    Swal
      .fire({
        title: 'Remover este e-mail?',
        showCancelButton: true,
        confirmButtonText: 'Remover',
        customClass: 'removeProfAlertClass',
        heightAuto: false,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: 'var(--ion-color-danger)',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.automationService.deleteIntegratedMail(mailId).subscribe({
            next: (response) => {
              this.refreshMailsIntegrated$.next(true);
            },
            complete: () => {
              this.successAlert('E-mail deletado com sucesso');

              console.log('deleteAutomatedTask request finished');
            },
            error: (error) => {
              this.errorAlert('Houve um erro ao deletar o e-mail, favor tentar novamente');
              console.error('An error occurred while processing the deleteAutomatedTask request:', error);
            }

          });
        }
      });

  }

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  confirm() {
    this.modal.dismiss(this.name, 'confirm');
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    if (ev.detail.role === 'confirm') {
      this.message = `Hello, ${ev.detail.data}!`;
    }
  }

  getUserDataById(id: string) {

    this.userdataService.getUserData(id).subscribe(result => {
      this.editUserDetails = result;

      this.edit_input_name = this.editUserDetails?.dados_pessoais?.nome
      this.edit_input_lastname = this.editUserDetails?.dados_pessoais?.sobrenome
      this.edit_input_email = this.editUserDetails?.email
      this.edit_input_celular = this.editUserDetails?.dados_pessoais?.celular
      this.edit_input_cargo = this.editUserDetails?.dados_pessoais?.cargo
      this.edit_input_username = this.editUserDetails?.username
      this.edit_input_funcao = this.editUserDetails?.function


      for (let i = 0; this.editUserDetails?.perfil_negocios?.length > i; i++) {

        if (this.edit_input_unities.indexOf(this.editUserDetails?.perfil_negocios[i]?.id.toString()) === -1) {

          this.edit_input_unities.push(this.editUserDetails?.perfil_negocios[i]?.id.toString())

        }

      }

      console.log('ARRAY UNITIES', this.edit_input_unities)


    },
      err => { },
      () => { }
    )

  }

  getUsers() {
    this.userdataService.getUsers(this.businessId).subscribe(result => {

      this.usersList = result;


    })
  }

  registerUser(userData: any) {

    let perfil_negocios = userData?.perfil_negocios

    let username = userData?.username + "@" + this.generateRandonString();

    if(perfil_negocios?.length < 1){
      this.warningAlert('O usuário precisa ter ao menos uma unidade associada!');
      return
    }

    this.authservice.createNew(username, userData?.firstname, userData?.lastname, userData?.email, userData?.password, userData?.celular, userData?.cargo, userData?.funcao, perfil_negocios).subscribe(
      {next:(result)=>{

        this.createdUser = result;

        if(this.insert_input_funcao === 'profissional' && this.insert_input_profissional){

          this.profissionaisService.insertProfissionalRelation(this.insert_input_profissional, this.createdUser?.id).subscribe({
            next:(result)=>{},
            error:(e)=>{},
            complete:()=>{            

            }
          })

        }

        if(this.insert_input_funcao === 'laboratorio' && this.insert_input_laboratorio){

          this.exameService.updateLaboratorio({usuario_associado:this.createdUser?.id},this.insert_input_laboratorio).subscribe({
            next:(result)=>{},
            error:(e)=>{},
            complete:()=>{            

            }
          })

        }


      let logData = {
        what_changed: 'usuario',
        action: 'created',
        details: {
          description: 'Criação de usuário via painel de gerenciamento PETinhodemim',

        },
        element_id: this.createdUser?.id,
        performed_by: {
          nome: this.userPersonalData?.dados_pessoais?.nome + ' ' + this.userPersonalData?.dados_pessoais?.sobrenome,
          usuario: this.userPersonalData?.username,
          email: this.userPersonalData?.email
        },
        perfil_negocio: this.businessId

      }

      this.createLog(logData, null)

      },
       error:(e)=>{this.errorAlert('Houve um erro ao criar o usuário, por favor tente novamente')},
       complete:()=>{

        this.insert_input_name = '';
        this.insert_input_lastname = '';
        this.insert_input_email = '';
        this.insert_input_password = '';
        this.insert_input_passconfirm = '';
        this.insert_input_celular = '';
        this.insert_input_cargo = '';
        this.insert_input_username = '';
        this.insert_input_unities = [];
        this.insert_input_funcao = '';
        this.insert_input_profissional = null;
        this.insert_input_laboratorio = null

        this.successAlert('Usuário criado com sucesso'); this.confirm(); this.getUsers()}
      }
    )

  }

  updateUser(id: any) {


    let userData = {

      email: this.edit_input_email,
      confirmPassword: this.edit_input_passconfirm,
      dados_pessoais: {
        nome: this.edit_input_name,
        sobrenome: this.edit_input_lastname,
        email: this.edit_input_email,
        celular: this.edit_input_celular?.replace(/[^0-9.]/g, ''),
        cargo: this.edit_input_cargo,
      },
      function: this.edit_input_funcao,
      perfil_negocios: this.edit_input_unities

    }

    this.userdataService.updateUser(userData, id).subscribe(result => {

      this.successAlert('Informações atualizadas com sucesso');
      this.getUsers()

    },
      (error) => { this.errorAlert('Houve um erro ao atualizar as informações, favor tentar novamente') }
    )

  }

  removeUser(id: any) {
    this.userdataService.removeUser(id).subscribe(result => {

      this.removedUser = result

      let logData = {
        what_changed: 'usuario',
        action: 'deleted',
        details: {
          description: 'Remoção de usuário via painel de gerenciamento PETinhodemim',

        },
        element_id: this.removedUser?.id,
        performed_by: {
          nome: this.userPersonalData?.dados_pessoais?.nome + ' ' + this.userPersonalData?.dados_pessoais?.sobrenome,
          usuario: this.userPersonalData?.username,
          email: this.userPersonalData?.email
        },
        perfil_negocio: this.businessId

      }

      this.createLog(logData, null)

      this.successAlert("Usuário removido com sucesso");
      this.getUsers()

    })
  }

  blockUser(id: any) {

    let userData = { blocked: true }

    this.userdataService.updateUser(userData, id).subscribe(result => {
      this.successAlert('Usuário Bloqueado');
      this.getUsers();
    })
  }

  unblockUser(id: any) {

    let userData = { blocked: false }

    this.userdataService.updateUser(userData, id).subscribe(result => {
      this.successAlert('Usuário Desbloqueado');
      this.getUsers();
    })
  }

  removeConfirmation(id: any) {

    Swal.fire({
      title: 'Você deseja remover este usuário?',
      showCancelButton: true,
      confirmButtonText: 'Remover',
      customClass: 'removeProfAlertClass',
      heightAuto: false,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: 'var(--ion-color-danger)'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.removeUser(id)
      }
    })

  }

  testPassStrength(senha) {
    this.forca = 0;
    this.stregthBarColor = 'danger'
    if (senha.length < 6) {
      return this.forca;
    }
    if (senha.match(/[a-z]/)) {
      this.forca++;
      this.forca++;
    }
    if (senha.match(/[A-Z]/)) {
      this.forca++;
      this.forca++;
    }
    if (senha.match(/\d+/)) {
      this.forca++;
      this.forca++;
    }
    if (senha.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) {
      this.forca++;
    }
    if (senha.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) {
      this.forca++;
    }
    if (senha.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) {
      this.forca++;
    }
    if (senha.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) {
      this.forca++;
    }

    this.forca = (this.forca / 10);

    if (this.forca <= 0.3) {
      this.stregthBarColor = 'danger'
    } else if (this.forca > 0.3 && this.forca <= 0.8) {
      this.stregthBarColor = 'warning'
    } else if (this.forca > 0.9) {
      this.stregthBarColor = 'success'
    }

    console.log('FORÇA DA SENHA', this.forca)

    return this.forca;
  }

  generateRandonString() {
    var caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var resultado = '';
    for (var i = 0; i < 15; i++) {
      resultado += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
    }
    return resultado;
  }

  validRegisterForme() {
    if (!this.insert_input_name ||
      !this.insert_input_lastname ||
      !this.insert_input_email ||
      !this.insert_input_password ||
      !this.insert_input_passconfirm ||
      !this.insert_input_cargo ||
      !this.insert_input_username ||
      this.insert_input_unities.length < 1) {

      this.registerFormValid = false;

    } else {
      this.registerFormValid = true;
    }
  }

  validEditForm() {
    if (!this.edit_input_name ||
      !this.edit_input_lastname ||
      !this.edit_input_email ||
      !this.edit_input_cargo ||
      this.edit_input_unities.length < 1) {

      this.editFormValid = false;

    } else {
      this.editFormValid = true;
    }
    console.log('FORMULÁRIO DE EDIÇÃO', this.editFormValid)
  }

  valideEmail(email) {
    var padrao = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (padrao.test(email) === true) {
      this.emailIsValid = true;
    }
    return this.emailIsValid;
  }

  async openEditModal() {
    const modal = await this.modalCtrl.create({
      component: EdituserComponent,
      cssClass: 'edit-user-modal',
      componentProps: {
        userId: this.selectedUserId,
        buzProfiles: this.buzProfiles,
        perfilNegocio: this.businessId,
        userName: this.userPersonalData?.dados_pessoais?.nome + ' ' + this.userPersonalData?.dados_pessoais?.sobrenome
      }
    });

    modal.onDidDismiss().then(() => {
      this.getUsers();
    });

    modal.present();

  }

  createLog(elementData: any, id: any) {


    this.logsService.createLogs(elementData).subscribe(result => {

      console.log('Log criado')

    })
  }

  getLogs(pageNumber: number = 1) {

    let page = pageNumber;

    this.logsService.getLogsByBusiness(this.businessId, page).subscribe(result => {

      this.logs = result?.data;
      this.resultMeta = result?.meta;
      this.selectedPage = this.resultMeta?.pagination?.page;

    })

  }

  getLogsByDate(date: any) {

    let page = 1;

    var datePipe = new DatePipe('pt-BR');
    let selectedDate = datePipe.transform(date, 'yyyy-MM-dd');

    this.logsService.getLogsByBusinessAndDate(this.businessId, page, selectedDate).subscribe(result => {

      this.logs = result?.data
      this.resultMeta = result?.meta;
      this.selectedPage = this.resultMeta?.pagination?.page;

    })
  }


  getElementDetails(id: any, element: string) {

    var elementUri

    switch (element) {
      case 'profissional':
        elementUri = 'profissionals';
        break;
      case 'tutor':
        elementUri = 'tutors';
        break;
      case 'agendamento':
        elementUri = 'agendamentos';
        break;
      case 'perfil_negocio':
        elementUri = 'perfil-negocios';
        break;
      case 'usuario':
        elementUri = 'users';
        break;
      case 'integracao':
        elementUri = 'integration-mails';
        break;
      case 'automacao':
        elementUri = 'automated-tasks';
        break;
      case 'reputation':
        elementUri = 'notes';
        break;
      case 'automacao_action':
        elementUri = 'actions-automations';
        break;
      case 'pet':
        elementUri = 'pets';
        break;
    }

    this.logsService.getlementDetails(id, elementUri).subscribe(result => {

      if (element != 'usuario') {
        this.elementDetails = result.data;
      } else {
        this.elementDetails = result
      }

      this.elementError = null

    },
      err => {
        this.elementError = err;
        console.log('ERROR', this.elementError)
      }
    )

  }

  clearDateFilter() {
    this.selectedDate = null;
    this.getLogs();
  }

  warningAlert(title: string) {

    Swal.fire({
      position: 'center',
      icon: 'warning',
      text: title,
      showConfirmButton: false,
      timer: 2000,
      heightAuto: false
    })
  
  }
  


}




