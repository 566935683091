// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   locateApi: 'https://servicodados.ibge.gov.br/api/v1/localidades/estados/',
//   strapiApiUrl: 'https://api.petinhodemim.online/api',
//   strapiBaseUrl: 'https://api.petinhodemim.online'
//  };


// export const environment = {
//   production: false,
//   locateApi: 'https://servicodados.ibge.gov.br/api/v1/localidades/estados/',
//   strapiApiUrl: 'http://149.28.223.221:16337/api',
//   strapiBaseUrl: 'http://149.28.223.221:16337'
// };

export const environment = {
  production: false,
  locateApi: 'https://servicodados.ibge.gov.br/api/v1/localidades/estados/',
  strapiApiUrl: 'https://api.petinhodemim.com/api',
  strapiBaseUrl: 'https://api.petinhodemim.com',
  CHAVE_SECRETA: 'chaveSecreta123'
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
