import { style } from '@angular/animations';
import { ModalController, NavParams } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import VariablesPlugin from './variables-plugin';
import { AutomationService } from 'src/app/services/automation.service';
import { UserdataService } from 'src/app/services/userdata.service';
import { UtilsService } from 'src/app/services/utils.service';




//fazer um formulario usando o formbuider
@Component({
  selector: 'app-add-template-modal',
  templateUrl: './add-template-modal.component.html',
  styleUrls: ['./add-template-modal.component.scss'],
})
export class AddTemplateModalComponent implements OnInit, AfterViewInit {
  MailTemplateForm: FormGroup;
  MailTemplateId = '';

  @ViewChild('editorComponent') editorComponent;


  public Editor = ClassicEditor;
  public editorContent: string = '';

  public config = {
    // extraPlugins: [VariablesPlugin],
    toolbar: {
      items: [
        // other toolbar items
        'heading',
        '|',
        'bold',
        'italic',
        // 'link',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
        '|',
        'blockQuote',
        'insertTable',
        // 'variables', // add the button for inserting variables
      ],
    }

  }






  // public richTextContent: string = '';




  selectedBuzzIndex: string | number;
  perfilNegocio: any;
  mode: string;
  MailTemplate: any;
  insertVariable_array: any;


  constructor(
    private formBuilder: FormBuilder,
    private automationService: AutomationService,
    private modalController: ModalController,
    private userDataService: UserdataService,
    private NavParams: NavParams,
    private utilsService: UtilsService,
  ) { }

  ngOnInit() {

    this.insertVariable_array = [
      { chave: 'Tutor_Nome', texto: 'Tutor: Nome' },
      { chave: 'Tutor_NomeCompleto', texto: 'Tutor: Nome Completo' },
      { chave: 'Profissional_Nome', texto: 'Profissional: Nome' },
      { chave: 'Profissional_NomeCompleto', texto: 'Profissional: Nome Completo' },
      { chave: 'Pet_Nome', texto: 'Pet: Nome' },
      { chave: 'Especialidade', texto: 'Especialidade' },
      { chave: 'Data', texto: 'Data' },
      { chave: 'Horario', texto: 'Horario' },
      { chave: 'Valor', texto: 'Valor' },
      { chave: 'Link_Avaliacao', texto: 'Pesquisa de satisfação' },
      { chave: 'Logotipo', texto: 'Logotipo' },
    ]






    this.mode = this.NavParams.get('mode');
    if (this.mode === 'edit') {
      this.MailTemplateId = this.NavParams.get('id');
      console.log('this.MailTemplate', this.MailTemplateId)
      this.MailTemplate = this.automationService.getMailTemplateId(this.MailTemplateId).subscribe(
        {
          next: (result) => {
            console.log('result', result)
            this.MailTemplateForm.patchValue({
              nome: result?.data?.attributes?.nome,
              assunto: result?.data?.attributes?.assunto,
              message: result?.data?.attributes?.message,
            })
          }
        }
      );
    }


    this.MailTemplateForm = this.formBuilder.group(
      {
        nome: ['', Validators.required],
        assunto: ['', Validators.required],
        message: ['', Validators.required],
        perfil_negocios: [''],
      }
    );



  }

  ngAfterViewInit() {

  }


  insertVariable(variable: string): void {
    const editorInstance = this.editorComponent.editorInstance;

    if (editorInstance) {
      editorInstance.model.change(writer => {
        const variableText = writer.createText(`{{${variable}}}`);
        const insertPosition = editorInstance.model.document.selection.getFirstPosition();

        editorInstance.model.insertContent(variableText, insertPosition);

        // Update the selection after the text is inserted
        const newPosition = insertPosition.getShiftedBy(variableText.offsetSize);
        writer.setSelection(newPosition);
      });
    }
  }


  onSubmit() {

    if (this.MailTemplateForm.invalid || this.MailTemplateForm.value.nome === '' || this.MailTemplateForm.value.assunto === '' || this.MailTemplateForm.value.message === '') {
      this.utilsService.errorAlert('Dados inválidos, por favor verifique os campos e tente novamente.');
      return;
    }

    this.userDataService.getUserPersonalData()
      .subscribe(
        {
          next: (result) => {
            this.selectedBuzzIndex = localStorage.getItem('buzz_index') || 0;

            this.perfilNegocio = result?.perfil_negocios[this.selectedBuzzIndex]?.id;

            console.log('this.MailTemplateForm.value', this.MailTemplateForm.value)
            this.MailTemplateForm.patchValue({
              perfil_negocios: [this.perfilNegocio]
            })



            if (this.mode === 'edit') {
              const MAIL_TEMPLATE_FORM = {
                data: this.MailTemplateForm.value
              }
              this.automationService.updateMailTemplate(this.MailTemplateForm.value, this.MailTemplateId).subscribe(
                {
                  next: (result) => {
                    this.automationService.refreshTemplateMails_Subject$.next(true)
                    console.log(result)
                  },
                  complete: () => {
                    console.log('complete')
                    this.modalController.dismiss(
                      {
                        'dismissed': true,
                        'data': this
                      }
                    );
                  },
                  error: (error) => {
                    console.log(error)
                  }

                }
              )
            } else {
              this.automationService.createMailTemplate(this.MailTemplateForm.value).subscribe(
                {
                  next: (result) => {
                    // show an alert message
                    this.automationService.refreshTemplateMails_Subject$.next(true)
                    console.log(result)

                  },
                  complete: () => {
                    console.log('complete')
                    this.modalController.dismiss();
                  },
                  error: (error) => {
                    console.log(error)
                  }

                }
              )
            }
          },
          complete: () => {
            console.log('complete')


          },
          error: (error) => {
            console.log(error)
          }
        }
      )

    // console.log('submit')
    // console.log(this.MailTemplateForm.value)


  }

  onCancelarClick() {
    this.modalController.dismiss();
  }

}
