import { Component, OnInit, ViewChild } from '@angular/core';
import { UserdataService } from 'src/app/services/userdata.service';
import Swal from 'sweetalert2';
import { IonModal } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core/components';
import { ModalController, PopoverController } from '@ionic/angular';
import {LogsService} from 'src/app/services/logs.service';
import { ProfissionaisService } from '../../services/profissionais.service';
import { ExamesService } from '../../services/exames.service';

@Component({
  selector: 'app-edituser',
  templateUrl: './edituser.component.html',
  styleUrls: ['./edituser.component.scss'],
})
export class EdituserComponent implements OnInit {

  @ViewChild(IonModal) modal: IonModal;
  edit_input_name:string;
  edit_input_lastname:string;
  edit_input_email:string;
  edit_input_password:string;
  edit_input_passconfirm:string;
  edit_input_celular:string
  edit_input_cargo:string;
  edit_input_username:string;
  edit_input_unities:any = [];
  edit_input_funcao:string;
  edit_input_profissional:any = [];
  edit_input_laboratorio:any = []

  editUserDetails:any = [];

  forca:any;
  stregthBarColor:string;
  registerFormValid:Boolean = false;
  editFormValid:Boolean = false;
  emailIsValid:Boolean = false;
  selectedUserId:any;
  userId:any;
  buzProfiles:any;
  userData:any = []
  perfilNegocio:any;
  userName:any;
  profissionaisList:any = [];
  laboratoriosList:any = [];

  constructor(private userdataService: UserdataService,  private modalCtrl: ModalController,private logsService: LogsService, private exameService:ExamesService, private profissionaisService:ProfissionaisService) { }

  ngOnInit() {

    this.getUserDataById(this.userId)
    console.log('PERFIS DE NEGOCIO',this.buzProfiles)
    this.userData = JSON.parse(localStorage.getItem('currentUser')).user
    this.getProfissionaisList();
    this.getLaboratoriosList();

  }

  cancel() {
    this.modal.dismiss();
  }

  confirm() {
    this.modal.dismiss('confirm');
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    if (ev.detail.role === 'confirm') {
      
    }
  }

  getUserDataById(id:string){

    this.userdataService.getUserData(id).subscribe(result=>{
      this.editUserDetails = result;

    this.edit_input_name = this.editUserDetails?.dados_pessoais?.nome
    this.edit_input_lastname = this.editUserDetails?.dados_pessoais?.sobrenome
    this.edit_input_email = this.editUserDetails?.email
    this.edit_input_celular = this.formatarCelular(this.editUserDetails?.dados_pessoais?.celular)
    this.edit_input_cargo = this.editUserDetails?.dados_pessoais?.cargo
    this.edit_input_username = this.editUserDetails?.username
    this.edit_input_funcao = this.editUserDetails?.function
    this.edit_input_profissional = this.editUserDetails?.profissional?.id
    this.edit_input_laboratorio = this.editUserDetails?.laboratorio?.id
    

    for(let i=0; this.editUserDetails?.perfil_negocios?.length > i;i++){

      if(this.edit_input_unities.indexOf(this.editUserDetails?.perfil_negocios[i]?.id.toString()) === -1){

        this.edit_input_unities.push(this.editUserDetails?.perfil_negocios[i]?.id.toString())

      }     

    }

    console.log('ARRAY UNITIES',this.edit_input_unities)


    },
    err=>{},
    ()=>{}
    )

  }

  updateUser(id:any) {


    let userData = {

      email: this.edit_input_email,
      confirmPassword: this.edit_input_passconfirm,
      dados_pessoais: {
        nome: this.edit_input_name,
        sobrenome: this.edit_input_lastname,
        email: this.edit_input_email,
        celular: this.edit_input_celular?.replace(/[^0-9.]/g, ''),
        cargo: this.edit_input_cargo,
      },
      function: this.edit_input_funcao,
      perfil_negocios:this.edit_input_unities,

    }

    if(this.edit_input_unities?.length < 1){
      this.warningAlert('O usuário precisa ter ao menos uma unidade associada!');
      return
    }

    this.userdataService.updateUser(userData,id).subscribe(
      {
        next:(result)=>{
        this.successAlert('Informações atualizadas com sucesso');

        let logData = {
          what_changed:'usuario',
          action:'updated',
          details:{
            description:'Atualização de dados de usuário via painel de gerenciamento PETinhodemim',
            
          },
          element_id:result?.id,
          performed_by:{
            nome:this.userName,
            usuario: this.userData?.username,
            email: this.userData?.email
          },
          perfil_negocio:this.perfilNegocio

        }
        if(this.edit_input_funcao === 'profissional' && this.edit_input_profissional){

          this.profissionaisService.insertProfissionalRelation(this.edit_input_profissional, id).subscribe({
            next:(result)=>{},
            error:(e)=>{},
            complete:()=>{            

            }
          })

        }

        if(this.edit_input_funcao === 'laboratorio' && this.edit_input_laboratorio){

          this.exameService.updateLaboratorio({usuario_associado:id},this.edit_input_laboratorio).subscribe({
            next:(result)=>{},
            error:(e)=>{},
            complete:()=>{            

            }
          })

        }

      this.createLog(logData, this.userData?.id)
      this.cancelEdition()
      },
      error:(e)=>{this.errorAlert('Houve um erro ao atualizar as informações, favor tentar novamente')},
      complete:()=>{}
      }
    )

  }

  validEditForm(){
    if(!this.edit_input_name ||
      !this.edit_input_lastname ||
      !this.edit_input_email || 
      !this.edit_input_cargo ||    
      this.edit_input_unities.length < 1){
  
        this.editFormValid = false;
  
    }else{
      this.editFormValid = true;
    }
    console.log('FORMULÁRIO DE EDIÇÃO',this.editFormValid)
  }
  
  valideEmail(email) {
    var padrao = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  
    if(padrao.test(email) === true){
      this.emailIsValid = true;
    }
    return this.emailIsValid;
  }

  testPassStrength(senha) {
    this.forca = 0;
    this.stregthBarColor = 'danger'
    if (senha.length < 6) {
        return this.forca;
    }
    if (senha.match(/[a-z]/)) {
        this.forca++;
        this.forca++;
    }
    if (senha.match(/[A-Z]/)) {
        this.forca++;
        this.forca++;
    }
    if (senha.match(/\d+/)) {
        this.forca++;
        this.forca++;
    }
    if (senha.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) {
        this.forca++;
    }
    if (senha.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) {
      this.forca++;
    }
    if (senha.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) {
      this.forca++;
    }
    if (senha.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) {
      this.forca++;
    }

    this.forca = (this.forca/10);

    if(this.forca <= 0.3){
      this.stregthBarColor = 'danger'
    }else if(this.forca > 0.3 && this.forca <= 0.8){
      this.stregthBarColor = 'warning'
    }else if(this.forca > 0.9){
      this.stregthBarColor = 'success'
    }

    console.log('FORÇA DA SENHA',this.forca)

    return this.forca;
}

successAlert(title: string) {

  Swal.fire({
    position: 'center',
    icon: 'success',
    title: title,
    showConfirmButton: false,
    timer: 2000,
    heightAuto: false
  })

}

errorAlert(title: string) {

  Swal.fire({
    position: 'center',
    icon: 'error',
    title: title,
    showConfirmButton: false,
    timer: 2000,
    heightAuto: false
  })

}

warningAlert(title: string) {

  Swal.fire({
    position: 'center',
    icon: 'warning',
    text: title,
    showConfirmButton: false,
    timer: 2000,
    heightAuto: false
  })

}
cancelEdition(){

  this.modalCtrl.dismiss({
    'dismissed': true,
    'data': this
  });

}

formatarCelular(celular) {
  celular = celular?.replace(/\D/g, "");
  celular = celular?.replace(/^(\d{2})(\d)/g, "($1) $2");
  celular = celular?.replace(/(\d)(\d{4})$/, "$1-$2");
  return celular;
}

createLog(elementData:any, id:any){


  this.logsService.createLogs(elementData).subscribe(result=>{

    console.log('Log criado')

  })
}

getProfissionaisList(){
    
  let profissionais$ = this.profissionaisService.getProfissionaisList(this.perfilNegocio).subscribe({
    next:(result)=>{
      this.profissionaisList = result.data;
    },
    error:(e)=>{console.log('Erro Lista de Profissionais - Cadastrar Usuário')},
    complete:()=>{
      profissionais$.unsubscribe();
    }
  })

}

getLaboratoriosList(){

  let laboratorios$ = this.exameService.getLaboratorios(1,50,this.perfilNegocio).subscribe({
    next:(result)=>{
      this.laboratoriosList = result.data;
    },
    error:(e)=>{},
    complete:()=>{
      laboratorios$.unsubscribe();
    }
  })

}

}
