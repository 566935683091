import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_helpers/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/tabs/dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'solicitar-senha',
    loadChildren: () => import('./forgetpass/forgetpass.module').then( m => m.ForgetpassPageModule)
  },  
  {
    path: 'nova-senha',
    loadChildren: () => import('./newpass/newpass.module').then( m => m.NewpassPageModule)
  },
  {
    path: 'add-event-modal',
    loadChildren: () => import('./modals/add-event-modal/add-event-modal.module').then( m => m.AddEventModalPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'card-agendamento-dia',
    loadChildren: () => import('./modals/card-agendamento-dia/card-agendamento-dia.module').then( m => m.CardAgendamentoDiaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'incluir-agendamento',
    loadChildren: () => import('./modals/incluir-agendamento/incluir-agendamento.module').then( m => m.IncluirAgendamentoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profissionais-card-agenda',
    loadChildren: () => import('./profissionais-card-agenda/profissionais-card-agenda.module').then( m => m.ProfissionaisCardAgendaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'nova-especialidade',
    loadChildren: () => import('./modals/nova-especialidade/nova-especialidade.module').then( m => m.NovaEspecialidadePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'novo-pet',
    loadChildren: () => import('./modals/novo-pet/novo-pet.module').then( m => m.NovoPetPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'nova-especialidade-modal',
    loadChildren: () => import('./modals/nova-especialidade-modal/nova-especialidade-modal.module').then( m => m.NovaEspecialidadeModalPageModule),
    canActivate: [AuthGuard]
  },
 
  {
    path: 'tutores',
    loadChildren: () => import('./tutores/tutores.module').then( m => m.TutoresPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tutores-cadastro',
    loadChildren: () => import('./tutores-cadastro/tutores-cadastro.module').then( m => m.TutoresCadastroPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tutores-info-card',
    loadChildren: () => import('./tutores-info-card/tutores-info-card.module').then( m => m.TutoresInfoCardPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'agenda',
    loadChildren: () => import('./agenda/agenda.module').then( m => m.AgendaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'client/:id',
    loadChildren: () => import('./client/client.module').then( m => m.ClientPageModule),
    
  },
  {
    path: 'incluir-tutor-modal',
    loadChildren: () => import('./modals/incluir-tutor-modal/incluir-tutor-modal.module').then( m => m.IncluirTutorModalPageModule)
  },
  {
    path: 'agenda-profissional',
    loadChildren: () => import('./modals/agenda-profissional/agenda-profissional.module').then( m => m.AgendaProfissionalPageModule)
  },  
  {
    path: 'loader/:url',
    loadChildren: () => import('./loader/loader.module').then( m => m.LoaderPageModule)
  },
  {
    path: 'avaliation/:id',
    loadChildren: () => import('./avaliation/avaliation.module').then( m => m.AvaliationPageModule)
  },
  {
    path: 'reputation',
    loadChildren: () => import('./reputation/reputation.module').then( m => m.ReputationPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tipos-exames',
    loadChildren: () => import('./tipos-exames/tipos-exames.module').then( m => m.TiposExamesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'laboratorios',
    loadChildren: () => import('./laboratorios/laboratorios.module').then( m => m.LaboratoriosPageModule),
    canActivate: [AuthGuard]
  }
  
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
