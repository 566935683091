import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IncluirAgendamentoPage } from '../incluir-agendamento/incluir-agendamento.page';
import swal from 'sweetalert2';
import { AgendaService } from 'src/app/services/agenda.service';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { UserdataService } from 'src/app/services/userdata.service';
import { UtilsService } from 'src/app/services/utils.service';
import {LogsService} from 'src/app/services/logs.service'

@Component({
  selector: 'app-card-agendamento-dia',
  templateUrl: './card-agendamento-dia.page.html',
  styleUrls: ['./card-agendamento-dia.page.scss'],
})
export class CardAgendamentoDiaPage implements OnInit {
  public horaInicial: any;
  public horaFinal: any;
  public metaDados: any;
  public statusColor: string = '';
  statusSelector: string = '';
  strapiUrl = environment.strapiBaseUrl;
  hoje: string;
  showRetorno: Boolean;
  colSize: number;
  private subs = new SubSink();
  userData: any;
  perfilNegocio: any;
  PetFaltas: any;
  PetUltimaConsulta: any;
  selectedBuzzIndex: string | number;

  constructor(
    private modalCtrl: ModalController,
    private agendaService: AgendaService,
    private userDataService: UserdataService,
    public utilsService: UtilsService,
    private logsService: LogsService
  ) { }

  ngOnInit() {
    this.statusSelector = this.metaDados?.statusAgendamento;

    if (this.metaDados?.statusAgendamento != 'pacienteAtendido') {
      this.showRetorno = false;
    }
    this.selectStatusColor();

    console.log(
      this.metaDados?.especialidade_procedimentoSelected?.[0]?.retorno
    );

    this.subs.add(
      this.userDataService.getUserPersonalData().subscribe((result) => {
        this.userData = result;
        this.selectedBuzzIndex = localStorage.getItem('buzz_index') || 0;
        this.perfilNegocio = this.userData?.perfil_negocios[this.selectedBuzzIndex]?.id;
        // //console.log(this.perfilNegocio)

        let Faltas = this.agendaService
          .getPetFaltas(
            this.perfilNegocio,
            this.metaDados?.petId,
            'Ncompareceu'
          )
          .subscribe((result) => {
            console.log(result);
            this.PetFaltas = result?.meta?.pagination?.total;
          });

        let ultima_consulta = this.agendaService
          .getPetUltimaconsulta(
            this.perfilNegocio,
            this.metaDados?.petId,
            'Consulta',
            'pacienteAtendido'
          )
          .subscribe((result) => {
            console.log(result);
            this.PetUltimaConsulta = result?.data?.[0]?.attributes?.data;
          });
      })
    );

    let hoje = new Date()
      .toLocaleDateString('pt-BR')
      .substring(0, 10)
      .replace(/\//g, '-');
    const parts = hoje.split('-');
    this.hoje = parts[2] + '-' + parts[1] + '-' + parts[0];


    if (this.metaDados?.dataMaximaRetorno) {
      if (

        new Date(`${this.metaDados?.dataMaximaRetorno}T00:00:00-03:00`) < new Date(`${this.hoje}T00:00:00-03:00`)
        ||
        this.metaDados?.statusAgendamento != 'pacienteAtendido'
      ) {
        this.showRetorno = false;
      } else {
        this.showRetorno = true;
        // this.showRetorno = new Date(`${this.hoje}T00:00:00-03:00`) < new Date(`${this.metaDados?.dataMaximaRetorno}T00:00:00-03:00`);
        console.log(
          new Date(`${this.hoje}T00:00:00-03:00`) <
          new Date(`${this.metaDados?.dataMaximaRetorno}T00:00:00-03:00`),
          new Date(`${this.hoje}T00:00:00-03:00`),
          new Date(`${this.metaDados?.dataMaximaDesconto}T00:00:00-03:00`)
        );
      }
    } else if (this.metaDados?.dataMaximaDesconto && this.metaDados?.valorDesconto) {
      console.log(this.metaDados?.dataMaximaDesconto)
      if (
        new Date(`${this.metaDados?.dataMaximaDesconto}T00:00:00-03:00`) < new Date(`${this.hoje}T00:00:00-03:00`)
        || this.metaDados?.statusAgendamento != 'pacienteAtendido'
      ) {
        this.showRetorno = false;
      } else {
        this.showRetorno = true;
      }

    }





    if (this.showRetorno) {
      this.colSize = 4;
    } else {
      this.colSize = 6;
    }
  }

  //------------------------------------------------------------------------------------------------------FUnções editar e incluir agendamento
  async onEditarAgendamento(
    idAgendamento,
    TutorNome,
    TutorCelular,
    metaDados,
    horaInicial,
    horaFinal
  ) {
    const modal = await this.modalCtrl.create({
      component: IncluirAgendamentoPage,
      cssClass: 'modal-incluir-agendamento',
      componentProps: {
        mode: 'edit',
        id: idAgendamento,
        tutorNome: TutorNome,
        tutorCelular: TutorCelular,
        horaInicial: horaInicial,
        horaFinal: horaFinal,
        metaDados: metaDados,
      },
    });
    await modal.present();

    modal.onDidDismiss().then((NovoAgendamento_Object) => {
      console.log(NovoAgendamento_Object);
      if (NovoAgendamento_Object?.data) {
        this.modalCtrl.dismiss();
      }
    });
  }

  async onRetornoAgendamento(
    idAgendamento,
    TutorNome,
    TutorCelular,
    metaDados
  ) {
    const modal = await this.modalCtrl.create({
      component: IncluirAgendamentoPage,
      cssClass: 'modal-incluir-agendamento',
      componentProps: {
        mode: 'retorno',
        id: idAgendamento,
        tutorNome: TutorNome,
        tutorCelular: TutorCelular,
        metaDados: metaDados,
      },
    });
    await modal.present();
  }

  deletarAgendamento(id_agendamento) {
    swal
      .fire({
        title: 'Você deseja remover este Agendamento?',
        showCancelButton: true,
        confirmButtonText: 'Remover',
        customClass: 'removeProfAlertClass',
        heightAuto: false,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: 'var(--ion-color-danger)',
      })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.agendaService.removeAgendamento(id_agendamento).subscribe(
            (result) => {

              var removedAgendamento:any = [];
              removedAgendamento = result
              
              //create a Log

            let logData = {
              what_changed:'agendamento',
              action:'deleted',
              details:{
                description:'Remoção de agendamento via painel de gerenciamento PETinhodemim',
                return:result
              },
              element_id:removedAgendamento?.data?.id,
              performed_by:{
                nome:this.userData?.dados_pessoais?.nome + ' ' + this.userData?.dados_pessoais?.sobrenome,
                usuario: this.userData?.username,
                email: this.userData?.email
              },
              perfil_negocio:this.perfilNegocio

            }

            this.createLog(logData, this.userData?.id)

              this.modalCtrl.dismiss();
              // this.refreshEvents$.next(true);
              // this.refreshResizableCreator();
            },
            (error) => {
              console.error(error);
            }
          );
        }
      });
  }

  selectStatusColor() {
    switch (this.statusSelector) {
      case 'confirmado':
        this.statusColor = 'success';
        break;
      case 'agendado':
        this.statusColor = 'warning';
        break;
      case 'aguardandoAtendimento':
        this.statusColor = 'medium';
        break;
      case 'Ncompareceu':
        this.statusColor = 'dark';
        break;
      case 'pacienteAtendido':
        this.statusColor = 'atendido';
        break;
      case 'pacientAtrasado':
        this.statusColor = 'primary';
        break;
      case 'cancelado':
        this.statusColor = 'danger';
        break;
      default:
        this.statusColor = 'warning';
    }
  }

  async onIncluirAgendamento() {
    const modal = await this.modalCtrl.create({
      component: IncluirAgendamentoPage,
      cssClass: 'modal-incluir-agendamento',
      componentProps: {
        mode: 'insert',
      },
    });
    await modal.present();
  }

  salvar(idAgendamento) {
    let UpdateStatusAgendamentoInput = {
      data: {
        status: this.statusSelector,
      },
    };
    this.agendaService
      .updateAgendamento(UpdateStatusAgendamentoInput, idAgendamento)
      .subscribe(
        {
          next: (result) => {
            console.log(result);

            //create a Log

            let logData = {
              what_changed:'agendamento',
              action:'updated',
              details:{
                description:'Atualização do status do agendamento via painel de gerenciamento PETinhodemim',
                satatus:UpdateStatusAgendamentoInput
              },
              element_id:result?.data?.id,
              performed_by:{
                nome:this.userData?.dados_pessoais?.nome + ' ' + this.userData?.dados_pessoais?.sobrenome,
                usuario: this.userData?.username,
                email: this.userData?.email
              },
              perfil_negocio:this.perfilNegocio

            }

            this.createLog(logData, this.userData?.id)

            this.modalCtrl.dismiss();
          },
          error: (error) => {
            console.error(error);
            this.modalCtrl.dismiss();
          }
        }
      );
  }

  createLog(elementData:any, id:any){


    this.logsService.createLogs(elementData).subscribe(result=>{

      console.log('Log criado')

    })
  }

}
