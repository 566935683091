import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { UtilsService } from './utils.service';
import { IPet, ITutor } from '../interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class TutorService {

  private NovoTutorSubject = new Subject<any>();
  private TutoresSubject = new Subject<any>();
  private $TutorById_Subject = new Subject<any>();


  tutors = this.utilsService.tutors;
  refeshTutors$ = new BehaviorSubject<boolean>(true);



  constructor(
    public utilsService: UtilsService,
  ) { }


  setNovoTutor(data: any) {
    this.NovoTutorSubject.next(data);
    console.log(data, 'tutor objeto enviado')
  }

  getNovoTutor(): Observable<any> {
    console.log('tutor objeto recebido');
    return this.NovoTutorSubject.asObservable();
  }


  setTutores(data: any) {
    this.TutoresSubject.next(data);
    console.log(data, 'tutores array enviado')
  }

  getTutores(): Observable<any> {
    console.log('tutores array recebido');
    return this.TutoresSubject.asObservable();
  }



  getSelectedTutor(): Observable<any> {
    console.log('TutorById_Subject recebido');
    return this.$TutorById_Subject.asObservable();
  }

  setSelectedTutor(data: any) {
    this.$TutorById_Subject.next(data);
    console.log(data, 'TutorById_Subject enviado')
  }


  //--------------------------TESTE---------------------


  getTutores_TESTE(): Observable<ITutor[]> {
    console.log(this.tutors);
    return of(this.tutors)
  }

  addTutor_TESTE(novoTutor: any) {
    this.tutors = [...this.tutors, novoTutor]
  }

  editTutor(UpdatedTutor, id: string) {
    this.tutors = this.tutors.map(tutor => {
      if (tutor.id === id) {
        return UpdatedTutor;
      }
      return tutor;
    });
  }

  getPetFromTutor(id_tutor: string): Observable<IPet[]>  {
    const tutor = this.tutors.find(tutor => tutor.id == id_tutor);
    if(tutor){
      let pets: IPet[] = tutor.pets;
      return of(pets);
    } else {
      return of([]);
    }
  }

  editPet(UpdatedPet, Tutorid: string, Petid: string) {
    console.log(UpdatedPet, Tutorid, Petid)

    this.tutors = this.tutors.map(tutor => {
      if (tutor.id == Tutorid) {
        console.log(Tutorid)

        tutor.pets.map((pet) => {
          if(pet.id == Petid) {
            console.log(UpdatedPet)
            return UpdatedPet;
          }
        })
      }
      return tutor;
    });

    this.refeshTutors$.next(true)
  }




}
