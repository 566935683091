import { CardAgendamentoDiaPage } from '../../modals/card-agendamento-dia/card-agendamento-dia.page';
import { IonSegment } from '@ionic/angular';
import {
  IonContent,
  ModalController,
  NavParams,
  PopoverController,
  ViewDidLeave,
  ViewWillEnter,
  ViewWillLeave  
} from '@ionic/angular';
import { WeekViewHourSegment, MonthView, MonthViewDay } from 'calendar-utils';
import {
  catchError,
  finalize,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { faSliders } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { AfterViewInit, Directive, ElementRef, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { SubSink } from 'subsink';

import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit,
  ViewEncapsulation,
  Injectable,
  ChangeDetectorRef,
  Output,
  Input,
  EventEmitter,

  OnDestroy,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
  endOfWeek,
  addMinutes,
  parse,
  differenceInHours
} from 'date-fns';
import { BehaviorSubject, fromEvent, Observable, of, Subject } from 'rxjs';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarEventTitleFormatter,
  CalendarView,
  CalendarDayViewComponent,
  DAYS_OF_WEEK,
  CalendarMonthViewBeforeRenderEvent,
  CalendarDayViewBeforeRenderEvent,
  CalendarWeekViewBeforeRenderEvent,
  CalendarWeekViewAllDayEvent
} from 'angular-calendar';

import { EventColor } from 'calendar-utils';
import { CalendarDateFormatterService } from '../../services/calendar-date-formatter.service';
import { IncluirAgendamentoPage } from 'src/app/modals/incluir-agendamento/incluir-agendamento.page';
import {
  CustomEventTitleFormatter,
  CustomDateFormatter,
} from './custom-event-title-formatter.provider';
import { TutorService } from 'src/app/services/tutor.service';
import { ITutor } from 'src/app/interfaces/interfaces';
import { UtilsService } from 'src/app/services/utils.service';
import { AgendaService } from 'src/app/services/agenda.service';
import { ProfissionaisService } from 'src/app/services/profissionais.service';
import { UserdataService } from 'src/app/services/userdata.service';
import swal from 'sweetalert2';
import { AutoUnsubscribe } from 'src/app/decorators/auto-unsubscribe.decorator';
import { weekdays } from 'moment';
import { SharedService } from '../../services/shared.service'


function floorToNearest(amount: number, precision: number) {
  // Usada no Arrastar para adicionar evento
  return Math.floor(amount / precision) * precision;
}

function ceilToNearest(amount: number, precision: number) {
  // Usada no Arrastar para adicionar evento
  return Math.ceil(amount / precision) * precision;
}

const colors: Record<string, EventColor> = {
  red: {
    primary: '#fff',
    secondary: '#fff',
  },
  blue: {
    primary: '#fff',
    secondary: '#fff',
  },
  yellow: {
    primary: '#fff',
    secondary: '#fff',
  },
};



/*
@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  weekTooltip(event: CalendarEvent, title: string) {
   if (!event.meta.tmpEvent) { // se houver um template para os eventos esse if deve ser ativado
    return super.weekTooltip(event, title);
    }
  }

  dayTooltip(event: CalendarEvent, title: string) {
    if (!event.meta.tmpEvent) {
    return super.dayTooltip(event, title);
   }
  }
}
*/

@AutoUnsubscribe

@Component({
  selector: 'app-agenda-slider',
  changeDetection: ChangeDetectionStrategy.OnPush,

  templateUrl: './agenda-slider.component.html',
  styleUrls: ['./agenda-slider.component.scss'],
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
  styles: [
    `
      .disable-hover {
        pointer-events: none;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})

export class AgendaSliderComponent
  implements OnInit, ViewWillEnter, ViewWillLeave, ViewDidLeave, OnDestroy, AfterViewInit {
  @Input() ParentComponent: string;
  @Input() ProfissionalModalId: any;
  @ViewChild('selectorAlert') selectorAlert;
  @ViewChild('toScroll') content: IonContent;
  @ViewChild(IonSegment, { static: false }) segment: IonSegment;
  view: CalendarView = CalendarView.Week;

  private mutationObserver: MutationObserver;
  // @ViewChild('EventsContainer', { static: false }) container: IonContent;

  // events$: Observable<CalendarEvent[]>;
  events$: Observable<any[]>;
  negocio$ = new Subject<any>();
  subs = new SubSink();
  perfil_negocio$ = new Subject<any>();
  countAgendamentos$ = new BehaviorSubject<any>(0);
  selectedDateTime?:Date;

  scrollToTop() {
    this.content?.scrollToTop(1000);

  }

  hourStepChange: number = 2;


  //--------------------------------------------------------------------------------------------------------------------SEARCHBAR E DEMAIS

  componentName = 'agendaSlider';
  searchTerm: any;
  property = '';

  Object_refinarBusca = [
    {
      key: 'pet',
      value: 'Paciente',
    },

    {
      key: 'tutor',
      value: 'Tutor',
    },

    {
      key: 'profissional',
      value: 'Profissional',
    },
  ];

  selectedBuzzIndex: any;
  dragToSelectEvent: CalendarEvent;
  tutors: ITutor[];
  tutorId: any;
  colsConfig: string;
  arrayEvents: any = [];
  userPersonalData: any;
  perfil_negocio: any;
  TodayEvents = [];
  filtroEspecialidade: any;
  filtroProfissional: any;
  filtroHorario: any;
  filtroAtivo = false;
  filtroEspProfHor_array: CalendarEvent<any>[];
  especialidades_cadastradas = [];
  listaprofissionals = [];
  isOpen = false;
  dynamicPlaceholder: string = 'Selecione um filtro no botão de ajustes';
  filterSelectorColor: string = 'medium';
  statusLIst = [];
  filtroStatus: any;
  TutorIdModal: any;
  eventsToWeekCalendar: CalendarEvent<any>[];
  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  especialidadesPerfil:any = [];
  speciality_select:string = '';
  profissionaisList:any = [];
  profissional_select:number = null;
  specialityFilterActive:Boolean = false;
  profissionalName:string = '';
  profissionalId:string = '';
  interval:any;
  atendenceDays:any = [];
  hourSegment:string = '1';
  hourDuration:string = '30';
  tempoMinDuracao:number = null;
  hourSegmentHeight:string = '85';
  bloqueiosProfissional:any = [];
  janelasProfissional:any = [];
  tempoAvisoMin:any;
  selectedProfissionalInitialHour:any;
  selectedProfissionalEndHour:any;

  presentPopover(e: Event) {
    if (this.property?.length < 1) {
      this.selectorAlert.event = e;
      this.isOpen = true;
      this.searchTerm = '';
    }
  }

  refinarBusca(filtro: string) {
    this.property = filtro;
    console.log(filtro);

    this.selectedTabs = 'agenda-hoje';

    if (this.property?.length > 1) {
      this.filterSelectorColor = 'medium';
    }

    switch (filtro) {
      case 'tutor':
        this.dynamicPlaceholder = 'Buscar por nome do Tutor';
        break;
      case 'pet':
        this.dynamicPlaceholder = 'Buscar por nome do Pet';
        break;
      case 'profissional':
        this.dynamicPlaceholder = 'Buscar por nome do Profissional';
        break;
    }
  }

  orderTitle: any;
  order: any;
  faSlider = faSliders;

  onOrderClick(arg0: string, arg1: string) {
    throw new Error('Method not implemented.');
  }
  situacaoTitle: any;
  situacao: any;
  onSituacaoClick(arg0: string) {
    console.log(arg0);
    return (this.filtroStatus = arg0);
  }
  dataInativoDesde: string | number | Date;

  ///----------------------------------------------------------------------------------------------------------------------------

  event: CalendarEvent;
  locale: string = 'pt-PT';
  selectedTabs: string;
  // view: CalendarView = CalendarView.Month;

  // viewDate: Date = new Date();

  @Input() day: MonthViewDay;

  @Input() openDay: MonthViewDay;

  @Output() eventClicked = new EventEmitter<{
    event: CalendarEvent;
    sourceEvent: MouseEvent;
  }>();

  @Input() CardTemplate: TemplateRef<any>;

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;


  @Directive({
    selector: '[colsize]',
  })
  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        console.log('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        console.log('Deleted', event);
      },
    },
  ];

  refresh = new Subject<void>();
  private eventsSubject = new BehaviorSubject<CalendarEvent[]>([]);
  public eventsToWeekCalendar$: Observable<CalendarEvent[]>;

  dragToCreateActive = false;
  weekStartsOn: 0 = 0;

  //--------------------- Events é um array de objetos a serem exibidos no calendário via ng-template------------------------------------------

  dataHoje = '';

  events: CalendarEvent[];
  //= this.utilsService.events;
  refreshEvents$ = new BehaviorSubject(true);

  selectedProfissional$: Observable<any[]>;

  //------------------------------------------------------------------------------------------------------------------------------------------

  activeDayIsOpen: boolean = true;
  // horaInicial_draged: Time;
  // horaFinal_draged: Time;
  horaInicial_draged: Date;
  horaFinal_draged: Date;
  dataInicial_draged: Date;
  NovoAgendamento_ToEvents: any;
  Novo_AgendamentosObject_fromModal: any;
  dataGendamento: any;

  eventsLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(
    public customEventTitleFormatter: CustomEventTitleFormatter,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private cdr: ChangeDetectorRef,
    private tutorService: TutorService,
    private agendaService: AgendaService,
    public profissionaisService: ProfissionaisService,
    public utilsService: UtilsService,
    private router: ActivatedRoute,
    private userdataService: UserdataService,
    private popoverController: PopoverController,
    private renderer: Renderer2,
    private sharedService: SharedService
  ) { }

  ionViewWillEnter() {
    console.log('WILL ENTER');
    console.log('CURRENT USER',this.currentUser);
    
  }

  ionViewDidEnter() { }

  ionViewDidLeave() {
    console.log('didleave');
  }

  ionViewWillLeave() {
    // this.tutorId = this.route.snapshot.paramMap.get('id');
  }

  ngAfterViewInit() {
    console.log('afterviewinit');
    
  }

  resizeEventDetails() {
    const resizeLogic = () => {
      const hourSegmentWidth = document?.getElementsByClassName('cal-hour-segment')[42]?.clientWidth;
      var eventDIV = document?.getElementsByClassName('event-details-class');
      // console.log('div?.clientWidth <= 200', eventDIV, hourSegmentWidth, (hourSegmentWidth * 0.2));
      Array?.from(eventDIV)?.map((div: HTMLElement) => {
        if (div?.clientWidth <= (hourSegmentWidth * 0.2) || div?.clientWidth <= 69) {
          div.className = div?.className?.replace('event-details-class', 'event-Smalldetails-class');
          this.cdr.detectChanges();
        } if (div?.clientWidth > (hourSegmentWidth * 0.2)) {
          div.className = div?.className?.replace('event-Smalldetails-class', 'event-details-class');
          this.cdr.detectChanges();
        }
      });
    };

    // Executa a lógica imediatamente e a cada 1 minuto (60000 ms)
    resizeLogic();
    setInterval(resizeLogic, 2000);
  }




  pinFormatter(value: number) {

    // console.log(value)

    if (value === 2) {
      return '30"';
    } else if (value === 3) {
      return '15"';
    } else if (value === 1) {
      return "1'";
    }

  }

  changeHourStep($event) {

    if (typeof ($event?.detail?.value) != 'undefined') {
      console.log($event?.detail?.value, $event?.detail?.value == 3)
      if ($event?.detail?.value == 3) {
        this.hourStepChange = 4
        this.resizeEventDetails()

      } else {
        this.hourStepChange = $event?.detail?.value
        this.resizeEventDetails()
      }

      return this.cdr.detectChanges();
    }
  }


  ngOnInit() {

    this.profissionalId = this.route.snapshot.paramMap.get('id');
    console.log('PROFISSIONAL ID', this.profissionalId)

    this.sharedService.currentVariable.subscribe(value => {      
      this.clearSpecialityFilter();
      this.eventsLoaded$.next(true);
    });

    console.log(this.TodayEvents?.length);
    this.selectedBuzzIndex = localStorage.getItem('buzz_index') || '0';    

    this.ProfissionalModalId = this.ProfissionalModalId || null;
    console.log('entering', this.ProfissionalModalId);

    //this.filtroEspProfHor_array = []

    let hoje = new Date()
      .toLocaleDateString('pt-BR')
      .substring(0, 10)
      .replace(/\//g, '-');
    const parts = hoje.split('-');
    this.dataHoje = parts[2] + '-' + parts[1] + '-' + parts[0];
    console.log(this.dataHoje);

    this.dataHoje;
    // this.especialidades_cadastradas = this.utilsService.listaEspecialidades; // usado no filtro
    this.selectedTabs = 'agenda-hoje';
    this.tutorId = this.router.snapshot.paramMap.get('id');
    if (this.tutorId) {
      this.colsConfig = '12';
    } else {
      this.colsConfig = '9';
    }

    const a = this.userdataService.getUserPersonalData().
      subscribe((result) => {
        this.userPersonalData = result;
        console.log(this.userPersonalData?.perfil_negocios);        
        this.perfil_negocio = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.id;
        console.log(this.perfil_negocio)    

        this.subs.add(
          this.agendaService
            .getAgendamentosByNegocio(this.perfil_negocio)
            .subscribe(
              (data) => {
                console.log(data);
                data?.data.map((result) => {
                  if (result?.attributes?.especialidade) {
                    this.especialidades_cadastradas?.push(
                      result?.attributes?.especialidade
                    );
                    this.statusLIst?.push(result?.attributes?.status);
                    // console.log(this.statusLIst)
                    this.listaprofissionals?.push(
                      result?.attributes?.profissional?.data?.attributes
                        ?.dados_pessoais?.nome
                    );
                  }
                });
              },
              (error) => {
                console.log(error);
              }
            )
        );


      })


    // if (this.ParentComponent == 'AgendaGeral') {
    //   let color_class;
    //   this.events$ = this.refreshEvents$.pipe(
    //     switchMap((_) => {

    //       this.userdataService.getUserPersonalData().
    //         subscribe((result) => {
    //           this.userPersonalData = result;
    //           console.log(this.userPersonalData?.perfil_negocios)
    //           //console.log para ver qunto tempo demora para carregar os dados
    //           console.log('teste')
    //           this.selectedBuzzIndex = localStorage.getItem('buzz_index') || 0;
    //           this.perfil_negocio = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.id;
    //           console.log(this.selectedBuzzIndex, this.perfil_negocio)


    //           this.agendaService
    //             .getAgendamentosDoMesByNegocio(this.perfil_negocio)
    //             .subscribe((result) => {
    //               // console.log(result.data);
    //               this.arrayEvents = result?.data?.map((dados) => {
    //                 const {
    //                   data,
    //                   horario_inicial,
    //                   horario_final,
    //                   pet,
    //                   profissional,
    //                   tutors,
    //                 } = dados?.attributes;
    //                 // console.log(parse(data, 'yyyy-MM-dd', new Date()), data)
    //                 // console.table(dados?.attributes);
    //                 const [horaInicial, minutesInicial] =
    //                   this.utilsService.extractHoursElements_Array(
    //                     horario_inicial
    //                   );
    //                 const [horaFinal, minutesFinal] =
    //                   this.utilsService.extractHoursElements_Array(horario_final);
    //                 const petObject = pet?.data;
    //                 // console.log(petObject);
    //                 const petDateOfBirth = petObject?.attributes?.data_nascimento;
    //                 const petAge = petDateOfBirth
    //                   ? Math.floor(
    //                     Math.floor(
    //                       (new Date().getTime() -
    //                         new Date(petDateOfBirth).getTime()) /
    //                       1000 /
    //                       60 /
    //                       60 /
    //                       24 /
    //                       365
    //                     )
    //                   )
    //                   : '';

    //                 let status = dados?.attributes?.status;

    //                 switch (status) {
    //                   case 'confirmado':
    //                     color_class = 'confirmado';
    //                     break;
    //                   case 'agendado':
    //                     color_class = 'agendado';
    //                     break;
    //                   case 'aguardandoAtendimento':
    //                     color_class = 'completo';
    //                     break;
    //                   case 'Ncompareceu':
    //                     color_class = 'noshow';
    //                     break;
    //                   case 'pacienteAtendido':
    //                     color_class = 'atendido';
    //                     break;
    //                   case 'pacientAtrasado':
    //                     color_class = 'atrasado';
    //                     break;
    //                   case 'cancelado':
    //                     color_class = 'cancelado';
    //                     break;
    //                 }

    //                 return {
    //                   color: { ...colors.yellow },
    //                   actions: this.actions,
    //                   cssClass1: `${color_class}`,
    //                   cssClass: `${color_class}`,
    //                   draggable: false,
    //                   start: addMinutes(
    //                     addHours(
    //                       startOfDay(parse(data, 'yyyy-MM-dd', new Date())),
    //                       horaInicial
    //                     ),
    //                     minutesInicial
    //                   ),
    //                   end: addMinutes(
    //                     addHours(
    //                       startOfDay(parse(data, 'yyyy-MM-dd', new Date())),
    //                       horaFinal
    //                     ),
    //                     minutesFinal
    //                   ),
    //                   title: 'agendamento',
    //                   resizable: { beforeStart: false, afterEnd: false },
    //                   meta: {
    //                     idAgendamento: dados?.id,
    //                     tutorObject: tutors?.data[0],
    //                     tutor: tutors?.data[0]?.attributes?.dados_pessoais?.nome,
    //                     profissional:
    //                       profissional?.data?.attributes?.dados_pessoais?.nome,
    //                     profissionalID: profissional?.data?.id,
    //                     email: tutors?.data[0]?.attributes?.dados_pessoais?.email,
    //                     celular:
    //                       tutors?.data[0]?.attributes?.dados_pessoais?.celular,

    //                     especialidade_procedimentoSelected:
    //                       profissional?.data?.attributes?.especialidades_procedimentos?.filter(
    //                         (result) =>
    //                           result?.nome === dados?.attributes?.especialidade
    //                       ),
    //                     canalAgendamento: dados?.attributes?.canal_agendamento,
    //                     id_usuario_agendamento:
    //                       dados?.attributes?.id_usuario_agendamento,
    //                     tipo_atendimento: dados?.attributes?.tipo_atendimento,
    //                     tipo_agendamento: dados?.attributes?.tipo_agendamento,
    //                     agendadoPor: dados?.attributes?.agendadoPor,
    //                     especialidade: dados?.attributes?.especialidade,
    //                     faltas: dados?.attributes?.faltas,
    //                     statusAgendamento: dados?.attributes?.status,
    //                     dataMaximaRetorno: dados?.attributes?.dataMaximaRetorno,
    //                     dataMaximaDesconto: dados?.attributes?.dataMaximaDesconto,
    //                     valorDesconto: dados?.attributes?.valorDesconto,

    //                     createdAt: new Date(dados?.attributes?.publishedAt),
    //                     updatedAt: new Date(dados?.attributes?.updatedAt),

    //                     pet: petObject?.attributes?.nome,
    //                     petId: petObject?.id,
    //                     petAvatar:
    //                       petObject?.attributes?.avatar?.data?.attributes?.url,
    //                     especie: petObject?.attributes?.especie,
    //                     idade: petAge,
    //                     peso: petObject?.attributes?.peso,
    //                     ultima_consulta: '05/12/2022',
    //                     data: data,
    //                     dataString: data,
    //                   },
    //                 };
    //               });
    //               this.events = this.arrayEvents;

    //               this.TodayEvents = this.arrayEvents.filter((events) => {
    //                 // console.log(events.meta?.data, this.dataHoje, events.meta?.data === this.dataHoje)
    //                 return events.meta?.data === this.dataHoje;
    //               });

    //               // console.log(this.events);
    //               this.eventsToWeekCalendar = this.events;
    //               // this.eventsToWeekCalendar$ = this.eventsSubject.asObservable();
    //               this.eventsLoaded$.next(true); // indica que os eventos foram carregados

    //               this.eventsSubject.next(this.events);

    //               return this.events;
    //             },

    //             )


    //         })
    //       return of(this.events)
    //     }))

    //   setTimeout(() => {
    //     this.refreshEvents$.next(true);
    //   }
    //     , 1000);

    // }
    // else if (this.ParentComponent == 'AgendaProfissional') {
    //   let selectedProfissional_Id_String =
    //     this.route.snapshot.paramMap.get('id') || this.ProfissionalModalId;
    //   console.log(selectedProfissional_Id_String);

    //   let unity = sessionStorage.getItem('unity_id');

    //   let color_class;

    //   this.events$ = this.refreshEvents$.pipe(
    //     switchMap((_) => {
    //       this.userdataService.getUserPersonalData().
    //         subscribe((result) => {
    //           this.userPersonalData = result;
    //           console.log(this.userPersonalData?.perfil_negocios)
    //           this.selectedBuzzIndex = localStorage.getItem('buzz_index') || 0;
    //           this.perfil_negocio = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.id;
    //           console.log(this.selectedBuzzIndex, this.perfil_negocio)
    //           this.agendaService
    //             .getAgendamentosDoMesByProfissionalNegocio(this.perfil_negocio,
    //               selectedProfissional_Id_String)
    //             .subscribe(
    //               (result) => {
    //                 // console.log(result.data);
    //                 this.arrayEvents = result?.data?.map((dados) => {
    //                   const {
    //                     data,
    //                     horario_inicial,
    //                     horario_final,
    //                     pet,
    //                     profissional,
    //                     tutors,
    //                   } = dados?.attributes;
    //                   // console.log(parse(data, 'yyyy-MM-dd', new Date()), data)
    //                   // console.table(dados?.attributes);
    //                   const [horaInicial, minutesInicial] =
    //                     this.utilsService.extractHoursElements_Array(
    //                       horario_inicial
    //                     );
    //                   const [horaFinal, minutesFinal] =
    //                     this.utilsService.extractHoursElements_Array(horario_final);
    //                   const petObject = pet?.data;
    //                   // console.log(petObject);
    //                   const petDateOfBirth = petObject?.attributes?.data_nascimento;
    //                   const petAge = petDateOfBirth
    //                     ? Math.floor(
    //                       Math.floor(
    //                         (new Date().getTime() -
    //                           new Date(petDateOfBirth).getTime()) /
    //                         1000 /
    //                         60 /
    //                         60 /
    //                         24 /
    //                         365
    //                       )
    //                     )
    //                     : '';

    //                   let status = dados?.attributes?.status;

    //                   switch (status) {
    //                     case 'confirmado':
    //                       color_class = 'confirmado';
    //                       break;
    //                     case 'agendado':
    //                       color_class = 'agendado';
    //                       break;
    //                     case 'aguardandoAtendimento':
    //                       color_class = 'completo';
    //                       break;
    //                     case 'Ncompareceu':
    //                       color_class = 'noshow';
    //                       break;
    //                     case 'pacienteAtendido':
    //                       color_class = 'atendido';
    //                       break;
    //                     case 'pacientAtrasado':
    //                       color_class = 'atrasado';
    //                       break;
    //                     case 'cancelado':
    //                       color_class = 'cancelado';
    //                       break;
    //                   }

    //                   return {
    //                     color: { ...colors.yellow },
    //                     actions: this.actions,
    //                     cssClass1: `${color_class}`,
    //                     cssClass: `${color_class}`,
    //                     draggable: false,
    //                     start: addMinutes(
    //                       addHours(
    //                         startOfDay(parse(data, 'yyyy-MM-dd', new Date())),
    //                         horaInicial
    //                       ),
    //                       minutesInicial
    //                     ),
    //                     end: addMinutes(
    //                       addHours(
    //                         startOfDay(parse(data, 'yyyy-MM-dd', new Date())),
    //                         horaFinal
    //                       ),
    //                       minutesFinal
    //                     ),
    //                     title: 'agendamento',
    //                     resizable: { beforeStart: false, afterEnd: false },
    //                     meta: {
    //                       idAgendamento: dados?.id,
    //                       tutorObject: tutors?.data[0],
    //                       tutor: tutors?.data[0]?.attributes?.dados_pessoais?.nome,
    //                       profissional:
    //                         profissional?.data?.attributes?.dados_pessoais?.nome,
    //                       profissionalID: profissional?.data?.id,
    //                       email: tutors?.data[0]?.attributes?.dados_pessoais?.email,
    //                       celular:
    //                         tutors?.data[0]?.attributes?.dados_pessoais?.celular,

    //                       especialidade_procedimentoSelected:
    //                         profissional?.data?.attributes?.especialidades_procedimentos?.filter(
    //                           (result) =>
    //                             result?.nome === dados?.attributes?.especialidade
    //                         ),
    //                       canalAgendamento: dados?.attributes?.canal_agendamento,
    //                       id_usuario_agendamento:
    //                         dados?.attributes?.id_usuario_agendamento,
    //                       tipo_atendimento: dados?.attributes?.tipo_atendimento,
    //                       tipo_agendamento: dados?.attributes?.tipo_agendamento,
    //                       agendadoPor: dados?.attributes?.agendadoPor,
    //                       especialidade: dados?.attributes?.especialidade,
    //                       faltas: dados?.attributes?.faltas,
    //                       statusAgendamento: dados?.attributes?.status,
    //                       dataMaximaRetorno: dados?.attributes?.dataMaximaRetorno,
    //                       dataMaximaDesconto: dados?.attributes?.dataMaximaDesconto,
    //                       valorDesconto: dados?.attributes?.valorDesconto,

    //                       createdAt: new Date(dados?.attributes?.publishedAt),
    //                       updatedAt: new Date(dados?.attributes?.updatedAt),

    //                       pet: petObject?.attributes?.nome,
    //                       petId: petObject?.id,
    //                       petAvatar:
    //                         petObject?.attributes?.avatar?.data?.attributes?.url,
    //                       especie: petObject?.attributes?.especie,
    //                       idade: petAge,
    //                       peso: petObject?.attributes?.peso,
    //                       ultima_consulta: '05/12/2022',
    //                       data: data,
    //                       dataString: data,
    //                     },
    //                   };
    //                 });
    //                 this.events = this.arrayEvents;

    //                 this.TodayEvents = this.arrayEvents.filter((events) => {
    //                   // console.log(events.meta?.data, this.dataHoje, events.meta?.data === this.dataHoje)
    //                   return events.meta?.data === this.dataHoje;
    //                 });

    //                 // console.log(this.events);
    //                 this.eventsToWeekCalendar = this.events;
    //                 // this.eventsToWeekCalendar$ = this.eventsSubject.asObservable();
    //                 this.eventsSubject.next(this.events);
    //                 this.eventsLoaded$.next(true);
    //                 return this.events;
    //               },

    //             )


    //         })
    //       // indica que os eventos foram carregados
    //       return of(this.events)
    //     }))


    // } 



    // Main code
    if (this.ParentComponent == 'AgendaGeral') {
      
      this.events$ = this.refreshEvents$.pipe(
        switchMap((_) => {
          this.userdataService.getUserPersonalData().subscribe((result) => {
            this.handleUserData(result);
            this.agendaService.getAgendamentosDoMesByNegocio(this.perfil_negocio).subscribe((result) => {
              this.mapEventArray(result);             
              
              console.log('TOTAL AGENDAMENTOS', this.countAgendamentos$)

            });
          });
          return of(this.events) // indica que os eventos foram carregados
        }));

      if(!this.specialityFilterActive){
        setTimeout(() => {
          this.refreshEvents$.next(true);
        }, 1000);
      }
      
    }
    else if (this.ParentComponent == 'AgendaProfissional') {
      let selectedProfissional_Id_String = this.route.snapshot.paramMap.get('id') || this.ProfissionalModalId || localStorage.getItem('_pId');
      let unity = sessionStorage.getItem('unity_id');

      this.events$ = this.refreshEvents$.pipe(
        switchMap((_) => {
          this.userdataService.getUserPersonalData().subscribe((result) => {
            this.handleUserData(result);
            this.agendaService.getAgendamentosDoMesByProfissionalNegocio(this.perfil_negocio, selectedProfissional_Id_String).subscribe((result) => {
              this.mapEventArray(result);
              this.profissionaisList = [result?.data[0]?.attributes?.profissional?.data];
              this.profissional_select = result?.data[0]?.attributes?.profissional?.data?.id;
              this.getProfissionalName(this.profissional_select)    
            });
          });
          return of(this.events)
        }));
    }

    this.checkPresentAgendamentosCount();


    this.interval = setInterval(run=>{
      this.checkLastestAgendamentosCount();
      this.refreshEvents$.next(true);
    },150000)
  }

  //Esta função deverá ser chamada na inicialização dom componente para checar a quantidade de agendamentos em determinado período

  checkPresentAgendamentosCount(){
    let checkCount$ = this.agendaService.checkPresentAgendamentosCount(this.perfil_negocio).subscribe({
      next:(result)=>{

        let presentCount = result.meta.pagination.total;

        console.log('CONTAGEM ATUAL', presentCount)
        
        this.countAgendamentos$.next(presentCount);
        
      },
      error:(e)=>{},
      complete:()=>{checkCount$.unsubscribe()}
    })
  }

  //Esta função será chamada a cada 5 minutos para verificar a existência de novos agendamentos, sendo assim recarrega os agendamentos

  checkLastestAgendamentosCount(){

    if(this.profissional_select){
      return
    }

    let checkCount$ = this.agendaService.checkPresentAgendamentosCount(this.perfil_negocio).subscribe({
      next:(result)=>{

        let presentCount = result.meta.pagination.total;
        let lastCount

        let lastCountCall = this.countAgendamentos$.subscribe({
          next:(result)=>{lastCount = result},
          error:(e)=>{},
          complete:()=>{lastCountCall.unsubscribe()}
        });

        console.log('LAST COUNT',presentCount);
        console.log('PRESENT COUNT', lastCount);
        
        if(presentCount > lastCount && !this.specialityFilterActive){

          this.events$ = this.refreshEvents$.pipe(
            switchMap((_) => {
              this.userdataService.getUserPersonalData().subscribe((result) => {
                this.handleUserData(result);
                this.agendaService.getAgendamentosDoMesByNegocio(this.perfil_negocio).subscribe((result) => {
                  this.mapEventArray(result);
                  
                  this.checkPresentAgendamentosCount();                                
    
                });
              });
              return of(this.events) // indica que os eventos foram carregados
            }));
    
          setTimeout(() => {
            this.refreshEvents$.next(true);
          }, 1000);

        }
        
      },
      error:(e)=>{},
      complete:()=>{checkCount$.unsubscribe()}
    })
  }


  getProfissionaisByEspeciality(speciality:string){

    this.profissionaisList = [];

    console.log('PERFIL NEGOCIO', this.perfil_negocio);

    let unity_id = sessionStorage.getItem('unity_id');

      this.agendaService.getProfissionaisToFilterBySpeciality(this.perfil_negocio, speciality).subscribe({

        next:(result)=>{          
          for(let profissional of result.data){
            for(let janela of profissional.attributes.programacao.janela_disponibilidade){

              if(janela.especialidades.includes(speciality) && janela.perfil_negocio === unity_id){
                this.profissionaisList.push(profissional)
              }

            }
          }
        },
        error:(e)=>{},
        complete:()=>{}

      })

  }

  getProfissionalName(pid:number){

    let profissional = this.profissionaisList.filter((profissional)=>profissional.id == pid)

    console.log('PROFISSIONAL SELECIONADO', profissional)

    this.profissionalName  = profissional[0]?.attributes?.dados_pessoais?.nome
    this.atendenceDays = this.obterDiasUnicos(profissional[0]?.attributes?.programacao?.janela_disponibilidade);
    this.tempoAvisoMin = profissional[0]?.attributes?.programacao?.tempo_minimo_aviso;
    this.tempoMinDuracao = this.encontrarMenorDuracaoAtendimento(profissional);    
    this.hourDuration = this.tempoMinDuracao.toString();
    this.bloqueiosProfissional = profissional[0]?.attributes?.programacao?.bloquear_agenda;
    this.janelasProfissional = profissional[0]?.attributes?.programacao?.janela_disponibilidade;
    this.janelasProfissional = this.janelasProfissional.filter((janela)=>janela.perfil_negocio === this.perfil_negocio.toString());

    let janelaPorEspecialidade = this.janelasProfissional.filter((janela)=>janela.especialidades.includes(this.speciality_select))

    this.selectedProfissionalInitialHour = new Date(janelaPorEspecialidade[0]?.horario_inicial)?.getHours();
    this.selectedProfissionalEndHour = addMinutes(new Date(janelaPorEspecialidade[0]?.horario_final), - this.tempoMinDuracao).getHours();

    console.log('JANELAS PROFISSIONAL', this.janelasProfissional)
    
    console.log('HORA INICIAL', this.selectedProfissionalInitialHour);
    console.log('HORA FINAL', this.selectedProfissionalEndHour);

    if(this.tempoMinDuracao <= 30){
      this.hourSegmentHeight = '55'
    }
    else if(this.tempoMinDuracao > 30 && this.tempoMinDuracao >= 60){      
      this.hourSegmentHeight = '65'
    }
    else if(this.tempoMinDuracao > 60){
      this.hourSegmentHeight = '85'
    }
    

  }

  changeDayView(){
    this.selectedTabs = 'agenda-dia';
  }
  selectSameView(tab:string){
    this.selectedTabs = 'agenda-hoje'
    setTimeout(()=>{
      this.selectedTabs = tab;
    },1000)
    
  }

  handleAgendamentosFilter(pid:number){

    if(!pid){
      return
    }

    this.events = this.events.filter((event)=>
      event?.meta?.profissionalID == pid
    );

    this.eventsToWeekCalendar = this.events;
    this.eventsSubject.next(this.events);
    this.eventsLoaded$.next(true);
    this.refreshEvents$.next(true);

    this.specialityFilterActive = true;

    clearInterval(this.interval);

    //this.selectedTabs = 'agenda-mes'

    console.log('EVENTOS FILTRADOS', this.events)

    //this.refreshEvents$.next(true);

  }

  clearSpecialityFilter(){
    this.profissionalName = '';
    this.atendenceDays = [];
    this.selectedDateTime = null;
    this.speciality_select = null;
    this.selectedProfissionalEndHour = null;
    this.selectedProfissionalInitialHour = null;
    this.tempoAvisoMin = null;
    this.profissional_select = null;
    this.bloqueiosProfissional = [];
    this.janelasProfissional = [];
    this.specialityFilterActive = false;
    this.refreshEvents$.next(true);
    this.checkPresentAgendamentosCount();
    this.hourSegmentHeight = '85';
    this.hourDuration = '60';
    this.interval = setInterval(run=>{      
      this.checkLastestAgendamentosCount();
      this.refreshEvents$.next(true);
    },150000)

  }

  handleUserData(result) {
    this.userPersonalData = result;
    this.selectedBuzzIndex = localStorage.getItem('buzz_index') || 0;
    this.perfil_negocio = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.id;
    this.getSpecialitiesByProfile();
    //this.especialidadesPerfil = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.especialidades_perfil_negocio
  }

  mapEventArray(result) {
    this.arrayEvents = result?.data?.map((dados) => this.mapEventData(dados));
    this.events = this.arrayEvents;
    this.TodayEvents = this.arrayEvents.filter((events) => events.meta?.data === this.dataHoje);
    this.eventsToWeekCalendar = this.events;
    if(this.profissional_select){
      this.events = this.events.filter((event)=>
        event?.meta?.profissionalID == this.profissional_select
      );
      this.eventsToWeekCalendar = this.events.filter((event)=>
        event?.meta?.profissionalID == this.profissional_select
      );
    }
    this.eventsSubject.next(this.events);
    this.eventsLoaded$.next(true);
    console.log('AGENDAMENTOS 995',this.events)
  }

  mapEventData(dados) {
    const {
      data,
      horario_inicial,
      horario_final,
      pet,
      profissional,
      tutors,
    } = dados?.attributes;
    const [horaInicial, minutesInicial] = this.utilsService.extractHoursElements_Array(horario_inicial);
    const [horaFinal, minutesFinal] = this.utilsService.extractHoursElements_Array(horario_final);
    const petObject = pet?.data;
    const petAge = this.getPetAge(petObject?.attributes?.data_nascimento);
    const color_class = this.getColorClass(dados?.attributes?.status);

    return {
      color: { ...colors.yellow },
      actions: this.actions,
      cssClass1: `${color_class}`,
      cssClass: `${color_class}`,
      draggable: false,
      start: addMinutes(
        addHours(
          startOfDay(parse(data, 'yyyy-MM-dd', new Date())),
          horaInicial
        ),
        minutesInicial
      ),
      end: addMinutes(
        addHours(
          startOfDay(parse(data, 'yyyy-MM-dd', new Date())),
          horaFinal
        ),
        minutesFinal
      ),
      title: 'agendamento',
      resizable: { beforeStart: false, afterEnd: false },
      meta: {
        idAgendamento: dados?.id,
        tutorObject: tutors?.data[0],
        tutor: tutors?.data[0]?.attributes?.dados_pessoais?.nome,
        profissional:
          profissional?.data?.attributes?.dados_pessoais?.nome,
        profissionalID: profissional?.data?.id,
        email: tutors?.data[0]?.attributes?.dados_pessoais?.email,
        celular:
          tutors?.data[0]?.attributes?.dados_pessoais?.celular,

        especialidade_procedimentoSelected:
          profissional?.data?.attributes?.especialidades_procedimentos?.filter(
            (result) =>
              result?.nome === dados?.attributes?.especialidade
          ),
        canalAgendamento: dados?.attributes?.canal_agendamento,
        id_usuario_agendamento: dados?.attributes?.id_usuario_agendamento,
        tipo_atendimento: dados?.attributes?.tipo_atendimento,
        tipo_agendamento: dados?.attributes?.tipo_agendamento,
        agendadoPor: dados?.attributes?.agendadoPor,
        especialidade: dados?.attributes?.especialidade,
        faltas: dados?.attributes?.faltas,
        statusAgendamento: dados?.attributes?.status,
        dataMaximaRetorno: dados?.attributes?.dataMaximaRetorno,
        dataMaximaDesconto: dados?.attributes?.dataMaximaDesconto,
        valorDesconto: dados?.attributes?.valorDesconto,

        createdAt: new Date(dados?.attributes?.publishedAt),
        updatedAt: new Date(dados?.attributes?.updatedAt),

        pet: petObject?.attributes?.nome,
        petId: petObject?.id,
        petAvatar:
          petObject?.attributes?.avatar?.data?.attributes?.url,
        especie: petObject?.attributes?.especie,
        idade: petAge,
        peso: petObject?.attributes?.peso,
        ultima_consulta: '05/12/2022',
        data: data,
        dataString: data,
      },
    }

  }


  getPetAge = (petDateOfBirth) => {
    if (!petDateOfBirth) {
      return '';
    }

    const age = Math.floor(
      (new Date().getTime() - new Date(petDateOfBirth).getTime()) /
      (1000 * 60 * 60 * 24 * 365)
    );

    return age;
  };

  getColorClass = (status) => {
    let color_class;
    switch (status) {
      case 'confirmado':
        color_class = 'confirmado';
        break;
      case 'agendado':
        color_class = 'agendado';
        break;
      case 'aguardandoAtendimento':
        color_class = 'completo';
        break;
      case 'emAtendimento':
        color_class = 'emAtendimento';
        break;
      case 'Ncompareceu':
        color_class = 'noshow';
        break;
      case 'pacienteAtendido':
        color_class = 'atendido';
        break;
      case 'pacientAtrasado':
        color_class = 'atrasado';
        break;
      case 'cancelado':
        color_class = 'cancelado';
        break;
      default:
        color_class = '';
    }
    return color_class;
  };


  ngOnDestroy(): void {
    console.log('agenda destroyed');

  }

  changeTab() {
    // create a decorator factory that will receive selectedTabs and will verify if it is equal to 'agenda-hoje'
    this.selectedTabs = 'agenda-hoje';

  }

  getUserData() {
    this.subs.add(
      this.userdataService.getUserPersonalData().subscribe(
        (result) => {
          this.userPersonalData = result;
          this.perfil_negocio = this.userPersonalData?.perfil_negocios?.[this.selectedBuzzIndex]?.id;
          console.log(this.perfil_negocio);
        },
        (error) => {
          console.error(error);
          this.utilsService.errorAlert(
            'Erro ao obter dados pessoais do usuário. Por favor, tente novamente mais tarde.'
          );
        }
      )
    );
  }

  teste(event: any) {
    console.log(event);
  }

  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------Criar Evento ao Arrastar
  // startDragToCreate(
  //   segment: WeekViewHourSegment,
  //   mouseDownEvent: MouseEvent,
  //   segmentElement: HTMLElement
  // ) {
  //   this.dragToSelectEvent = {
  //     id: this.events.length,
  //     title: 'New event',
  //     start: segment.date || new Date(),
  //     color: colors.red,
  //     actions: this.actions,
  //     resizable: {
  //       beforeStart: true, // this allows you to configure the sides the event is resizable from
  //       afterEnd: true,
  //     },
  //     draggable: true,
  //     meta: {
  //       tmpEvent: true,
  //       tutor: 'Tutor',
  //       profissional: 'Profissional',
  //       pet: 'Pet',
  //     },
  //   };

  //   this.events = [...this.events, this.dragToSelectEvent];

  //   const segmentPosition = segmentElement.getBoundingClientRect();
  //   this.dragToCreateActive = true;
  //   const endOfView = endOfWeek(this.viewDate, {
  //     weekStartsOn: this.weekStartsOn,
  //   });

  //   fromEvent(document, 'mousemove')
  //     .pipe(
  //       finalize(() => {
  //         delete this.dragToSelectEvent.meta.tmpEvent;
  //         this.dragToCreateActive = false;
  //         this.refreshResizableCreator();
  //       }),
  //       takeUntil(fromEvent(document, 'mouseup'))
  //     )
  //     .subscribe((mouseMoveEvent: MouseEvent) => {
  //       const minutesDiff = ceilToNearest(
  //         mouseMoveEvent.clientY - segmentPosition.top,
  //         30
  //       );

  //       const daysDiff =
  //         floorToNearest(
  //           mouseMoveEvent.clientX - segmentPosition.left,
  //           segmentPosition.width
  //         ) / segmentPosition.width;

  //       const newEnd = addDays(addMinutes(segment.date, minutesDiff), daysDiff);
  //       if (newEnd > segment.date && newEnd < endOfView) {
  //         this.dragToSelectEvent.end = newEnd;
  //       }

  //       this.refreshResizableCreator();
  //     })
  //     .add(
  //       // apos terminar de arrastrar o mouse abre um modal para conclusaão do agendamento
  //       (data: any) => {
  //         this.dataInicial_draged = this.dragToSelectEvent.start;
  //         this.horaInicial_draged = this.dragToSelectEvent.start;
  //         this.horaFinal_draged = this.dragToSelectEvent.end;
  //         console.log(data,this.dragToSelectEvent);
  //         // this.horaInicial_draged =  {
  //         //   hours: dragToSelectEvent.start.getHours(),
  //         //   minutes: dragToSelectEvent.start.getMinutes()
  //         // };
  //         // this.horaFinal_draged =  {
  //         //   hours: dragToSelectEvent.end.getHours(),
  //         //   minutes: dragToSelectEvent.end.getMinutes()
  //         // };

  //         // return this.onIncluirAgendamento_DragEvent(); /// Chama o modal INcluir Agendamento com as datas preenchidas
  //       }
  //     );
  // }

  private refreshResizableCreator() {
    this.events = [...this.events];
    console.log(this.events);
    this.cdr.detectChanges();
    this.refresh.next();
    this.resizeEventDetails();


  }
  //---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------#######################
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;      
    }
    
  }

  validateEventTimesChanged = (
    //não permiti colocar 2 eventos no mesmo horario
    { event, newStart, newEnd, allDay }: CalendarEventTimesChangedEvent,
    addCssClass = true
  ) => {
    if (event.allDay) {
      return true;
    }

    delete event.cssClass;
    // don't allow dragging or resizing events to different days
    const sameDay = isSameDay(newStart, newEnd);

    if (!sameDay) {
      return false;
    }

    // don't allow dragging events to the same times as other events
    const overlappingEvent = this.events.find((otherEvent) => {
      return (
        otherEvent !== event &&
        !otherEvent.allDay &&
        ((otherEvent.start < newStart && newStart < otherEvent.end) ||
          (otherEvent.start < newEnd && newStart < otherEvent.end))
      );
    });

    if (overlappingEvent) {
      if (addCssClass) {
        event.cssClass = 'invalid-position';
      } else {
        return false;
      }
    }

    return true;
  };

  eventTimesChanged(
    //atualiza datas ao mover os eventos  etc
    eventTimesChangedEvent: CalendarEventTimesChangedEvent
  ): void {
    delete eventTimesChangedEvent.event.cssClass;
    if (this.validateEventTimesChanged(eventTimesChangedEvent, false)) {
      const { event, newStart, newEnd } = eventTimesChangedEvent;
      event.start = newStart;
      event.end = newEnd;
      this.refresh.next();
    }
  }

  // handleEvent(action: string, event: CalendarEvent): void {
  //   this.modalData = { event, action };
  //   return console.log(event, action);
  // }

  handleEvent({ event }: { event: CalendarEvent }): void {
    this.modalData = { action: 'Click', event };
    this.VerDetalhesModalCard(event.start, event.end, event.meta);
    //--------------------------------------------------------------------------------------------------------------------Dados enviados para o formulario de novo agendamento
    this.dataInicial_draged = event.start;
    this.horaInicial_draged = event.start;
    this.horaFinal_draged = event.end;
    //----------------------------------------------------------------------------------------------------------------------##################################################
    console.log('Cliked', event);
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  // setView(view: CalendarView) {
  //   this.view = view;
  // }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  async VerDetalhesModalCard(start, end, meta) {
    const modal = await this.modalCtrl.create({
      component: CardAgendamentoDiaPage,
      cssClass: 'modalCard-pet-agendamento',
      componentProps: {
        horaInicial: start,
        horaFinal: end,
        metaDados: meta,
      },
    });

    modal.onDidDismiss().then(() => {     
      //this.clickSegmentButton(this.selectedTabs);
      const interval = setInterval(()=>{
        this.refreshEvents$.next(true);
        this.refresh.next();
        this.cdr.detectChanges();
      },500)
      setTimeout(()=>{
        clearInterval(interval);
      },3000)
      console.log('EVENTO ALTERADO')
      // this.resizeEventDetails();
    });
    await modal.present();
  }

  // async onIncluirAgendamento() { /// ---------------------------------INcluir agendamento pelo Botão de ação
  //   const modal = await this.modalCtrl.create({
  //     component: IncluirAgendamentoPage,
  //     cssClass: "modal-incluir-agendamento",
  //     componentProps: {
  //       mode: 'insert'
  //     }
  //   })
  //   await modal.present()
  // }



  async onIncluirAgendamento(event:Event = null) {

    if(event){

      console.log('CLASSNAMES', (event.target as Element).className);

    let className = (event.target as Element).className;

    if(className.includes('cal-day-hour-disabled')){
      return
    }

    }    

    console.log('DATA PASSADA AO MODAL', this.selectedDateTime);

    if (this.route.snapshot.paramMap.get('id') || localStorage.getItem('_pId')) {
      const modal = await this.modalCtrl.create({
        component: IncluirAgendamentoPage,
        cssClass: 'modal-incluir-agendamento',
        componentProps: {
          mode: 'insert',
          idProfissional: this.route.snapshot.paramMap.get('id') || localStorage.getItem('_pId'),
          //selectedDatetime: this.selectedDateTime,
        },
      });

      modal.onDidDismiss().then((NovoAgendamento_Object) => {       
        this.refreshEvents$.next(true);
        this.cdr.detectChanges();

      });
      await modal.present();
    } else if(this.selectedDateTime && this.profissional_select){
      const modal = await this.modalCtrl.create({
        component: IncluirAgendamentoPage,
        cssClass: 'modal-incluir-agendamento',
        componentProps: {
          mode: 'insert',
          selectedDatetime: this.selectedDateTime,
          profissionalId: this.profissional_select,
          speciality:this.speciality_select,
          selectedHour:(this.selectedDateTime.getHours().toString()).padStart(2,'0') + ':' + (this.selectedDateTime.getMinutes().toString()).padEnd(2,'0') + ':00'          
        },
      });

      modal.onDidDismiss().then((NovoAgendamento_Object) => {  
        console.log('AGENDAMENTO PROFISSIONAL');      
        this.handleAgendamentosFilter(this.profissional_select);
        this.getProfissionalName(this.profissional_select);
        this.refreshEvents$.next(true);
        this.cdr.detectChanges();
        // this.resizeEventDetails();
      });
      await modal.present();
    }else{
      const modal = await this.modalCtrl.create({
        component: IncluirAgendamentoPage,
        cssClass: 'modal-incluir-agendamento',
        componentProps: {
          mode: 'insert',                    
        },
      });

      modal.onDidDismiss().then((NovoAgendamento_Object) => {
        this.refreshEvents$.next(true);
        this.cdr.detectChanges();
        // this.resizeEventDetails();
      });
      await modal.present();
    }
  }

  deletarAgendamento(id_agendamento) {
    swal
      .fire({
        title: 'Você deseja remover este Agendamento?',
        showCancelButton: true,
        confirmButtonText: 'Remover',
        customClass: 'removeProfAlertClass',
        heightAuto: false,
        cancelButtonText: 'Cancelar',
        confirmButtonColor: 'var(--ion-color-danger)',
      })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.agendaService.removeAgendamento(id_agendamento).subscribe(
            () => {
              this.refreshEvents$.next(true);
              this.cdr.detectChanges();
              // this.refreshResizableCreator();
            },
            (error) => {
              console.error(error);
            }
          );
        }
      });
  }

  filtroEspProfHor() {
    let color_class;

    return this.subs.add(
      this.agendaService
        .getAgendamentosByNegocio(this.perfil_negocio)
        .subscribe(
          (result) => {
            this.filtroEspProfHor_array = result?.data
              ?.map((dados) => {
                const {
                  data,
                  horario_inicial,
                  horario_final,
                  pet,
                  profissional,
                  tutors,
                } = dados?.attributes;
                const [horaInicial, minutesInicial] =
                  this.utilsService.extractHoursElements_Array(horario_inicial);
                const [horaFinal, minutesFinal] =
                  this.utilsService.extractHoursElements_Array(horario_final);
                const petObject = pet?.data;
                const petDateOfBirth = petObject?.attributes?.data_nascimento;
                const petAge = petDateOfBirth
                  ? Math.floor(
                    Math.floor(
                      (new Date().getTime() -
                        new Date(petDateOfBirth).getTime()) /
                      1000 /
                      60 /
                      60 /
                      24 /
                      365
                    )
                  )
                  : '';

                let status = dados?.attributes?.status;

                switch (status) {
                  case 'confirmado':
                    color_class = 'confirmado';
                    break;
                  case 'agendado':
                    color_class = 'agendado';
                    break;
                  case 'aguardandoAtendimento':
                    color_class = 'completo';
                    break;
                  case 'Ncompareceu':
                    color_class = 'noshow';
                    break;
                  case 'pacienteAtendido':
                    color_class = 'atendido';
                    break;
                  case 'pacientAtrasado':
                    color_class = 'atrasado';
                    break;
                  case 'cancelado':
                    color_class = 'cancelado';
                    break;
                }

                return {
                  color: { ...colors.yellow },
                  actions: this.actions,
                  cssClass1: `${color_class}`,
                  cssClass: `${color_class}`,
                  draggable: false,
                  start: addMinutes(
                    addHours(
                      startOfDay(parse(data, 'yyyy-MM-dd', new Date())),
                      horaInicial
                    ),
                    minutesInicial
                  ),
                  end: addMinutes(
                    addHours(
                      startOfDay(parse(data, 'yyyy-MM-dd', new Date())),
                      horaFinal
                    ),
                    minutesFinal
                  ),
                  title: '',
                  resizable: { beforeStart: false, afterEnd: false },
                  meta: {
                    idAgendamento: dados?.id,
                    tutorObject: tutors?.data[0],
                    tutor: tutors?.data[0]?.attributes?.dados_pessoais?.nome,
                    profissional:
                      profissional?.data?.attributes?.dados_pessoais?.nome,
                    profissionalID: profissional?.data?.id,
                    email: tutors?.data[0]?.attributes?.dados_pessoais?.email,
                    celular:
                      tutors?.data[0]?.attributes?.dados_pessoais?.celular,

                    canalAgendamento: dados?.attributes?.canal_agendamento,
                    id_usuario_agendamento:
                      dados?.attributes?.id_usuario_agendamento,
                    tipo_atendimento: dados?.attributes?.tipo_atendimento,
                    tipo_agendamento: dados?.attributes?.tipo_agendamento,
                    agendadoPor: dados?.attributes?.agendadoPor,
                    especialidade: dados?.attributes?.especialidade,
                    faltas: dados?.attributes?.faltas,
                    statusAgendamento: dados?.attributes?.status,
                    dataMaximaRetorno: dados?.attributes?.dataMaximaRetorno,
                    dataMaximaDesconto: dados?.attributes?.dataMaximaDesconto,
                    valorDesconto: dados?.attributes?.valorDesconto,
                    createdAt: new Date(dados?.attributes?.publishedAt),
                    updatedAt: new Date(dados?.attributes?.updatedAt),

                    pet: petObject?.attributes?.nome,
                    petId: petObject?.id,
                    especie: petObject?.attributes?.especie,
                    idade: petAge,
                    peso: petObject?.attributes?.peso,
                    ultima_consulta: '05/12/2022',
                    data: data,
                    dataString: data,
                  },
                };
              })
              .filter((event) => {
                let isStartValid = false;
                let isEspecialidadeValid =
                  !this.filtroEspecialidade ||
                  event.meta.especialidade == this.filtroEspecialidade;
                let isProfissionalValid =
                  !this.filtroProfissional ||
                  event.meta.profissional == this.filtroProfissional;

                if (!this.filtroHorario || this.filtroHorario == 'todos') {
                  isStartValid = true;
                } else {
                  const start = new Date(event.start);
                  const hour = start.getHours();

                  console.log(start, hour);
                  if (
                    this.filtroHorario == 'manha' &&
                    hour >= 1 &&
                    hour <= 11
                  ) {
                    console.log(start, hour);
                    isStartValid = true;
                  } else if (
                    this.filtroHorario == 'tarde' &&
                    hour >= 12 &&
                    hour <= 18
                  ) {
                    isStartValid = true;
                  } else if (
                    this.filtroHorario == 'noite' &&
                    hour >= 19 &&
                    hour <= 23
                  ) {
                    isStartValid = true;
                  }
                }

                this.filtroAtivo = true;
                console.log(this.filtroAtivo);
                this.searchTerm = false;

                return (
                  isEspecialidadeValid && isProfissionalValid && isStartValid
                );
              });

            this.dismissPopover();
          },
          (error) => {
            console.error(error);
          }
        )
    );
  }

  async dismissPopover() {
    await this.popoverController.dismiss().then((data) => {
      (this.filtroAtivo = true),
        (this.searchTerm = false),
        console.log('dismiss');
    });
  }

  async dismissSpecialityPopover(){    

    await this.popoverController.dismiss();
  }

  limparfiltroEspProfHor() {
    this.filtroHorario = null;
    this.filtroEspecialidade = null;
    this.filtroProfissional = null;
    this.filtroStatus = null;
    this.searchTerm = null;
    this.filtroAtivo = false;
  }

  limparfiltroEspProfHor1() {
    this.filtroHorario = null;
    this.filtroEspecialidade = null;
    this.filtroProfissional = null;
    this.filtroAtivo = false;

    this.dismissPopover();
  }

  limparfiltroStatus() {
    this.filtroStatus = null;

    this.dismissPopover();
  }

  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {    
    renderEvent.body.forEach((day) => {
      const dayOfWeek = this.obterDiaSemana(day.date);
      let aviso_min;

      if(this.atendenceDays.includes('Todos os dias')){
        this.atendenceDays = ['Dom','Seg','Ter','Qua','Qui','Sex','Sáb'];
      }

      switch(this.tempoAvisoMin){

        case 'tempo_1h':
          aviso_min = 1;                    
          break;
        case 'tempo_6h':
          aviso_min = 6;
          break;
        case 'tempo_12h':
          aviso_min = 12;
          break;
        case 'tempo_18h':
          aviso_min = 18;
          break;
        case 'tempo_24h':
          aviso_min = 24;
        break;
        case 'tempo_48h':
          aviso_min = 48;
        break;
        default:
          aviso_min = 0;
      }

      let dateNow = new Date(new Date().toDateString());
      let dateWithAvisoMin = addHours(new Date(), aviso_min).toISOString();
      let differenceHours = differenceInHours(new Date(day.date.toDateString()), dateNow);

      console.log('DIA CALENDARIO', new Date(day.date.toDateString()));
      console.log('DIA ATUAL', dateNow);
      console.log('AVISO MINIMO', aviso_min);
      

      console.log('ATENDENCE DAYS',this.atendenceDays);
      console.log('DIFERENÇA EM HORAS', differenceHours)

      if(this.atendenceDays.length > 0 && !this.atendenceDays.includes(dayOfWeek) || dateNow > new Date(day.date.toDateString()) || differenceHours < aviso_min){
        day.cssClass = 'cal-day-segment-disabled';
        console.log('DISABLED')
      }

      console.log(dayOfWeek);

      // if(day.isWeekend)
      // {
      //   day.cssClass = "text-red";
      // }

    });
  }

  beforeDayViewRender(renderEvent: CalendarDayViewBeforeRenderEvent): void { 

    //console.log('DAY VIEW RENDER', renderEvent);
    console.log('BLOQUEIOS PROFISSIONAL', this.bloqueiosProfissional);

    let nowTime = new Date();

    renderEvent.hourColumns[0].hours.forEach((hour) => {
      hour.segments.forEach((segment)=>{       
        if(this.verificarDataNoIntervalo(this.bloqueiosProfissional,(segment.date).toISOString(),this.perfil_negocio.toString()) || new Date(segment.date.toISOString()) < nowTime || (this.verificarHorario(this.janelasProfissional, (segment.date).toISOString(), this.perfil_negocio.toString()) === false && this.janelasProfissional.length > 0)){
          segment.cssClass = 'cal-day-hour-disabled';          
          //console.log('DISABLED HOUR')
        }
      });   

      // if(day.isWeekend)
      // {
      //   day.cssClass = "text-red";
      // }

    });
  }

  beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent): void { 

    //console.log('WEEK VIEW RENDER', renderEvent);
    let nowTime = new Date();  

    renderEvent.hourColumns.forEach((hourColumns) => {
      hourColumns.hours.forEach((hour)=>{

        hour.segments.forEach((segment)=>{       
          //console.log('SEGMENT WEEK', segment)
          //console.log('WEEK SEGMENT DATA',segment.date)
          
          if(this.verificarDataNoIntervalo(this.bloqueiosProfissional,(segment.date).toISOString(),this.perfil_negocio.toString()) || new Date(segment.date.toISOString()) < nowTime || (this.verificarHorario(this.janelasProfissional, (segment.date).toISOString(), this.perfil_negocio.toString()) === false && this.janelasProfissional.length > 0)){
            segment.cssClass = 'cal-day-hour-disabled';
            //console.log('DISABLED HOUR WEEK',this.janelasProfissional)
          }
        }); 

      });
    });
  }

  obterDiaSemana(data) {
    const diasSemana = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
    const dataObj = new Date(data);
    const diaSemana = dataObj.getDay();
    return diasSemana[diaSemana];
  }

  obterDiasUnicos(array) {
    const diasSet = new Set();

    array.forEach(objeto => {
      if(objeto?.perfil_negocio === this.perfil_negocio.toString()){
        objeto.dias_disponiveis.forEach(dia => {
          diasSet.add(dia);
        });
      }        
    });

    return Array.from(diasSet);
}

encontrarMenorDuracaoAtendimento(profissionais) {
  return profissionais.map(profissional => {
      const duracoes = profissional.attributes.programacao.janela_disponibilidade.filter((janela)=>janela.perfil_negocio === this.perfil_negocio.toString()).flatMap(janela => 
          janela.duracao_TempoAtendimento.map(this.converterParaMinutos)
      );
      const menorDuracao = Math.min(...duracoes);
      console.log('MENOR DURAÇÃO',menorDuracao);
      return menorDuracao;
  });
}

converterParaMinutos(duracao) { 
  let duracaoTime = duracao.replace('h', ' h');

  console.log('duração', duracaoTime);

  const [valor, unidade] = duracaoTime.split(' ');
  if (unidade === 'min') {
      return parseInt(valor);
  } else if (unidade === 'h') {
      return parseInt(valor) * 60;
  } else if (unidade === 'h' && valor.includes('h')) {
      const [horas, minutos] = valor.split('h');
      return parseInt(horas) * 60 + parseInt(minutos);
  }
  return 0;
}

verificarDataNoIntervalo(array, data, perfilNegocio) {
  // Converte a data recebida para um objeto Date
  const dataVerificada = new Date(data);

  // Filtra os objetos do array que possuem o perfil_negocio desejado
  const objetosFiltrados = array.filter(obj => obj.perfil_negocio === perfilNegocio);

  // Itera sobre os objetos filtrados e verifica se a data está no intervalo
  for (let obj of objetosFiltrados) {
      const dataInicial = new Date(obj.data_hora_inicial);
      const dataFinal = new Date(obj.data_hora_final);

      if (dataVerificada >= dataInicial && dataVerificada <= dataFinal) {
          return true; // A data está dentro do intervalo
      }
  }

  return false; // A data não está dentro de nenhum intervalo
}

verificarHorario(array, data, perfilNegocio) {

  //console.log('JANELAS DE DISPONIBILIDADE', data)

  // Converte a data recebida para um objeto Date
  const dataVerificada = new Date(data);
  
  // Obtém o dia da semana como uma string curta (ex: "Mon", "Tue", etc.)
  const diasDaSemana = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
  const diaSemana = diasDaSemana[dataVerificada.getUTCDay()];  

  //console.log('DATA VERIFICADA WEEK',diaSemana)

  // Filtra os objetos do array que possuem o perfil_negocio desejado
  const objetosFiltrados = array.filter(obj => obj.perfil_negocio === perfilNegocio);

  //console.log('JANELAS DE DISPONIBILIDADE FILTRADOS', objetosFiltrados)

  // Itera sobre os objetos filtrados e verifica se a data está no intervalo e no dia da semana
  for (let obj of objetosFiltrados) {

      let horario_inicial = data.split('T',1)[0] + 'T' + obj.horario_inicial.split('T',2)[1];
      let horario_final = data.split('T',1)[0] + 'T' + obj.horario_final.split('T',2)[1];

      //console.log('INICIAL FINAL OBJ', horario_inicial, horario_final, obj)

      const horarioInicial = new Date(horario_inicial);
      const horarioFinal = new Date(horario_final);

      if(obj.dias_disponiveis.includes('Todos os dias')){
        obj.dias_disponiveis = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
      }

      if (dataVerificada >= horarioInicial && dataVerificada <= horarioFinal && obj.dias_disponiveis.includes(diaSemana)) {
          return true; // A data está dentro do intervalo e no dia da semana correto
      }
  }

  return false; // A data não está dentro de nenhum intervalo ou dia da semana
}


  





  ///---------------Reutilizar depois- modal de novo evento a partir do arrastar do mouse

  // async onIncluirAgendamento_DragEvent() {///---------------------------------- INcluir agendamento via arrastar
  //   const modal = await this.modalCtrl.create({
  //     component: IncluirAgendamentoPage,
  //     cssClass: "modal-incluir-agendamento",
  //     componentProps: {
  //       mode: 'insert-draged',
  //       dataInicial_draged: this.dataInicial_draged,
  //       horaInicial_draged: this.horaInicial_draged,
  //       horaFinal_draged: this.horaFinal_draged,
  //     }
  //   });

  //   modal.onDidDismiss().then((NovoAgendamento_Object) => {

  //     this.events.splice(this.events.length - 1, 1); // deleta o template criado ao arrastar pois depois esse evento é adcionado ao array
  //     this.refreshResizableCreator();

  //     if (NovoAgendamento_Object.data === undefined) {
  //       console.log(NovoAgendamento_Object, 'CRIAR REGRA PARA OBJETO UNDEFINED')
  //     } else {
  //       this.Novo_AgendamentosObject_fromModal = NovoAgendamento_Object.data;

  //       this.NovoAgendamento_ToEvents = this.Novo_AgendamentosObject_fromModal.data.Novo_AgendamentosObject;
  //       //console.log(`Novo_AgendamentosObject mudou de  ${change.previousValue} para ${change}`);
  //       if (this.NovoAgendamento_ToEvents != undefined) {

  //         this.refreshResizableCreator();
  //         this.addEvent(this.NovoAgendamento_ToEvents);
  //       }

  //     }
  //   });
  //   await modal.present()
  // }

  // addEvent(NovoAgendamento) {

  //   console.log(NovoAgendamento);

  //   let newEvent = {
  //     title: 'New event',
  //     start: addMinutes(addHours(startOfDay(new Date(NovoAgendamento?.data)), NovoAgendamento?.horario_inicial.hours), NovoAgendamento?.horario_inicial.minutes),
  //     end: addMinutes(addHours(startOfDay(new Date(NovoAgendamento?.data)), NovoAgendamento?.horario_final.hours), NovoAgendamento?.horario_final.minutes),
  //     color: { ...colors.yellow },
  //     draggable: true,
  //     meta: {
  //       tutorObject: NovoAgendamento?.tutor,
  //       tutor: NovoAgendamento?.tutor.dados_pessoais.nome,
  //       email: NovoAgendamento?.tutor.dados_pessoais.email,
  //       celular: NovoAgendamento?.tutor.dados_pessoais.celular,

  //       profissionalObjecto: NovoAgendamento?.profissionalObject,
  //       profissional: NovoAgendamento?.profissional,
  //       profissionalID: NovoAgendamento?.profissionalID,
  //       especialidade: 'Cilinico Geral',

  //       petObject: NovoAgendamento?.petObject,
  //       pet: NovoAgendamento?.pet,
  //       especie: NovoAgendamento?.pet?.especie,
  //       idade: NovoAgendamento?.pet?.idade,
  //       peso: NovoAgendamento?.pet?.peso,

  //       data: NovoAgendamento?.data,
  //       ultima_consulta: '05/12/2022',
  //       faltas: 2,

  //       canalAgendamento: NovoAgendamento?.canal_agendamento,
  //       id_usuario_agendamento: NovoAgendamento?.id_usuario_agendamento,
  //       agendadoPor: 'Olivia Freitas',
  //       statusAgendamento: 'Novo agendamento',
  //     },
  //     actions: this.actions,
  //     resizable: {
  //       beforeStart: true,
  //       afterEnd: true,
  //     },
  //   }

  //   this.utilsService.addEvent(newEvent);
  //   this.refeshEvents$.next(true);

  //   // this.events = [
  //   //   ...this.events,
  //   //   {
  //   //     title: 'New event',
  //   //     start: addMinutes(addHours(startOfDay(new Date(NovoAgendamento?.data)), NovoAgendamento?.horario_inicial.hours), NovoAgendamento?.horario_inicial.minutes),
  //   //     end: addMinutes(addHours(startOfDay(new Date(NovoAgendamento?.data)), NovoAgendamento?.horario_final.hours), NovoAgendamento?.horario_final.minutes),
  //   //     color: { ...colors.yellow },
  //   //     draggable: true,
  //   //     meta: {
  //   //       tutorObject: NovoAgendamento?.tutor,
  //   //       tutor: NovoAgendamento?.tutor.dados_pessoais.nome,
  //   //       email: NovoAgendamento?.tutor.dados_pessoais.email,
  //   //       celular: NovoAgendamento?.tutor.dados_pessoais.celular,

  //   //       profissional: NovoAgendamento?.profissional,
  //   //       especialidade: 'Cilinico Geral',

  //   //       petObject: NovoAgendamento?.petObject,
  //   //       pet: NovoAgendamento?.pet,
  //   //       especie: 'gato',
  //   //       idade: 1,
  //   //       peso: 10,

  //   //       data: NovoAgendamento?.data.toLocaleDateString(),
  //   //       ultima_consulta: '05/12/2022',
  //   //       faltas: 2,

  //   //       canalAgendamento: 'Telefone',
  //   //       agendadoPor: 'Olivia Freitas',
  //   //       statusAgendamento: 'Confirmado',
  //   //     },
  //   //     actions: this.actions,
  //   //     resizable: {
  //   //       beforeStart: true,
  //   //       afterEnd: true,
  //   //     },
  //   //   },
  //   // ];

  //   // console.log(this.events);
  //   return this.refreshResizableCreator();

  // }

  onDayClick(date:any) {

    this.selectedDateTime = date;

    const newEvent = {
      start: date,
      end: new Date(date.getTime() + 60 * 60 * 1000), // Duração de uma hora
      title: 'Novo Evento'
    };
    
    console.log('EVENTO A SER ADICIONADO', event);
  }

  getSpecialitiesByProfile(){

    this.especialidadesPerfil = [];    
    let perfil_id = this.userPersonalData?.perfil_negocios[this.selectedBuzzIndex]?.id;

    console.log('ESPECIALIDADES perfil id', perfil_id)

    function ordenarArrayAlfabeticamente(array) {
      return array.sort((a, b) => a.localeCompare(b));
    }

    
    
    let specialities$ = this.agendaService.getSpecialitiesByProfile(perfil_id).subscribe({
      next:(result)=>{

        console.log('ESPECIALIDES NA FUNÇÃO', result)

        for(let profissional of result.data){

          for(let janela of profissional.attributes.programacao.janela_disponibilidade){

            for(let especialidade of janela.especialidades){

              let index = this.especialidadesPerfil?.indexOf(especialidade);
              if(index === -1 && janela.perfil_negocio === perfil_id.toString()){
                this.especialidadesPerfil.push(especialidade);
              }                                      
            }            
          }

        }

        this.especialidadesPerfil = ordenarArrayAlfabeticamente(this.especialidadesPerfil);

        console.log('LISTA ESPECIALIDADES', this.especialidadesPerfil);

      },
      error:(e)=>{},
      complete:()=>{
        specialities$.unsubscribe();
      }
    })

  }

  clickSegmentButton(segmentId: string): void {
    const segmentButton = document.querySelector(`ion-segment-button[id='${segmentId}']`) as HTMLElement;
    if (segmentButton) {
      segmentButton.click();
    } else {
      console.error(`Segment button with id '${segmentId}' not found`);
    }
  }

}
